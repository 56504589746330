<template>
    <button @click="action" class="custom-publish-button" :style="style()" :disabled="disabled">
        <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="width" viewBox="0 0 24 24"><path fill="currentColor" d="M11 20v-8.15l-2.6 2.6L7 13l5-5l5 5l-1.4 1.45l-2.6-2.6V20h-2ZM4 9V6q0-.825.588-1.412T6 4h12q.825 0 1.413.588T20 6v3h-2V6H6v3H4Z"/></svg>
    </button>
</template>
<script setup>
    import { createDarkerColorFromHexOrRgb } from '@/tools/colorTools';
    import styles from '@/assets/scss/exports/main-variables.module.scss';
  
    // PROPS
    const props = defineProps({
        isOpen: {
            type: Boolean,
            required: false
        },
        width: {
            type: [ Number, String ],
            required: false,
        },
        color: {
            type: String,
            required: false,
        },
        action: {
            type: Function,
            required: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    });
    const style = () => {
        const { width, color } = props;
        const style = {
            'width': width || '32px',
            'height': width || '32px',
            'color': color || styles.mainColor,
            '--hover-color': createDarkerColorFromHexOrRgb(color || styles.mainColor, 30),
        };
        return style;
    };
</script>
<style lang="scss" scoped>
    :root {
        --hover-color: #defaultColor;
    }
    .custom-publish-button {
        border: none;
        padding: 0;
        width: fit-content;
        background-color: transparent;
        border-radius: 100%;
        
        :hover {
            color: var(--hover-color);
        }
        &:disabled {
            color: $light-grey-1 !important;
            pointer-events: none;
        }
    }
</style>