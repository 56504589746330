<template>
    <button :style="buttonStyle" class="custom-classic-button" :disabled="loading">
        <div v-show="!hideSlot" class="custom-classic-button__wrapper">
            <slot></slot>
        </div>
        <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </button>
</template>
<script setup>
import buttonsStyle from '@/assets/scss/exports/buttons-variables.module.scss';
import { createDarkerColorFromHexOrRgb } from '@/tools/colorTools.js';
import { computed, watch, ref } from 'vue';
const props = defineProps({
    loading: {
        type: Boolean,
        required: false,
        default: false
    },
    buttonStyle: {
        type: Object,
        required: false,
    },
    color: {
        type: String,
        required: false,
    },
    hideSlotInLoading: {
        type: Boolean,
        default: false
    }
});
const hideSlot = ref(false);
const buttonStyle = computed(() => {
    const color = props.color;
    const style =  {
        ...props.buttonStyle,
        'background-color': color ? color : buttonsStyle.grey1Color,
        'color': color ? createDarkerColorFromHexOrRgb(color, 60) : createDarkerColorFromHexOrRgb(buttonsStyle.grey1Color, 60),
    };
    return style;
});
watch(() => props.loading, (newValue) => {
    if (newValue === true && props.hideSlotInLoading === true) {
        hideSlot.value = true;
    } else {
        hideSlot.value = false;
    }
});
</script>
<style lang="scss" scoped>
.custom-classic-button {
    display: flex;
    min-width: fit-content;
    align-items: center;
    padding: 0.3rem 0.5rem;
    border-radius: 0.4rem;
    border: none;
    font-size: 1rem;

    &__wrapper {
        display: flex;
        height: 100%;
        align-items: center;
    }
}
</style>