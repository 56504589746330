<template>
    <div id="chart-container">
        <VueApexCharts id="chart" :options="options" :series="series" type="radialBar" height="120"/>
    </div>
</template>
<script setup>
    import { ref } from 'vue';
    import VueApexCharts from 'vue3-apexcharts';
    const props = defineProps({
        value: {
            type: String,
            required: true,
        },
    });

    const getColor = (value) => {
        if (value < 40) {
            return '#94E4AF';
        } else if (value < 60) {
            return '#fbcf33';
        } else {
            return '#ea0606';
        }
    };

    const options = ref({
        chart: {
            height: 130,
        },
        colors: [`${getColor(props.value)}`],
        plotOptions: {
            radialBar: {
                startAngle: -100,
                endAngle: 100,
              
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        show: true,
                        fontSize: '16px',
                        offsetY: 6,
                    },
                },
            },
        },
    });
    const series = ref([props.value]);
   
    
</script>
<style scoped>
    #chart-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
    #chart {
        text-align: center;
    }
</style>