<template>
    <div @click="handleClick" class="side-nav-item" :class="{ 'closed': openStatus === 0, 'active-link': isActive }" role="button">
        <div class="side-nav-item__icon tiny-item-card" :class="{ 'active-link': isActive }">
            <div v-if="notificationsRef" class="side-nav-item__icon__notification">{{ notificationsRef }}</div>
            <component :is="iconsComponents[item.icon]" />
        </div>
        <div v-if="openStatus === 1" class="side-nav-item__label">
            <p :class="{ 'active-link': isActive }">{{ item.label }}</p>
        </div>
            <div v-if="openStatus === 1 && item.hasSubItems" class="side-nav-item__open-marker" :class="{ 'opened': isSubListOpen }">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12 14.95q-.2 0-.375-.062t-.325-.213l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213T12 14.95"/>
                </svg>
            </div>
    </div>
    <Transition name="expanded">
        <ul v-if="item.hasSubItems && openStatus === 1 && isSubListOpen">
            <SideNavSubItem 
                v-for="(subItem, index) in item.subItems"
                :key="index"
                :item="subItem"
            />
        </ul>
    </Transition>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SideNavSubItem from './components/SideNavSubItem.vue';
import { openChat } from '@/services/intercomService.js';
import DashboardIcon from './icons/DashboardIcon.vue';
import ArticlesIcon from './icons/ArticlesIcon.vue';
import SocialNetworkIcon from './icons/SocialNetworkIcon.vue';
import LinksIcon from './icons/LinksIcon.vue';
import InternalOptimizationIcon from './icons/InternalOptimizationIcon.vue';
import NaturalSeoIcon from './icons/NaturalSeoIcon.vue';
import SeoIcon from './icons/SeoIcon.vue';
import ChatIcon from './icons/ChatIcon.vue';
import AccountIcon from './icons/AccountIcon.vue';
import ArticlesWritingIcon from './icons/ArticlesWritingIcon.vue';
import HistoryIcon from './icons/HistoryIcon.vue';
import CreateBriefIcon from './icons/CreateBriefIcon.vue';
import PublishArticleIcon from './icons/PublishArticleIcon.vue';
import CustomersFilesIcon from './icons/CustomersFilesIcon.vue';
import ArticlesBriefsIcon from './icons/ArticlesBriefsIcon.vue';
import ArticlesValidationIcon from './icons/ArticlesValidationIcon.vue';
import ArticlesCorrectionIcon from './icons/ArticlesCorrectionIcon.vue';
import AddArticlesIcon from './icons/AddArticlesIcon.vue';
import WritingsMonitoringIcon from './icons/WritingsMonitoringIcon.vue';
import UsersManagementIcon from './icons/UsersManagementIcon.vue';

const route = useRoute();
const router = useRouter();
const props = defineProps({
    item: {
        type: Object,
        required: true
    },
    openStatus: {
        type: Number,
        required: true
    },
    notifications: {
        type: Object,
        required: false,
    }
});

const isSubListOpen = ref(false);
const iconsComponents = {
    'dashboard': DashboardIcon,
    'articles': ArticlesIcon,
    'social-network': SocialNetworkIcon,
    'links': LinksIcon,
    'internal-optimization': InternalOptimizationIcon,
    'natural-seo': NaturalSeoIcon,
    'seo': SeoIcon,
    'chat': ChatIcon,
    'account': AccountIcon,
    'articles-writing': ArticlesWritingIcon,
    'history': HistoryIcon,
    'create-brief': CreateBriefIcon,
    'publish-articles': PublishArticleIcon,
    'customers-files': CustomersFilesIcon,
    'articles-briefs': ArticlesBriefsIcon,
    'articles-validation': ArticlesValidationIcon,
    'articles-correction': ArticlesCorrectionIcon,
    'add-articles': AddArticlesIcon,
    'writings-monitoring': WritingsMonitoringIcon,
    'users-management': UsersManagementIcon
};
const functions = {
    'openChat': openChat
};

const isActive = computed(() => {
    const itemRoute = props.item?.route;
    const hasSubRoutes = props.item?.hasSubItems || false;
    if (hasSubRoutes) {
        return route.path.includes(itemRoute);
    }
    return props.item.route === route.path;
});
const notificationsRef = computed(() => {
    const userNotifications = props.notifications;
    const routeNotifications = props.item.notifications;
    if (userNotifications && routeNotifications) {;
        return userNotifications[routeNotifications];
    }
});
const handleClick = () => {
    const item = props.item;
    if (item.hasSubItems) {
        toggleSublistOpenStatus();
    } 
    else if (item.hasFunction) {
        execFunction();
    }
    else {
        router.push(item.route);
    }
};
const toggleSublistOpenStatus = () => {
    if (!props.item.hasSubItems) return;
    isSubListOpen.value = !isSubListOpen.value;
};
const execFunction = () => {
    if (props.item.hasFunction) {
        functions[props.item.function]();
    }
};
</script>
<style lang="scss" scoped>
.side-nav-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 0.3rem;
    gap: 0.3rem;

    &:hover {
        color: $primary-blue-color;
    }
    &.closed {
       justify-content: center;
    }
    &.active-link {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        border-radius: 0.4rem;
    }

    &__icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary-background-color;
        padding: 0.4rem;

        svg, img {
            width: 18px;
            height: 18px;
        }
        &.active-link {
            background-color: $primary-color;

            svg {
                color: #fff;
            }
        }

        &__notification {
            width: 1rem;
            height: 1rem;
            font-size: 0.70rem;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -50%;
            right: -50%;
            transform: translate(-50%, 50%);
            background-color: red;
            color: #fff;
            border-radius: 50%;
            z-index: 1;
            box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
        } 
    }

    &__label {
        margin-left: 0.5rem;
        
        p {
            margin: 0;
            font-size: 0.9rem;
            font-weight: 500;

            &.active-link {
                font-weight: 600;
            }
        }
    }

    &__open-marker {
        align-self: center;
        margin-left: auto;
        font-weight: 700;
        transition: all 0.2s ease-out;
        color: $light-grey-1;

        &.opened {
            transform: rotate(180deg);
            color: $primary-blue-color;
        }
    }
}

    .expanded-enter-from {
        opacity: 0;
    }
    .expanded-enter-to {
        opacity: 1;
        bottom: 0;
    }
    .expanded-enter-active {
        transition: all 0.1s ease-in;
    }
    .expanded-leave-from {
        opacity: 1;
    }
    .expanded-leave-to {
        opacity: 0;
    }
    .expanded-leave-active {
        transition: all 0.1s ease-out;
    }

</style>