<template>
    <div class="datalist">
        <label v-if="label" class="datalist__label" for="customers">{{ label }} <span>{{ required ? '*' : null }}</span></label>
        <div class="datalist__wrapper">
            <input 
                @keyup="filterDataList"
                @focus="toggleList" 
                :style="setInputsStyle()" 
                type="text"
                :id="`${label}-input`" 
                class="datalist__wrapper__input form-control"
                v-model="inputValue"
                autocomplete="off"
            >
            <svg @click="resetData" class="datalist__wrapper__reset" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 15 15"><path fill="currentColor" fill-rule="evenodd" d="M11.782 4.032a.575.575 0 1 0-.813-.814L7.5 6.687L4.032 3.218a.575.575 0 0 0-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 0 0 .814.814L7.5 8.313l3.469 3.469a.575.575 0 0 0 .813-.814L8.313 7.5l3.469-3.468Z" clip-rule="evenodd"/></svg>
        </div>
        <select v-show="listOpened" :style="setInputsStyle()" :id="`${label}-list`" class="datalist__list form-control" multiple>
            <option 
                v-for="(data, index) in filteredDataList" 
                @click="setItem(data)" 
                :key="index" 
                :value="data.value"
            >
            {{ data.label }}
            </option>
        </select>
    </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, watch, defineEmits } from 'vue';
const emit = defineEmits(['valueChange']);
const props = defineProps({
    dataList: {
        type: Array,
        required: true
    },
    label: {
        type: String,
        required: false,
        // default: () => {
        //     let randomNumber = Math.floor(Math.random() * (9999 - 1000 + 1)) + min;
        //     return parseInt(randomNumber);
        // }
    },
    inputsStyle: {
        type: Object,
        required: false,
    },
    required: {
        type: Boolean,
        required: false,
        default: false
    }
});

const listOpened = ref(false);
const inputValue = ref('');
const selectedValue = ref({ value: '', label: '' });
const oldSelectedValue = ref({ value: '', label: '' });
const filteredDataList = ref([]);

const toggleList = (event) => {
    event.preventDefault();
    listOpened.value = !listOpened.value;
};
const setInputsStyle = () => {
    if (props.inputsStyle) {
        return props.inputsStyle;
    }
    return '';
};
const isSelectedItemExistInDataList = (itemData) => {
    return props.dataList.some((data) => {
        return data.value === itemData.value && data.label === itemData.label;
    });
};
const handleClickOutside = (event) => {
    const input = document.getElementById(`${props.label}-input`);
    const list = document.getElementById(`${props.label}-list`);
    if (input && list) {
        if (![input, ...list].includes(event.target)) {
            try {
                
                if (selectedValue.value.value === '' && selectedValue.value.label === '' ) {
                    inputValue.value = '';
                    return;
                }

                const isSelectedItemExist = isSelectedItemExistInDataList(selectedValue.value);

                if (!isSelectedItemExist) {
                    resetData();
                    return;
                }

                inputValue.value = JSON.parse(JSON.stringify(selectedValue.value.label));
        
                if (selectedValue.value.value !== oldSelectedValue.value.value) {
                    oldSelectedValue.value = JSON.parse(JSON.stringify(selectedValue.value));
                    emit('valueChange', selectedValue.value);
                }
                
            } finally {
                filterDataList();
                listOpened.value = false;
            }
        }
    }
};
const setItem = (itemData) => {
    inputValue.value = JSON.parse(JSON.stringify(itemData.label)); 
    selectedValue.value = JSON.parse(JSON.stringify(itemData));
};
const resetData = () => {
    inputValue.value = '';
    selectedValue.value = { value: '', label: '' };
    filterDataList();
    oldSelectedValue.value = { value: '', label: '' };
    emit('valueChange', selectedValue.value);
};
const filterDataList = () => {
    filteredDataList.value = props.dataList.filter((data) => {
        return data.label.toLowerCase().includes(inputValue.value.toLowerCase());
    });
};
onMounted(() => {
    document.addEventListener('click', handleClickOutside);
    filteredDataList.value = props.dataList;
});
onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>
<style lang="scss" scoped>
.datalist {
    display: flex;
    flex-direction: column;
    position: relative;

    &__wrapper {
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;

        &__input {
            border-radius: 0.5rem;
            font-size: 0.875rem;
        }

        &__reset {
            cursor: pointer;
            position: absolute;
            height: 90%;
            right: 0.4rem;
            background-color: white;
        }
    }
   
    &__list {
        position: absolute;
        width: 100%;
        top: 100%;
        z-index: 1000;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        border-radius: 0.6rem;
    }
}
</style>