<template>
    <div class="round-pagination">
        <div @click="handleClick(1)" class="round-pagination__circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m11 7l-5 5l5 5m6-10l-5 5l5 5"/>
            </svg>
        </div>
        <div @click="handleClick(currentPage - 1)" class="round-pagination__circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24">
                <path fill="currentColor" d="m10.8 12l3.9 3.9q.275.275.275.7t-.275.7t-.7.275t-.7-.275l-4.6-4.6q-.15-.15-.212-.325T8.425 12t.063-.375t.212-.325l4.6-4.6q.275-.275.7-.275t.7.275t.275.7t-.275.7z"/>
            </svg>
        </div>
        <div v-show="isNotHidden(index + 1)" @click="handleClick(index + 1)" v-for="(page, index) in availablePagesNb" :key="page" class="round-pagination__circle" :class="{ 'current': index + 1 == currentPage }">
            {{  index + 1 }}
        </div>
        <div @click="handleClick(currentPage + 1)" class="round-pagination__circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12.6 12L8.7 8.1q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l4.6 4.6q.15.15.213.325t.062.375t-.062.375t-.213.325l-4.6 4.6q-.275.275-.7.275t-.7-.275t-.275-.7t.275-.7z"/>
            </svg>
        </div>
        <div @click="handleClick(availablePagesNb)" class="round-pagination__circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 7l5 5l-5 5m6-10l5 5l-5 5"/>
            </svg>
        </div>
    </div>
</template>
<script setup>
import { defineEmits } from 'vue';

const emit = defineEmits(['pageRequest']);
const props = defineProps({
    availablePagesNb: {
        type: Number,
        required: true
    },
    currentPage: {
        type: Number,
        required: true
    }
});

const handleClick = (page) => {
    if (page < 1 || page > props.availablePagesNb) {
        return;
    }
    emit('pageRequest', page);
};

const isNotHidden = (page) => {
    const currentPage = props.currentPage;
    const range = 2;
    return page <= currentPage + range && page >= currentPage - range;
}
</script>
<style lang="scss" scoped>
.round-pagination {
    width: 100%;
    display: flex;
    gap: 0.3rem;
    justify-content: center;
    margin: 0.5rem 0;

    &__circle {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        color: $light-grey-2;
        font-size: 1rem;
        cursor: pointer;
        border: 1.5px solid $light-grey;

        &:hover {
            background-color: $primary-color;
            color: $light-grey;
        }

        &.current {
            background-color: $primary-color;
            color: $light-grey;
        }
    }
}
</style>