<template>
    <div class="row w-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Mes factures</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="card h-100 p-4">
                        <div class="table-responsive">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th width="70%" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Nom de la facture</th>
                                        <th width="20%" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date de mise en ligne</th>
                                        <th width="10%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="loading" style="width:100%;" class="text-center">
                                        <td colspan="4">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="err.status" style="width:100%;" class="text-center">
                                        <td colspan="4">
                                            {{ err.message }}
                                        </td>
                                    </tr>  
                                    <tr v-show="!loading && !err.status" v-for="(billing, index) in billingsList" :key="index">
                                        <td>{{ billing.name }}</td>
                                        <td class="text-center">{{ getDate(billing.createdAt) }}</td>
                                        <td class="text-right">
                                            <a :href="billing.link" target="_blank">
                                                <SoftButton>Voir la facture</SoftButton>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from "vue";
    import Cookies from "js-cookie";
    import SoftButton from "@/components/SoftButton.vue";

    // STATE
    const loading = ref(true);
    const err = ref({
        status: false,
        message: "Une erreur est survenue durant la récupération des factures..."
    });
    const billingsList = ref([]);
    // METHODS
    const getBillings = async () => {
        try {
            err.value.status = false;
            loading.value = true;

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/billings/get-all?customerRoute=true`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`,
                    "Content-Type": "application/json",
                }
            });

            const response = await promise.json();
            
            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }
            
            if (response.billings.length === 0) {
                err.value.message = "Vous n'avez pour le moment aucune facture...";
                err.value.status = true;
            }

            billingsList.value = response.billings;
        } catch (error) {
            
            if (error.status === 401 || 404 || 400) {
                err.value.message = error.error;
            }
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };
    const getDate = (date) => {
        return new Date(date).toLocaleDateString("FR-fr")
    };
    // LIFECYCLE HOOKS
    onMounted(() => {
        getBillings();
    });
</script>

<style scoped>
    .table-responsive {
        max-height: 80vh;
        overflow: auto;
    }
    thead {
        position: sticky;
        top: 0;
        background-color: white;
    }
</style>