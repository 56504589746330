<template>
    <ItemCardLayout :closeButton="true" class="publication-card-correction-request">
        <div class="publication-card-correction-request__wrapper">
            <div class="publication-card-correction-request__wrapper__correction">
                <h5>Demande de retouche</h5>
                <div class="publication-card-correction-request__wrapper__correction__editor">
                    <TextEditor @returnContent="setContentFromEditor" :placeholder="textEditorOptions.defaultHtml"/>
                </div>
                <div class="publication-card-correction-request__wrapper__correction__button">
                    <CustomClassicButton @click="sendCorrectionRequest">
                        Envoyer en retouche
                    </CustomClassicButton>
                    <EmbeddedError v-if="err.status" :message="err.message" />
                </div>
            </div>
            <!-- <div class="publication-card-correction-request__wrapper__publication">
                <h5>Publication</h5>
                <div class="publication-card-correction-request__wrapper__publication__left">
                    <div class="publication-card-correction-request__wrapper__publication__left__visual">
                        <div v-if="publication.images.length" class="publication-card-correction-request__wrapper__publication__left__visual__images">
                            <PublicationCardImages :images="publication.images" />
                        </div>
                        <div v-else-if="publication.video?.link" class="publication-card-correction-request__wrapper__publication__left__visual__video">
                            <PublicationCardVideo :video="publication.video" />
                        </div>
                    </div>
                </div>
                <div class="publication-card-correction-request__wrapper__publication__right">

                </div>
            </div> -->
        </div>
    </ItemCardLayout>
</template>
<script setup>
import CustomClassicButton from '@/components/standalone/customsButtons/CustomClassicButton.vue';
import { requestSocialNetworkPublicationCorrection } from '@/useCases/socialNetworkPublicationUseCases';
import EmbeddedError from '@/components/standalone/statusMessages/errors/EmbeddedError.vue';
import TextEditor from '@/components/standalone/textEditor/TextEditor/TextEditor.vue';
import ItemCardLayout from '@/components/layouts/cardsLayouts/ItemCardLayout.vue';
import PublicationCardContent from './PublicationCardContent.vue';
import PublicationCardImages from './PublicationCardImages.vue';
import { displayConfirmationMessage } from '@/tools/modalTools';
import PublicationCardVideo from './PublicationCardVideo.vue';
import { ref, defineEmits } from 'vue';

const props = defineProps({
    publication: {
        type: Object,
        required: true
    }
});
const emits = defineEmits([
    'correctionRequestSended'
]);
const err = ref({
    status: false,
    message: ''
});
const textEditorOptions = ref({
    defaultHtml: 'Décrivez votre demande de retouche ici...'
});
const correctionContent = ref('');
const setContentFromEditor = (content) => {
    correctionContent.value = content;
};
const sendCorrectionRequest = async() => {
    const correctionNotes = correctionContent.value;
    const publicationId = props.publication._id;
    const confirmationMessage = 'Êtes-vous sûr de vouloir envoyer cette publication en retouche ?';
    err.value.status = false;
    try {
        checkContent();
        const isConfirmed = await displayConfirmationMessage(confirmationMessage);
        if (!isConfirmed) return;
        await requestSocialNetworkPublicationCorrection(publicationId, correctionNotes);
        emits('correctionRequestSended');
        // emits('close');
    } catch (error) {
        if (error.error) {
            err.value.message = error.error;
        }
        err.value.status = true;
    }
};
const checkContent = () => {
    const content = correctionContent.value;
    if (!content || content === '<p></p>') {
        throw {
            error: 'Veuillez décrire votre demande de retouche'
        }
    }
};
</script>
<style lang="scss" scoped>
.publication-card-correction-request {
    width: 40dvw;
    height: 50dvh;
    padding: 1rem;

    @media screen and (max-width: 1600px) {
        // height: 75dvh;
        height: 50dvh;
        width: 50dvw; 
    }
    @media screen and (max-width: 1199px) {
        // height: 74dvh;
        height: 50dvh;
        width: 60dvw;
    }
    @media screen and (max-width: 1099px) {
        // height: 73dvh;
        height: 50dvh;
        width: 70dvw;
    }
    @media screen and (max-width: 900px) {
        // height: 72dvh;
        height: 50dvh;
        width: 80dvw;
    }
    @media screen and (max-width: 500px) {
        // height: 70dvh;
        height: 100dvh;
        width: 100dvw;
    }
    
    &__wrapper {
        // display: grid;
        // height: 100%;
        // grid-template-rows: 100%;
        // grid-template-columns: 100%;
        display: flex;
        height: 100%;

        &__correction {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 92%;
            width: 100%;

            h5 {
                margin: 0;
            }

            &__editor {
                display: flex;
                height: 80%;
                width: 100%;
            }

            &__button {
                text-align: center;
            }
        }

        &__publication {
            display: flex;
        
        }
    }
}
</style>

