<template>
        <div class="card h-100 p-4">
            <div class="btn-group col-3" role="group" aria-label="Basic example">
                <button @click="changeContentType('articles')" type="button" class="btn btn-secondary">Articles</button>
                <button @click="changeContentType('links')" type="button" class="btn btn-secondary">Liens</button>
            </div>
            <div class="table-responsive">

                <div v-if="contentLoading" style="width:100%;" class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <div v-if="err.status === true">
                    <div class="alert alert-danger">
                        <p>{{ err.message }}</p>  
                    </div>
                </div>

                <table v-if="contentType === 'articles' && !contentLoading" class="table align-items-center mb-0">
                    <thead>
                        <tr>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Titre de l'article</th>
                            <th v-if="verifiedUserRank >= 3" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type d'article</th>
                            <th v-if="verifiedUserRank >= 3" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Statut</th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mot clé</th>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
                            <th v-if="verifiedUserRank >= 3"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="articlesList.length == 0" class="text-center">
                            <td colspan="6">Aucun article ...</td>
                        </tr>
                        <tr v-else v-for="(article, index) in articlesList" :key="index">
                            <td class="text-center">{{ article.title }}</td>
                            <td v-if="verifiedUserRank >= 3" class="text-center">{{ articleTools.getType(article.type) }}</td>
                            <td v-if="verifiedUserRank >= 3" class="text-center">
                                <div class="d-flex justify-content-center">
                                    <div class="alert" :class="{ 
                                        'alert-dark': article.status === 0, 
                                        'alert-primary': article.status === 1 || article.status === 4,
                                        'alert-danger': article.status === 3,
                                        'alert-warning': article.status === 2,
                                        'alert-info': article.status === 5,
                                        'alert-success': article.status === 6
                                        }">
                                    {{ getStringStatus(article.status) }}
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">{{ article.keywords.primary }}{{ article.keywords.secondary ? `/ ${article.keywords.secondary}` : ""}}</td>
                            <td class="text-center">{{ getArticleDate(article) }}</td>
                            <td v-if="verifiedUserRank >= 3" class="text-center">
                                <div class="d-flex justify-content-around align-items-center">
                                    <router-link :to="`/article-writing/${article._id}`" target="_blank">
                                        <SoftButton>
                                            <div>Voir</div>
                                        </SoftButton>
                                    </router-link>
                                </div>
                            </td>
                        </tr> 
                    </tbody>
                </table>

                <!-- Links Table -->
                <table v-if="contentType === 'links' && !contentLoading" class="table align-items-center mb-0">
                    <thead>
                        <tr>
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Lien</th> 
                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
                            <!-- <th></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="linksList.length == 0" class="text-center">
                            <td colspan="6">Aucun lien ...</td>
                        </tr>
                        <tr v-else v-for="(link, index) in linksList" :key="index">
                            <td class="text-center"><a target="_blank" :href="link.link">{{ link.link }}</a></td>
                            <td class="text-center">{{ getArticleDate(link) }}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import axios from "axios";
    import Cookies from "js-cookie";
    import SoftButton from "@/components/SoftButton.vue";
    import { getArticleDate } from "@/tools/dateTools";
    import userTools from "@/tools/userTools";
    import articleTools from "@/tools/articleTools.js";
    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`
    };

    // PROPS
    const props = defineProps({
        customerId: {
            type: String,
            required: true
        }
    });

    // STATE
    const err = ref({
      message: "",
      status: false
    });
    const contentType = ref("articles");
    const contentLoading = ref(false);

    const articlesList = ref([]);
    const linksList = ref([]);
    const verifiedUserRank = ref(null);

    // METHODS
    const changeContentType = (type) => {
        contentType.value = type;
        switch (type) {
            case "articles":
                getArticlesList()
            break;
            case "links":
                getLinksList();
            break;
        }
    };
    const getArticlesList = async () => {
        try {
            contentLoading.value = true;
            err.value.status = false;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/advisor/customers/get-articles-list?customerId=${props.customerId}`,
                headers: axiosConfig,
            });
            
            articlesList.value = response.data;
        } catch (error) {
            if (error.status === 400) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue durant la récupération des articles";
            }
            err.value.status = true;
        } finally {
            contentLoading.value = false;
        }
    };
    const getLinksList = async () => {
        try {
            contentLoading.value = true;
            err.value.status = false;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/advisor/customers/get-links-list/${props.customerId}`,
                headers: axiosConfig,
            });
            linksList.value = response.data;
        } catch (error) {
            if (error.status === 400) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue durant la récupération des liens";
            }
            err.value.status = true;
        } finally {
            contentLoading.value = false;
        }
    };
    const getStringStatus = (status) => {
        switch (status) {
            case 0 :
                return "En rédaction"
            case 1:
                return "Attente validation admin";
            case 2: 
                return "Attente validation client";
            case 3: 
                return "En correction";
            case 4:
                return "En cours de publication";
            case 5:
                return "En attente de parution"
            case 6: 
                return "Publié"
        }
    };
    const verifyUserRank = async () => {
        try {
            const authParams = 'rankLevel';
            const authorizations = await userTools.getUserAuthorizations(Cookies.get('token'), authParams);
            verifiedUserRank.value = authorizations.rankLevel;
        } catch (error) {
            verifiedUserRank.value = 1
        } 
    };

    // LIFECYCLE HOOKS
    onMounted(async() => {
        await verifyUserRank();
        getArticlesList();
    });

</script>
<style scoped>
    .table-responsive {
        min-height: 40px;
    }
    .btn-secondary {
        padding: 0.25rem 1rem;
        font-size: 12px;
    }
    .btn-secondary .btn-selected {
        box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);
    }
    .alert {
        margin-bottom: 0;
        padding: 5px 15px;
        border: none;
        border-radius: 10px;
        z-index: 1;
    }
    .table-responsive {
        max-height: 60vh;
        overflow: auto;
    }
    thead {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 2;
    }
    td {
        height: 10px;
    }
    .alert {
        margin-bottom: 0;
        padding: 5px 15px;
        width: fit-content;
    }
</style>