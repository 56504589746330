<template>
    <div class="card">
        <div class="card-body">
            <div v-if="err.status === true" id="error-container">
                <div>{{ err.message }}</div>
            </div>
            <div v-if="loading" id="spinner-container">
                <div class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-if="!loading && err.status === false" class="row">
                <div class="stat-box col">
                    <h6>Nouveaux utilisateurs</h6>
                    <div class="d-flex align-items-center">
                        <div class="count">{{ reportData.newUsers.value }}</div>
                        <div class="evolution-row">
                            <svg v-if="parseFloat(reportData.newUsers.evolution) > 0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#94E4AF" d="M11 18V9.825L7.4 13.4L6 12l6-6l6 6l-1.4 1.4L13 9.825V18h-2Z"/></svg>
                            <svg v-if="parseFloat(reportData.newUsers.evolution) < 0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#c43540" d="m12 18l-6-6l1.4-1.4l3.6 3.575V6h2v8.175l3.6-3.575L18 12l-6 6Z"/></svg>
                            <div class="text-sm">{{ reportData.newUsers.evolution }}</div>
                        </div>
                    </div>
                </div>
                <div class="stat-box col">
                    <h6>Total utilisateurs</h6>
                    <div class="d-flex align-items-center">
                        <div class="count">{{ reportData.activeUsers.value }}</div>
                        <div class="evolution-row">
                            <svg v-if="parseFloat(reportData.activeUsers.evolution) > 0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#94E4AF" d="M11 18V9.825L7.4 13.4L6 12l6-6l6 6l-1.4 1.4L13 9.825V18h-2Z"/></svg>
                            <svg v-if="parseFloat(reportData.activeUsers.evolution) < 0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#c43540" d="m12 18l-6-6l1.4-1.4l3.6 3.575V6h2v8.175l3.6-3.575L18 12l-6 6Z"/></svg>
                            <div class="text-sm">{{ reportData.activeUsers.evolution }}</div>
                        </div>
                    </div>
                </div>
                <div class="stat-box col">
                    <h6>Durée moyenne session</h6>
                    <div class="d-flex align-items-center">
                        <div class="count">{{ getStringTime(reportData.sessionDuration.value) }}</div>
                        <div class="evolution-row">
                            <svg v-if="parseFloat(reportData.sessionDuration.evolution) > 0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#94E4AF" d="M11 18V9.825L7.4 13.4L6 12l6-6l6 6l-1.4 1.4L13 9.825V18h-2Z"/></svg>
                            <svg v-if="parseFloat(reportData.sessionDuration.evolution) < 0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#c43540" d="m12 18l-6-6l1.4-1.4l3.6 3.575V6h2v8.175l3.6-3.575L18 12l-6 6Z"/></svg>
                            <div class="text-sm">{{ reportData.sessionDuration.evolution }}</div>
                        </div>
                    </div>
                </div>
                <div class="stat-box col">
                    <h6>Nombre de pages vues</h6>
                    <div class="d-flex align-items-center">
                        <div class="count">{{ reportData.viewedPages.value }}</div>
                        <div class="evolution-row">
                            <svg v-if="parseFloat(reportData.viewedPages.evolution) > 0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#94E4AF" d="M11 18V9.825L7.4 13.4L6 12l6-6l6 6l-1.4 1.4L13 9.825V18h-2Z"/></svg>
                            <svg v-if="parseFloat(reportData.viewedPages.evolution) < 0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#c43540" d="m12 18l-6-6l1.4-1.4l3.6 3.575V6h2v8.175l3.6-3.575L18 12l-6 6Z"/></svg>
                            <div class="text-sm">{{ reportData.viewedPages.evolution }}</div>
                        </div>
                    </div>
                </div>
                <div class="stat-box col">
                    <h6>Pages par session</h6>
                    <div class="d-flex align-items-center">
                        <div class="count">{{ parseFloat(reportData.pagesPerSession.value).toFixed(2) }}</div>
                        <div class="evolution-row">
                            <svg v-if="parseFloat(reportData.pagesPerSession.evolution) > 0" width="32" height="32" viewBox="0 0 24 24"><path fill="#94E4AF" d="M11 18V9.825L7.4 13.4L6 12l6-6l6 6l-1.4 1.4L13 9.825V18h-2Z"/></svg>
                            <svg v-if="parseFloat(reportData.pagesPerSession.evolution) < 0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#c43540" d="m12 18l-6-6l1.4-1.4l3.6 3.575V6h2v8.175l3.6-3.575L18 12l-6 6Z"/></svg>
                            <div class="text-sm">{{ reportData.pagesPerSession.evolution }}</div>
                        </div>
                    </div>
                </div>
                <div class="stat-box col">
                    <h6>Taux de rebond</h6>
                    <div class="d-flex align-items-center">
                        <div class="count">{{ reportData.bouceRate.value }}%</div>
                        <div class="evolution-row">
                            <svg v-if="parseFloat(reportData.bouceRate.evolution) > 0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#c43540" d="M11 18V9.825L7.4 13.4L6 12l6-6l6 6l-1.4 1.4L13 9.825V18h-2Z"/></svg>
                            <svg v-if="parseFloat(reportData.bouceRate.evolution) < 0" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#94E4AF" d="m12 18l-6-6l1.4-1.4l3.6 3.575V6h2v8.175l3.6-3.575L18 12l-6 6Z"/></svg>
                            <div class="text-sm">{{ reportData.bouceRate.evolution }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from "vue";
    import axios from "axios";
    import Cookies from "js-cookie";

    const props = defineProps({
        period: {
            type: String,
            required: true
        }
    });

    // STATE
    const loading = ref(true);
    const err = ref({
        message: "",
        status: false
    });
    const reportData = ref({
        newUsers: {},
        activeUsers: {},
        sessionDuration: {},
        viewedPages: {},
        pagesPerSession: {},
        bouceRate: {}
    });

    // METHODS
    const getReport = async () => {
        try {
            loading.value = true;
            err.value.status = false;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/user/google/get-data/report?period=${props.period}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                },
            });
            reportData.value = response.data;
        } catch (error) {
            if (error.response.status === 401) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue durant le chargement des données, vérifiez que vous êtes bien connecté avec votre compte google et que la propriété choisie possède bien des données..."
            }
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };
    const getStringTime = (time) => {
        return `${Math.floor(time / 60)} min ${Math.round(time % 60)}`
    };

    // LIFECYCLE HOOKS
    onMounted(() => {
        getReport();
    });
</script>

<style scoped>
    h6 {
        font-size: 13px;   
    }
    #error-container {
        text-align: center;
    }
    #spinner-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
    }
    .stat-box {
        height: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        padding: 0.8rem;
    }
    .count {
        font-size: 16rpx;
    }
    .evolution-row {
        display: flex;
        align-items: center;
    }
    .evolution-row > div {
        font-weight: 700;
    }
    .count {
        margin-right: 8px;
    }
</style>