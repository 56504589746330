<template>
	<component :is="layout">
		<router-view />
	</component>
</template>
<script setup>
import PrivatePageLayout from "@/layouts/PrivatePageLayout/PrivatePageLayout.vue";
import PublicPageLayout from "@/layouts/PublicPageLayout/PublicPageLayout.vue";
import { useRoute } from "vue-router"; 
import { computed } from "vue";

const route = useRoute();

const layout = computed(() => {
	return route.meta.layout === "private" ? PrivatePageLayout : PublicPageLayout;
});

</script>
<style scoped>
 
</style>
