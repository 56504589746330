// quillConfig.js
import { Quill } from '@vueup/vue-quill';

// Étendre la classe de lien de Quill pour personnaliser l'attribut `rel`
const Link = Quill.import('formats/link');

class CustomLink extends Link {
  static create(value) {
    let node = super.create(value);
    node.removeAttribute('rel'); // Supprimer l'attribut `rel`
    // node.setAttribute('rel', 'votre-valeur'); // Pour personnaliser l'attribut `rel`
    return node;
  }
}

Quill.register(CustomLink, true);

// Options de configuration générales pour Quill
export const editorOptions = {
  theme: 'snow', // ou 'bubble'
  modules: {
    toolbar: [['link']],
  },
};
