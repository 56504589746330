<template>
    <div id="google-analytics-connexion-status">
        <div id="google-analytics-connexion-status__analytics-logo">
            <img :src="GoogleAnalyticsLogo" alt="google-analytics-logo">
        </div>
        <div 
            id="google-analytics-connexion-status__status" 
            :class="{ 'connected': connected.message === 'Connecté', 'warning': ['Déconnecté', 'Erreur de connexion'].includes(connected.message) }"
        >
            <div id="google-analytics-connexion-status__status__icon">
                <svg v-if="connected.status" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
                    <path fill="currentColor" d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4zM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.712T12 15q-.425 0-.712.288T11 16q0 .425.288.713T12 17m-1-4h2V7h-2zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/>
                </svg>
            </div>
            <div id="google-analytics-connexion-status__status__text" >
                {{ connected.message }}
            </div>
        </div>
    </div>
</template>
<script setup>
import GoogleAnalyticsLogo from "@/assets/img/logos/logo-google-analytics.png";
import { checkUserAnalyticsConnexion } from "@/useCases/googleAnalyticsUseCases.js";
import { ref, onMounted } from 'vue';


const connected = ref({
    status: false,
    message: ""
});
const messages = {
    connected: "Connecté",
    disconnected: "Déconnecté",
    default: "Vérification...",
    error: "Erreur de connexion"
};


const checkConnexion = async () => {
    try {
        connected.value.message = messages.default;
        const response = await checkUserAnalyticsConnexion();
        if (response.message === "CONNECTED") {
            connected.value.status = true;
            connected.value.message = messages.connected;
            return;
        }
        connected.value.status = false;
        connected.value.message = messages.disconnected;
    } catch (error) {
        connected.value.message = messages.error;
        connected.value.status = false;
    }
};


onMounted(() => {
    checkConnexion();
});
</script>
<style lang="scss" scoped>
#google-analytics-connexion-status {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    gap: 8px;
    

    &__analytics-logo {
        height: 100%;
        display: flex;

        img {
            height: 100%;
            object-fit: contain;
        }
    }

    &__status {
        display: flex;
        align-items: center;

        &.connected {
            color: #57b677;
        }
        &.warning {
            color: #d31010;
        }
        
        &__icon {
            
        }
        &__text {
            font-size: 10px;
            font-weight: 500;
        }
    }
}

</style>