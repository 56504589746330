"use strict";

export const formatHtmlLink = (link) => {
    if (link.startsWith("http")) {
        return link;
    } else {
        return `https://${link}`;
    }
};

export const removeHttpFromLink = (link) => {
    const regex = /^https?:\/\//i;
    const formatedLink = link.replace(regex, "");
    return formatedLink;
};

