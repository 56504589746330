<template>
    <div class="row w-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                            <h2 class="mb-0 font-weight-bolder">Articles à publier</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="card h-100 p-4 text-center">
                        <div class="d-flex btn-group col-4" role="group" aria-label="Basic example">
                            <button @click="reloadArticlesList(4)" type="button" class="btn btn-secondary" :class="{ 'btn-selected': articlesStatusParams === '' }">En attente de publication</button>
                            <button @click="reloadArticlesList(5)" type="button" class="btn btn-secondary" :class="{ 'btn-selected': articlesStatusParams === 'old' }">En attente de parution</button>
                        </div>
                        <div class="table-responsive">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Titre</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Client</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mot(s) clé(s)</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type d'article</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date limite</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="articlesLoading" style="width:100%;" class="text-center">
                                        <td colspan="6">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="err.status === true">
                                        <td colspan="6">
                                            <div class="alert alert-danger">
                                                <p v-if="err.status">{{ err.message }}</p>  
                                            </div>
                                        </td>
                                    </tr>
                                    
                                    <tr v-if="articlesList.length == 0 && err.status === false && articlesLoading === false">
                                        <td colspan="6">Aucun article ...</td>
                                    </tr>

                                    <!-- Articles List -->
                                    <tr v-show="articlesList.length > 0 && !err.status && !articlesLoading" v-for="(article, index) in articlesList" :key="index">
                                        <td class="text-center ellipse large">{{ article.title }}</td>
                                        <td class="text-center ellipse medium">{{ `${article.customerUser.websiteUrl} - ${article.customerUser.fullName} / ${article.customerUser.email} ` }}</td>
                                        <td class="text-center ellipse short">{{ article.keywords.primary }}{{ article.keywords.secondary ? `/ ${article.keywords.secondary}` : ""}}</td>
                                        <td class="text-center ellipse short">{{ articleTools.getType(article.type) }}</td>
                                        <td class="text-center">{{ getArticleDate(article) }}</td>
                                        <td class="text-center">
                                            <div class="d-flex justify-content-around align-items-center">
                                                <router-link :to="`/article-writing/${article._id}`">
                                                    <SoftButton>
                                                        <div>Vérifier</div>
                                                    </SoftButton>
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr> 
                                    <!-- Articles List End-->

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import axios from "axios";
    import SoftButton from "@/components/SoftButton.vue";
    import Cookies from "js-cookie";
    import { getArticleDate } from "@/tools/dateTools";
    import articleTools from "@/tools/articleTools.js";

    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`
    };
    // STATE
    const articlesLoading = ref(true);
    const err = ref({
      message: "",
      status: false
    });

    const articlesList = ref([]);
    const articlesStatusParams = ref(4);

    // METHODS
    const getArticlesList = async () => {
        try {
            articlesLoading.value = true;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/advisor/articles/get-articles?status=${articlesStatusParams.value}`,
                headers: axiosConfig,
            });
            if (response.status === 200 || 204) {
                articlesList.value = response.data;
            }
        } catch (error) {
            if (error.response.status === 404 || 401) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue..."
            }
        } finally {
            articlesLoading.value = false;
        }
    };

    const reloadArticlesList = async (status) => {
        articlesStatusParams.value = status;
        getArticlesList();
    };

    // LIFECYCLE HOOKS
    onMounted(() => {
        getArticlesList();
    })
</script>
<style scoped>
    @import "../../../../../src/assets/css/tables-custom.css";
    .btn-secondary {
        padding: 0.25rem 1rem;
        font-size: 12px;
    }
    .table-responsive {
        height: 73vh;
        overflow: auto;
    }
</style>