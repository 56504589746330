<template>
    <ClassicPageLayout pageTitle="Partage de fichiers" class="file-sharing">
        <div class="container-card file-sharing__card">
            <h5 class="file-sharing__card__title">Partagez facilement vos fichiers avec notre équipe</h5>
            <EmbeddedError v-if="uploadFiles.error.status" :message="uploadFiles.error.message" />
            <EmbeddedSuccess v-if="uploadFiles.success.status" :message="uploadFiles.success.message" />
            <FileDragAndDropArea 
                ref="fileDragAndDropAreaRef"
                @fileChange="handleFileChange"
                :loading="uploadFiles.loading"
            />
        </div>
    </ClassicPageLayout>
</template>
<script setup>
import { ref } from 'vue';
import { uploadUserFiles } from '@/useCases/fileManagementUseCases.js';
import ClassicPageLayout from '@/components/layouts/pagesLayouts/ClassicPageLayout.vue';
import EmbeddedSuccess from '@/components/standalone/statusMessages/success/EmbededSuccess.vue';
import EmbeddedError from '@/components/standalone/statusMessages/errors/EmbeddedError.vue';
import FileDragAndDropArea from '@/components/standalone/FileDragAndDropArea/FileDragAndDropArea.vue';

const uploadFiles = ref({
    error: {
        message: '',
        status: false,
    },
    success: {
        message: '',
        status: false,
    },
    loading: false,
});

const fileDragAndDropAreaRef = ref(null);

const handleFileChange = async (files) => {
    try {
        uploadFiles.value.success.status = false;
        uploadFiles.value.error.status = false;
        uploadFiles.value.loading = true;
        if (files.length === 0) {
            return;
        }
        await uploadUserFiles(files);
        uploadFiles.value.success.message = "Vos fichiers ont bien été envoyés";
        uploadFiles.value.success.status = true;
        fileDragAndDropAreaRef.value.cleanFiles();
    } catch (error) {
        console.log(error);
        if (error.error) {
            uploadFiles.value.error.message = error.error;
        } else {
            uploadFiles.value.error.message = "Une erreur est survenue lors de l'envoi du/des fichier(s)";
        }
        uploadFiles.value.error.status = true;
    } finally {
        uploadFiles.value.loading = false;
    }
};
</script>
<style lang="scss" scoped>
.file-sharing {
  
    &__card {
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;

        &__title {
            margin: 1rem;
        }
    }
}
</style>
