<template>
    <ClassicPageLayout pageTitle="Validation des posts">
        <div class="my-social-networks-publications">
            <ContainerCardLayout>
                <EmbeddedError
                    v-if="mySocialNetworksPublications.error.status"
                    :message="mySocialNetworksPublications.error.message" 
                />
                <MainLoader 
                    v-else-if="mySocialNetworksPublications.loading" 
                />
                <div v-else class="my-social-networks-publications__list">
                    <div v-if="mySocialNetworksPublications.data.length === 0" class="my-social-networks-publications__list__empty">
                        <p>Aucun post...</p>
                    </div>
                    <div v-else class="my-social-networks-publications__list__not-empty">
                        <MySocialNetworksPublicationCard 
                            v-for="publication in mySocialNetworksPublications.data"
                            :key="publication.id"
                            :publication="publication"
                            @reload="getMySocialNetworksPublications"
                        />
                        <!-- <hr />
                        <SocialNetworkPublicationCard 
                            v-for="publication in mySocialNetworksPublications.data"
                            :key="publication.id"
                            :publication="publication"
                            :cardStyle="{ width: '294px', height: '432px'}"
                        /> -->
                    </div>
                </div>
            </ContainerCardLayout>
        </div>
    </ClassicPageLayout>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import MySocialNetworksPublicationCard from '@/components/linked/mySocialNetworksComponents/MySocialNetworksPublicationCard/MySocialNetworksPublicationCard.vue';
import SocialNetworkPublicationCard from '@/components/services/SocialNetworkPublicationCard/SocialNetworkPublicationCard.vue';
import ClassicPageLayout from '@/components/layouts/pagesLayouts/ClassicPageLayout.vue';
import ContainerCardLayout from '@/components/layouts/cardsLayouts/ContainerCardLayout.vue';
import EmbeddedError from '@/components/standalone/statusMessages/errors/EmbeddedError.vue';
import MainLoader from '@/components/standalone/loaders/MainLoader.vue';
import { fetchMySocialNetworkPublications } from '@/useCases/socialNetworkPublicationUseCases.js';
import { useRouter } from 'vue-router';
import { fetchUserMetricoolId } from '@/useCases/userUseCases.js';

//DATA
const router = useRouter();
const mySocialNetworksPublications = ref({
    data: [],
    error: {
        status: false,
        message: 'Une erreur est survenue.'
    },
    loading: true
});
let metricoolId = '';

//METHODS
const getMySocialNetworksPublications = async () => {
    try {
        mySocialNetworksPublications.value.loading = true;
        const publications = await fetchMySocialNetworkPublications();
        mySocialNetworksPublications.value.data = publications;
    } catch (error) {
        console.log(error);
    } finally {
        mySocialNetworksPublications.value.loading = false;
    }
};

const getUserMetricoolId = async () => {
    try {
        const userMetricoolId = await fetchUserMetricoolId();
        if (!userMetricoolId) {
            router.push('/social-networks/subscribe')
        }
        metricoolId = userMetricoolId;
    } catch (error) {
        console.log(error);
    }
};

//LIFECYCLE HOOKS
onMounted(async () => {
    await getUserMetricoolId();
    getMySocialNetworksPublications();
});

</script>
<style lang="scss" scoped>
    .my-social-networks-publications {
        &__list {
           
            &__empty {
                display: flex;
                justify-content: center;
                p {
                    margin: 0;
                }
            }
            &__not-empty {
                padding: 1rem;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                gap: 2rem;
            }
        }
    }
</style>
