<template>
    <div id="main-container">
        <div class="row w-100 g-0 p-4">
            <div v-if="!articleLoading" class="position-relative z-index-2">
                <div class="mb-1 card card-plain">
                    <div class="p-3 card-body">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-flex flex-column h-100">
                                    <h2 class="mb-0 font-weight-bolder">Titre: {{ article.title }}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-3">
                    <router-link target="_blank" :to="`/advisor/customer/${article.customerUser}`">
                        <div id="client-folder-button">
                            <strong>Dossier client </strong>
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24"><circle cx="10" cy="8" r="4" fill="#94e4af"/><path fill="currentColor" d="M10.35 14.01C7.62 13.91 2 15.27 2 18v2h9.54c-2.47-2.76-1.23-5.89-1.19-5.99zm9.08 4.01c.36-.59.57-1.28.57-2.02c0-2.21-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4c.74 0 1.43-.22 2.02-.57L20.59 22L22 20.59l-2.57-2.57zM16 18c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2z"/></svg>
                        </div>
                    </router-link>
                    <div v-if="article.websiteUrl">
                        <strong>Site client: </strong> 
                        <a :href="formatHtmlLink(article.websiteUrl)" target="_blank">{{ article.websiteUrl }}</a>
                    </div>
                    <div v-if="article.link">
                        <strong>Lien: </strong>
                        <a :href="formatHtmlLink(article.link)" target="_blank">{{ article.link }}</a>
                    </div>
                    <div v-if="article.keywords.primary">
                        <strong>Mot clé principal: </strong>
                        {{ article.keywords.primary }}
                    </div>
                    <div v-if="article.keywords.secondary">
                        <strong>Mot clé secondaire: </strong>
                        {{ article.keywords.secondary }}
                    </div>
                    <div v-if="article.type">
                        <strong>Type d'article: </strong>
                        {{ articleTools.getType(article.type) }}
                    </div>
                    <div v-if="article.status">
                        <strong>Status d'article: </strong>
                        {{ articleTools.getStringStatus(article.status) }}
                    </div>
                    <ItemAccordion
                        title="Notes"
                        :content="article.editorialNotes"
                        contentType="html"
                        image="eye"
                    />
                    <ItemAccordion
                        v-if="verifiedUserRank === 4 && article.correctionNotes"
                        title="Notes de correction"
                        :content="article.correctionNotes"
                        contentType="html"
                        image="eye"
                        titleStyle="color: #ea0606"
                    />
                    <div role="button" @click="getHtmlFile">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m12 15.577l-3.539-3.538l.708-.72L11.5 13.65V5h1v8.65l2.33-2.33l.709.719zM6.616 19q-.691 0-1.153-.462T5 17.384v-2.423h1v2.423q0 .231.192.424t.423.192h10.77q.23 0 .423-.192t.192-.424v-2.423h1v2.423q0 .691-.462 1.153T17.384 19z"/></svg>
                        HTML
                    </div>
                </div>

                <div v-if="verifiedUserRank !== null" id="editor-main-container" class="container-fluid p-3">
                    <div class="d-flex mb-3 justify-content-end column" :class="{ 'justify-content-between': err.status === true }">
                        <div v-if="err.status === true" class="d-flex align-items-center text-danger">
                            Erreur: {{ err.message }}
                        </div>

                        <!-- BOUTONS -->
                        <div class="d-flex gap-2">
                            <SoftButton v-if="(article.status <= 1 && verifiedUserRank === 2) || verifiedUserRank === 4" @click="saveArticle" class="d-flex">
                                <div v-if="saveLoading" class="spinner-border spinner-border-sm " role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <div class="m-0">Sauvegarder</div>
                            </SoftButton>
                            <SoftButton v-if="article.status <= 0 && verifiedUserRank >= 2" @click="askValidationConfirmation">
                                Envoyer pour validation
                            </SoftButton>
                            <SoftButton v-if="verifiedUserRank === 4 && article.status === 1 || article.status === 3" @click="askCustomerValidation">
                                Envoyer validation client
                            </SoftButton>
                            <SoftButton v-if="verifiedUserRank >= 3 && article.status === 4 || article.status === 5" @click="validatePublication">
                                Valider la publication
                            </SoftButton>
                            <SoftButton :color="'warning'" v-if="verifiedUserRank >= 3 && article.status === 4 && article.type === 'Externe'" @click="isWaitingForPublication">
                                Placer en attente de parution
                            </SoftButton>
                        </div>
                    </div>

                    <!-- LIEN DE L'ARTICLE -->
                    <!-- <div v-if="verifiedUserRank >= 3 && article.type === 'Externe' && article.status === 4 || article.status === 5" class="d-flex my-3 input-group">
                        <label for="link" class="input-group-text m-0">Lien de l'article</label>
                        <input v-model="linkForPublishing" type="text" id="link" name="link" class="form-control" placeholder="Lien de l'article">
                    </div> -->

                    <!-- LISTE DES ARTICLES ET DES LIENS DU CLIENT  -->
                    <CustomerArticlesAndLinksList v-if="verifiedUserRank >= 3 && article.status >= 4 && article.status < 6" :customerId="article.customerUser" />
                    <div class="d-flex justify-content-end" style="font-size: 13px;" :class="goodWordsNumber" >{{ getWordsCount() }} mot(s) &nbsp;<strong>{{ estimatedWordsRequired ? `/ ${estimatedWordsRequired} mots requis estimés` : "" }}</strong> </div>
                    <!-- QUILL FOR WRITERS -->
                    <div v-if="verifiedUserRank === 2">
                        <QuillEditor @click="selectArea" :options="editorOptions" @keyup="refreshSeoWordsCounts" v-if="article.status <= 1" style="background-color: white; min-height: 50vh;" id="editor" content-type="html" v-model:content="article.content" theme="snow" toolbar="full" :read-only="false"/>
                        <QuillEditor @click="selectArea" :options="editorOptions" v-else style="background-color: white; min-height: 50vh;" id="editor" content-type="html" v-model:content="article.content" theme="snow" toolbar="full" :read-only="true"/>
                    </div>

                    <!-- QUILL FOR ADMIN -->
                    <div v-if="verifiedUserRank === 4">
                        <QuillEditor @click="selectArea" :options="editorOptions" @keyup="refreshSeoWordsCounts" v-if="article.status <= 5" style="background-color: white; min-height: 50vh;" id="editor" content-type="html" v-model:content="article.content" theme="snow" toolbar="full" :read-only="false"/>
                        <QuillEditor @click="selectArea" :options="editorOptions" v-else style="background-color: white; min-height: 50vh;" id="editor" content-type="html" v-model:content="article.content" theme="snow" toolbar="full" :read-only="true"/>
                    </div>
                    <div v-if="verifiedUserRank === 3">
                        <QuillEditor @click="selectArea" :options="editorOptions" style="background-color: white; min-height: 50vh;" id="editor" content-type="html" v-model:content="article.content" theme="snow" toolbar="full" :read-only="true"/>
                    </div>
                </div>            
            </div>
            <div v-else class="text-center">
                Article en cours de chargement...
            </div>
        </div>
        <div v-if="showRightSideToolsBox" id="right-side-tools-box-container">
            <RightSideToolsBox ref="RightSideToolsBoxRef" :articleContent="article.content"/>
        </div>
    </div>
</template>
<script setup>
    import { onBeforeUnmount, onMounted, ref } from "vue";
    import { QuillEditor } from '@vueup/vue-quill';
    import { editorOptions } from "../../../../quillLib/LinkBlot";
    import { convert } from 'html-to-text';
    import axios from "axios";
    import '@vueup/vue-quill/dist/vue-quill.snow.css';
    import '@vueup/vue-quill/dist/vue-quill.bubble.css';
    import SoftButton from "@/components/SoftButton.vue";
    import { useRoute, useRouter } from "vue-router";
    import Cookies from "js-cookie";
    import CustomerArticlesAndLinksList from "./ArticleWritingComponents/CustomerArticlesAndLinksList.vue";
    import { formatHtmlLink } from "@/tools/divTools";
    import userTools from "@/tools/userTools";
    import ItemAccordion from "@/components/ItemAccordion/ItemAccordion.vue";
    import RightSideToolsBox from "@/components/RightSideToolsBox/RightSideToolsBox.vue";
    import articleTools from "@/tools/articleTools.js";
    
    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        "Content-Type": "application/json"
    };
    const quillOption = {
        modules: {
            toolbar: [['link']]
        }
    }

    const saveLoading = ref(false);
    const articleLoading = ref(true);
    const err = ref({
      message: "",
      status: false
    });

    const route = useRoute();
    const router = useRouter();

    const articleId = route.params.id;
    const article = ref({
        title: "",
        content: "",
        previousContent: "",
        status: 0,
        websiteUrl: "",
        type: "",
        keywords: {
            primary: "",
            secondary: ""
        },
        editorialNotes: "",
        correctionNotes: "",
    });

    const linkForPublishing = ref("");
    const verifiedUserRank = ref(null);
    const showRightSideToolsBox = ref(true);
    const RightSideToolsBoxRef = ref();
    const estimatedWordsRequired = ref();
    const goodWordsNumber = ref('');
    
    const verifyUserRank = async () => {
        try {
            const authParams = 'rankLevel';
            const authorizations = await userTools.getUserAuthorizations(Cookies.get('token'), authParams);
            verifiedUserRank.value = authorizations.rankLevel;
        } catch (error) {
            verifiedUserRank.value = 1
        } 
    };
    const getArticleData = async () => {
        try {
            err.value.status = false;
            articleLoading.value = true;

            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/writer/get-article-data/${articleId}`,
                headers: axiosConfig,
            });
            if (response.status === 200) {
                article.value = { ...response.data };
                article.value.previousContent = response.data.content;
                articleLoading.value = false;
            }
        } catch (error) {
            if (error.response.status === 404 || error.response.status === 401) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue durant le chargement de l'article";
            }
            err.value.status = true;
        } finally {
            articleLoading.value = false;
        }
    };
    const saveArticle = async () => {
        try {
            err.value.status = false;
            saveLoading.value = true;
            const response = await axios({
                method: "PATCH",
                url: `${process.env.VUE_APP_SERVER}/writer/save-article`,
                headers: axiosConfig,
                data: {
                    articleId: route.params.id,
                    content: article.value.content
                }
            });

        } catch (error) {
            if (error.response.status === 404 || error.response.status === 401 || error.response.status === 400 ) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue durant la sauvegarde de l'article";
            }
            err.value.status = true;
        } finally {
            saveLoading.value = false;
        }
    };
    const getWordsCount = () => {
        const option = {
            selectors: [ 
                { selector: 'img', format: 'skip' },
                { selector: 'a', options: { ignoreHref: true } }
            ]
        }

        const htmlArticle = article.value.content;

        const htmlContentToText = convert(htmlArticle, option);
        const parsedText = htmlContentToText.replace(/’|'/gu, '').replace(/[^\w\s\p{L}]/gu, ' ').trim();
        const words = parsedText.split(/\s+/);

        if (words.length >= estimatedWordsRequired.value ) goodWordsNumber.value = 'good-words-number';
        else if (words.length < estimatedWordsRequired.value) goodWordsNumber.value = 'low-words-number';
        else goodWordsNumber.value = '';

        return words.length !== 1 ? words.length : 0;
    };
    const askValidationConfirmation = async () => {
        const textConfirmation = ("Souhaitez vous envoyer cet article pour validation administrateur ?");
        if (window.confirm(textConfirmation)) {
            try {
                err.value.status = false;
                const response = await axios({
                    method: "PATCH",
                    url: `${process.env.VUE_APP_SERVER}/writer/send-article-for-validation`,
                    headers: axiosConfig,
                    data: {
                        articleId: route.params.id,
                    }
                });
                if (response.status === 200) {
                    saveArticle();
                    router.push("/articles-writing-list");
                }
            } catch (error) {
                if (error.rasponse.status === 404 || error.response.status === 401) {
                    err.value.message = error.response.data.error;
                } else {
                    err.value.message = "Une erreur est survenue durant lal demande de validation de l'article";
                }
                err.value.status = true;
            }
        } else {
            return;
        }
    };
    const askCustomerValidation = async() => {
        const textConfirmation = ("Souhaitez vous envoyer cet article pour validation client ?");
        if (window.confirm(textConfirmation)) {
            try {
                err.value.status = false;
                const response = await axios({
                    method: "PATCH",
                    url: `${process.env.VUE_APP_SERVER}/admin/articles/send-customer-validation`,
                    headers: axiosConfig,
                    data: {
                        articleId: route.params.id,
                    }
                });
                if (response.status === 200) {
                    if (article.value.status === 3 ) {
                        router.push("/admin/articles/correction-list");
                    }
                    router.push("/admin-articles-validation");
                }
            } catch (error) {
                if (error.rasponse.status === 404 || error.response.status === 401) {
                    err.value.message = error.response.data.error;
                } else {
                    err.value.message = "Une erreur est survenue durant la demande de validation de l'article";
                }
                err.value.status = true;
            }
        } else {
            return;
        }
    };  
    const validatePublication = async () => {
        const textConfirmation = ("Veuillez entrer le LIEN de l'ARTICLE, puis cliquez sur OK pour valider la publication. \nPar exemple: https://monsite.com/mon-article");

        const link = window.prompt(textConfirmation);
        
        if (!link) {
            alert("Vous devez renseigner un lien pour passer un article au status: PUBLIÉ");
            return;
        }
        
        try {
            err.value.status = false;
            await axios({
                method: "PATCH",
                url: `${process.env.VUE_APP_SERVER}/advisor/articles/validate-publication`,
                headers: axiosConfig,
                data: {
                    articleId: route.params.id,
                    link: link
                }
            });
            router.push("/advisor/articles/publication-list");
        } catch (error) {
            if (error.response?.status === 401 || error.response?.status === 400) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue durant la publication de l'article";
            }
            err.value.status = true;
        }
    };
    const isWaitingForPublication = async () => {
        try {
            err.value.status = false;
            const response = await axios({
                method: "PATCH",
                url: `${process.env.VUE_APP_SERVER}/advisor/articles/is-waiting-for-publication`,
                headers: axiosConfig,
                data: {
                    articleId: route.params.id,
                    link: linkForPublishing.value
                }
            });
            router.push("/advisor/articles/publication-list");
        } catch (error) {
            if (error.response.status === 404 || error.response.status === 400) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue durant la publication de l'article";
            }
            err.value.status = true;
        }
    };

    const selectArea = () => {
        const area = document.querySelector("#editor .ql-editor");
        area.focus()
    };
    const getSizeAndCloseOrOpen = () => {
		const size = window.innerWidth;
		if (size >= 992) {
			showRightSideToolsBox.value = true
		}
		if (size < 992) {
			showRightSideToolsBox.value = false;
		}
	};
    const getEstimatedWordsRequired = async () => {
        const promise = await fetch(`${process.env.VUE_APP_SERVER}/article-seo-suggestions/get-one`, {
            method: 'POST',
            headers: axiosConfig,
            body: JSON.stringify({
                articleId: articleId,
                target: 'data.estimatedWordsRequired'
            })
        });

        const status = promise.status;
        const response = await promise.json();
        
        if (status !== 200) {
            return
        }

        estimatedWordsRequired.value = response.data.estimatedWordsRequired;
        
    };
    const refreshSeoWordsCounts = async (event) => {
        const reactiveKeys = [',', '.', '!', '?'];
        RightSideToolsBoxRef.value.refreshWordsCounts(article.value.content);
        if (reactiveKeys.includes(event?.key)) {
            await saveArticle();
            RightSideToolsBoxRef.value.refreshSEOScores();
        }
    };

    const getHtmlFile = async () => {
        const htmlContent = article.value.content;
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'article.txt';
        a.click();
    };

    // EVENTS LISTENERS
    window.addEventListener("resize", getSizeAndCloseOrOpen);
      
    // LIFECYCLE HOOKS
    onMounted(async() => {
        getSizeAndCloseOrOpen();
        await verifyUserRank();
        await getArticleData();
        // await autoSave();
        getEstimatedWordsRequired();
        refreshSeoWordsCounts();
    });
</script>
<style lang="css" scoped>
    #main-container {
        display: flex;
        flex-direction: row;
        /* overflow: hidden; */
    }
    #editor {
        background-color: white !important;
    }
    #editor:first-child {
        min-height: 50vh;
    }
    .ql-container {
        background-color: white !important;
    }
    .spinner-border {
        margin-right: 10px;
    }
    .ql-clipboard {
        position: fixed;
        left: 50%;
        top: 50%;
        width: 0px; 
    }
    #notesButton {
        color: unset;
    }
    #notesButton:hover {
        color: #94E4AF;
    }
    #eye {
        transform: rotateX(180deg);
        transform: rotatey(180deg);
        margin-left: 5px;
    }
    #link {
        border-left: 1px solid #d2d6da;
        padding: 5px;
    }
    #GPT-container, #Article-keywords-suggestions-container {
        width: fit-content;
        position: relative;
        z-index: 100;
    }
    #client-folder-button {
        display: flex;
        align-items: center;
        width: max-content;
    }
    .good-words-number {
        color: #88cf9f;
    }
    .low-words-number {
        color: #aa2a2a;
    }
</style>