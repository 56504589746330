<template>
  <div
    class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg" :style="{backgroundImage:'url(' + require('@/assets/img/curved-images/greenfibre.jpg') + ')',}">
     <span class="mask bg-gradient-dark opacity-6"></span>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center">
            <h5>Connexion</h5>
          </div>
            <div class="card-body">
                <div class="text-start">
                <div class="mb-3">
                    <input @keyup.enter="connectUser" v-model="user.email" class="form-control" id="email" type="email" placeholder="Email" name="email" />
                </div>
                <div class="mb-3">
                    <input @keyup.enter="connectUser" v-model="user.password" class="form-control" id="password" name="password" type="password" placeholder="Password" />
                </div>
                <div class="d-flex justify-content-end">
                  <a href="/formMail">Mot de passe oublié</a>
                </div>
                <div class="alert alert-danger" v-if="err.status === true ">
                    <p>{{ err.message }}</p> 
                </div>	
                <div class="text-center">
                    <button @click="connectUser" class="my-4 mb-2 btn mb-0" variant="gradient" color="info">Connexion</button>
                </div>
                <div class="mb-2 text-center position-relative d-flex w-100">
                    <p class="px-3 mb-2 text-sm bg-white font-weight-bold text-secondary text-border d-inline z-index-2 w-100">ou</p>
                </div>
                <div class="text-center">
                    <router-link to="/register">
                        <button class="mt-2 mb-4 btn mb-0" variant="gradient" color="dark">Créer un compte</button>
                    </router-link>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script setup>
    import { ref } from "vue";  
    import axios from 'axios';
    import Cookies from "js-cookie";
    import { useRouter } from "vue-router";
    import { fetchMyCompanyInformations } from "@/useCases/userUseCases";
    const router = useRouter();

    const user = ref({
        email: "",
        password: ""
    });

    const err = ref({
      message: "",
      status: false
    });
    
    const getMyCompanyInformations = async () => {
        try {
            const companyInformations = await fetchMyCompanyInformations();
            return companyInformations;
        } catch (error) {
        }
    };

    const handleCompanyInformations = async (companyInformations) => {
        Cookies.set('companyInformations', JSON.stringify(companyInformations), { expires: 0.45, sameSite: 'none', secure: true })
    };

    const connectUser = async () => {
        try {
            const response = await axios ({
                method: 'POST',
                url: `${process.env.VUE_APP_SERVER}/user/login`,
                data: user.value
            });
            if (response.status === 200) {
                Cookies.set('token', response.data.token, { expires: 0.45, sameSite: 'none', secure: true });
                Cookies.set('rank', response.data.rank, { expires: 0.45, sameSite: 'none', secure: true });
                const companyInformations = await getMyCompanyInformations();
                if(companyInformations) {
                    await handleCompanyInformations(companyInformations); 
                }
                router.push("/");
            }
        } catch (error) {    
        console.log(error);
            if (error.response.status === 400 || 403 || 404) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue, veuillez réessayer ultérieurement";
            }
            err.value.status = true;
        }
    };


</script>
<style scoped>
    #logo {
        max-width: 50%;
    }
    div.alert-danger {
        background-color: rgba(193, 63, 89, 0.843)!important;
    }
    .alert-danger > p {
        color: #ffffff;
        text-align: center;
    }
    
    a {
      font-size: 12px;
    }
</style>