<template>
    <section id="dashboard-latest-publications" class="container-card">
        <div id="dashboard-latest-publications__header" class="container-card__header">
            <h3>Publications récentes</h3>
            <router-link to="/social-networks/publications-validation" class="link-light">
                Tout voir
            </router-link>
        </div>
        <div id="dashboard-latest-publications__body" class="container-card__body">
            <MainLoader 
                v-if="getPublicationsStatus.loading" 
            />
            <EmbeddedError 
                v-else-if="getPublicationsStatus.error.status"
                :message="getPublicationsStatus.error.message"
            />
            <div v-else-if="publications.length" id="dashboard-latest-publications__body__medias">
                <BorderlessMediaCard
                    v-for="(publication, index) in publications" 
                    :key="index" :type="detectMediaType(publication)" 
                    :media="publication" 
                />
            </div>
            <EmbeddedMessage 
                v-else
                message="Aucune publication trouvée."
            />
        </div>
    </section>
</template>
<script setup>
import { fetchMyLatestCustomerSocialNetworkPublications } from "@/useCases/socialNetworkPublicationUseCases.js";
import BorderlessMediaCard from "@/components/base/cards/BorderlessMediaCard.vue";
import MainLoader from "@/components/standalone/loaders/MainLoader.vue";
import EmbeddedError from "@/components/standalone/statusMessages/errors/EmbeddedError.vue";
import EmbeddedMessage from "@/components/standalone/statusMessages/standard/EmbeddedMessage.vue";
import { onMounted, ref } from "vue";

const publications = ref([])
const getPublicationsStatus = ref({
    error: {
        status: false,
        message: "",
    },
    loading: true,
});

const getPublications = async () => {
    try {
        getPublicationsStatus.value.error = false;
        getPublicationsStatus.value.loading = true;
        const response = await fetchMyLatestCustomerSocialNetworkPublications();
        publications.value = response;
    } catch (error) {
        getPublicationsStatus.value.error = {
            status: true,
            message:  "Une erreur est survenue. Veuillez réessayer plus tard ou contacter un administrateur.",
        };
    } finally {
        getPublicationsStatus.value.loading = false;
    }
};

const detectMediaType = (publication) => {
    if (publication.video?.link) {
        return "video";
    }
    else if (publication.images?.length) {
        return "image";
    }
    else if (publication.content) {
        return "html";
    }
};

onMounted(() => {
    getPublications();
});

</script>
<style lang="scss" scoped>
#dashboard-latest-publications {
    &__header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }
    &__body {
        &__medias {
            display: flex;
            flex-direction: row;   
            padding: 0.8rem 1rem;
            overflow-x: auto;
            gap: 2rem;
        }
    }
}
</style>