<template>
    <div class="row w-100 p-4 g-0">
        <div v-if="articleLoading" class="text-center">
            Article en cours de chargement...
        </div>

        <div v-else class="col-lg-12 position-relative z-index-2">
            <div v-if="err.status" class="text-center text-danger">
                {{ err.message }}
            </div>
            <div v-else class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Validation d'article</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-3">
                    <div v-if="article.title">
                        <strong>Titre de l'article: </strong> 
                        {{  article.title }}
                    </div>
                    <div v-if="article.keywords.primary">
                        <strong>Mot clé principal: </strong>
                        {{ article.keywords.primary }}
                    </div>
                    <div v-if="article.keywords.secondary">
                        <strong>Mot clé secondaire: </strong>
                        {{ article.keywords.secondary }}
                    </div>
                    <div v-if="article.type">
                        <strong>Type d'article: </strong>
                        {{ articleTools.getType(article.type) }}
                    </div>
                </div>
                <div id="editor-main-container" class="container-fluid p-3">
                    <div class="d-flex mb-3 justify-content-end column">
                        <div class="d-flex gap-2">
                            <SoftButton @click="correctionNote.openStatus = !correctionNote.openStatus" id="correction-button" class="d-flex align-items-center">
                                <svg v-if="!correctionNote.openStatus" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="M12 14.975q-.2 0-.387-.075q-.188-.075-.313-.2l-4.6-4.6q-.275-.275-.275-.7q0-.425.275-.7q.275-.275.7-.275q.425 0 .7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275q.425 0 .7.275q.275.275.275.7q0 .425-.275.7l-4.6 4.6q-.15.15-.325.212q-.175.063-.375.063Z"/></svg>
                                <svg v-else xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="M6.7 14.7q-.275-.275-.275-.7q0-.425.275-.7l4.6-4.6q.15-.15.325-.213q.175-.062.375-.062t.388.075q.187.075.312.2l4.6 4.6q.275.275.275.7q0 .425-.275.7q-.275.275-.7.275q-.425 0-.7-.275L12 10.8l-3.9 3.9q-.275.275-.7.275q-.425 0-.7-.275Z"/></svg>
                                <div class="m-0">Correction / Retouche</div>
                            </SoftButton>
                            <div>
                                <div class="d-flex gap-2">
                                    <SoftButton @click="validateForPublishing" class="d-flex">
                                        Valider pour publication
                                    </SoftButton>
                                    <SoftButton v-if="editingRights === true" @click="saveArticle" class="d-flex">
                                        <div v-if="saveLoading" class="spinner-border spinner-border-sm " role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <div class="m-0">Sauvegarder</div>
                                    </SoftButton>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div v-if="publicationError.status" class="text-danger text-center">
                        {{ publicationError.message }}
                    </div>
                    <!-- CORRECTION NOTE -->
                    <div v-if="correctionNote.openStatus" class="mb-4">
                        <div id="correction-note-main-container">
                            <div class="text-center mb-4">
                                <h5><strong>Demande de correction / retouche</strong></h5>
                            </div>
                            <div class="my-3">
                                <QuillEditor @click="selectArea" style="background-color: white; min-height: 50vh;" id="editor" content-type="html" v-model:content="correctionNote.content" theme="snow" :read-only="false"/>
!                            </div>
                            <div class="text-warning text-center mb-3">
                                Attention les demandes de correction et de retouches peuvent impacter les performances du référencement !
                            </div>
                            <div v-if="correctionNote.error.status === true" class="text-danger text-center mb-3">
                                {{ correctionNote.error.message }}
                            </div>
                            <div class="d-flex justify-content-center">
                                <SoftButton v-if="!openPublicationValidation" @click="requestACorrection" class="d-flex">
                                    Envoyer la demande
                                </SoftButton>
                            </div>
                        </div>
                        <hr class="horizontal dark">
                    </div>
                    <!-- EDITEUR -->
                    <div class="container-fluid py-4">
                        <QuillEditor v-if="editingRights === true" style="background-color: white; min-height: 50vh;" id="editor" content-type="html" v-model:content="article.content" theme="snow" :read-only="false"/>       
                        <QuillEditor v-else style="background-color: white; min-height: 50vh;" id="editor" content-type="html" v-model:content="article.content" theme="snow" :toolbar="[]" :read-only="true"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onBeforeMount, onMounted, ref } from "vue";
    import axios from "axios";
    import Cookies from "js-cookie";
    import { useRoute, useRouter } from "vue-router";
    import { QuillEditor } from '@vueup/vue-quill';
    import SoftButton from "@/components/SoftButton.vue";
    import articleTools from "@/tools/articleTools.js";

    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`
    };

    const route = useRoute();
    const router = useRouter();
    //STATE
    const articleLoading = ref(true);

    const err = ref({
        status: false,
        message: ""
    });

    const article = ref({});

    const correctionNote = ref({
        content: "",
        openStatus: false,
        error: {
            status: false,
            message: ""
        }
    });

    const editingRights = ref(false);
    const saveLoading = ref(false);

    const publicationError = ref({
        status: false,
        message: ""
    });

    //METHODS
    const getArticleData = async () => {
        try {
            articleLoading.value = true;

            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/user/articles/get-one/${route.params.id}`,
                headers: axiosConfig,
            });
            
            if (response.status === 200) {
                article.value = { ...response.data };
            }
        } catch (error) {
            if (error.response.status === 404) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur si le problème persiste...";
            }
            err.value.status = true;
        } finally {
            articleLoading.value = false;
        }
    };
    const validateForPublishing = async () => {
        const textConfirmation = ("Souhaitez vous envoyer cet article en publication ? (Action irréversible)");
        publicationError.value.status = false;
        
        if (window.confirm(textConfirmation)) {
            try {
                const response = await axios({
                    method: "POST",
                    url: `${process.env.VUE_APP_SERVER}/user/articles/approve-publication`,
                    headers: axiosConfig,
                    data: {
                        articleId: article.value._id,
                    }
                });
                router.push("/natural-seo/history-of-contents"); 
            } catch (error) {
                if (error.response.status === 400) {
                    publicationError.value.message = error.response.data.error;
                } else {
                    publicationError.value.message = "Une erreur est survenue durant la demande publication, veuillez réessayer plus tard ou contacter un administrateur...";
                }
                publicationError.value.status = true;
            }
        } else {
            return;
        }
    };
    const requestACorrection = async () => {
        try {
            correctionNote.value.error.status = false;
            
            const response = await axios({
                method: "PATCH",
                url: `${process.env.VUE_APP_SERVER}/user/articles/request-correction`,
                headers: axiosConfig,
                data: {
                    articleId: article.value._id,
                    correctionNotes: correctionNote.value.content
                }
            });

            if (response.status === 200) {
                router.push("/natural-seo/history-of-contents");
            }
        } catch (error) {
            if (error.response.status === 400) {
                correctionNote.value.error.message = error.response.data.error;
            } else {
                correctionNote.value.error.message = "Une erreur est survenue durant la demande de correction, veuillez réessayer plus tard ou contacter un administrateur..."
            }
            correctionNote.value.error.status = true;
        }
    };
    const saveArticle = async () => {
        try {
            err.value.status = false;
            saveLoading.value = true;
            const response = await axios({
                method: "PATCH",
                url: `${process.env.VUE_APP_SERVER}/user/save-article`,
                headers: axiosConfig,
                data: {
                    articleId: route.params.id,
                    content: article.value.content
                }
            });
        } catch (error) {
            if (error.response.status === 404 || 401) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue durant la sauvegarde de l'article";
            }
        } finally {
            saveLoading.value = false;
        }
    };
    const getEditingRights = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/user/get-editing-rights`,
                headers: axiosConfig,
            });
            console.log(response);
            switch (response.data.editingRights) {
                case true: 
                    editingRights.value = true;
                break;
                default: 
                    editingRights.value = false;
            }
        } catch (error) {
            editingRights.value = false;
        }
        
    };
    const selectArea = () => {
        const area = document.querySelector("#editor .ql-editor");
        area.focus()
    };
    // LIFECYCLE HOOKS
    onMounted(() => {
        getArticleData();
        getEditingRights();
    });
  
</script>
<style scoped>
    .cancel-button {
        background-color: #8392AB;
        box-shadow: none;
    }
    #correction-button {
        background-color: #f39b9b;
        box-shadow: none;
    }
</style>