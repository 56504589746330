<template>
    <div class="input-customers-list">
        <Datalist 
            @valueChange="$emit('valueChange', $event)"
            :label="dataList.label"
            :inputsStyle="inputStyle"
            :dataList="dataList.options"
            :required="required"
        />
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { fetchCustomersList } from '@/useCases/userUseCases';
import Datalist from '@/components/standalone/inputs/Datalist.vue';

const props = defineProps({
    inputStyle: {
        type: Object,
        required: false
    },
    required: {
        type: Boolean,
        required: false,
        default: false
    }
});

const dataList = ref({
    options: [],
    label: 'Client',
});

const getCustomersList = async ()  => {
    const customersList = await fetchCustomersList({ scope: 'minimal' });

    dataList.value.options = customersList.map(customer => {
        return {
            value: customer._id,
            label: customer.fullName.concat(customer.websiteUrl ? ' - ' + customer.websiteUrl : '')
        };
    });
};

onMounted(() => {
    getCustomersList();
});
</script>
<style lang="scss" scoped></style>