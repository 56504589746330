<template>
    <div class="row w-100 p-4 g-0">
        <div v-if="articleLoading" class="text-center">
            Article en cours de chargement...
        </div>

        <div v-else class="col-lg-12 position-relative z-index-2">
            <div v-if="err.status" class="text-center text-danger">
                {{ err.message }}
            </div>
            <div v-else class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Lecture Article</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-3">
                    <div v-if="article.title">
                        <strong>Titre de l'article: </strong>
                        {{ article.title }} 
                    </div>
                    <div v-if="article.link">
                        <strong>Lien: </strong>
                        <a :href="formatHtmlLink(article.link)" target="_blank">{{ article.link }}</a>
                    </div>
                    <div v-if="article.keywords.primary">
                        <strong>Mot clé principal: </strong>
                        {{ article.keywords.primary }}
                    </div>
                    <div v-if="article.keywords.secondary">
                        <strong>Mot clé secondaire: </strong>
                        {{ article.keywords.secondary }}
                    </div>
                    <div v-if="article.type">
                        <strong>Type d'article: </strong>
                        {{ articleTools.getType(article.type) }}
                    </div>
                </div>
                <div id="editor-main-container" class="container-fluid p-3">
                    <!-- EDITEUR -->
                    <div class="container-fluid py-4">       
                        <QuillEditor style="background-color: white; min-height: 50vh;" id="editor" content-type="html" v-model:content="article.content" theme="snow" :toolbar="[]" :read-only="true"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import axios from "axios";
    import Cookies from "js-cookie";
    import { useRoute, useRouter } from "vue-router";
    import { QuillEditor } from '@vueup/vue-quill';
    import { formatHtmlLink } from "@/tools/divTools";
    import articleTools from "@/tools/articleTools.js";
    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`
    };

    const route = useRoute();
    const router = useRouter();
    //STATE
    const articleLoading = ref(true);

    const err = ref({
        status: false,
        message: ""
    });

    const article = ref({});

    //METHODS
    const getArticleData = async () => {
        try {
            articleLoading.value = true;

            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/user/articles/get-one/${route.params.id}`,
                headers: axiosConfig,
            });
            
            if (response.status === 200) {
                article.value = { ...response.data };
            }
        } catch (error) {
            if (error.response.status === 404) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur si le problème persiste...";
            }
            err.value.status = true;
        } finally {
            articleLoading.value = false;
        }
    };

    // LIFECYCLE HOOKS
    onMounted(() => {
        getArticleData();
    });
</script>
<style scoped>
    .cancel-button {
        background-color: #8392AB;
        box-shadow: none;
    }
    #published-now {
        margin-left: 10px;
    }
</style>