"use strict";

const domainKeywordsTools = {
    getPositionsDifferences: (keyword) => {
        let result = keyword.Position - keyword['Previous Position'];
        if (result < 0) {
            const absoluteNumber = Math.abs(result);
            return `
                <div class="difference-container" style="display: flex; align-items: center; justify-content: center;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#94E4AF" d="M11 18V9.825L7.4 13.4L6 12l6-6l6 6l-1.4 1.4L13 9.825V18h-2Z"/></svg>
                    <div>${absoluteNumber}</div>
                </div>
            `
        } 
        else if (result > 0) {
            return `
                <div class="difference-container" style="display: flex; align-items: center; justify-content: center;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#c43540" d="m12 18l-6-6l1.4-1.4l3.6 3.575V6h2v8.175l3.6-3.575L18 12l-6 6Z"/></svg>
                    <div>${result}</div>
                </div>
            `
        }
    }
};

export default domainKeywordsTools;