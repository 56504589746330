<template>
    <main id="history-of-content" class="classic-page">
        <div id="history-of-content__header" class="classic-page__header">
            <h2>Historique des contenus rédigés</h2>
        </div>
        <div id="history-of-content__body" class="classic-page__body">
            <div id="history-of-content__body__filters">
                <InputTextManifyingGlass 
                    :name="'article-title'"
                    :label="'Titre de l\'article'"
                    :placeholder="'Titre de l\'article'"
                    v-model="articlesFilters.title"
                />
                <SingleSelect 
                    :name="'article-status'"
                    :label="'Statut'"
                    :options="articleStatusList"
                    v-model="articlesFilters.status"
                />
                <SingleSelect 
                    :name="'article-type'"
                    :label="'Type'"
                    :options="articleTypesList"
                    v-model="articlesFilters.type"
                />
                <SingleSelect 
                    :name="'article-status'"
                    :label="'Ordre'"
                    :options="orderList"
                    v-model="articlesFilters.order"
                    :defaultValue="orderList[0].value"
                />
                <DatesIntervalSelector 
                    v-model="datesRange"
                />
                <div id="history-of-content__body__filters__buttons">
                    <CustomKernelButton @click="getArticles()" class="circle-button primary-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M7 9H2V7h5v2zm0 3H2v2h5v-2zm13.59 7l-3.83-3.83c-.8.52-1.74.83-2.76.83c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5c0 1.02-.31 1.96-.83 2.75L22 17.59L20.59 19zM17 11c0-1.65-1.35-3-3-3s-3 1.35-3 3s1.35 3 3 3s3-1.35 3-3zM2 19h10v-2H2v2z"/>
                        </svg>
                    </CustomKernelButton>
                    <CustomKernelButton @click="resetFilters" class="circle-button secondary-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 15 15"><path fill="currentColor" d="M3.64 2.27L7.5 6.13l3.84-3.84A.92.92 0 0 1 12 2a1 1 0 0 1 1 1a.9.9 0 0 1-.27.66L8.84 7.5l3.89 3.89A.9.9 0 0 1 13 12a1 1 0 0 1-1 1a.92.92 0 0 1-.69-.27L7.5 8.87l-3.85 3.85A.92.92 0 0 1 3 13a1 1 0 0 1-1-1a.9.9 0 0 1 .27-.66L6.16 7.5L2.27 3.61A.9.9 0 0 1 2 3a1 1 0 0 1 1-1c.24.003.47.1.64.27"/></svg>
                    </CustomKernelButton>
                </div>
            </div>
            <div id="history-of-content__body__content">
                <div id="history-of-content__body__content__card" class="container-card">
                    <MainLoader 
                        v-if="getArticlesStatus.loading" 
                    />
                    <EmbeddedError 
                        v-if="getArticlesStatus.error.status"
                        :message="getArticlesStatus.error.message"
                    />
                    <FlexTable 
                        v-if="articles.length && !getArticlesStatus.loading"
                        :headers="headers"
                        :data="articles"
                        breakpoint="1024px"
                    />
                    <EmbeddedMessage 
                        v-else-if="!articles.lenght && !getArticlesStatus.error.status && !getArticlesStatus.loading"
                        message="Aucun article trouvé."
                    />
                    <RoundPagination 
                        v-if="pagination.availablePages > 0" 
                        @pageRequest="handlePageRequest"
                        :availablePagesNb="pagination.availablePages" 
                        :currentPage="pagination.currentPage"
                    />
                    <ListCounter 
                        v-if="articles.length && !getArticlesStatus.loading"
                        :total="totalArticles"
                        :perPage="pagination.perPage"
                    />
                </div>
            </div>
        </div>
    </main>
</template>
<script setup>
import { articlesStatusListForCustomersInputs, articleTypesListForInputs } from '@/tools/articleTools.js';
import { getArticleStringStatus, getArticleColorStatus, getArticleType, getArticleActionForCustomer } from '@/tools/articleTools.js';
import EmbeddedMessage from "@/components/standalone/statusMessages/standard/EmbeddedMessage.vue";
import EmbeddedError from "@/components/standalone/statusMessages/errors/EmbeddedError.vue";
import InputTextManifyingGlass from '@/components/base/inputs/InputTextManifyingGlass.vue';
import DatesIntervalSelector from '@/components/base/inputs/DatesIntervalSelector.vue';
import CustomKernelButton from '@/components/base/buttons/CustomKernelButton.vue';
import {  fetchArticlesHistoryForCustomer } from '@/useCases/articleUseCases.js';
import RoundPagination from '@/components/base/paginations/RoundPagination.vue';
import FlexTable from '@/components/base/tables/FlexTable/FlexTable.vue';
import MainLoader from '@/components/standalone/loaders/MainLoader.vue';
import SingleSelect from '@/components/base/inputs/SingleSelect.vue';
import ListCounter from '@/components/base/other/ListCounter.vue';
import { getArticleDateForCustomer } from '@/tools/dateTools.js';
import { ref, onMounted, watch } from 'vue';

const articleStatusList = ref(articlesStatusListForCustomersInputs);
const articleTypesList = ref(articleTypesListForInputs);
const orderList = ref([
    { value: "desc", label: "Plus recents",},
    { value: "asc", label: "Plus anciens",},
]);
const headers = [
    { label: "Titre de l'article", tooltip: true, style: { 'flex': '2' }},
    { label: "Mot-clé principal", tooltip: true },
    { label: "Date" },
    { label: "Status"},
    { label: "Type de contenu"},
    { label: "" },
];
const setDefaultArticleFilters = () => {
    return {
        title: '',
        status: '',
        type: '',
        order: 'desc',
        startDate: '',
        endDate: '',
        page: 1
    };
};
const datesRange = ref({
    start: '',
    end: ''
});
const articlesFilters = ref(setDefaultArticleFilters());

const getArticlesStatus = ref({
    error: {
        status: false,
        message: "",
    },
    loading: true,
});
const articles = ref([]);
const totalArticles = ref(0);
const pagination = ref({
    availablePages: 0,
    currentPage: 1,
    perPage: 10
});


const prepareArticles = (articles) => {
    return articles.map(article => {
        return {
            title: {
                type: "text",
                label: "Titre de l'article",
                value: article.title,
                style: {
                    "flex": "2"
                }
            },
            keyword: {
                type: "text",
                label: "Mot-clé principal",
                value: article.keywords?.primary,
            },
            date: {
                type: "text",
                label: "Date",
                value: getArticleDateForCustomer(article),
            },
            status: {
                type: "label",
                label: "Status",
                value: getArticleStringStatus({ reqStatus: article.status, isCustomer: true}),
                color: getArticleColorStatus({ reqStatus: article.status, isCustomer: true}),
            },
            type: {
                type: "text",
                label: "Type de contenu",
                value: getArticleType({ reqType: article.type }),
            },
            action: getArticleActionForCustomer({ reqStatus: article.status, articleId: article._id }),
        }
    });
};

const getArticles = async ({ firstPage = true } = {}) => {
    try {
        if (firstPage) {
            articlesFilters.value.page = 1;
        }
        getArticlesStatus.value.loading = true;
        getArticlesStatus.value.error.status = false;
        const foundArticles = await fetchArticlesHistoryForCustomer(articlesFilters.value);
        const preparedArticles = prepareArticles(foundArticles.articlesList);
        articles.value = preparedArticles;
        pagination.value = {
            ...pagination.value,
            currentPage: foundArticles.currentPage,
            availablePages: foundArticles.pages,
            perPage: foundArticles.perPage
        }
        totalArticles.value = foundArticles.totalArticles;
    } catch (error) {
        if (error.error) {
            getArticlesStatus.value.error.message = error.error;
        } else {
            getArticlesStatus.value.error.message = "Une erreur est survenue durant la récupération des articles. Veuillez réessayer plus tard ou contacter un administrateur.";
        }
        getArticlesStatus.value.error.status = true;
    } finally {
        getArticlesStatus.value.loading = false;
    }
};
const resetFilters = () => {
    articlesFilters.value = setDefaultArticleFilters();
    datesRange.value = {
        start: '',
        end: ''
    };
    getArticles();
};
const handlePageRequest = (page) => {
    articlesFilters.value.page = page;
    getArticles({ firstPage: false });
};

watch (() => datesRange.value, () => {
    articlesFilters.value.startDate = datesRange.value.start;
    articlesFilters.value.endDate = datesRange.value.end;
}, { deep: true });

onMounted(() => {
    getArticles();
});
</script>
<style lang="scss" scoped>
#history-of-content {
    &__body {
        
        &__filters {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: end;
            gap: 0.5rem;
            margin-bottom: 2rem;

            &__buttons {
                display: block;
                position: relative;

                .secondary-button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(100%, -20%);
                    padding: 0.3rem;

                    &:hover {
                        background-color: red;
                    }
                }
            }
        }
    }
};

</style>