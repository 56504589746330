<template>
    <div class="row w-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Suivi rédactions</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <!-- INPUTS AND FILTERS -->
                    <div id="form-container">
                        <div class="input-container">
                            <label for="article-name">Titre de l'article</label>
                            <div class="input-group">
                                <span class="input-group-text text-body">
                                    <i class="fas fa-search" aria-hidden="true"></i>
                                </span>
                                <input v-model="articleParams.title" id="article-name" type="text" class="form-control" placeholder="Nom de l'article"/>
                            </div>
                        </div>
                        <div class="input-container">
                            <label for="writers">Rédacteur</label>
                            <input @keyup="filterUsersList('writersList')" v-model="articleParams.writerUser.displayValue" @focusin="writersList.show = true" @focusout="closeList('writersList')" id="writer" type="text" class="form-control" autocomplete="off">
                            <div v-if="writersList.show" class="datalist-container">
                                <ul class="datalist">
                                    <li v-for="writer in writersList.filteredList" :key="writer._id" @click.once="setUserParams(writer, 'writer')">
                                        {{ `${writer.email} - ${writer.fullName}` }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="input-container">
                            <label for="customers">Client</label>
                            <input @keyup="filterUsersList('customersList')" v-model="articleParams.customerUser.displayValue" @focusin="customersList.show = true" @focusout="closeList('customersList')" id="customer" type="text" class="form-control" autocomplete="off">
                            <div v-if="customersList.show" class="datalist-container">
                                <ul class="datalist">
                                    <li v-for="customer in customersList.filteredList" :key="customer._id" @click.once="setUserParams(customer, 'customer')">
                                        {{ `${customer.websiteUrl} / ${customer.fullName}` }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="input-container">
                            <label for="status">Status</label>
                            <select v-model="articleParams.status" name="status" id="status" class="form-control">
                                <option v-for="status in statusList" :key="status.value" :value="status.value">{{ status.displayValue }}</option>
                            </select>
                        </div>
                        <div class="input-container">
                            <label for="type">Type</label>
                            <select v-model="articleParams.type" name="type" id="type" class="form-control">
                                <option v-for="articleType in typeList" :key="articleType.value" :value="articleType.value">{{ articleType.displayValue }}</option>
                            </select>
                        </div>
                        <div class="input-container">
                            <label for="order">Ordre</label>
                            <select v-model="articleParams.order" name="order" id="order" class="form-control">
                                <option v-for="order in orderList" :key="order.value" :value="order.value">{{ order.displayValue }}</option>
                            </select>
                        </div>
                        <div id="dates-container" class="input-container">
                            <div>
                                <label for="start-date">Début</label>
                                <input v-model="articleParams.startDate" type="date" name="start-date" id="start-date" class="form-control">
                            </div>
                            <div>
                                <label for="end-date">Fin</label>
                                <input v-model="articleParams.endDate" type="date" name="end-date" id="end-date" class="form-control">
                            </div>
                        </div>
                        <div class="input-container">
                            <label for="date-type">Filtrer par date:</label>
                            <select v-model="articleParams.dateType" name="date-type" id="date-type" class="form-control">
                                <option v-for="dateType in dateTypesList" :key="dateType.value" :value="dateType.value">{{ dateType.displayValue }}</option>
                            </select>
                        </div>
                        <div id="apply-button-container" class="input-container">
                            <SoftButton @click="getArticles(true)" id="apply-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M7 9H2V7h5v2zm0 3H2v2h5v-2zm13.59 7l-3.83-3.83c-.8.52-1.74.83-2.76.83c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5c0 1.02-.31 1.96-.83 2.75L22 17.59L20.59 19zM17 11c0-1.65-1.35-3-3-3s-3 1.35-3 3s1.35 3 3 3s3-1.35 3-3zM2 19h10v-2H2v2z"/></svg>
                            </SoftButton>
                        </div>
                    </div>
                    <div v-if="err.status" id="error-message" class="text-center text-danger">
                        {{ err.message }}
                    </div>
                    <!-- END INPUTS AND FILTERS -->
                    <div class="card h-100 p-4">
                        <div id="result-counter">
                            <p><strong>Résultats:</strong> {{ writingsList?.length }} <span v-if="isMoreArticles">+</span></p>
                        </div>
                        <div class="table-responsive">
                            
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nom article</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Rédacteur</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Client</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date publication</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date rédaction</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="loading" colspan="7">
                                        <td colspan="7" style="width:100%;" class="text-center">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-show="!loading" v-for="(article, index) in writingsList" :key="index"> 
                                        <td class="text-center ellipse large">
                                            <span tabindex="0" data-bs-toggle="tooltip" data-bs-placement="top" :data-bs-title="article.title">
                                                <router-link :to="`/article-writing/${article._id}`" target="_blank">{{ article.title }}</router-link>
                                            </span>
                                        </td>
                                        <td class="text-center ellipse medium">
                                            <router-link :to="article.writerUser ? `/advisor/customer/${article?.writerUser._id}`: ''" :target="article.writerUser ? '_blank' : ''">{{ article.writerUser ? article.writerUser.fullName : "Non défini" }}</router-link>
                                        </td>
                                        <td class="text-center ellipse medium">
                                            <router-link :to="`/advisor/customer/${article?.customerUser._id}`" target="_blank">{{ article.customerUser.fullName + (article.customerUser.websiteUrl ? ` / ${article.customerUser.websiteUrl}`: "")}}</router-link>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <div class="alert" :class="{ 
                                                    'alert-dark': article.status === 0, 
                                                    'alert-primary': article.status === 1 || article.status === 4,
                                                    'alert-danger': article.status === 3,
                                                    'alert-warning': article.status === 2,
                                                    'alert-info': article.status === 5,
                                                    'alert-success': article.status === 6
                                                    }">
                                                    <div>
                                                        {{ articleTools.getStringStatus(article.status) }}
                                                    </div>
                                                    <div v-if="article.lastStatusDate && article.status === 1" class="text-center text-sm">
                                                        {{ getLocaleDateStringFromISO(article.lastStatusDate) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">{{ articleTools.getType(article.type) }}</td>    
                                        <td class="text-center">{{ getArticleDate(article) }}</td>
                                        <td class="text-center">{{ article.writingDate ? getLocaleDateStringFromISO(article?.writingDate) : "Aucune date"}}</td>
                                        <td>
                                            <div id="last-buttons-container">
                                                <router-link :to="`/admin/articles/brief-validation/${article._id}`" target="_blank">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20"><path fill="currentColor" d="M12.43 14.34A5 5 0 0 1 10 15a5 5 0 1 1 3.95-2L17 16.09V3a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 1.45-.63z"/><circle cx="10" cy="10" r="3" fill="currentColor"/></svg>
                                                    Brief
                                                </router-link>
                                                <a @click="approveArticleForPublication(article._id)" v-if="article.status === 2" href="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"><path fill="currentColor" d="m10.6 13.4l-2.15-2.15q-.275-.275-.7-.275t-.7.275q-.275.275-.275.7t.275.7L9.9 15.5q.3.3.7.3t.7-.3l5.65-5.65q.275-.275.275-.7t-.275-.7q-.275-.275-.7-.275t-.7.275L10.6 13.4ZM5 21q-.825 0-1.413-.588T3 19V5q0-.825.588-1.413T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.588 1.413T19 21H5Z"/></svg>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="isMoreArticles">
                                        <td colspan="7">
                                            <div id="more-articles-button-container">
                                                <div id="more-articles-button" @click="getArticles(false)">
                                                    <div v-if="loadMoreLoading" id="load-more-loading" class="spinner-border" role="status">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div>
                                                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 30c7.732 0 14-6.268 14-14S23.732 2 16 2S2 8.268 2 16s6.268 14 14 14Zm-1-19a1 1 0 1 1 2 0v4h4a1 1 0 1 1 0 2h-4v4a1 1 0 1 1-2 0v-4h-4a1 1 0 1 1 0-2h4v-4Z"/></svg>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import axios from 'axios';
    import Cookies from "js-cookie";
    import articleTools from "@/tools/articleTools";
    import SoftButton from "@/components/SoftButton.vue";
    import { getArticleDate, getLocaleDateStringFromISO } from "@/tools/dateTools.js";
    import { fetchArticleMonitoringList } from "@/useCases/articleUseCases.js"
    
    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        "Content-Type": "application/json",
    };

    // STATE
    const initArticleParams = () => {
        return {
            title: "",
            customerUser: {
                displayValue: "",
                id: ""
            },
            writerUser: {
                displayValue: "",
                id: ""
            },
            status: "",
            type: "",
            startDate: "",
            endDate: "",
            order: "newest",
            dateType: "all"
        }
    };
    const err = ref({
        status: false,
        message: ""
    });
    const loading = ref(true);
    const loadMoreLoading = ref(false);
    const statusList = ref([
        { value: "", displayValue: "Tous" },
        { value: "0", displayValue: "En rédaction" },
        { value: "1", displayValue: "Attente validation admin" },
        { value: "2", displayValue: "Attente validation client" },
        { value: "3", displayValue: "En correction" },
        { value: "4", displayValue: "En cours de publication" },
        { value: "5", displayValue: "En attente de parution" },
        { value: "6", displayValue: "Publié" },
    ]);
    const typeList = ref([
        { value: "", displayValue: "Tous" },
        { value: "Externe", displayValue: "Externe" },
        { value: "Interne", displayValue: "Page SEO" },
        { value: "Blog", displayValue: "Blog"}
    ]);
    const orderList = ref([
        { value: "newest", displayValue: "Plus récents" },
        { value: "oldest", displayValue: "Plus anciens" }
    ]);
    const dateTypesList = ref([
        { value: "all", displayValue: "Toutes" },
        { value: "writingDate", displayValue: "de rédaction" },
        { value: "publishedDate", displayValue: "de publication" }
    ]);
    const customersList = ref({
        show: false,
        list: [],
        filteredList: []
    });
    const writersList = ref({
        show: false,
        list: [],
        filteredList: []
    });
    const articleParams = ref(initArticleParams());
    const writingsList = ref([]);
    const isMoreArticles = ref(false);
    // METHODS
    const closeList = (listName) => {
        setTimeout(() => {
            switch (listName) {
                case "customersList":
                    customersList.value.show = false;
                break;
                case "writersList":
                    writersList.value.show = false;
                break;
            } 
        }, 250);
    };
    const filterUsersList = (type) => {
        let filteredList = [];
        switch (type) {
            case "customersList":
                articleParams.value.customerUser.id = "";
                filteredList = customersList.value.list.filter((user) => {
                    return user.fullName.toLowerCase().includes(articleParams.value.customerUser.displayValue.toLowerCase()) ||
                        user.websiteUrl?.toLowerCase().includes(articleParams.value.customerUser.displayValue.toLowerCase()) ||
                        user.email?.toLowerCase().includes(articleParams.value.customerUser.displayValue.toLowerCase());
                });
                customersList.value.filteredList = filteredList;
            break;
            case "writersList":
                articleParams.value.writerUser.id = "";
                filteredList = writersList.value.list.filter((user) => {
                    return user.fullName.toLowerCase().includes(articleParams.value.writerUser.displayValue.toLowerCase()) ||
                        user.email?.toLowerCase().includes(articleParams.value.writerUser.displayValue.toLowerCase());
                });
                writersList.value.filteredList = filteredList;
            break;  
        }
    };
    const setUserParams = (user, userType) => {
        switch (userType) {
            case "customer":
                articleParams.value.customerUser.displayValue = `${user.websiteUrl} - ${user.fullName}`;
                articleParams.value.customerUser.id = user._id;
            break;
            case "writer":
                articleParams.value.writerUser.displayValue = user.fullName;
                articleParams.value.writerUser.id = user._id;
            break;
        }
    };
    const getCustomersList = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/admin/users/get-all?rankLevel=client`,
                headers: axiosConfig
            });
            if (response.status === 200) {
                customersList.value.list = response.data;
                customersList.value.filteredList = response.data;
            }
        } catch (error) {
            err.value.message = "Une erreur est survenue durant la récupérations des clients...";
            err.value.status = true;
        }
    };
    const getWritersList = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/admin/users/get-all?rankLevel=staff`,
                headers: axiosConfig
            });
            if (response.status === 200) {
                writersList.value.list = response.data;
                writersList.value.filteredList = response.data;
            }
        } catch (error) { 
            err.value.message = "Une erreur est survenue durant la récupérations des rédacteurs...";
            err.value.status = true;
        }
    };
    const getArticles = async (fullRefresh) => {
        try {
            if (fullRefresh === true) loading.value = true;
            else loadMoreLoading.value = true;
            
            err.value.status = false;

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/admin/writings-monitoring`, {
                method: "POST",
                headers: axiosConfig,
                body: JSON.stringify({
                    title: articleParams.value.title,
                    status: articleParams.value.status,
                    writerUser: articleParams.value.writerUser.id,
                    customerUser: articleParams.value.customerUser.id,
                    type: articleParams.value.type,
                    startDate: articleParams.value.startDate,
                    endDate: articleParams.value.endDate,
                    order: articleParams.value.order,
                    previousLimit: writingsList.value?.length,
                    dateType: articleParams.value.dateType,
                    fullRefresh: fullRefresh
                })
            });

            const response = await promise.json();
            
            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }
            if (fullRefresh === true) {
                writingsList.value = response.writingsMonitoringList;
            } else {
                writingsList.value.push(...response.writingsMonitoringList);
            }
            isMoreArticles.value = response.isMoreArticles;
        } catch (error) {
            if (error.status === 400 || 404) {
                err.value.message = error.error;
            } else {
                err.value.message = "Une erreur est survenue...";
            }
            err.value.status = true;
        } finally {
            loading.value = false;
            loadMoreLoading.value = false;
        }
    };
    const approveArticleForPublication = async (articleId) => {
        err.value.status = false;
        const textConfirmation = ("Souhaitez vous vraiment valider cet article pour publication ?");
        if (window.confirm(textConfirmation)) {
            try {
                const promise = await fetch(`${process.env.VUE_APP_SERVER}/article/approve`, {
                method: "POST",
                headers: axiosConfig,
                body: JSON.stringify({
                    articleId
                })
            });

            const response = await promise.json();

            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }

            getArticles(true);

            } catch (error) {
                if (error.status === 400 || 404 || 401) {
                    err.value.message = error.error;
                } else {
                    err.value.message = "Une erreur est survenue...";
                }
                err.value.status = true;
            }
            
        } else return;
    }

    // LIFECYCLE HOOKS
    onMounted(async() => {
        await getCustomersList();
        await getWritersList();
        getArticles(true);
    });
</script>
<style scoped>
    @import "../../../../../src/assets/css/tables-custom.css";
    #form-container {
        display: flex;
        margin-bottom: 1rem;
        flex-wrap: wrap;
        align-items: end;
    }
    .datalist-container {
        position: relative;
        width: 100%;
        z-index: 3;
    }
    .datalist {
        background-color: white;
        position: absolute;
        width: 100%;
        border-radius: 10px;
        padding: 0;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        height: 300px;
        overflow: auto;
        z-index: 2;
    }
    .datalist > li {
        list-style: none;
        padding: 10px;
    }
    .datalist > li:hover {
        cursor: pointer;
        background-color: #94E4AF;
    }
    .datalist > li:first-child {
        border-radius: 10px 10px 0 0;
    }
    .datalist > li:last-child {
        border-radius: 0 0 10px 10px;
    }
    .datalist > li:only-child {
        border-radius: 10px 10px 10px 10px;
    }
    .input-container {
        margin-right: 1rem;
        margin-top: 10px;
    }
    #apply-button {
       padding: 0.5rem 0.75rem;
       border-radius: 30px;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 38px;
        cursor: pointer;
        color: transparent;
        background: transparent;
    }
    #dates-container {
        display: flex;
    }
    #dates-container > div {
        position: relative;
    }
    #dates-container > div:first-child > input {
        border-radius: 0.5rem 0 0 0.5rem !important;
    }
    #dates-container > div:last-child > input {
        border-radius: 0 0.5rem 0.5rem 0 !important;
        border-left: none;
    }
    .alert {
        margin-bottom: 0;
        padding: 5px 15px;
        width: fit-content;
    }
    #error-message {
        margin: 1rem;
    }
    #more-articles-button-container {
        display: flex;
        justify-content: center;
    }
    #more-articles-button > svg {
        color:#94E4AF;
        cursor: pointer;
    }
    #more-articles-button > svg:hover {
        color: #7abe91;
    }
    #last-buttons-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    #last-buttons-container a {
        margin-left: 5px;
    }
    .table-responsive {
        height: 72vh;
        overflow: auto;
    }
    #result-counter p {
        font-size: 14px;
        margin: 0;
    }
    
    
    
</style>