<template>
    <ClassicPageLayout>
        <ContainerCardLayout>
            <div class="subscribe-to-network-offer">
                <div class="subscribe-to-network-offer__networks">
                    <ul class="subscribe-to-network-offer__networks__list" >
                        <li v-for="(network, index) in socialNetworkList" :key="index"  class="subscribe-to-network-offer__networks__list__item" >
                            <img :src="getSocialNetworkImagesFromName(network)" :alt="network">
                        </li>
                    </ul>
                </div>
                <p>Pour profiter d'une gestion complète et d'un suivi détaillé vos réseaux sociaux,
                <br>souscrivez à notre offre en contactant notre service commercial.</p>
            </div>
        </ContainerCardLayout>
    </ClassicPageLayout>
</template>
<script setup>
import { ref } from 'vue';
import ClassicPageLayout from '@/components/layouts/pagesLayouts/ClassicPageLayout.vue';
import ContainerCardLayout from '@/components/layouts/cardsLayouts/ContainerCardLayout.vue';
import { getSocialNetworkImagesFromName } from '@/tools/socialNetworkTools.js';

const socialNetworkList = ref([
    'facebook',
    'instagram',
    'twitter',
    'linkedin',
    'pinterest',
    'youtube',
    'tiktok',
]);
</script>
<style lang="scss" scoped>
.subscribe-to-network-offer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__networks {
        margin-bottom: 2rem;

        &__list {
            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;
            flex-wrap: wrap;
            gap: 1rem;
            
            &__item {

                img {
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }
        }
    }

    p {
        font-size: 1.2rem;
        font-weight: 500;
        
    }
}

</style>