<template>
    <div class="article-seo-suggestions">
        <div v-if="err.status" class="article-seo-suggestions__error">
            {{ err.message }}
        </div>
        <div v-if="!err.status && !articleSeoSuggestionsCurrentlyRecovering">
            <div id="scores-container">
                <div @click="fetchScores" class="reload-button" :class="{ 'reload-button_loading': scoreLoading }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="currentColor" d="M6 12.05q0 1.125.425 2.188T7.75 16.2l.25.25V15q0-.425.288-.713T9 14q.425 0 .713.288T10 15v4q0 .425-.288.713T9 20H5q-.425 0-.713-.288T4 19q0-.425.288-.713T5 18h1.75l-.4-.35q-1.3-1.15-1.825-2.625T4 12.05Q4 9.7 5.2 7.787T8.425 4.85q.35-.2.738-.025t.512.575q.125.375-.013.75t-.487.575q-1.45.8-2.313 2.213T6 12.05Zm12-.1q0-1.125-.425-2.187T16.25 7.8L16 7.55V9q0 .425-.288.713T15 10q-.425 0-.713-.288T14 9V5q0-.425.288-.713T15 4h4q.425 0 .713.288T20 5q0 .425-.288.713T19 6h-1.75l.4.35q1.225 1.225 1.788 2.663T20 11.95q0 2.35-1.2 4.263t-3.225 2.937q-.35.2-.737.025t-.513-.575q-.125-.375.013-.75t.487-.575q1.45-.8 2.313-2.212T18 11.95Z"/></svg>
                </div>
                <ArticleSeoSuggestionsScore 
                    scoreTitle="Score SEO"
                    scoreType="%"
                    :score="scores.currentScore"
                    :requiredScore="scores.requiredScore"
                    :subtext="`Sur les ${scores.requiredScore}% requis`"
                />
                <ArticleSeoSuggestionsScore 
                    scoreTitle="Introduction"
                    scoreType="%"
                    :score="scores.currentScoreIntro"
                    :requiredScore="15"
                    subtext="Objectif 15% / 200 mots"
                />
                <ArticleSeoSuggestionsScore 
                    scoreTitle="Suroptimisation"
                    scoreType="%"
                    :score="scores.overOptimisation"
                    :requiredScore="scores.maxOverOptimisation"
                    :subtext="`Sur ${scores.maxOverOptimisation}% max requis`"
                />
                <div class="score-notice">
                    <p>
                        Pour actualiser manuellement, sauvegardez votre article et cliquez sur 
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="currentColor" d="M6 12.05q0 1.125.425 2.188T7.75 16.2l.25.25V15q0-.425.288-.713T9 14q.425 0 .713.288T10 15v4q0 .425-.288.713T9 20H5q-.425 0-.713-.288T4 19q0-.425.288-.713T5 18h1.75l-.4-.35q-1.3-1.15-1.825-2.625T4 12.05Q4 9.7 5.2 7.787T8.425 4.85q.35-.2.738-.025t.512.575q.125.375-.013.75t-.487.575q-1.45.8-2.313 2.213T6 12.05Zm12-.1q0-1.125-.425-2.187T16.25 7.8L16 7.55V9q0 .425-.288.713T15 10q-.425 0-.713-.288T14 9V5q0-.425.288-.713T15 4h4q.425 0 .713.288T20 5q0 .425-.288.713T19 6h-1.75l.4.35q1.225 1.225 1.788 2.663T20 11.95q0 2.35-1.2 4.263t-3.225 2.937q-.35.2-.737.025t-.513-.575q-.125-.375.013-.75t.487-.575q1.45-.8 2.313-2.212T18 11.95Z"/></svg>
                    </p>
                </div>
                <!-- <ArticleSeoSuggestionsWordsCounter 
                    v-if="route.fullPath === '/customer-writing'"
                    :currentCount="10"
                    :requiredCount="getEstimatedWordsRequired()"
                /> -->
            </div>
            <ArticleSeoSuggestionsKeywords
                sectionName="Mots-clés obligatoires"
                :data="articleSeoSuggestionsRequiredKeywords"
            />
            <ArticleSeoSuggestionsKeywords
                sectionName="Mots-clés complémentaires"
                :data="articleSeoSuggestionsAdditionalKeywords"
            />
        </div>
    </div>
</template>
<script setup>
    import { onMounted, ref } from 'vue';
    import Cookies from 'js-cookie';
    import { useRoute } from 'vue-router';
    import ArticleSeoSuggestionsWordsCounter from '../ArticleSeoSuggestionsWordsCounter/ArticleSeoSuggestionsWordsCounter.vue';
    import ArticleSeoSuggestionsKeywords from '../ArticleSeoSuggestionsKeywords/ArticleSeoSuggestionsKeywords.vue';
    import ArticleSeoSuggestionsScore from '../ArticleSeoSuggestionsScore/ArticleSeoSuggestionsScore.vue';
    import { customerArticleStorage } from "@/tools/localstorageTools.js"; 

    const fetchConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        "Content-Type": "application/json",
    };
    const route = useRoute();
    const articleId = route.params.id;

    //PROPS
    const props = defineProps({
        articleContent: {
            type: String,
            required: true
        },
        disableBackups: {
            type: Boolean,
            required: false
        },
    });

    // DATA
    const err = ref({
        message: "",
        status: false
    });
    const article = ref(props.articleContent);
    const articleSeoSuggestionsCurrentlyRecovering = ref(false);
    const articleSeoSuggestionsRequiredKeywords = ref([]);
    const articleSeoSuggestionsAdditionalKeywords = ref([]);
    const estimatedWordsRequired = ref(0);
    const scores = ref({
        currentScore: 0,
        currentScoreIntro: 0,
        overOptimisation: 0,
        maxOverOptimisation: 0,
        requiredScore: 0,
    });
    const scoreLoading = ref(false);
    const keywordFromFront = ref('');
    
    // METHODS
    const fetchScores = () => {
        if (props.disableBackups === true) {
            fetchCurrentScoresWithoutDatabase();
        } else {
            fetchCurrentScores();
        }
    };
    const fetchArticleSeoSuggestions = async () => {
        try {
            err.value.status = false;

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/article-seo-suggestions/get-one`, {
                method: 'POST',
                headers: fetchConfig,
                body: JSON.stringify({
                    articleId: articleId
                })
            });

            const status = promise.status;
            const response = await promise.json();
            
            if (status !== 200) throw {
                status: status,
                ...response
            }
            

            if (response.currentlyRecovering === true) throw {
                status: 204,
                error: "Les données sont en cours de récupération, veuillez patienter..."
            }
           
            const data = response.data;
            
            if (!data) throw {
                status: 404,
                error: "Aucune donnée trouvée, veuillez relancer une recherche de suggestions"
            }
            
            articleSeoSuggestionsRequiredKeywords.value = getWordsCount(props.articleContent, data.requiredKeywords);
            articleSeoSuggestionsAdditionalKeywords.value = getWordsCount(props.articleContent, data.additionalKeywords);
            estimatedWordsRequired.value = data.estimatedWordsRequired;
            scores.value = data;            
           
        } catch (error) {
            if (error.status === 400 || 404 || 204) err.value.message = error.error;
            err.value.status = true;
        }
    };
    const fetchArticleSeoSuggestionsWithoutDatabase = async (keyword) => {
        try {

            err.value.message = "Les données sont en cours de récupération, veuillez patienter...";
            err.value.status = true;

            const storage = JSON.parse(localStorage.getItem('customerArticle'));

            keywordFromFront.value = keyword;

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/article-seo-suggestions/get-one-without-database`, {
                method: 'POST',
                headers: fetchConfig,
                body: JSON.stringify({
                    keyword: keyword,
                    htmlContent: storage.content
                })
            });

            const status = promise.status;
            const response = await promise.json();
            
            if (status !== 200) throw {
                status: status,
                ...response
            }
            

            storage.requiredKeywords = response.requiredKeywords;
            storage.additionalKeywords = response.additionalKeywords;
            storage.scores = response.scores;

            localStorage.setItem('customerArticle', JSON.stringify(storage));
            
            articleSeoSuggestionsRequiredKeywords.value = getWordsCount(props.articleContent, response.requiredKeywords);
            articleSeoSuggestionsAdditionalKeywords.value = getWordsCount(props.articleContent, response.additionalKeywords);
            
            scores.value = response.scores;
            
            err.value.status = false;
        } catch (error) {
            console.log(error);
        }
    };
    const fetchCurrentScores = async () => {
        try {
            scoreLoading.value = true;

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/article-seo-suggestions/get-current-scores`, {
                method: 'POST',
                headers: fetchConfig,
                body: JSON.stringify({
                    articleId: articleId
                })
            });

            const status = promise.status;
            const response = await promise.json();
            
            if (status !== 200) throw {
                status: status,
                ...response
            }

            scores.value.currentScore = response.currentScore;
            scores.value.overOptimisation = response.overOptimisation;
            scores.value.currentScoreIntro = response.currentScoreIntro;
            scores.value.maxOverOptimisation = response.maxOverOptimisation;
            scores.value.requiredScore = response.requiredScore;
            estimatedWordsRequired.value = response.estimatedWordsRequired;

        } catch (error) {
            
        } finally {
            scoreLoading.value = false;
        }
    };
    const fetchCurrentScoresWithoutDatabase = async () => {
        try {
            scoreLoading.value = true;
            const storage = JSON.parse(localStorage.getItem('customerArticle'));

            if (!storage?.requiredKeywords && !storage.additionalKeywords || !storage.estimatedWordsRequired) return;
           
            const allKeywords = storage?.requiredKeywords.concat(storage.additionalKeywords);

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/article-seo-suggestions/get-current-scores-without-database`, {
                method: 'POST',
                headers: fetchConfig,
                body: JSON.stringify({
                    htmlContent: storage.content,
                    allKeywords: allKeywords,
                    estimatedWordsRequired: storage.estimatedWordsRequired
                })
            });

            const status = promise.status;
            const response = await promise.json();
            
            if (status !== 200) throw {
                status: status,
                ...response
            }

            scores.value = response;
            scores.value.maxOverOptimisation = storage.scores.maxOverOptimisation;
            scores.value.requiredScore = storage.scores.requiredScore;
        } catch (error) {
            console.log(error);
        } finally {
            scoreLoading.value = false;
        }
    };
    const getWordsCount = (text, words) => {
        words.map((word) => {
            const lowerCaseText = text.toLowerCase();
            const lowerCaseWord = word[0].toLowerCase();
            const regex = new RegExp(`\\b(${lowerCaseWord}|${lowerCaseWord}s)\\b`, 'g');
            const findedWords = lowerCaseText.match(regex);
            word[3] = findedWords ? findedWords.length : 0;
            return word;
        }); 
        return words;       
    };
    const refreshWordsCounts = (articleContent) => {
        article.value = articleContent;
        articleSeoSuggestionsRequiredKeywords.value = getWordsCount(article.value, articleSeoSuggestionsRequiredKeywords.value);
        articleSeoSuggestionsAdditionalKeywords.value = getWordsCount(article.value, articleSeoSuggestionsAdditionalKeywords.value);
    };
    defineExpose({
        refreshWordsCounts,
        fetchScores,
        fetchArticleSeoSuggestionsWithoutDatabase
    });

    // LIFECYCLE HOOKS
    onMounted(() => {
        if (props.disableBackups === true) {

            const storage = customerArticleStorage.getOrCreateAndReturnStorage();
            keywordFromFront.value = storage?.keyword;

            if (!keywordFromFront.value) {
                err.value.message = "Veuillez renseigner un mot-clé pour obtenir une analyse SEO";
                err.value.status = true;
                return;
            }
            
            if (!storage?.content && !storage?.requiredKeywords) {
                err.value.message = "Une erreur est survenue durant la récupération locale des suggestions de mots-clés, veuillez tenter de valider à nouveau votre mot-clé.";
                err.value.status = true;
                return;
            };

            articleSeoSuggestionsRequiredKeywords.value = getWordsCount(storage.content, storage.requiredKeywords);
            articleSeoSuggestionsAdditionalKeywords.value = getWordsCount(storage.content, storage.additionalKeywords);

            fetchCurrentScoresWithoutDatabase();
        }
        else {
            fetchArticleSeoSuggestions();
        }
    });

</script>
<style src="./article-seo-suggestions.scss" lang="scss" scoped>

</style>