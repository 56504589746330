<template>
    <div class="create-article classic-page">
        <div class="create-article__page-title classic-page__header">
            <h2>Création brief d'article</h2>
        </div>
        <div class="create-article__form-wrapper container-card">
            <div class="create-article__form-wrapper__keywords">
                <InputText 
                    label="Mot-clé principal" 
                    name="primary-keyword" 
                    :required="true"
                    v-model="article.keywords.primary"
                />
                <InputText 
                    label="Mot-clé secondaire" 
                    name="secondary-keyword" 
                    :required="false"
                    v-model="article.keywords.secondary"
                />
            </div>
            <div class="create-article__form-wrapper__title">
                <InputText 
                    label="Titre de l'article" 
                    name="article-title" 
                    :required="true"
                    v-model="article.title"
                />
            </div>
            <div class="create-article__form-wrapper__customer">
                <InputCustomersList 
                    :key="refreshKey"
                    @valueChange="handleCustomerChange" 
                    :required="true" 
                />
                <div v-if="article.customerUser"  class="create-article__form-wrapper__customer__services">
                    <CustomerArticlesTinyList :customerId="article.customerUser"/>
                    <CustomerFilesLink :customerUserId="article.customerUser"/>
                </div>
            </div>
            <div v-if="verifiedUserRank === 4" class="create-article__form-wrapper__writer">
                <InputWritersList  
                    :key="refreshKey"
                    @valueChange="handleWriterChange"
                />
            </div>
            <div class="create-article__form-wrapper__editorial-notes">
                <TextArea 
                    label="Notes" 
                    name="editorial-notes" 
                    v-model="article.editorialNotes"
                />
            </div>
            <div class="create-article__form-wrapper__date">
                <InputDate 
                    label="Date limite" 
                    name="due-date" 
                    :required="true"
                    v-model="article.dueDate"
                />
            </div>
            <div class="create-article__form-wrapper__article-type">
                <SingleSelect 
                    label="Type d'article" 
                    name="article-type" 
                    :required="true"
                    v-model="article.type"
                    :options="optionsList"
                />
            </div>
            <div v-if="verifiedUserRank === 4" class="create-article__form-wrapper__seo-suggestions">
                <CustomKernelRadio 
                    legend="Activer les suggestions SEO" 
                    name="seo-suggestions" 
                    v-model="article.seoSuggestions"
                    :options="seoSuggestionsOptions"
                />
            </div>
            <div class="create-article__form-wrapper__buttons-wrapper">
                <CustomKernelButton 
                    @click="requestArticleCreation" 
                    :loading="requestArticleCreationStatus.loading"
                    :disabled="requestArticleCreationStatus.loading"
                    class="primary-button"  
                >
                    Créer le brief
                </CustomKernelButton>
                <div class="create-article__form-wrapper__buttons-wrapper__required">
                    * Champs obligatoires
                </div>
            </div>
            <EmbeddedError 
                v-if="requestArticleCreationStatus.error.status" 
                :message="requestArticleCreationStatus.error.message"
            />
            <EmbededSuccess 
                v-if="requestArticleCreationStatus.success.status" 
                :message="requestArticleCreationStatus.success.message"
            />
        </div>
    </div>
</template>
<script setup>
    import { ref, onMounted } from "vue";
    import articleTools from "@/tools/articleTools.js";
    import { fetchMyUserRank } from "@/useCases/userUseCases";
    import { createArticle } from "@/useCases/articleUseCases";
    import TextArea from "@/components/standalone/inputs/TextArea.vue";
    import InputDate from "@/components/standalone/inputs/InputDate.vue";
    import InputText from "@/components/standalone/inputs/InputText.vue";
    import InputWritersList from "@/components/services/InputWritersList.vue";
    import SingleSelect from "@/components/standalone/inputs/SingleSelect.vue";
    import CustomerFilesLink from "@/components/services/CustomerFilesLink.vue";
    import InputCustomersList from '@/components/services/InputCustomersList.vue';
    import CustomKernelButton from "@/components/base/buttons/CustomKernelButton.vue";
    import CustomKernelRadio from "@/components/base/inputs/CustomKernelRadio.vue";
    import CustomerArticlesTinyList from "@/components/services/CustomerArticlesTinyList.vue";
    import EmbeddedError from "@/components/standalone/statusMessages/errors/EmbeddedError.vue";
    import EmbededSuccess from "@/components/standalone/statusMessages/success/EmbededSuccess.vue";
    
    const refreshKey = ref(0);
    const setDefaultArticle = () => {
        return {
            title: '',
            keywords: {
                primary: '',
                secondary: ''
            },
            editorialNotes: '',
            customerUser: '',
            writerUser: '',
            dueDate: '',
            type: '',
            seoSuggestions: false
        }
    };
    const optionsList = articleTools.getArticleTypes();
    const seoSuggestionsOptions = [
        { value: true, label: 'Activer' },
        { value: false, label: 'Désactiver'}
    ];
    const requestArticleCreationStatus = ref({
        loading: false,
        error: {
            status: false,
            message: "Une erreur est survenue lors de la création de l'article. Veuillez réessayer."
        },
        success: {
            status: false,
            message: "L'article a bien été créé."
        }
    });

    const verifiedUserRank = ref(null);
    const article = ref(setDefaultArticle());

    const handleCustomerChange = (customer) => {
        article.value.customerUser = customer.value;
    };
    const handleWriterChange = (writer) => {
        article.value.writerUser = writer.value;
    };
    const checkIfArticleIsComplete = () => {
        const articleData = article.value;
        if (!articleData.title) {
            throw { error: "Le titre de l\'article est obligatoire." };
        }
        if (!articleData.keywords.primary) {
            throw { error: "Le mot-clé principal est obligatoire." };
        }
        if (!articleData.customerUser) {
            throw { error: "Le client est obligatoire." };
        }
        if (!articleData.dueDate) {
            throw { error: "La date limite est obligatoire." };
        }
        if (!articleData.type) {
            throw { error: "Le type d\'article est obligatoire." };
        }
    };
    const requestArticleCreation = async () => {
        try {
            requestArticleCreationStatus.value.loading = true;
            requestArticleCreationStatus.value.error.status = false;
            requestArticleCreationStatus.value.success.status = false;
            checkIfArticleIsComplete();

            await createArticle({ 
                article: article.value 
            });
            article.value = setDefaultArticle();

            refreshKey.value++;
            requestArticleCreationStatus.value.success.status = true;
        } catch (error) {
            console.error(error);
            if (error.error) {
                requestArticleCreationStatus.value.error.message = error.error;
            } else {
                requestArticleCreationStatus.value.error.message = "Une erreur est survenue lors de la création de l\'article. Veuillez réessayer.";
            }
            requestArticleCreationStatus.value.error.status = true;
        } finally {
            requestArticleCreationStatus.value.loading = false;
        }
    };
    
    onMounted(async () => {
        verifiedUserRank.value = await fetchMyUserRank();
    });
</script>
<style lang="scss" scoped>
.create-article {
    &__form-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &__keywords {
            width: 100%;
            display: flex;
            gap: 0.5rem;
            flex-wrap: wrap;
        }

        &__customer {

            &__services {
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 0 1rem;
                justify-content: end;
                gap: 0.5rem;
            }
        }

        &__date {
            width: 100%;
            max-width: 250px;
        }

        &__article-type {
            width: 100%;
            max-width: 250px;
        }

        &__buttons-wrapper {
            margin-top: 1rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            &__required {
               font-size: 0.8rem;
            }
        }
    }
}
</style>