import { createRouter, createWebHistory } from 'vue-router';
import Settings from '../views/pages/Account/Settings.vue';
import Error404 from '../views/auth/error/Error404.vue';
import AdminUsers from '../views/pages/AdminSpace/AdminUsers/AdminUsers.vue';
import AdminArticlesValidation from '../views/pages/AdminSpace/AdminArticles/AdminArticlesValidation/AdminArticlesValidationList.vue';
import ArticlesBriefValidationList from '../views/pages/AdminSpace/AdminArticles/ArticleBriefValidation/ArticlesBriefValidationList.vue';
import ArticleBriefValidation from '../views/pages/AdminSpace/AdminArticles/ArticleBriefValidation/ArticleBriefValidation.vue';
import ArticleValidation from '../views/pages/CustomerSpace/ArticleValidation/ArticleValidation.vue';
import AdminCorrectionList from '../views/pages/AdminSpace/AdminArticles/AdminCorrection/AdminCorrectionList.vue';
import ArticleReading from '../views/pages/CustomerSpace/ArticleReading/ArticleReading.vue';
import AdvisorArticlePublicationList from '../views/pages/AdvisorSpace/AdvisorArticlePublication/AdvisorArticlePublicationList.vue';
import CustomersFilesList from '../views/pages/AdvisorSpace/CustomersFiles/CustomersFilesList.vue';
import CustomerFiles from '../views/pages/AdvisorSpace/CustomersFiles/CustomerFiles.vue';
import MyLinksList from '../views/pages/CustomerSpace/MyLinks/MyLinksList.vue';
import Cookies from 'js-cookie';
import Billing from '../views/pages/CustomerSpace/MyAccount/Billing.vue';
import AdminAddCompleteArticle from '../views/pages/AdminSpace/AdminArticles/AdminAddCompleteArticle/AdminAddCompleteArticle.vue';
import AdminWritingsMonitoring from '../views/pages/AdminSpace/AdminWritingsMonitoring/AdminWritingsMonitoring';
import AddCustomerSocialNetworkPublication from '../views/pages/AddCustomerSocialNetworkPublication/AddCustomerSocialNetworkPublication.vue';
import AdvisorSocialNetworksPublications from '../views/pages/AdvisorSpace/AdvisorSocialNetworksPublications/AdvisorSocialNetworksPublications.vue';
import SocialNetworksConnections from '@/views/pages/Account/SocialNetworksConnections/SocialNetworksConnections.vue';
import FileSharing from '@/views/pages/Account/FileSharing/FileSharing.vue';
import customerRoutes from './customerRoutes';
import commonRoutes from './commonRoutes.js';
import writerRoutes from './writerRoutes.js';


const routes = [
    {
        path: "/my-links",
        name: "Mes liens",
        component: MyLinksList,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/article-validation/:id",
        name: "Validation d'article",
        component: ArticleValidation,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/article-reading/:id",
        name: "Article à lire",
        component: ArticleReading,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    // ▼▼ MY ACCOUNT ▼▼ //
    {
        path: "/my-account/billing",
        name: "Mes factures",
        component: Billing,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/my-account/settings",
        name: "Mes paramètres",
        component: Settings,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/my-account/file-sharing",
        name: "Partage de fichiers",
        component: FileSharing,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/my-account/social-networks-connections",
        name: "Connexions aux réseaux sociaux",
        component: SocialNetworksConnections,
        meta: {
            auth: true,
            layout: "private"
        }
    },
     // ▲▲ MY ACCOUNT ▲▲ //
    // Advisors Routes
    {
        path: "/advisor/articles/publication-list",
        name: "Liste de publication des articles",
        component: AdvisorArticlePublicationList,
        meta: {
            auth: true,
            advisor: true,
            layout: "private"
        }
    },
    {
        path: "/advisor/social-network-publication/list",
        name: "Publications des réseaux sociaux",
        component: AdvisorSocialNetworksPublications,
        meta: {
            auth: true,
            advisor: true,
            layout: "private"
        }
    },
    {
        path: "/advisor/customers-files-list",
        name: "Liste des fichiers clients",
        component: CustomersFilesList,
        meta: {
            auth: true,
            advisor: true,
            layout: "private"
        }
    },
    {
        path: "/advisor/customer/:id",
        name: "Dossier client",
        component: CustomerFiles,
        meta: {
            auth: true,
            writer: true,
            referrer: "",
            layout: "private"
        },
        
    },
    {
        path: "/advisor/customer/:id/social-network-publication/add",
        name: "Ajouter une publication de réseau social",
        component: AddCustomerSocialNetworkPublication,
        meta: {
            auth: true,
            advisor: true,
            layout: "private"
        }
    },
    // Admin Routes
    {
        path: "/admin-users",
        name: "Admin Users",
        component: AdminUsers,
        meta: {
            auth: true,
            admin: true,
            layout: "private"
        }
    },
    {
        path: "/admin-articles-validation",
        name: "Validation des articles par l'administrateur",
        component: AdminArticlesValidation,
        meta: {
            auth: true,
            admin: true,
            layout: "private"
        }
    },
    {
        path: "/admin/articles/brief-validation-list",
        name: "Articles brief liste de validation",
        component: ArticlesBriefValidationList,
        meta: {
            auth: true,
            admin: true,
            layout: "private"
        }
    },
    {
        path: "/admin/articles/brief-validation/:id",
        name: "Articles Brief des validations",
        component: ArticleBriefValidation,
        meta: {
            auth: true,
            admin: true,
            layout: "private"
        }
    },
    {
        path: "/admin/articles/correction-list",
        name: "Articles Correction List",
        component: AdminCorrectionList,
        meta: {
            auth: true,
            admin: true,
            layout: "private"
        }
    },
    {
        path: "/admin/articles/add-complete-article",
        name: "Ajout Article Completé",
        component: AdminAddCompleteArticle,
        meta: {
            auth: true,
            admin: true,
            layout: "private"
        }
    },
    {
        path: "/admin/writings-monitoring",
        name: "Suivi Rédactions",
        component: AdminWritingsMonitoring,
        meta: {
            auth: true,
            admin: true,
            layout: "private"
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        ...routes, 
        ...commonRoutes,
        ...customerRoutes,
        ...writerRoutes,
        {
            name: 'Error Error404',
            path: '/:pathMatch(.*)*',
            component: Error404,
            meta: {
                layout: "public"
            }
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    document.title = `Kernel - ${to.name ? to.name : ""}`;
    to.meta.referrer = from.fullPath;
    try {
        if (to.meta.auth) {
            const token = await Cookies.get("token");
            if (!token) {
                throw "Invalid Token";
            } 
        }

        if (to.meta.writer) {
            let rank = await Cookies.get("rank");
            if (rank >= "2") {
                next();
            } else {
                next(from);
            }
        }
        else if (to.meta.advisor) {
            let rank = await Cookies.get("rank");
            if (rank >= "3") {
                next();
            } else {
                next(from);
            }
        }
        else if (to.meta.admin) {
            let rank = await Cookies.get("rank");
            if (rank === "4") {
                next();
            } else {
                next(from);
            }
        }
        else {
            next();
        }
    } catch (error) {
        window.Intercom('shutdown', {
            app_id: process.env.VUE_APP_INTERCOM_APP_ID,
        });
        next({ name: 'Connexion' });
        
    }
});

export default router;
