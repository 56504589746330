<template>
    <ClassicPageLayout class="advisor-social-networks-publications" pageTitle="Publications réseaux sociaux">
        <div class="advisor-social-networks-publications__filters">
            <SingleSelectOld 
                @valueChange="handleDateTypeValue"
                class="advisor-social-networks-publications__filters__date-type" 
                :label="dateTypeSelect.label"
                :options="dateTypeSelect.options"
                :name="dateTypeSelect.name"
                :selectStyle="dateTypeSelect.selectStyle"
            />
            <SingleSelectOld 
                @valueChange="handleDateOrderValue"
                class="advisor-social-networks-publications__filters__date-order" 
                :label="dateOrderSelect.label"
                :options="dateOrderSelect.options"
                :name="dateOrderSelect.name"
                :selectStyle="dateOrderSelect.selectStyle"
            />
            <SingleSelectOld 
                @valueChange="handleStatusValue"
                class="advisor-social-networks-publications__filters__status" 
                :label="statusSelect.label"
                :options="statusSelect.options"
                :name="statusSelect.name"
                :selectStyle="statusSelect.selectStyle"
            />
            <MultiSelect 
                @valueChange="handleNetworksValue"
                class="advisor-social-networks-publications__filters__networks" 
                :label="networksSelect.label"
                :options="networksSelect.options"
                :name="networksSelect.name"
                :placeholder="networksSelect.placeholder"
                :size="networksSelect.options.length"
                :selectStyle="networksSelect.selectStyle"
            />
            <InputCustomersList 
                @valueChange="handleCustomerValue"
                class="advisor-social-networks-publications__filters__customer" 
                :inputStyle=" { 'font-size': '0.8rem','padding': '0.3rem 0.4rem' }"
            />
        </div>
        <ContainerCardLayout height="80dvh">
            <transition name="switch">
                <ResponsiveTable
                    v-show="currentView === 'table'" 
                    @scrollEnd="loadMorePublications"
                    :noDataMessage="table.noDataMessage"
                    :tableHeaders="table.headers"
                    :tableData="table.data"
                    :loading="table.loading"
                    :error="table.err"
                    :bodyTrStyle="table.bodyTrStyle"
                />
            </transition>
            <transition name="switch">
                <SocialNetworkPublicationEditor
                    v-if="currentView === 'editor'"
                    @close="closeEditorAndRefreshOne"
                    :readOnly="socialNetworkPublicationEditor.readOnly"
                    :publicationId="socialNetworkPublicationEditor.publicationId"
                />
            </transition>
        </ContainerCardLayout>
    </ClassicPageLayout>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import mainStyle from '@/assets/scss/exports/main-variables.module.scss';
import ClassicPageLayout from '@/components/layouts/pagesLayouts/ClassicPageLayout.vue';
import ContainerCardLayout from '@/components/layouts/cardsLayouts/ContainerCardLayout.vue';
import ResponsiveTable from '@/components/standalone/tables/ResponsiveTable/ResponsiveTable.vue';
import SingleSelectOld from '@/components/standalone/inputs/SingleSelectOld.vue';
import MultiSelect from '../../../../components/standalone/inputs/MultiSelect.vue';
import InputCustomersList from '@/components/services/InputCustomersList.vue';
import SocialNetworkPublicationEditor from '@/components/linked/SocialNetworkPublicationEditor/SocialNetworkPublicationEditor.vue';
import { getColorStatus } from '@/tools/socialNetworkTools.js';
import { 
    fetchOneSocialNetworkPublicationById,
    fetchAllSocialNetworkPublications, 
    deleteSocialNetworkPublication, 
    publishSocialNetworkPublication 
} from '@/useCases/socialNetworkPublicationUseCases';
import { getLocalWeekStringFromWeekString, getLocaleDateStringFromISO } from '@/tools/dateTools';
import { displayConfirmationMessage } from '@/tools/modalTools';

const router = useRouter();
const currentView = ref('table');
const socialNetworkPublicationEditor = ref({
    readOnly: false,
    publicationId: null,
});
const table = ref({
    loading: true,
    err: {
        message: '',
        status: false
    },
    noDataMessage: 'Aucune publication trouvée',
    headers: [
        "",
        "Client",
        "Canaux",
        "Contenu",
        "Visuels de publication",
        "Semaine / Année",
        "Date d'import",
        "Status",
    ],
    data: [],
    bodyTrStyle: {
        'height': '110px',
    },
    tableContainerStyle: {
        'overflow-x': 'auto',
        'overflow-y': 'auto',
    }
});
const dateTypeSelect = ref({
    label: 'Trier par :',
    name: 'date-type',
    selectStyle: {
        'font-size': '0.8rem',
        'padding': '0.3rem 0.4rem'
    },
    options: [
        {
            label: "Semaine",
            value: "week",
        },
        {
            label: "Date d'import",
            value: "createdAt",
        }
    ],
});
const dateOrderSelect = ref({
    label: 'Ordre:',
    name: 'date-order',
    selectStyle: {
        'font-size': '0.8rem',
        'padding': '0.3rem 0.4rem'
    },
    options: [
        {
            label: "Plus récents",
            value: -1,
        },
        {
            label: "Plus anciens",
            value: 1,
        }
    ],
});
const statusSelect = ref({
    label: 'Status:',
    name: 'status',
    selectStyle: {
        'font-size': '0.8rem',
        'padding': '0.3rem 0.4rem'
    },
    options: [
        {
            label: "Toutes",
            value: 'all',
        },
        {
            label: "En attente",
            value: 0,
        },
        {
            label: "Validé",
            value: 1,
        },
        {
            label: "Publié",
            value: 2,
        },
        {
            label: "Correction",
            value: 3,
        }
    ],
});
const networksSelect = ref({
    label: 'Canaux:',
    placeholder: 'Non triés',
    name: 'networks',
    selectStyle: {
        'font-size': '0.8rem',
        'padding': '0.3rem 0.4rem'
    },
    options: [
        {
            label: "Facebook",
            value: 'facebook',
        },
        {
            label: "Instagram",
            value: 'instagram',
        },
        {
            label: "LinkedIn",
            value: 'linkedin',
        },
        {
            label: "Twitter",
            value: 'twitter',
        },
        {
            label: "TikTok",
            value: 'tiktok',
        },
        {
            label: "Pinterest",
            value: 'pinterest',
        },
        {
            label: "Youtube",
            value: 'youtube',
        },
    ],
});
const requestFilters = ref({
    dateType: 'week',
    dateOrder: -1,
    publicationStatus: 'all',
    networks: [],
    customerUserId: '',
});

const loadMorePublications = () => {
    if (table.value.loading) {
        return;
    }
    const loadMore = true;
    getPublications(loadMore);
};
const handleDateTypeValue = (value) => {
    requestFilters.value.dateType = value;
    getPublications();
};
const handleDateOrderValue = (value) => {
    requestFilters.value.dateOrder = value;
    getPublications();
};
const handleStatusValue = (value) => {
    requestFilters.value.publicationStatus = value;
    getPublications();
};
const handleNetworksValue = (value) => {
    requestFilters.value.networks = value;
    getPublications();
};
const handleCustomerValue = (value) => {
    requestFilters.value.customerUserId = value.value;
    getPublications();
};
const setCurrentView = (newView) => {
    currentView.value = newView;
};
const closeEditorAndRefreshOne = (publicationId) => {
    updateOnePublicationInTable(publicationId);
    setCurrentView('table');
};

const getPublications = async(loadMore) => {
    try {
        const currentIndex = loadMore ? table.value.data.length : 0;
        const filters = requestFilters.value;
        filters.currentIndex = currentIndex;

        loadMore ? '' : table.value.loading = true;

        const publications =  await fetchAllSocialNetworkPublications(filters);
        if (!loadMore) {
            table.value.data = [];
        }
        table.value.data.push(...formatPublicationData(publications));
    } catch (error) {
        if (error.error) {
            table.value.err.message = error.error;
        }
        table.value.err.status = true;
    } finally {
        table.value.loading = false;
    }
};

const updateOnePublicationInTable = async(publicationId) => {
    const publication = table.value.data.find((publication) => {
        return publication._id.value === publicationId;
    });
    const publicationIndex = table.value.data.indexOf(publication);
    const updatedPublication = await getOnePublication(publicationId);
    const updatedData = {
        ...publication,
        networks: {
            ...publication.networks,
            value: updatedPublication.networks,
        },
        week: {
            ...publication.week,
            value: getLocalWeekStringFromWeekString(updatedPublication.week),
        },
        content: {
            ...publication.content,
            value: updatedPublication.content,
        },
        visual: {
            ...publication.visual,
            value: getVisual(updatedPublication).value,
            contentType: getVisual(updatedPublication).contentType,
        },
        label: {
            ...publication.label,
            value: updatedPublication.stringStatus,
            color: getColorStatus(updatedPublication.status),
        },
    }
    table.value.data.splice(publicationIndex, 1, updatedData);
};

const getOnePublication = async(publicationId) => {
    try {
        const publicationData = await fetchOneSocialNetworkPublicationById(publicationId);
        return publicationData;
    } catch (error) {
        console.log(error);
    }
};

const formatPublicationData = (publications) => {
    const formatedPublications = publications.map((data) => {
        const visual = getVisual(data);
        const formatedItem = {
            _id: {
                value: data._id,
                tdStyle: {
                    'display': 'none'
                }          
            },
            buttons: {
                value: [
                    {
                        label: 'view',
                        color: mainStyle.grey1,
                        width: '18px',
                        action: () => {
                            setCurrentView('editor');
                            socialNetworkPublicationEditor.value.readOnly = true;
                            socialNetworkPublicationEditor.value.publicationId = data._id;
                        },
                    },
                    {
                        label: 'edit',
                        color: mainStyle.grey1,
                        width: '18px',
                        disabled: data.status !== 0 && data.status !== 3 && data.status !== 1,
                        action: () => {
                            console.log('edit');
                            setCurrentView('editor');
                            socialNetworkPublicationEditor.value.readOnly = false;
                            socialNetworkPublicationEditor.value.publicationId = data._id;
                        },
                    },
                    {
                        label: 'publish',
                        color: mainStyle.grey1,
                        width: '20px',
                        disabled: data.status === 2,
                        action: async() => {
                            await publishCustomerPublication(data._id);
                        },
                    },
                    {
                        label: 'delete',
                        color: mainStyle.errorColor,
                        width: '18px',
                        action: async() => {
                            await deleteCustomerPublication(data._id);
                        },
                    }
                ],
                contentType: 'buttons',
                tdStyle: {
                    'background-color': mainStyle.lightGrey,
                    'box-shadow': 'inset 0px 0px 2px 0px rgba(0,0,0,0.25), rgba(67, 71, 85, 0.27) 0px 0px 0.15em',
                    'width': '30px',
                }
            },
            customerUser: {
                value: (() => {
                    const customer = data.customerUserInformations;
                    return customer.fullName + ` - ${customer.websiteUrl}` || '';
                })(),
                link: '/advisor/customer/' + data.customerUserInformations._id,
                dataLabel: 'Client',
                contentType: 'link',
                tableLinkStyle: {
                    'text-align': 'center',
                    'font-size': '13px',
                    'font-weight': '600',
                },
            },
            networks: {
                value: data.networks,
                dataLabel: 'Canaux',
                contentType: 'networks-list',
                networksListStyle: {
                    'flex-direction': 'row',
                },
            },
            content: {
                value: data.content,
                dataLabel: 'Contenu',
                contentType: 'html',
                tdStyle: {
                    'width': '30%',
                    'text-align': 'center'
                }
            },
            visual: {
                value: visual.value,
                dataLabel: 'Visuels de publication',
                contentType: visual.contentType,
                hidePoints: true,
                tdStyle: {
                    'width': '12%',
                }
            },
            week: {
                value: getLocalWeekStringFromWeekString(data?.week),
                dataLabel: 'Semaine / année',
                contentType: 'text',
                fontSize: '12px',
                fontWeight: '600',
                isVerticalAllowed: true,
                tdStyle: {
                    'box-shadow': 'inset 0px 0px 1px 0px rgba(0,0,0,0.25), inset rgba(67, 71, 85, 0.27) 0px 0px 0.15em',
                    'background-color': mainStyle.lightGrey2,
                }
            },
            importDate: {
                value: getLocaleDateStringFromISO(data?.createdAt),
                dataLabel: 'Date d\'import',
                fontSize: '12px',
                contentType: 'text',
                isVerticalAllowed: true,
            },
            label: {
                value: data.stringStatus,
                color: getColorStatus(data.status),
                contentType: 'label',
                fontSize: '12px',
                dataLabel: 'Status',
                isVerticalAllowed: true
            },
        };
        return formatedItem;
    });
    return formatedPublications;
};

const deleteCustomerPublication = async(publicationId) => {
    try {
        const confirmationMessage = `Êtes-vous sûr de vouloir supprimer cette publication ?`;
        const isConfirmed = await displayConfirmationMessage(confirmationMessage);
        if (!isConfirmed) {
            return;
        }
        await deleteSocialNetworkPublication(publicationId);
        getPublications();
    } catch (error) {
        
    }
};

const publishCustomerPublication = async(publicationId) => {
    try {
        const confirmationMessage = `Êtes-vous sûr de vouloir publier cette publication ?`;
        const isConfirmed = await displayConfirmationMessage(confirmationMessage);
        if (!isConfirmed) {
            return;
        }
        
        await publishSocialNetworkPublication(publicationId);
        getPublications();
    } catch (error) {
        console.log(error);
    }
};

const getVisual = (data) => {
    if (data.images.length > 0) {
        return {
            value: data.images,
            contentType: 'images'
        }
    }
    else if (data.video?.link) {
        return {
            value: data.video,
            contentType: 'video'
        }
    }
    else {
        return {
            value: 'Aucun visuel',
            contentType: 'text'
        }
    };
};


onMounted(() => {
    getPublications();
});
</script>
<style lang="scss" scoped>
.advisor-social-networks-publications {
    &__filters {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin: 1rem 0;
        
        &__date-type, 
        &__date-order, 
        &__status {
            width: 130px;
        }
        &__networks {
            min-width: 130px;
            max-width: 210px;
        }
        &__customer {
            width: 250px;
        }
    }
}

.switch-enter-from {
   
}
.switch-enter-to {
  
}
.switch-leave-from {
   
}
.switch-leave-to {
  
}
</style>