<template>
    <div class="editor-correction-note">
        <label @click="toggleOpen" class="editor-correction-note__label">
            Notes de correction
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>                                        
        </label>
        <ContainerCardLayout v-show="isOpen"   class="editor-correction-note__content">
            <div v-html="sanitizedHtml()"></div>
        </ContainerCardLayout>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { sanitizeHtml } from '@/tools/htmlTools';
import styles from '@/assets/scss/exports/main-variables.module.scss';
import ContainerCardLayout from '@/components/layouts/cardsLayouts/ContainerCardLayout.vue';
const props = defineProps({
    correctionNote: {
        type: String ,
        required: true
    },
});
const isOpen = ref(false);
const sanitizedHtml = () => {
    return sanitizeHtml(props.correctionNote);
};
const toggleOpen = () => {
    console.log('toggle');
    isOpen.value = !isOpen.value;
};
</script>
<style lang="scss" scoped>
.editor-correction-note {
    height: 100%;
    width: 100%;
    position: relative;

    &__label {
        cursor: pointer;
        font-weight: bold;
        margin-bottom: 1rem;
        color: $error-color;
        user-select: none;

        &:hover {
            text-decoration: underline;
        }
    }
    
    &__content {
        position: absolute;
        background-color: white;
        top: 100%;
        right: 0;
        width: 40dvw;
        max-height: 250px;
        overflow-y: auto;
        padding: 1rem;
        border: 1px solid $error-color;
    }
}

</style>