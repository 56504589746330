<template>
    <CustomKernelButton @click="handleClick" class="maslo-button transparent-button">
        <img class="maslo-button__img" :src="imageButton" alt="Maslo" />
    </CustomKernelButton>
</template>
<script setup>
import { displayPrompt } from '@/tools/modalTools';
import { addCustomNumberOfPoints } from '@/useCases/masloUseCases.js';
import CustomKernelButton from '@/components/base/buttons/CustomKernelButton.vue';

const props = defineProps({
    user: {
        userId: String,
        fullName: String,
        required: true
    }
})
const imageButton = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAVFBMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD////Dw8Pu7u78/Pxvb2+ysrKUlJTOzs6Dg4OmpqbW1tZCQkJGRkbf398YGBhpTDkEAAAADHRSTlMAcJdZxn+4rc3oKxv7MYnjAAAAxElEQVQokX3SWRaDIAwFUMWKUwKCU233v89CDSg29H1F7gnRY4rCpxJNElEVISUwKQ9rOANovNW8AdQOk4N9sWoND8nA3Y7os8SxceK+IWWKUx9UKcQ7toRPf6GiTGYxFzT4ReqwrrYRDd03nzhHfGGilm6hzpGCEdfLCyVxOBoO9Xx0AoturqXhDKJaEU0O3TdryCI1ZnCDP/gOKH/RUC3vW6LPP+b2ZEjR6GgwZJeP1q/jrTsWV3Imw8r3ou2StKL35x9jeiL4sBwwMAAAAABJRU5ErkJggg=="
const handleClick = async () => {
    try {
        const response = await displayPrompt({ 
            promptMessage: `Combien de point souhaitez vous ajouter à ${props.user.fullName} ?`,
            defaultValue: 0,
            returnedType: 'number'
        });
        if (!response) return;
        await addCustomNumberOfPoints({
            userId: props.user.userId,
            points: response,
        });
    } catch (error) {
        console.error(error);
    }
};

</script>
<style lang="scss" scoped>
.maslo-button {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;

    &__img {
        width: 100%;
        height: 100%;
    }
}
</style>