<template>
    <div class="article-seo-suggestions-score">
        <h6>{{ scoreTitle }}</h6>
        <p class="score" :class="getScoreClass()" >{{ score }}{{ scoreType }}</p>
        <p v-if="subtext" class="subtext">{{ subtext }}</p>
    </div>
</template>
<script setup>
    const props = defineProps({
        scoreTitle: {
            type: String,
            required: true
        },
        score: {
            type: Number,
            required: true,
            default: 0
        },
        requiredScore: {
            type: Number,
            required: false
        },
        scoreType: {
            type: String,
            required: false,
            default: '...'
        },  
        subtext: {
            type: String, 
            required: false,
            default: undefined
        }
    });

    const getScoreClass = () => {
        const score = props.score;
        const required = props.requiredScore;
        const scoreTitle = props.scoreTitle;

        if (score < required && scoreTitle !== 'Suroptimisation') return 'score_bad';
        else if (score < required && scoreTitle === 'Suroptimisation') return 'score_good';
        else if (score > required && scoreTitle !== 'Suroptimisation') return 'score_good';
        else if (score > required && scoreTitle === 'Suroptimisation') return 'score_bad'
    };
</script>
<style src="./article-seo-suggestions-score.scss" lang="scss" scoped>

</style>