<template>
    <div class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg" :style="{backgroundImage:'url(' + require('@/assets/img/curved-images/greenfibre.jpg') + ')',}">
        <span class="mask bg-gradient-dark opacity-6"></span>
    </div>
    <div class="container">
        <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
            <div class="card z-index-0">
            <div class="pt-3 text-center">
                <h5>Inscription</h5>
            </div>
            <div class="card-body">
                <form role="form" >
                    <div class="mb-3">
                        <input class="form-control" v-model="user.email" id="email" type="email" placeholder="Adresse email" aria-label="Email"/>
                    </div>
                    <div class="mb-3">
                        <input class="form-control" v-model="user.fullName" id="name" type="text" placeholder="Prénom et Nom" aria-label="Name"/>
                    </div>
                    <div class="mb-3">
                        <input class="form-control" v-model="user.phone" id="Phone" type="tel" placeholder="Numéro de téléphone" aria-label="Phone"/>
                    </div>
                    <div class="mb-3">
                        <input class="form-control" v-model="user.password" id="password" type="password" placeholder="Mot de passe" aria-label="Password"/>
                    </div>
                    <div class="mb-3">
                        <input class="form-control" v-model="user.passwordValidation" id="password-validation" type="password" placeholder="Confirmation de mot de passe" aria-label="PasswordValidation"/>
                    </div>
                    <div class="mb-3">
                        <input class="form-control" v-model="user.websiteUrl" id="url" type="text" placeholder="URL de votre site (sans le https://)" aria-label="URL"/>
                    </div>
                    <div id="cgu" class="font-weight-light">
                        En m'inscrivant j'accepte les <a href="https://www.workwithkernel.com/mentions-legales" target="_blank" class="text-dark font-weight-bolder">Conditions générales d'utilisation</a>
                    </div>
                    <div class="text-center">
                            <soft-button @click.prevent="createUser" color="dark" full-width variant="gradient" class="my-4 mb-2">Créer mon compte</soft-button>
                    </div>
                    <div v-if="err.status === true || success.status === true" class="alert" :class="{'alert-danger': err.status, 'alert-success': success.status }">
                        <p v-if="err.status">{{ err.message }}</p> 
                        <p v-if="success.status">{{ success.message }}</p> 
                    </div>	
                    <p class="text-sm mt-3 mb-0">Déjà inscrit ?
                        <router-link to="/login" class="text-dark font-weight-bolder">
                            Connexion
                        </router-link>
                    </p>
                </form>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script setup>
    import { ref } from "vue";  
    import axios from 'axios';
    import { useRouter } from 'vue-router';
    import SoftButton from "@/components/SoftButton.vue";

    
    const router = useRouter();

    const user = ref({
        email: "",
        fullName: "",
        password: "",
        passwordValidation: "",
        phone: "",
        websiteUrl: "",
        cgu: true
    });

    const err = ref({
        message: "",
        status: false
    });
    const success = ref({
        message: "",
        status: false
    });

    const initialForm = () => {
        return {
            email: "",
            fullName: "",
            phone: "",
            password: "",
            passwordValidation: "",
            websiteURL: "",
            cgu: true
            
        }
    }
    const requestSuccess = ref(false);


    const form = ref(initialForm());


    const createUser = async() => {
        try {
            err.value.status= false;

            const response = await axios({
                method: 'POST',
                url: `${process.env.VUE_APP_SERVER}/user/create`,
                data: user.value
            })
            if (response.status === 201) {
                success.value.status = true;
                success.value.message = response.data.message;
                form.value = user.value = initialForm();
                requestSuccess.value = true;
				setTimeout(() => {router.push("/login")}, 6000);
            }
        } catch (error) {
            if (error.response.status === 400 || 500 || 409) {
                err.value.status = true;
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue, veuillez réessayer ultérieurement";
            }
        }
    };
</script>

<style scoped>


    #logo {
        max-width: 50%;
    }
    #cgu {
        font-size: 14px;
        text-align: center;
    }
    .alert-danger {
        background-color: rgba(193, 63, 89, 0.843)!important;
    }
    .alert-danger > p, .alert-success > p {
        color: #ffffff;
        text-align: center;
    }
    .alert-success {
        background-color: #a3d463!important;
    }
</style>