<template>
    <div id="add-customer-social-network-publication" class="row w-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Ajout publication réseau social</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <CustomerInformations 
                        :customerInformations="customerUser"
                        :loading="getCustomerUserStatus.loading"
                        :err="getCustomerUserStatus.error.status"
                        class="customer-information"
                    />
                    <div class="card card-body">
                        <form>
                            <div>
                                <label for="content">Texte de publication</label>
                                <QuillEditor @click="selectArea" v-model:content="formData.content" style="background-color: white; min-height: 20vh;" id="editor" content-type="html" theme="snow" toolbar="essential" :read-only="false"/>
                            </div>
                            <div class="networks-container">
                                <label>Canaux</label>
                                <fieldset class="networks">
                                    <div class="network" v-for="network in networksList" :key="network.value">
                                        <input type="checkbox" :id="network.value" :name="network.value" :value="network.value" v-model="formData.networks">
                                        <label class="network__label" :for="network.value">{{ network.displayValue }}</label>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="week-container">
                                <label for="week">Semaine</label>
                                <input v-model="formData.week" id="week" class="form-control" type="week">
                            </div>
                            <div class="visual-type-container">
                                <label>Type de visuel</label>
                                <fieldset class="visual-type">
                                    <input v-model="visualType" value="image" type="radio" name="visual-type-image" id="visual-type-image" checked>
                                    <label for="visual-type-image">Image(s)</label>
                                    <input v-model="visualType" value="video" type="radio" name="visual-type-video" id="visual-type-video">
                                    <label for="visual-type-video">Vidéo</label>
                                </fieldset>
                            </div>
                            <div v-if="visualType === 'image'">
                                <label for="images-upload">Images</label>
                                <div class="images">
                                    <input @change="handleFileInput($event, 'image')" :accept="acceptedFormatsImages" class="form-control" type="file" name="images-upload" multiple="multiple">
                                </div>
                            </div>
                            <div v-if="visualType === 'video'" class="video-container">
                                <label for="video-upload">Vidéo</label>
                                <div class="video">
                                    <input @change="handleFileInput($event, 'video')" name="video-upload" :accept="acceptedFormatsVideos" type="file" class="form-control">
                                </div>
                                <div v-show="videoPreview">
                                    <video id="video-preview" :src="videoPreview" controls></video>
                                </div>
                            </div>
                            <div>
                                <SoftButton @click.prevent="createPublication" id="add-publication" :disabled="createPublicationStatus.loading">
                                    Ajouter la publication
                                    <div v-if="createPublicationStatus.loading" class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </SoftButton>
                            </div>
                            <div v-if="createPublicationStatus.error.status" class="text-center text-danger">
                                {{ createPublicationStatus.error.message }}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import CustomerInformations from '@/components/linked/customerFilesComponents/CustomerInformations.vue';
    import { createSocialNetworkPublication } from '@/useCases/socialNetworkPublicationUseCases.js';
    import { generateThumbnailFromVideoFile } from '@/tools/mediaTools.js';
    import { socialNetworksList } from '@/tools/socialNetworkTools.js';
    import { fetchUserById } from '@/useCases/userUseCases.js';
    import SoftButton from '@/components/SoftButton.vue';
    import { useRoute, useRouter } from 'vue-router';
    import { QuillEditor } from '@vueup/vue-quill';
    import { onMounted, ref } from 'vue';
    
    const route = useRoute();
    const router = useRouter();
    // DATA
    const customerUser = ref({});
    const getCustomerUserStatus = ref({
        loading: false,
        error: {
            status: false,
            message: ''
        }
    });
    const createPublicationStatus = ref({
        error: {
            status: false,
            message: ''
        },
        loading: false
    }); 
    const networksList = ref(socialNetworksList);
    const visualType = ref('image');
    const formData = ref({
        content: '',
        networks: [],
        week: '',
        customerUserId: route.params.id,
        thumbnail: "",
        video: {},
        images: [],
    });
    const acceptedFormatsImages = ref("image/png, image/jpeg");
    const acceptedFormatsVideos = ref(".wmv, .mpeg4, .avi, .mov, .mp4");
    const videoPreview = ref("");

    // METHODS
    const getCustomerUser = async() => {
        try {
            getCustomerUserStatus.value.loading = true;
            const userId = route.params.id;
            const user = await fetchUserById(userId);
            customerUser.value = user;
        } catch (error) {
            getCustomerUserStatus.value.error.status = true;
            getCustomerUserStatus.value.error.message = "Une erreur est survenue lors de la récupération des informations du client";
        } finally {
            getCustomerUserStatus.value.loading = false;
        }
    } ;

    const createPublication = async() => {
        try {
            createPublicationStatus.value.error.status = false;
            createPublicationStatus.value.loading = true;
            const selectedVisualType = visualType.value;
            const isContentChecked = checkPublicationFormData();
            const publication = formData.value;
            if (!isContentChecked) {
                return;
            }
            if (selectedVisualType === 'video') {
                formData.value.thumbnail = await generateThumbnailFromVideoFile(formData.value.video);
            }
            if (selectedVisualType === 'image') {
                const imagesDataUrl = await convertImageToDataUrl(formData.value.images);
                formData.value.images = imagesDataUrl;
                console.log({imagesDataUrl});
            }
           
            await createSocialNetworkPublication(publication);
            // redirectToCustomerFiles();
        } catch (error) {
            if (error.error) {
                createPublicationStatus.value.error.message = error.error;
            } else {
                createPublicationStatus.value.error.message = "Une erreur est survenue lors de la création de la publication";
            }
            createPublicationStatus.value.error.status = true;
        } finally {
            createPublicationStatus.value.loading = false;
        }
    };

    const checkPublicationFormData = () => {
        clearUnusedVisuals();
        const data = formData.value;
        if (data.networks.length === 0) throw { 
            error: "Vous devez sélectionner au moins un canal" 
        }
        if (!data.week) throw {
            error: "Veuillez selectionner une semaine"
        }
        return true;
    };

    const selectArea = () => {
        const area = document.querySelector("#editor .ql-editor");
        area.focus();
    };

    const clearUnusedVisuals = () => {
        const type = visualType.value;
        
        if (type === 'image' && formData.value.video) {
            formData.value.video = {};
        }
        if (type === 'video' && formData.value.images.length > 0) {
            formData.value.images = [];
        }
    };

    const handleFileInput = async(event, fileType) => {
        const eventFiles = event.target.files;
        resetFormDataForFileType(fileType);
        console.log(eventFiles);
        if (!eventFiles.length) {
            return;
        }
        
        const filesActions = {
            'image': () => {
                formData.value.images.push(...eventFiles);
            },
            'video': () => {
                formData.value.video = eventFiles[0];
                createVideoPreview(eventFiles[0]);
            }
        }

        const action = filesActions[fileType];
        if (action) {
            action();
        }
    };

    const resetFormDataForFileType = (fileType) => {
        const cleanActions = {
            'image': () => {
                formData.value.images = [];
            },
            'video': () => {
                formData.value.video = {};
                videoPreview.value = '';
            }
        }

        const cleanFiles = cleanActions[fileType];
        if (cleanFiles){
            cleanFiles() 
        }
        
    };

    const redirectToCustomerFiles = () => {
        const customerUserId = route.params.id;
        router.push(`/advisor/customer/${customerUserId}`)
    };

    const createVideoPreview = (video) => {;
        const url = URL.createObjectURL(video);
        videoPreview.value = url;
    };
    
    // LIFECYCLE HOOKS
    onMounted(() => {
        getCustomerUser();
    })
</script>
<style lang="scss" src="./add-customer-social-network-publication.scss" scoped>
</style>