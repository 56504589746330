"use strict";

export const customerArticleStorage = {
    getOrCreateAndReturnStorage: () => {
        let storage = JSON.parse(localStorage.getItem('customerArticle'));

        if (storage) return storage;

        const object = {
            content: "",
            keyword: ""
        };

        localStorage.setItem('customerArticle', JSON.stringify(object));

        storage = JSON.parse(localStorage.getItem('customerArticle'));

        return storage;
    }
};

