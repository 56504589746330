import userTools from "@/tools/userTools";
import Cookies from "js-cookie";

const getUserEditingRights = async () => {
    try {
        const authParams = 'editingRights';
        const token = Cookies.get('token');
        const authorizations = await userTools.getUserAuthorizations(token, authParams);
        if (authorizations.editingRights) return false;
        return true;
    } catch (error) {
        return true;
    } 
};

const sideNavItemsList = [
    // UTILISATEURS
    {
        label: "Dashboard",
        route: "/",
        hasSubItems: false,
        icon: "dashboard",
        role: "user" 
    },
    {
        label: "Référencement naturel",
        route: "/natural-seo",
        hasSubItems: true,
        icon: "natural-seo",
        role: "user",
        subItems: [
            {
                label: "Contenus",
                route: "/natural-seo/history-of-contents"
            },
            {
                label: "Notes d'intervention",
                route: "/natural-seo/speaking-notes"
            },
            {
                label: "Audit SEO",
                route: "/natural-seo/seo-audit"
            },
            {
                label: "Statistiques trafic",
                route: "/natural-seo/traffic-statistics"
            },
            {
                label: "Positions des mots-clés",
                route: "/natural-seo/keywords-positions"
            },
            {
                label: "Audit de mots-clés",
                route: "/natural-seo/keywords-audit"
            },
            {
                label: "Outil de rédaction",
                route: "/natural-seo/customer-writing-tool",
                isHidden: await getUserEditingRights()
            }
        ]
    },
    {
        label: "Réseaux sociaux",
        route: "/social-networks",
        hasSubItems: true,
        notifications: "socialNetworkPublicationsAwaitingCustomerValidation",
        icon: "social-network",
        role: "user" ,
        subItems: [
            {
                label: "Validation des posts",
                route: "/social-networks/publications-validation"
            },
            {
                label: "Statistiques",
                route: "/social-networks/statistics"
            },
            {
                label: "Messagerie",
                route: "/social-networks/inbox"
            },
            {
                label: "Calendrier des publications",
                route: "/social-networks/publications-calendar"
            },
        ]
    },
    {
        label: "Parler à l'équipe",
        route: "",
        hasSubItems: false,
        icon: "chat",
        role: "user",
        hasFunction: true,
        function: "openChat"
    },
    {
        label: "Mon compte",
        route: "/my-account",
        hasSubItems: true,
        icon: "account",
        role: "user",
        subItems: [
            {
                label: "Mes factures",
                route: "/my-account/billing"
            },
            {
                label: "Mes paramètres",
                route: "/my-account/settings"
            },
            {
                label: "Partage de fichiers",
                route: "/my-account/file-sharing"
            },
            {
                label: "Connexion des réseaux",
                route: "/my-account/social-networks-connections"
            },
        ] 
    },
    // REDACTEURS
    {
        label: "Articles à rédiger",
        route: "/articles-writing-list",
        hasSubItems: false,
        notifications: "articlesPendingWriting",
        icon: "articles-writing",
        role: "writer"
    },
    {
        label: "Historique de rédaction",
        route: "/my-redaction-history",
        hasSubItems: false,
        icon: "history",
        role: "writer"
    },
    // CONSEILLERS
    {
        label: "Création de Brief",
        route: "/create-article-brief",
        hasSubItems: false,
        icon: "create-brief",
        role: "advisor"
    },
    {
        label: "Publication d'articles",
        route: "/advisor/articles/publication-list",
        hasSubItems: false,
        notifications: "articlesPendingPublication",
        icon: "publish-articles",
        role: "advisor"
    },
    {
        label: "Dossiers clients",
        route: "/advisor/customers-files-list",
        hasSubItems: false,
        icon: "customers-files",
        role: "advisor"
    },
    {
        label: "Réseaux sociaux",
        route: "/advisor/social-network-publication/list",
        hasSubItems: false,
        notifications: "socialNetworkPublicationsPendingAdvisor",
        icon: "social-network",
        role: "advisor"
    },
    // ADMINISTRATEURS
    {
        label: "Briefs d'articles",
        route: "/admin/articles/brief-validation-list",
        hasSubItems: false,
        notifications: "articlesBriefsPendingAdminValidation",
        icon: "articles-briefs",
        role: "admin"
    },
    {
        label: "Validation d'articles",
        route: "/admin-articles-validation",
        hasSubItems: false,
        notifications: "articlesPendingAdminValidation",
        icon: "articles-validation",
        role: "admin"
    },
    {
        label: "Correction d'articles",
        route: "/admin/articles/correction-list",
        hasSubItems: false,
        notifications: "articlesPendingCorrection",
        icon: "articles-correction",
        role: "admin"
    },
    {
        label: "Ajout d'articles",
        route: "/admin/articles/add-complete-article",
        hasSubItems: false,
        icon: "add-articles",
        role: "admin"
    },
    {
        label: "Suivi de rédaction",
        route: "/admin/writings-monitoring",
        hasSubItems: false,
        icon: "writings-monitoring",
        role: "admin"
    },
    {
        label: "Gestion utilisateurs",
        route: "/admin-users",
        hasSubItems: false,
        notifications: "usersPendingAdminValidation",
        icon: "users-management",
        role: "admin"
    },
];

export default sideNavItemsList;


