<template>
    <div class="table-video">
        <video :style="videoStyle()" :src="video.link" preload="none" :poster="video?.thumbnail" controls></video>
    </div>
</template>
<script setup>
    const props = defineProps({
        video: {
            type: Object,
            required: true
        }, 
        containerStyle: {
            type: Object,
            required: false,
        },
        videoStyle: {
            type: Object,
            required: false,
        },
        innerHeight: {
            type: String,
            required: false,
            default: '100%'
        }
    });
    const videoStyle = () => {
        const { innerHeight } = props;
        return {
            'max-height': innerHeight,
            ...props.videoStyle,
        }
    };
    
</script>
<style lang="scss" scoped>
    .table-video {
        display: flex;
        align-items: center;
        justify-content: center;

        video {
            height: 100%;
            width: auto;
            object-fit: cover;
            border-radius: 10px;

            &::-webkit-media-controls-panel {
            background-image: none !important;
            }
        }
    }
</style>