import { getFetchConfig } from '@/tools/fetchTools.js'

export const createArticle = async ({ article }) => {
    const fetchConfig = getFetchConfig('json');

    const promise = await fetch(`${process.env.VUE_APP_SERVER}/article/create`, {
        method: "POST",
        headers: fetchConfig,
        body: JSON.stringify(article),
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 201) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const getMyArticleWritingHistory = async () => {
    const fetchConfig = getFetchConfig('json');

    const promise = await fetch(`${process.env.VUE_APP_SERVER}/article/get-my-writing-history`, {
        method: "GET",
        headers: fetchConfig,
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const fetchMyLatestCustomerArticles = async () => {
    const fetchConfig = getFetchConfig('json');

    const promise = await fetch(`${process.env.VUE_APP_SERVER}/article/customer/latest-articles`, {
        method: "GET",
        headers: fetchConfig,
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const fetchArticlesHistoryForCustomer = async ({ title, status, type, order, startDate, endDate, page } = {}) => {
    const fetchConfig = getFetchConfig('json');
    
    const queryParams = new URLSearchParams();
    if (title) queryParams.append('title', title);
    if (status) queryParams.append('status', status);
    if (type) queryParams.append('type', type);
    if (order) queryParams.append('order', order);
    if (startDate) queryParams.append('startDate', startDate);
    if (endDate) queryParams.append('endDate', endDate);
    if (page) queryParams.append('page', page);

    if (endDate && !startDate || startDate && !endDate) {
        throw {
            error: 'Vous devez renseigner une date de début et une date de fin pour filtrer les articles par date.'
        };
    }
    const url = `${process.env.VUE_APP_SERVER}/article/customer/articles-history?${queryParams.toString()}`;
    const promise = await fetch(url, {
        method: 'GET',
        headers: fetchConfig
    });

    const resStatus = promise.status;
    const response = await promise.json();

    if (resStatus !== 200) {
        throw {
            status: resStatus,
            ...response
        }
    }
    return response;
};
