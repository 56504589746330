<template>
    <div class="row w-100 h-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Mots-clés d'un domaine</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="form-container">
                        <div class="domain-name-input-container">
                            <label for="domain-name">Nom de domaine</label>
                            <input @change="inputDomainName.verified = false" v-model="inputDomainName.inputValue" id="domain-name" type="text" class="form-control" placeholder="Nom de domaine"/>
                        </div>
                        <div class="input-container">
                            <label for="database">Base de données</label>
                            <select @change="inputDomainName.verified = false" v-model="selectedDatabase" name="database" id="database" class="form-control">
                                <option v-for="(database, index) in databasesList" :key="index" :value="database.value">{{ database.displayValue }}</option>
                            </select>
                        </div>
                        <div id="apply-button-container">
                            <SoftButton @click="verifyDomainName" id="apply-button" :class="{ 'apply-button-validate-domain': inputDomainName.verified }">
                                <div v-if="verifyDomainNameLoading" class="load-more-loading spinner-border spinner-border-sm">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <!-- Check svg -->
                                <svg v-if="inputDomainName.verified && !verifyDomainNameLoading" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M9 16.17L5.53 12.7a.996.996 0 1 0-1.41 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71a.996.996 0 1 0-1.41-1.41L9 16.17z"/></svg>
                                <!-- Search svg -->
                                <svg v-if="!inputDomainName.verified && !verifyDomainNameLoading" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m18.9 20.3l-5.6-5.6q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.612 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l5.625 5.625q.275.275.275.675t-.3.7q-.275.275-.7.275t-.7-.275ZM9.5 14q1.875 0 3.188-1.313T14 9.5q0-1.875-1.313-3.188T9.5 5Q7.625 5 6.312 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14Z"/></svg>
                            </SoftButton>
                        </div>
                    </div>
                    <div v-show="inputDomainName.error.status" class="text-danger text-center">
                        {{ inputDomainName.error.message }}
                    </div>
                    <div id="tabs-and-cards-container">
                        <div id="page-tabs">
                            <div @click="selectedPage = 'keywordsByDomainName'; refreshCredits()" class="page-tabs-element" :class="{ 'active-tab': selectedPage === 'keywordsByDomainName' }">Par domaine</div>
                            <div @click="selectedPage = 'keywordsByKeyword'; refreshCredits()" class="page-tabs-element" :class="{ 'active-tab': selectedPage === 'keywordsByKeyword' }">Par mot-clé</div>
                            <div @click="selectedPage = 'keywordsByUrl'; refreshCredits()" class="page-tabs-element" :class="{ 'active-tab': selectedPage === 'keywordsByUrl' }">Par url</div>
                        </div>
                        <KeywordsByDomainName ref="KeywordsByDomainNameRef" v-show="selectedPage === 'keywordsByDomainName'" :domainName="currentDomain.domainName" :domainKeywordsId="currentDomain.id" :database="previousDatabase" :key="refreshKey"/>
                        <KeywordsByKeyword ref="KeywordsByKeywordRef" v-show="selectedPage === 'keywordsByKeyword'" :domainName="currentDomain.domainName" :domainKeywordsId="currentDomain.id" :database="previousDatabase" :key="refreshKey"/>
                        <KeywordsByUrl ref="KeywordsByUrlRef" v-show="selectedPage === 'keywordsByUrl'" :domainName="currentDomain.domainName" :domainKeywordsId="currentDomain.id" :database="previousDatabase" :key="refreshKey"/>
                    </div>
                </div>      
            </div>
        </div>
    </div>
</template>
<script setup>
    import {onMounted, ref } from "vue";
    import Cookies from "js-cookie";
    import SoftButton from "@/components/SoftButton.vue";
    import KeywordsByDomainName from "./KeywordsByDomainName.vue";
    import KeywordsByKeyword from "./KeywordsByKeyword.vue";
    import KeywordsByUrl from "./KeywordsByUrl.vue";
    
    const fetchConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        "Content-Type": "application/json",
    };

    // STATE

    // => LOADERS
    const verifyDomainNameLoading = ref(false);
    ////
    
    const databasesList = ref([
        { value: "fr", displayValue: "🇫🇷 - France" },
        { value: "uk", displayValue: "🇬🇧 - Royaume-Uni" },
        { value: "es", displayValue: "🇪🇸 - Espagne" },
        { value: "it", displayValue: "🇮🇹 - Italie" },
        { value: "ca", displayValue: "🇨🇦 - Canada" },
        { value: "ch", displayValue: "🇨🇭 - Suisse" },
        { value: "de", displayValue: "🇩🇪 - Allemagne" },
    ]);
    const selectedPage = ref("keywordsByDomainName");
    const inputDomainName = ref({
        inputValue: "",
        verified: false,
        error: {
            message: "",
            status: false
        }
    });
    const currentDomain = ref({
        domainName: "",
        id: ""
    });
    const refreshKey = ref(0);
    const KeywordsByDomainNameRef = ref();
    const KeywordsByKeywordRef = ref();
    const KeywordsByUrlRef = ref();
    const selectedDatabase = ref("fr");
    const previousDatabase = ref("");
    
    //METHODS
    /**
     * Check if there is already a DomainKeywords for requested user in database. If not it create one. Then it returns the DomainKeywords.
     */
    const verifyDomainName = async () => {
        try {
            inputDomainName.value.verified = false;
            verifyDomainNameLoading.value = true;

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/semrush/domain-keywords/verify-domain`, {
                method: 'POST',
                headers: fetchConfig,
                body: JSON.stringify({
                    domainName: inputDomainName.value.inputValue,
                    database: selectedDatabase.value
                })
            });

            const status = promise.status;
            const response = await promise.json();

            if (status !== 200) {
                throw {
                    status: status,
                    ...response
                }
            }
            
            inputDomainName.value.inputValue = response.domainName;
            inputDomainName.value.verified = true;
            currentDomain.value.domainName = response.domainName;
            currentDomain.value.id = response._id;
            previousDatabase.value = response.database;
            selectedDatabase.value = response.database;
            refreshPreviousKeywords();
            
        } catch (error) {
            if (error.status === 401 || 404) inputDomainName.value.error.message = error.error;
            else inputDomainName.value.error.message = "Une erreur est survenue";
            inputDomainName.value.error.status = true;
        } finally {
            verifyDomainNameLoading.value = false;
        }
    };
    const getPreviousDomainKeywordsDomainName = async () => {
        try {
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/semrush/domain-keywords/get-previous`, {
                method: 'POST',
                headers: fetchConfig,
                body: JSON.stringify({
                    target: "domainName database"
                })
            });

            const status = promise.status;
            const response = await promise.json();

            if (status !== 200) {
                throw {
                    status: status,
                    ...response
                }
            }
            
            currentDomain.value.domainName = response.domainName;
            inputDomainName.value.inputValue = response.domainName;
            currentDomain.value.id = response._id;
            selectedDatabase.value = response.database;
            previousDatabase.value = response.database;
        } catch (error) {
            
        }
    };
    const refreshCredits = () => {
        const page = selectedPage.value;
        switch (page) {
            case "keywordsByDomainName":
                KeywordsByDomainNameRef.value.refreshCredits();
            break;
            case "keywordsByKeyword":
                KeywordsByKeywordRef.value.refreshCredits();
            break;
            case "keywordsByUrl":
                KeywordsByUrlRef.value.refreshCredits();
            break;
        }
    };
    const refreshPreviousKeywords = () => {
        KeywordsByDomainNameRef.value.getPreviousKeywords();
        KeywordsByKeywordRef.value.getPreviousKeywords();
        KeywordsByUrlRef.value.getPreviousKeywords();
    };
    onMounted(() => {
        getPreviousDomainKeywordsDomainName();
    });
    
</script>
<style lang="scss" src="./domain-keywords.scss" scoped>
</style>