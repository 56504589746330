<template>
    <section id="customer-informations" class="card">
        <div class="card-header">
            <h6 class="text-center">Informations Client</h6>
        </div>
        <hr class="mt-0 horizontal dark">
        <div class="card-body">
            <div v-if="err.status" class="text-danger">
                Erreur: {{ err.message }}
            </div>
            <div v-if="loading" class="text-center" >
                <div class="spinner-border text-center" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-if="!err.status && !loading">
                <div>
                    <strong>Nom Complet: </strong>
                    {{ customerInformations.fullName }}
                </div>
                <div v-if="userRank >= 3">
                    <strong>Email: </strong>
                    {{ customerInformations.email }}
                </div>
                <div>
                    <strong>Site Web: </strong>
                    {{ customerInformations.websiteUrl }}
                </div>
                <div v-if="userRank >= 3">
                    <strong>Téléphone: </strong>
                    {{ customerInformations.phone }}
                </div>
                <div v-if="userRank >= 3">
                    <strong>Date inscription: </strong>
                        {{ getDate(customerInformations.createdAt) }}
                </div>
            </div>
        </div>  
    </section>
</template>
<script setup>
    // PROPS
    const props = defineProps({
        customerId: {
            type: String,
            required: false
        },
        userRank: {
            type: Number,
            required: false,
            default: 2
        },
        customerInformations: {
            type: Object,
            required: true
        }, 
        loading: {
            type: Boolean, 
            required: true
        },
        err: {
            message: {
                type: String,
                required: false
            },
            status: {
                type: Boolean,
                required: true
            }
        }
    });
    
    const getDate = (date) => {
        return new Date(date).toLocaleDateString("FR-fr")
    };

</script>
<style scoped>
    .card {
        height: 100%;
    }
    h6 {
        margin: 0;
    }
    div.card-header {
        padding: 1rem;
    }
    div.card-body {
        padding: 0.8rem 1.5rem;
    }
</style>