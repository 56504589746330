import { getFetchConfig } from '@/tools/fetchTools.js';

export const addCustomNumberOfPoints = async ({ points, userId }) => {
    if (!points || !userId) return;
    const fetchConfig = getFetchConfig('json');
    const promise = await fetch(`${process.env.VUE_APP_SERVER}/maslo/points/add-custom`, {
        method: "POST",
        headers: fetchConfig,
        body: JSON.stringify({ points, userId }),
    });
    const status = promise.status;
    const response = await promise.json();
    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};