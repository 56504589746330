const userTools = {
    getUserMainInformations: async (token) => {
        try {

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/me`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });

            const response = await promise.json();
            const status = promise.status;

            if (status !== 200) {
                throw {
                    status: status,
                    ...response
                }
            }

            return response;
            
        } catch (error) {
            throw error;
        }
    },
    getUserAuthorizations: async (token, authParams) => {
        try {
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/auth/get-user-authorizations?authParams=${authParams}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            });

            const response = await promise.json();
            const status = promise.status;

            if (status !== 200) {
                throw {
                    status: status,
                    ...response
                }
            }

            return response;
        } catch (error) {
            throw error;
        } 
    }
};

export default userTools;