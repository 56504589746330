<template>
    <div class="tools-box">
        <div class="main-container" :class="{ 'main-container_open': isOpen }">
            <div class="main-button" @click="switchToolsBoxOpenStatus" :class="{ 'main-button_open': isOpen }">
                <svg class="main-button__svg" :class="{ 'main-button__svg_open': isOpen }" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16 12l4-4a2.828 2.828 0 1 0-4-4l-4 4m-2 2l-7 7v4h4l7-7m.5-8.5l4 4"/><path d="M12 8L7 3M5 5L3 7l5 5M7 8L5.5 9.5M16 12l5 5m-2 2l-2 2l-5-5m4 1l-1.5 1.5M3 3l18 18"/></g></svg>
            </div>
            <nav class="navigation-tabs">
                <ul>
                    <li @click="changeCurrentTool('gpt')" :class="{ 'active': currentTool === 'gpt'}">ChatBOT</li>
                    <li @click="changeCurrentTool('seo')" :class="{ 'active': currentTool === 'seo'}">SEO</li>
                </ul>
            </nav>
            <div id="tools-container">
                <GPTChat v-show="currentTool === 'gpt'" />
                <ArticleSeoSuggestions v-show="currentTool === 'seo'" ref="ArticleSeoSuggestionsRef" 
                    :articleContent="articleContent" 
                    :disableBackups="disableBackups" 
                />
            </div>
        </div>
    </div>  
</template>
<script setup>
    import ArticleSeoSuggestions from "@/components/ArticleSeoSuggestions/ArticleSeoSuggestions/ArticleSeoSuggestions.vue"; 
    import GPTChat from "@/components/GPTChat/GPTChat.vue";
    import { ref } from "vue";
    
    const props = defineProps({
        articleContent: {
            type: String,
            required: true
        },
        disableBackups: {
            type: Boolean,
            required: false
        },
        keywordFromFront: {
            type: String,
            required: false,
        }
    });
    
    //DATA
    const article = ref(props.articleContent.replace(/<[^>]+>/g, '')?.trimEnd()); 
    const isOpen = ref(false);
    const currentTool = ref("gpt");
    const ArticleSeoSuggestionsRef = ref();
    
    //METHODS
    const switchToolsBoxOpenStatus = () => {
        isOpen.value = !isOpen.value;
    };
    const changeCurrentTool = (choosenTool) => {
        currentTool.value = choosenTool; 
    };
    const getArticleSeoSuggestions = async (keyword) => {
        await ArticleSeoSuggestionsRef.value.fetchArticleSeoSuggestionsWithoutDatabase(keyword);
    };
    const refreshWordsCounts = (articleContent) => {
        ArticleSeoSuggestionsRef.value.refreshWordsCounts(articleContent);
    };
    const refreshSEOScores = () => {
        ArticleSeoSuggestionsRef.value.fetchScores();
    };

    defineExpose({
        refreshWordsCounts,
        refreshSEOScores,
        getArticleSeoSuggestions
    });
</script>
<style lang="scss" scoped>
.tools-box {
    height: 100dvh;
    position: sticky;
    z-index: 2;
    margin-top: 2rem;

    .main-container {
        height: 92dvh;
        
        border-radius: 0 10px 10px 10px;
        background-color: #F5F6F7;
        width: 0px;
        transition: ease-out 0.1s;
        overflow: hidden;

        &_open {
            border: 1px #80808026 solid;
            width: 365px;
        }

        .main-button {
            position: absolute;
            left: -40px;
            top: 0px;
            border: 1px #80808026 solid;
            border-radius: 10px;
            padding: 6px;
            padding-right: 7px;
            z-index: 3;
            background-color: #F5F6F7;
            cursor: pointer;
            box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

            &_open {
                border-radius: 10px 0 0 10px;
                border-right: none;
                box-shadow: none;
            }
            
            &__svg {
                color: grey;

                &_open {
                    color: #94e4af;
                }
            }
        }

        .navigation-tabs {
            box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

            ul {
                list-style: none;
                display: flex;
                padding: 0;
                margin: 0;
                height: 41px;

                li {
                    cursor: pointer;
                    width: 100%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: ease-out 0.1s;

                    &.active {
                        background-color: #94e4af;
                        border-radius: 10px;
                        color: white;
                    }
                    &:hover:not(.active) {
                        color: #94e4af;
                    }
                }
                
            }
        }

        #tools-container {
            height: calc(100% - 41px);
            overflow-y: scroll;
            overflow-x: hidden;
            padding-bottom: 0!important;
        }
    }
}
</style>