<template>
    <div class="flex-table-label">
        <div class="flex-table-label__card" :class="{ 'vertical': isVertical }" :style="labelStyle()">
            <slot></slot>
        </div>
    </div>
</template>
<script setup>
    import { createDarkerColorFromHexOrRgb } from '@/tools/colorTools.js';

    const props = defineProps({
        labelColor: {
            type: String,
            required: false,
            default: '#000000'
        },
        isVertical: {
            type: Boolean,
            required: false,
            default: false,
        },
    });
    const labelStyle = () => {
        const color = createDarkerColorFromHexOrRgb(props.labelColor, 60);
        const object = {
            'background-color': props.labelColor,
            'color': color
        };
        return object;
    };
</script>
<style lang="scss" scoped>
    .flex-table-label {
        display: flex;
        justify-content: center;
        overflow: hidden;
        white-space: nowrap;

        &__card {
            border-radius: 6px;
            width: 90%;
            padding: 6px 8px;
            font-size: 0.85rem;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;

            &.vertical {
                width: 30%;
            }
        }
    }
</style>