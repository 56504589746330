<template>
    <div class="multi-select">
        <label v-if="hasLabel" :for="name">
            {{ label }}
        </label>
        <select id="button" :style="setSelectStyle()" class="form-control" @click="toggleList" @mousedown="handleMousedown">
            <option value="resume" hidden>{{ getValuesResume() }}</option>
        </select>
        <select v-show="listOpened" @hover="changeSize" :style="setSelectStyle()" class="list form-control" multiple :size="size">
            <option 
                v-for="(option, index) in options" 
                :key="index" 
                @click="addOrRemoveValue(option.value)" 
                :value="option.value" 
                :class="{ 'selected': currentValues.includes(option.value) }"
                >
                {{ option.label }}
            </option>
        </select>
    </div>
</template>
<script setup>
import { ref, defineEmits, onMounted, onUnmounted, watch } from 'vue';
const emit = defineEmits(['valueChange']);
const props = defineProps({
    hasLabel: {
        type: Boolean,
        default: true
    },
    label: {
        type: String,
        default: 'Label'
    },
    name: {
        type: String,
        default: ''
    },
    options: {
        type: Array,
        required: true
    },
    size: {
        type: Number,
    },
    placeholder: {
        type: String,
        default: ''
    },
    selectStyle: {
        type: Object,
    }
});
const setSelectStyle = () => {
    if (props.selectStyle) {
        return props.selectStyle;
    }
    return '';
};
const listOpened = ref(false);
const selectedValue = ref('resume');
const currentValues = ref([]);
const oldCurrentValues = ref([]);
const addOrRemoveValue = (value) => {
    if (currentValues.value.includes(value)) {
        currentValues.value = currentValues.value.filter((val) => val !== value);
    }
    else {
        currentValues.value.push(value);
    }
    selectedValue.value = 'resume';
};
const getValuesResume = () => {
    const valuesFirstLetterInCapital = currentValues.value.map((value) => value.charAt(0).toUpperCase() + value.slice(1).substring(0, 2));
    const valueToReturn = currentValues.value.length > 0 ? valuesFirstLetterInCapital.join(', ') : props.placeholder;
    return valueToReturn;
};
const toggleList = (event) => {
    event.preventDefault();
    listOpened.value = !listOpened.value;
};
const handleMousedown = (event) => {
    event.preventDefault();
};
const handleClickOutside = (event) => {
    const selectButton = document.getElementById('button');
    const selectList = document.getElementsByClassName('list');
    const option = document.querySelectorAll('.list option');
    if (selectButton && selectList && option) {
        if (![selectButton, selectList, ...option].includes(event.target)) {
            listOpened.value = false;
        }
    }
};
const checkIfCurrentValuesChanged = () => {
    const current = currentValues.value;
    const old = oldCurrentValues.value;
    if (current.length !== old.length) {
        return true;
    }
    const isCurrentValuesChanged = old.every((value) => current.includes(value));
    return isCurrentValuesChanged ? false : true;
};
watch(listOpened, (listOpenedValue) => {
    if (listOpenedValue === false && checkIfCurrentValuesChanged()) {
        oldCurrentValues.value = [...currentValues.value];
        emit('valueChange', currentValues.value);
    }
});
onMounted(() => {
    document.addEventListener('click', handleClickOutside);
});
onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>
<style lang="scss" scoped>
.multi-select {
    display: flex;
    flex-direction: column;
    position: relative;

    select {
        border-radius: 0.5rem;
        font-size: 0.875rem;
        &.list {
            position: absolute;
            width: 100%;
            top: 100%;
            z-index: 1000;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
            border-radius: 0.6rem;
        }
        option {
            &:active, &:hover, &:focus, &:checked {
                background-color: white;
            }
            &.selected {
                background-color: $grey-1;
                color: white;
            }
        }
    }
}
</style>
```