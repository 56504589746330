<template>
    <div class="table-link" :style="setTableLinkStyle()">
        <router-link :to="link" target="_blank" rel="noopener noreferrer">{{ text }}</router-link>
    </div>
</template>
<script setup>
    import { defineProps } from 'vue';
    const props = defineProps({
        text: {
            type: String,
            required: true
        },
        link: {
            type: String,
            required: true
        },
        tableLinkStyle: {
            type: Object,
            required: false,
            default: () => ({})
        }
    });
    const setTableLinkStyle = () => {
        return props.tableLinkStyle;
    };
</script>
<style lang="scss" scoped>
    .table-link {
        display: flex;
        align-items: center;
        justify-content: center;

        &__link {
            margin: 0;
            padding: 0;
            font-size: 14px;
            color: #007bff;
            text-decoration: underline;
        }
    }
</style>