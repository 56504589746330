<template>
    <div id="main-container">
        <strong>Mes crédits :</strong>
        <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else>
            {{ kernelCreditsBalance }}
        </div>
    </div>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import Cookies from "js-cookie";

    // STATE
    const kernelCreditsBalance = ref(0);
    const loading = ref(true);
    // METHODS
    const getKernelCreditsBalance = async () => {
        try {
            loading.value = true;
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/kernel-credits/get-current-balance`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`,
                    "Content-Type": "application/json",
                }
            });

            const response = await promise.json();

            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }

            const kernelCredits = response.kernelCredits;
            kernelCreditsBalance.value = kernelCredits.added +  kernelCredits.core;

        } catch (error) {
            
        } finally {
            loading.value = false;
        }
    };
    // LIFECYCLE HOOKS
    onMounted(() => {
        getKernelCreditsBalance()
    });
</script>
<style scoped>
    #main-container {
        display: flex;
    }
    strong {
        margin-right: 5px;
    }
</style>