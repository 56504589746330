<template>
    <div id="logo-area">
        <img v-show="openStatus === 1" :src="displayedLogo.full" class="logo-full"/>
        <img v-show="openStatus === 0" :src="displayedLogo.noText" class="logo-no-text"/>
    </div>
    <hr class="horizontal dark">
</template>
<script setup>
import { onMounted, ref } from 'vue';
import logo from '@/assets/img/logo-kernel.png';
import noTextLogo from '@/assets/img/logo-kernel-sans-texte.png';
import Cookies from 'js-cookie';

const props = defineProps({
    openStatus: {
        type: Number,
        required: true
    }
});

const displayedLogo = ref({
    full: '',
    noText: ''
});

const manageLogoDisplayAccordingToCompany = async () => {
    const company = Cookies.get('companyInformations');
    if (company) {
        const parsedCompany = JSON.parse(company);
        displayedLogo.value.full = parsedCompany?.logo;
        displayedLogo.value.noText = parsedCompany?.logo;
    } else {
        displayedLogo.value.full = logo;
        displayedLogo.value.noText = noTextLogo;
    }
};

onMounted(async () => {
    await manageLogoDisplayAccordingToCompany();
});
</script>
<style lang="scss" scoped>
#logo-area {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img.logo-full {
        width: 80%;
        height: auto;
    }
    img.logo-no-text {
        width: 60%;
        height: auto;  
    }
}
hr {
    margin: 0.4rem;
}
</style>