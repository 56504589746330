<template>
    <div class="dates-interval-selector">
        <div class="dates-interval-selector__start">
            <label for="start-date">Début</label>
            <input @input="updateStart($event.target.value)" :value="modelValue.start" type="date" name="start-date" id="start-date" class="form-control">
        </div>
        <div class="dates-interval-selector__end">
            <label for="end-date">Fin</label>
            <input @input="updateEnd($event.target.value)" :value="modelValue.end" type="date" name="end-date" id="end-date" class="form-control">
        </div>
    </div>
</template>
<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => {
            return {
                start: '',
                end: ''
            };
        }
    }
});

const updateStart = (value) => {
    emit('update:modelValue', { ...props.modelValue, start: value });
};
const updateEnd = (value) => {
    emit('update:modelValue', { ...props.modelValue, end: value });
};
</script>
<style lang="scss" scoped>
.dates-interval-selector {
    display: flex;

    &__start, &__end {
        position: relative;
        input[type="date"]::-webkit-calendar-picker-indicator {
            position: absolute;
            left: 0px;
            width: 100%;
            height: 38px;
            cursor: pointer;
            color: transparent;
            background: transparent;
        }
    }

    &__start {
        input#start-date {
            border-radius: 0.5rem 0 0 0.5rem !important;
        }
    }

    &__end {
        input#end-date {
            border-radius: 0 0.5rem 0.5rem 0 !important;
            border-left: none;
        }
    }
}
</style>