<template>
    <div class="row w-100 h-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Audit de mots-clés</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="card h-100 p-4 text-center">
                        <div class="form-container">
                            <div class="input-container">
                                <label for="keyword">Mot-clé</label>
                                <input v-model="inputKeyword" id="keyword" type="text" class="form-control" autocomplete="off"/>
                            </div>
                            <div class="input-container">
                                <label for="database">Base de données</label>
                                <select @change="checkPreviousDatabase" v-model="selectedDatabase" name="database" id="database" class="form-control">
                                    <option v-for="(database, index) in databasesList" :key="index" :value="database.value">{{ database.displayValue }}</option>
                                </select>
                            </div>
                            <div id="apply-button-container">
                                <SoftButton id="apply-button" @click="getMainKeyword">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M7 9H2V7h5v2zm0 3H2v2h5v-2zm13.59 7l-3.83-3.83c-.8.52-1.74.83-2.76.83c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5c0 1.02-.31 1.96-.83 2.75L22 17.59L20.59 19zM17 11c0-1.65-1.35-3-3-3s-3 1.35-3 3s1.35 3 3 3s3-1.35 3-3zM2 19h10v-2H2v2z"/></svg>
                                </SoftButton>
                            </div>
                        </div>
                        <div v-show="err.status" class="text-danger">
                            {{ err.message }}
                        </div>
                        <div id="cost-in-credits">
                            <KernelCreditsBalance :key="refreshKey" />
                            <div class="cost-per-line"><strong>Coût de la recherche :</strong> 1 crédit</div>
                            <svg v-if="mainKeyword" @click="getInCsv" id="csv-button" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="m213.65 82.34l-56-56A8 8 0 0 0 152 24H56a16 16 0 0 0-16 16v72a8 8 0 0 0 8 8h160a8 8 0 0 0 8-8V88a8 8 0 0 0-2.35-5.66ZM152 88V44l44 44ZM48 180c0 11 7.18 20 16 20a14.24 14.24 0 0 0 10.22-4.66a8 8 0 0 1 11.56 11.06A30.06 30.06 0 0 1 64 216c-17.64 0-32-16.15-32-36s14.36-36 32-36a30.06 30.06 0 0 1 21.78 9.6a8 8 0 0 1-11.56 11.06A14.17 14.17 0 0 0 64 160c-8.82 0-16 9-16 20Zm103.81 16.31a20.82 20.82 0 0 1-9.19 15.23C137.43 215 131 216 125.13 216a61.34 61.34 0 0 1-15.19-2a8 8 0 0 1 4.31-15.41c4.38 1.2 14.95 2.7 19.55-.36c.88-.59 1.83-1.52 2.14-3.93c.34-2.67-.72-4.1-12.78-7.59c-9.35-2.7-25-7.23-23-23.11a20.58 20.58 0 0 1 9-14.95c11.85-8 30.72-3.31 32.84-2.76a8 8 0 0 1-4.07 15.48c-4.49-1.17-15.23-2.56-19.83.56a4.57 4.57 0 0 0-2 3.67c-.11.9-.13 1.09 1.12 1.9c2.31 1.49 6.45 2.68 10.45 3.84c9.82 2.83 26.33 7.66 24.14 24.97Zm63.72-41.62l-20 56a8 8 0 0 1-15.07 0l-20-56a8 8 0 0 1 15.07-5.38l12.47 34.9l12.46-34.9a8 8 0 0 1 15.07 5.38Z"/></svg>
                        </div>
                        <div>
                            <h5>Mot-clé principal</h5>
                            <div class="cost-per-line" v-show="mainKeyword.updatedAt"><strong>Dernère actualisation : </strong>{{ getLocaleDateStringFromISO(mainKeyword.updatedAt) }}</div>
                        </div>
                        <div class="table-responsive">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mot-clé</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Volume de recherches / mois</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre de résultats</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Difficulté</th>
                                        <th width="35%" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tendances (12 derniers mois)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="loading.mainKeyword" style="width:100%;" class="text-center">
                                        <td colspan="7">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td>{{ mainKeyword.Keyword }}</td>
                                        <td>{{ mainKeyword['Search Volume'] }}</td>
                                        <td>{{ mainKeyword['Number of Results'] }}</td>
                                        <td>
                                            <CircleProgressBar v-if="mainKeyword['Keyword Difficulty Index']" :value="mainKeyword['Keyword Difficulty Index']"/>
                                        </td>
                                        <td class="text-center">
                                            <VueApexCharts v-if="mainKeyword.Trends" type="area" height="95px" class="d-flex justify-content-center" :options="mainKeyword.Trends.options" :series="mainKeyword.Trends.series" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <hr class="horizontal dark m-5">
                        <div>
                            <h5>Suggestions</h5>
                            <div class="cost-per-line"><strong>Coût par ligne :</strong> 4 crédits (10 lignes par requête) <strong v-show="relatedKeywordsUpdate">Dernère actualisation : </strong>{{ relatedKeywordsUpdate }}</div>
                        </div>
                        <div id="related-keywords-table" class="table-responsive">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mot-clé</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Volume de recherches / mois</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nombre de résultats</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Difficulté</th>
                                        <th width="35%" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tendances (12 derniers mois)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="loading.relatedKeywords" style="width:100%;" class="text-center">
                                        <td colspan="7">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-else v-for="(relatedKeyword, index) in relatedKeywords" :key="index">
                                        <td>{{ relatedKeyword.Keyword }}</td>
                                        <td>{{ relatedKeyword['Search Volume'] }}</td>
                                        <td>{{ relatedKeyword['Number of Results'] }}</td>
                                        <td>
                                            <CircleProgressBar v-if="relatedKeyword['Keyword Difficulty Index']" :value="relatedKeyword['Keyword Difficulty Index']"/>
                                        </td>
                                        <td class="text-center">
                                            <VueApexCharts v-if="relatedKeyword.Trends" type="area" height="95px" class="d-flex justify-content-center" :options="relatedKeyword.Trends.options" :series="relatedKeyword.Trends.series" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="more-keywords-button-container">
                            <SoftButton @click="getRelatedKeywords" class="more-keywords-button m-3" :disabled="canLoadMoreKeywords === false">
                                <div v-if="loadingMoreKeywords" class="load-more-loading spinner-border spinner-border-sm">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <div v-if="relatedKeywords.length === 0">Afficher des suggestions</div>
                                <div v-else>Afficher plus de résultats</div>
                            </SoftButton>
                            <p class="cost">Coût: 40 crédits</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import Cookies from "js-cookie";
    import { onMounted, ref, toRaw } from "vue";
    import { getLocaleDateStringFromISO } from "@/tools/dateTools";
    import csvTools from "@/tools/csvTools.js";
    import divTools from "@/tools/divTools.js";
    import VueApexCharts from "vue3-apexcharts";
    import SoftButton from "@/components/SoftButton.vue";
    import CircleProgressBar from "@/components/CircleProgressBar.vue";
    import KernelCreditsBalance from "@/components/KernelCreditsBalance/KernelCreditsBalance.vue";
    
    const fetchConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        "Content-Type": "application/json",
    };
    // STATE
    // => LOADERS
    const canLoadMoreKeywords = ref(false);
    const loadingMoreKeywords = ref(false);
    const loading = ref({
        mainKeyword: false,
        relatedKeywords: false
    });
    // => DATA
    const err = ref({ status: false, message: "" });
    const refreshKey = ref(0);
    const inputKeyword = ref("");
    const databasesList = ref([
        { value: "fr", displayValue: "🇫🇷 - France" },
        { value: "uk", displayValue: "🇬🇧 - Royaume-Uni" },
        { value: "es", displayValue: "🇪🇸 - Espagne" },
        { value: "it", displayValue: "🇮🇹 - Italie" },
        { value: "ca", displayValue: "🇨🇦 - Canada" },
        { value: "ch", displayValue: "🇨🇭 - Suisse" },
        { value: "de", displayValue: "🇩🇪 - Allemagne" },
    ]);
    const selectedDatabase = ref("fr");
    const previousDatabase = ref("fr");
    const mainKeyword = ref({});
    const relatedKeywords = ref([]);
    const relatedKeywordsUpdate = ref("");
    
    // METHODS
    const getMainKeyword = async () => {
        try {
            loading.value.mainKeyword = true;
            err.value.status = false;
            relatedKeywords.value = [];
            relatedKeywordsUpdate.value = "";

            if (!inputKeyword.value) {
                throw {
                    status: 400,
                    error: "Veuillez entrer un mot-clé"
                };
            }

            const promise = await fetch(
                `${process.env.VUE_APP_SERVER}/semrush/keyword-audit/get-main-keyword`,
                {
                    method: "POST",
                    headers: fetchConfig,
                    body: JSON.stringify({
                        keyword: inputKeyword.value,
                        database: selectedDatabase.value
                    })
                }
            );

            const response = await promise.json();
            const status = promise.status;
            
            if (status !== 200) {
                throw {
                    status: status,
                    ...response
                };
            }

            inputKeyword.value = response.keyword;
            mainKeyword.value = response.data;
            mainKeyword.value.updatedAt = response.updatedAt;
            refreshKey.value = refreshKey.value += 1;
            canLoadMoreKeywords.value = true;

        } catch (error) {
            if (error.status === 401 || 400 || 402 || 404) err.value.message = error.error;
            else err.value.message = "Une erreur est survenue";
            err.value.status = true;
        } finally {
            loading.value.mainKeyword = false;
        }
    };
    const getRelatedKeywords = async () => {
        try {
            if (!mainKeyword.value.Keyword) {
                throw {
                    status: 400,
                    error: "Veuillez rechercher un mot-clé principal"
                };
            }
           
            loadingMoreKeywords.value = true;
            err.value.status = false;
            const initialRequest = relatedKeywords.value.length > 0 ? false : true;
         
            const promise = await fetch(
                `${process.env.VUE_APP_SERVER}/semrush/keyword-audit/get-related-keywords`,
                {
                    method: "POST",
                    headers: fetchConfig,
                    body: JSON.stringify({
                        keyword: inputKeyword.value,
                        database: selectedDatabase.value,
                        initialRequest: initialRequest,
                        previousIndex: relatedKeywords.value.length
                    })
                }
            );

            const response = await promise.json();
            const status = promise.status;
                
            if (status !== 200) {
                throw {
                    status: status,
                    ...response
                };
            }

            if (initialRequest) relatedKeywords.value = [];
            
            relatedKeywords.value.push(...response.data);
            relatedKeywordsUpdate.value = getLocaleDateStringFromISO(response.updatedAt);
            refreshKey.value = refreshKey.value += 1;
        } catch (error) {
            if (error.status === 401 || 400 || 404 || 402) err.value.message = error.error;
            else err.value.message = "Une erreur est survenue";
            err.value.status = true;
        } finally {
            loadingMoreKeywords.value = false;
        }
    };
    const getPrevious = async () => {
        try {
            loading.value.mainKeyword = true;
            loading.value.relatedKeywords = true;
            err.value.status = false;
            const promise = await fetch(
                `${process.env.VUE_APP_SERVER}/semrush/keyword-audit/get-previous`,
                {
                    method: "GET",
                    headers: fetchConfig,
                }
            );
            const response = await promise.json();
            const status = promise.status;

            if (status !== 200) {
                throw {
                    status: status,
                    ...response
                };
            }
            inputKeyword.value = response.keyword;
            mainKeyword.value = response.mainKeyword.data;
            mainKeyword.value.updatedAt = response.mainKeyword.updatedAt;
            relatedKeywords.value = response.relatedKeywords.data;
            relatedKeywordsUpdate.value = getLocaleDateStringFromISO(response.relatedKeywords.updatedAt);
            selectedDatabase.value = response.database;
            previousDatabase.value = response.database;

        } catch (error) {
            if (error.status === 401 || 400) err.value.message = error.error;
            else err.value.message = "Une erreur est survenue";
            err.value.status = true;
        } finally {
            loading.value.mainKeyword = false;
            loading.value.relatedKeywords = false;
        }
    };
    const checkPreviousDatabase = () => {
        if (previousDatabase.value !== selectedDatabase.value) {
            canLoadMoreKeywords.value = false;
        } else {
            canLoadMoreKeywords.value = true;
        }
    };
    const getInCsv = () => {
        const jsonData = [];
        const mainK = JSON.parse(JSON.stringify(mainKeyword.value));
        const relatedK = JSON.parse(JSON.stringify(relatedKeywords.value));
        const database = selectedDatabase.value;
        jsonData.push(mainK);
        jsonData.push(...relatedK);
        let rawData = toRaw(jsonData);
        rawData.map((d) => {
            console.log(d);
            d.Trends = d.Trends ? d.Trends.series[0].data : "";
            d.updatedAt = mainK.updatedAt;
        });
        const filename = `${mainK.Keyword}-${database}-${getLocaleDateStringFromISO(mainK.updatedAt)}`;
        csvTools.getInCsv(rawData, filename);
    }
    
    // LIFECYCLE HOOKS
    onMounted(() => {
        getPrevious();
    });
</script>
<style lang="scss" src="./keywords-audit.scss" scoped>
</style>