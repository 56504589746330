import { getFetchConfig } from '@/tools/fetchTools.js';

export const fetchCompanies = async () => {
    const fetchConfig = getFetchConfig('json');
    const url = `${process.env.VUE_APP_SERVER}/company/list`;

    const promise = await fetch(url, {
        method: 'GET',
        headers: fetchConfig,
    });
    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

