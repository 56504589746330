import SocialNetworksPublicationsValidation from '@/pages/CustomerSpace/SocialNetworkSection/SocialNetworksPublicationsValidation.vue';
import SocialNetworksPublicationsCalendar from '@/pages/CustomerSpace/SocialNetworkSection/SocialNetworksPublicationsCalendar.vue';
import SubscribeToSocialNetworkOffer from '@/pages/CustomerSpace/SocialNetworkSection/SubscribeToSocialNetworkOffer.vue';
import OptimizationNotesList from '@/pages/CustomerSpace/NaturalSeoSection/OptimisationNotes/OptimisationNotesList.vue';
import CustomerWritingTool from '@/pages/CustomerSpace/NaturalSeoSection/CustomerWritingTool/CustomerWritingTool.vue';
import HistoryOfContents from '@/pages/CustomerSpace/NaturalSeoSection/HistoryOfContents/HistoryOfContents.vue';
import SocialNetworksStatistics from '@/pages/CustomerSpace/SocialNetworkSection/SocialNetworksStatistics.vue';
import OnlineSEOAudit from '@/pages/CustomerSpace/NaturalSeoSection/OnlineSEOAudit/OnlineSEOAudit.vue';
import DomainKeywords from '@/pages/CustomerSpace/NaturalSeoSection/DomainKeywords/DomainKeywords.vue';
import SocialNetworksInbox from '@/pages/CustomerSpace/SocialNetworkSection/SocialNetworksInbox.vue';
import KeywordsAudit from '@/pages/CustomerSpace/NaturalSeoSection/KeywordsAudit/KeywordsAudit.vue';
import MyStatistics from '@/pages/CustomerSpace/NaturalSeoSection/MyStatistics/MyStatistics.vue';
import Dashboard from '@/pages/CustomerSpace/Dashboard/Dashboard.vue';

const naturalSeoRoutes = [
    {
        path: "/natural-seo/speaking-notes",
        name: "Notes d'intervention",
        component: OptimizationNotesList,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/natural-seo/seo-audit",
        name: "Audit SEO",
        component: OnlineSEOAudit,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/natural-seo/traffic-statistics",
        name: "Statistiques trafic",
        component: MyStatistics,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/natural-seo/keywords-positions",
        name: "Positions des mots-clés",
        component: DomainKeywords,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/natural-seo/keywords-audit",
        name: "Audit de mots-clés",
        component: KeywordsAudit,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/natural-seo/history-of-contents",
        name: "Historique des contenus",
        component: HistoryOfContents,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/natural-seo/customer-writing-tool",
        name: "Editeur d'articles client",
        component: CustomerWritingTool,
        meta: {
            auth: true,
            layout: "private"
        }
    },
];

const socialNetworkRoutes = [
    {
        path: "/social-networks/publications-validation",
        name: "Validation des publications des réseaux sociaux",
        component: SocialNetworksPublicationsValidation,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/social-networks/inbox",
        name: "Messagerie des réseaux sociaux",
        component: SocialNetworksInbox,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/social-networks/statistics",
        name: "Statistiques des réseaux sociaux",
        component: SocialNetworksStatistics,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/social-networks/publications-calendar",
        name: "Calendrier des publications",
        component: SocialNetworksPublicationsCalendar,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    {
        path: "/social-networks/subscribe",
        name: "Soucrire à l'offre réseaux sociaux",
        component: SubscribeToSocialNetworkOffer,
        meta: {
            auth: true,
            layout: "private"
        }
    },
];


const customerRoutes = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            auth: true,
            layout: "private"
        }
    },
    ...naturalSeoRoutes,
    ...socialNetworkRoutes
];

export default customerRoutes;