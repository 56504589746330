export const displayConfirmationMessage = async (message) => {
    if (window.confirm(message)) {
        return true;
    };
    return false;
};

export const displayPrompt = async ({ promptMessage, defaultValue, returnedType }) => {
    if (returnedType === 'number') {
        return Number(prompt(promptMessage, defaultValue));
    }
    return prompt(promptMessage, defaultValue);
};

export const displayInputAlert = async ({ message, defaultValue = null }) => {
    const promptResponse = prompt(message, defaultValue);
    if (promptResponse === null) {
        return "not provided"
    }
    return promptResponse;
};

export const displayAlert = async (message) => {
    alert(message);
};