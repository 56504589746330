import userTools from "@/tools/userTools";
import Cookies from "js-cookie";


export const startIntercom = async () => {
    try {
        const currentUser = await userTools.getUserMainInformations(Cookies.get('token'));

        window.Intercom('boot', {
            app_id: process.env.VUE_APP_INTERCOM_APP_ID,
            user_id: currentUser._id,
            email: currentUser.email,
            name: currentUser.fullName
            
        });
    } catch (error) {
        console.error("Intercom Error");
        console.log(error)
    }
    
};

export const openChat = () => {
    if (typeof window.Intercom === 'undefined') {
        return;
    }
    window.Intercom('show');
};
