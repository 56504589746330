import labelsStyles from '../assets/scss/exports/labels-variables.module.scss';

export const socialNetworksList = [    
    { value: 'facebook', displayValue: 'Facebook' },
    { value: 'instagram', displayValue: 'Instagram' },
    { value: 'linkedin', displayValue: 'Linkedin' },
    { value: 'twitter', displayValue: 'Twitter' },
    { value: 'pinterest', displayValue: 'Pinterest' },
    { value: 'tiktok', displayValue: 'Tiktok' },
    { value: 'youtube', displayValue: 'Youtube' }
];

export const getSocialNetworkImagesFromName = (networkName) => {
    if (!socialNetworksList.some(network => network.value === networkName)) {
        throw new Error('Invalid network name');
    }
    const networksImagesList = {
        'facebook': '/images/social-networks/facebook_40px.png',
        'instagram': '/images/social-networks/instagram_40px.png',
        'linkedin': '/images/social-networks/linkedin_40px.png',
        'twitter': '/images/social-networks/twitter_40px.png',
        'youtube': '/images/social-networks/youtube_40px.png',
        'pinterest': '/images/social-networks/pinterest_40px.png',
        'tiktok': '/images/social-networks/tiktok_40px.png'
    }
    return networksImagesList[networkName];
};

export const getColorStatus = (status) => {
    const validStatus = [0, 1, 2, 3]; // 0: En attente, 1: Validé, 2: Publié, 3: Correction 
    if (!validStatus.includes(status)) {
        throw new Error('Invalid status');
    }
    const statusColors = {
        0: () => labelsStyles.warningColorLabel,
        1: () => labelsStyles.infoColorLabel,
        2: () => labelsStyles.successColorLabel,
        3: () => labelsStyles.errorColorLabel
    };
    const statusColor = statusColors[status];
    return statusColor();
};