<template>
    <div class="row w-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Gestion des utilisateurs</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="col-4 mb-3">
                        <div class="input-group">
                            <span class="input-group-text text-body">
                                <i class="fas fa-search" aria-hidden="true"></i>
                            </span>
                            <input v-model="inputFilter" @keyup="filterUsers" type="text" class="form-control"/>
                        </div>
                    </div>
                    <div class="card h-100 p-4">
                        <div class="btn-group col-3" role="group" aria-label="Basic example">
                            <button @click="reloadUsers('notStaff')" type="button" class="btn btn-secondary" :class="{ 'btn-selected': rankLevel === 'notStaff' }">Clients / En attente</button>
                            <button @click="reloadUsers('staff')" type="button" class="btn btn-secondary" :class="{ 'btn-selected': rankLevel === 'staff' }">Collaborateurs</button>
                        </div>
                        <div class="table-responsive">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th v-if="rankLevel === 'staff'"></th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Nom complet</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Adresse email</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Site web</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Rang / Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="usersLoading" style="width:100%;" class="text-center">
                                        <td colspan="4">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr v-else v-for="(user, index) in filteredUsersList" :key="index">
                                        <td v-if="rankLevel === 'staff'" class="text-center ellipse medium">
                                            <MasloButton :user="{ userId: user._id, fullName: user.fullName }"/>
                                        </td>
                                        <td class="text-center ellipse medium">{{ user.fullName }}</td  >
                                        <td class="text-center ellipse medium"><strong>{{ user.email }}</strong></td>
                                        <td class="text-center ellipse large">{{ user.websiteUrl }}</td>
                                        <td class="text-center">
                                            <div class="d-flex">
                                                <select @change="setUserRank(user._id, user.rankLevel)" v-model="user.rankLevel" class="form-control text-center" :class="{'text-danger': user.rankLevel === 0}">
                                                    <option value="0">En attente</option>
                                                    <option value="1">Client</option>
                                                    <option value="2">Rédacteur</option>
                                                    <option value="3">Conseiller</option>
                                                    <option value="4">Administrateur</option>
                                                </select>
                                                <!-- <div class="d-flex align-items-center trash">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"><path fill="#94e4af" d="m9.4 16.5l2.6-2.6l2.6 2.6l1.4-1.4l-2.6-2.6L16 9.9l-1.4-1.4l-2.6 2.6l-2.6-2.6L8 9.9l2.6 2.6L8 15.1ZM7 21q-.825 0-1.412-.587Q5 19.825 5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413Q17.825 21 17 21Z"/></svg>
                                                </div> -->
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from "vue"; 
    import axios from 'axios';
    import Cookies from "js-cookie";
    import MasloButton from "@/components/services/MasloButton.vue";
    import { displayInputAlert, displayAlert } from "@/tools/modalTools";
    import { fetchCompanies } from "@/useCases/companyUseCases";
    import { setUserCompany, fetchUserCompany } from "@/useCases/userUseCases";
    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`
    };

    const err = ref({
      message: "",
      status: false
    });
    const inputFilter = ref("");
    const usersLoading = ref(true);
    const usersList = ref([]);
    const filteredUsersList = ref([]);
    const rankLevel = ref("notStaff");
    const companiesList = ref([]);

    const getUsers = async () => {
        try {
            usersLoading.value = true;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/admin/users/get-all?rankLevel=${rankLevel.value}`,
                headers: axiosConfig
            });
            if (response.status === 200) {
                usersList.value = response.data;
                filteredUsersList.value = usersList.value;
            }
        } catch (error) {
            
        } finally {
            usersLoading.value = false;
        }
    };

    const getCompanyList = async () => {
        try {
            companiesList.value = await fetchCompanies();
        } catch (error) {
            throw error;
        }
    };

    const getCorrespondingCompanyId = (companyName) => {
        const company = companiesList.value.find(company => company.name === companyName);
        if (!company) {
            return null;
        }
        return company._id;
    };

    const getUserCompany = async (userId) => {
        try {
            const response = await fetchUserCompany(userId);
            console.log(response);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const assignCompanyToUser = async ({ userId, companyId }) => {
        try {
            await setUserCompany({ userId, companyId });
        } catch (error) {
            throw error;
        }
    };

    const handleUserCompany = async (userId) => {
        try {
            let selectedCompany = "";

            const userCompany = await getUserCompany(userId);

            if (Number(rankLevel) !== 0 && userCompany === "COMPANY_NOT_FOUND") {
                const companies = companiesList.value.map(company => `"${company.name}"`).join(" OU ");
                selectedCompany = await displayInputAlert({ message: `A quelle entreprise cet utilisateur est-il lié ?\n Options: ${companies}`, defaultValue: ""});

                if (selectedCompany == "not provided") {
                    location.reload();
                    return;
                }
                else if (selectedCompany === "") {
                    console.log("No company selected");
                    return;
                }

                const companyId = getCorrespondingCompanyId(selectedCompany);
                
                if (companyId === null) {
                    await displayAlert("ERREUR : Entreprise inexistante");
                    return;
                } 
                await assignCompanyToUser({ userId, companyId });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const setUserRank = async (userId, rankLevel) => {
        try {
            await handleUserCompany(userId);
           
            await axios({
                method: "PATCH",
                url: `${process.env.VUE_APP_SERVER}/admin/users/set-user-rank`,
                headers: axiosConfig,
                data: {
                    userId: userId,
                    rankLevel: parseInt(rankLevel)
                }
            });
        } catch (error) {
            console.log(error);
        } finally {

        }
    };

    const filterUsers = () => {  
        const filteredUsers = usersList.value.filter((user) => {
            return user?.fullName.toLowerCase().includes(inputFilter.value.toLowerCase()) ||
                user.email?.toLowerCase().includes(inputFilter.value.toLowerCase()) ||
                user.websiteUrl?.toLowerCase().includes(inputFilter.value.toLowerCase());
        });
        filteredUsersList.value = filteredUsers;
    };

    const reloadUsers = async (status) => {
        rankLevel.value = status;
        getUsers();
    };

    onMounted(() => {
        getUsers();
        getCompanyList();
    });

</script>
<style scoped>
    @import "../../../../../src/assets/css/tables-custom.css";
    .trash {
        margin-left: 10px;
    }
    .btn-secondary {
        padding: 0.25rem 1rem;
        font-size: 12px;
    }
    .btn-selected {       
        box-shadow: 0 0 0 0.2rem rgb(111 124 145 / 50%);
    }
    .table-responsive {
        max-height: 68vh;
        overflow: auto;
    }
    
</style>>

