<template>
    <div class="table-text-content">
        <p class="table-text-content__text" :class="{ 'table-text-content__text_vertical': isVerticalAllowed }" :style="style">{{ text }}</p>
    </div>
</template>
<script setup>
    import { computed } from 'vue';
    const props = defineProps({
        text: {
            type: String,
            required: true
        },
        fontWeight: {
            type: String,
            required: false,
            default: 'normal'
        },
        fontSize: {
            type: String,
            required: false,
            default: '14px'
        },
        isVerticalAllowed: {
            type: Boolean,
            required: false,
            default: false
        }
    });
    const style = computed(() => {
        const { fontWeight, fontSize } = props;
        return {
            'font-weight': fontWeight,
            'font-size': fontSize
        }
    });
</script>
<style lang="scss" scoped>
    .table-text-content {
        display: flex;
        align-items: center;
        justify-content: center;

        &__text {
            margin: 0;
            padding: 0;
            font-size: 14px;

            &_vertical {

                @media screen and (max-width: 1300px) and (min-width: 1001px) {
                    writing-mode: vertical-lr;
                    transform: rotate(-20deg);
                    // text-orientation: upright;
                    white-space: nowrap;
                }  
            }
        }
    }
</style>