<template>
    <div class="item-card">
        <div v-if="closeButton" class="item-card__header">
            <svg @click="emits('close')" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 15 15"><path fill="currentColor" fill-rule="evenodd" d="M11.782 4.032a.575.575 0 1 0-.813-.814L7.5 6.687L4.032 3.218a.575.575 0 0 0-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 0 0 .814.814L7.5 8.313l3.469 3.469a.575.575 0 0 0 .813-.814L8.313 7.5l3.469-3.468Z" clip-rule="evenodd"/></svg>
        </div>
        <slot></slot>
    </div>
</template>
<script setup>
    import { defineEmits } from 'vue';
    import { parseAndFormatDimension } from '@/tools/cssTools.js';

    const props = defineProps({
        closeButton: {
            type: Boolean,
            required: false,
            default: false
        },
    });
    const emits = defineEmits([
        'close'
    ]);
</script>
<style lang="scss" scoped>
    .item-card {
        overflow: hidden;
        background-color: #fff;
        border-radius: 1rem;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

        &__header {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 0.5rem;
            svg {
                cursor: pointer;
            }
        }


    }
</style>