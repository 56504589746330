<template>
  <div
    class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved9.jpg') + ')',
    }"
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
  </div>

  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center mt-2">
            <h5>Mot de passe oublié</h5>
            <p>Veuillez renseigner votre adresse email</p>
          </div>
          <div class="card-body">
              <div class="mb-3">
                <input
                  v-model="formMail"
                  class="form-control mt-2"
                  id="email"
                  type="email"
                  placeholder="Email"
                  name="email"
                />
              </div>
              <div v-if="err.status === true || success.status === true" class="alert" :class="{'alert-danger': err.status, 'alert-success': success.status }">
                  <p v-if="err.status">{{ err.message }}</p> 
                  <p v-if="success.status">{{ success.message }}</p> 
              </div>	       
              <div class="text-center">
                <soft-button
                  @click.once="recoverPassword"
                  color="dark"
                  full-width
                  variant="gradient"
                  class="my-4 mb-2"
                  >Envoyer</soft-button
                >
              </div>
              <p class="text-sm mt-3 mb-0">
                  <router-link to="/login" class="text-dark font-weight-bolder">
                      Connexion
                  </router-link>
              </p>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import SoftButton from "@/components/SoftButton.vue";
import { ref } from "vue";
import axios from "axios";
import Cookies from "js-cookie";

const axiosConfig = {
  Authorization: `Bearer ${Cookies.get("token")}`,
};

const formMail = ref("");

const err = ref({
  message: "",
  status: false,
});
const success = ref({
  message: "",
  status: false,
});

// const requestSuccess = ref(false);

const recoverPassword = async () => {
  try {
    err.value.status = false;
    const response = await axios({
      method: "POST",
      url: `${process.env.VUE_APP_SERVER}/user/recover-password`,
      headers: axiosConfig,
      data: {
        email: formMail.value,
      },
    });
    success.value.message = "Votre demande a bien été prise en compte, vous allez recevoir un email de réinitialisation. Pensez à vérifier vos spams et indésirables.";
    success.value.status = true;
  } catch (error) {
    console.log(error);
    err.value.status = true;
    err.value.message = "Une erreur est survenue";
  }
};
</script>

<style scoped>
img {
  max-width: 50%;
}
button {
  margin-top: 5rem;
}
</style>
