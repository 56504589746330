<template>
    <div class="table-networks-list" :style="setNetworksListStyle()">
        <img v-for="(network, index) in networksList" :key="index" :src="getSocialNetworkImagesFromName(network)" alt="">
    </div>
</template>
<script setup>
    import { getSocialNetworkImagesFromName } from '@/tools/socialNetworkTools.js';

    const props = defineProps({
        networksList: {
            type: Array,
            required: true
        },
        networksListStyle: {
            type: Object,
            required: false,
            default: () => ({})
        }
    });

    const setNetworksListStyle = () => {
        return props.networksListStyle;
    };
</script>
<style lang="scss" scoped>
    .table-networks-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2px;

        img {
            width: 22px;
        }

        @media (max-width: 1000px){
            flex-direction: row;
        }
    }
</style>