<template>
    <div class="responsive-table" :style="tableContainerStyle()" @scroll="handleScroll">
            <table >
                <thead>
                    <tr>
                        <th v-for="(header, index) in tableHeaders" :key="index" :class="thAlignRef">
                            {{ header }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="loading">
                        <td :colspan="headersLength()">
                            <MainLoader />
                        </td>
                    </tr>
                    <tr v-else-if="error.status" class="error-message status-message">
                        <td :colspan="headersLength()">
                            {{ error.message }}
                        </td>
                    </tr>
                    <tr v-else-if="dataLength() === 0" class="no-data-message status-message">
                        <td :colspan="headersLength()">
                            {{ noDataMessage }}
                        </td>
                    </tr>
                    <tr v-else v-for="(data, index) in tableData" :key="index" :style="bodyTrStyle()">
                        <td v-for="(item, index) in data" :key="index" :data-label="item.dataLabel" :style="item.tdStyle">
                            <div v-if="item.contentType === 'text'">
                                <br>
                                <TableTextContent 
                                    :text="item.value"
                                    :fontWeight="item?.fontWeight"
                                    :font-size="item?.fontSize"
                                    :isVerticalAllowed="item?.isVerticalAllowed"
                                />
                            </div>
                            <div v-else-if="item.contentType === 'link'">
                                <br>
                                <TableLink 
                                    :link="item.link"
                                    :text="item.value"
                                    :tableLinkStyle="item?.tableLinkStyle"
                                />
                            </div>
                            <div v-else-if="item.contentType === 'html'">
                                <br>
                                <TableHtmlContent 
                                    :html="item.value"
                                    :innerHeight="calcInnerHeight()"
                                />
                            </div>
                            <div v-else-if="item.contentType === 'networks-list'">
                                <br>
                                <TableNetworksList 
                                    :networks-list="item.value" 
                                    :networksListStyle="item?.networksListStyle"
                                />
                            </div>
                            <div v-else-if="item.contentType === 'images'" class="table-images-container">
                                <br>
                                <TableImages 
                                    :images="item.value"
                                    :innerHeight="calcInnerHeight()"
                                    :hidePoints="item?.hidePoints"
                                    :tableImagesStyle="item?.tableImagesStyle"
                                />
                            </div>
                            <div v-else-if="item.contentType === 'video'">
                                <br>
                                <TableVideo 
                                    :video="item.value"
                                    :innerHeight="calcInnerHeight()"
                                />
                            </div>
                            <div v-else-if="item.contentType === 'label'">
                                <br>
                                <TableLabel
                                    :label="item.value"
                                    :labelColor="item.color"
                                    :fontSize="item?.fontSize"
                                    :isVerticalAllowed="item?.isVerticalAllowed"
                                />
                            </div>
                            <div v-else-if="item.contentType === 'buttons'">
                                <TableButtonsArea 
                                    :buttons="item.value"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
    </div>
</template>
<script>
    const thAuthorizedAlignments = ['th-center', 'th-left', 'th-right'];
    const tdAuthorizedAlignments = ['td-center', 'td-left', 'td-right'];
</script>
<script  setup>
    import { ref, defineEmits } from "vue";
    import MainLoader from '@/components/standalone/loaders/MainLoader.vue';
    import TableNetworksList from './components/TableNetworksList.vue';
    import TableTextContent from "./components/TableTextContent.vue";
    import TableHtmlContent from "./components/TableHtmlContent.vue";
    import TableImages from "./components/TableImages.vue";
    import TableVideo from "./components/TableVideo.vue";
    import TableLabel from "./components/TableLabel.vue";
    import TableButtonsArea from "./components/TableButtonsArea.vue";
    import TableLink from "./components/TableLink.vue";
    const emit = defineEmits(['scrollEnd']);
    const props = defineProps({
        tableHeaders: {
            type: Array,
            required: true,
            validator: (array) => {
                return array.every((value) => typeof value === 'string');
            }
        },
        tableData: {
            type: Array,
            required: false,
        },
        noDataMessage: {
            type: String,
            required: false,
            default: 'Aucune donnée à afficher ...'
        },
        error: {
            type: Object,
            default: {
                message: {
                    type: String,
                    required: false,
                },
                status: {
                    type: Boolean,
                    required: false,
                    default: false
                }
            }
        },
        loading: {
            type: Boolean,
            required: true,
            default: true
        },
        tableContainerStyle: {
            type: Object,
            required: false
        },
        bodyTrStyle: {
            type: Object,
            required: false
        },
        thAlign: {
            type: String,
            required: false,
            default: 'th-center',
            validator: (value) => {
                return thAuthorizedAlignments.includes(value);
            }
        },
        itemTdStyle: {
            type: Object,
            required: false
        },
        
    });

    const thAlignRef = ref(props.thAlign);
    const tdAlignRef = ref(props.tdAlign);
    
    // METHODS
    const headersLength = () => {
        return props.tableHeaders?.length;
    };
    const dataLength = () => {
        return props.tableData.length;
    };
    const tableContainerStyle = () => {
        const { tableContainerStyle } = props; 
        const defaultStyle = {
            'height': '100%',
            'overflow-y': 'auto',
            'overflow-x': 'auto'
        }
        const mergedStyle = { ...defaultStyle, ...tableContainerStyle };
        return mergedStyle;
    };
    const bodyTrStyle = () => {
        const { bodyTrStyle } = props; 
        const defaultStyle = {
            'height': '100%',
        }
        const mergedStyle = { ...defaultStyle, ...bodyTrStyle };
        return mergedStyle;
    };
    const calcInnerHeight = () => {
        const height = bodyTrStyle().height;
        const innherHeight = parseInt(height) - 40;
        return innherHeight + 'px';
    };
    const handleScroll = (event) => {
        const { target } = event;
        const { scrollTop, scrollHeight, clientHeight } = target;
        const offset = 2;
        if (scrollTop + clientHeight >= scrollHeight - offset) {
            emit('scrollEnd');
        }
    };
</script>
<style lang="scss" scoped>
    .responsive-table {
        table {
            width: 100%;

            thead {
                box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 3px;
                background-color: #fffffff7;

                tr {
                    padding: 1rem;

                    th {
                        padding: 0.75rem 0.5rem;
                        letter-spacing: 0;
                        font-size: 0.75rem;
                        font-weight: 700;
                        color: $grey-1;

                        &.th-center {
                            text-align: center;
                        }
                    }
                }
            }

            tbody {
                tr {
                    box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.25);
                    
                    
                    td {
                        
                        padding: 0.50rem 0.35rem;

                    
                        br {
                            display: none;
                        }

                    }
                }
                tr.status-message {
                    td {
                        text-align: center;
                        vertical-align: middle;
                    }
                }
                tr.error-message {
                    td {
                        color: $error-color;
                    }
                }
            }
        
            @media (max-width: 1000px){
                thead {
                    display: none;
                }
                tbody {
                    tr {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: auto !important;
                        margin-bottom: 1.5rem;
                        border-radius: 10px;
                        overflow: hidden;
                        
                        td {
                            display: block;
                            text-align: right;
                            width: 100% !important;
                            br {
                                display: block;
                            }
                        }
                        td:before {
                            content: attr(data-label);
                            float: left;
                            font-size: 0.75rem;
                            font-weight: 700;
                            color: $grey-1;
                        }
                    }
                }
            }
        }    
    }
</style>