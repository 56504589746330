<template>
    <div class="table-label">
        <div class="table-label__card" :class="{ 'table-label__card_vertical': isVerticalAllowed }" :style="labelStyle()">
            <span>{{ label }}</span>
        </div>
    </div>
</template>
<script setup>
    import { createDarkerColorFromHexOrRgb } from '@/tools/colorTools.js';

    const props = defineProps({
        label: {
            type: [ String, Number],
            required: true
        },
        labelColor: {
            type: String,
            required: false,
        },
        fontWeight: {
            type: String,
            required: false,
            default: 'normal'
        },
        fontSize: {
            type: String,
            required: false,
            default: '1rem'
        },
        isVerticalAllowed: {
            type: Boolean,
            required: false,
            default: false
        }
    });
    const labelStyle = () => {
        const color = createDarkerColorFromHexOrRgb(props.labelColor, 50);
        const object = {
            'background-color': props.labelColor,
            'font-weight': props.fontWeight,
            'font-size': props.fontSize,
            'color': color,
        };
        return object;
    };
   
</script>
<style lang="scss" scoped>
    .table-label {
        display: flex;
        justify-content: center;

        &__card {
            border-radius: 6px;
            width: fit-content;
            padding: 4px 8px;

            &_vertical {
                @media screen and (min-width: 1000px) {
                    writing-mode: vertical-lr;
                    transform: rotate(360deg);
                    padding: 8px 4px;
                }
            }
        }
    }
</style>