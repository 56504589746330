'use strict';
import Cookies from 'js-cookie';

export const getFetchConfig = (type = 'none') => {
    const contentTypeList = {
        'json': 'application/json',
        'none': null
    };

    const authorization = `Bearer ${Cookies.get('token')}`;
    const contentType = contentTypeList[type];

    const object  = {
        'Authorization': authorization,
        ... contentType ? { 'Content-Type': contentType } : ''
    }
    return object;
};

