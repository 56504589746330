export const detectColorFormat = (color) => {
    if (color.startsWith('#')) {
        return 'hex';
    } else if (color.startsWith('rgb')) {
        return 'rgb';
    }
    throw new Error('Invalid color format');
};

export const rgbToHex = (rgb) => {
    const match = rgb.match(/(\d+),\s*(\d+),\s*(\d+)/);
    if (!match) {
        throw new Error('Invalid RGB format');
    }

    const rgbArray = rgb.split(",");
    const r = parseInt(rgbArray[0].split("(")[1]);
    const g = parseInt(rgbArray[1]);
    const b = parseInt(rgbArray[2].split(")")[0]);
    const hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    return hex;
};

export const createDarkerColorFromHexOrRgb = (color, percent) => {
    const colorFormat = detectColorFormat(color);
    if (colorFormat === 'rgb') {
        color = rgbToHex(color);
    }
    color = color.replace('#', '');

    const r = parseInt(color.slice(0, 2), 16);
    const g = parseInt(color.slice(2, 4), 16);
    const b = parseInt(color.slice(4, 6), 16);

    const darkenedR = Math.round(r * (1 - percent / 100));
    const darkenedG = Math.round(g * (1 - percent / 100));
    const darkenedB = Math.round(b * (1 - percent / 100));

    const darkerColor = `#${darkenedR.toString(16).padStart(2, '0')}${darkenedG.toString(16).padStart(2, '0')}${darkenedB.toString(16).padStart(2, '0')}`;
    return darkerColor;
};




