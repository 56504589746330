import CreateArticleBrief from '@/pages/WriterSpace/CreateArticleBrief/CreateArticleBrief.vue';
import ArticlesWritingList from '../views/pages/WriterSpace/ArticleWriting/ArticlesWritingList.vue';
import RedactionHistory from '../views/pages/WriterSpace/RedactionHistory/RedactionHistory.vue';
import ArticleWriting from '../views/pages/WriterSpace/ArticleWriting/ArticleWriting.vue';

const writerRoutes = [
    {
        path: "/articles-writing-list/",
        name: "Liste de rédaction d'articles",
        component: ArticlesWritingList,
        meta: {
            auth: true,
            writer: true,
            layout: "private"
        }
    },
    {
        path: "/article-writing/:id",
        name: "Article en cours d'écriture",
        component: ArticleWriting,
        meta: {
            auth: true,
            writer: true,
            layout: "private"
        }
    },
    {
        path: "/my-redaction-history",
        name: "Mon historique de rédaction",
        component: RedactionHistory,
        meta: {
            auth: true,
            writer: true,
            layout: "private"
        }
    },
    {
        path: "/create-article-brief",
        name: "Création brief article",
        component: CreateArticleBrief,
        meta: {
            auth: true,
            writer: true,
            layout: "private"
        }
    },
];

export default writerRoutes;