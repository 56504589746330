<template>
    <section id="customer-files-social-networks" class="card p-4">
        <div class="card-header">
            <InputTextOld 
                v-show="currentView === 'table'"
                class="card-header__metricool-id"
                :label="`ID Metricool`"
                :inputStyle="{ 'height': '34px' }"
                name="id-metricool"
                :value="metricoolId"
                @valueChange="handleMetricoolValue"
            />
            <CustomClassicButton 
                v-show="currentView === 'table'"
                @click="validateMetricoolId" 
                :loading="validateMetricoolIdLoading" 
                :hideSlotInLoading="true"
                class="card-header__validate-id"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M5.615 20q-.69 0-1.152-.462Q4 19.075 4 18.385V5.615q0-.69.463-1.152Q4.925 4 5.615 4h10.29q.324 0 .629.13q.304.132.522.349l2.465 2.465q.217.218.348.522q.131.305.131.628v10.29q0 .691-.462 1.154q-.463.462-1.153.462zM19 7.85L16.15 5H5.615q-.269 0-.442.173T5 5.615v12.77q0 .269.173.442t.442.173h12.77q.269 0 .442-.173t.173-.442zm-7 8.688q.827 0 1.413-.586q.587-.587.587-1.414t-.587-1.413q-.586-.587-1.413-.587t-1.413.587Q10 13.712 10 14.538t.587 1.414q.586.586 1.413.586M7.577 9.77h5.808q.348 0 .578-.23q.23-.23.23-.577V7.577q0-.348-.23-.578q-.23-.23-.578-.23H7.577q-.348 0-.578.23q-.23.23-.23.578v1.385q0 .348.23.577q.23.23.578.23M5 7.85V19V5z"/></svg>
            </CustomClassicButton>
        </div>
        <div class="add-network">
            <div class="add-network__open-button">
                <CustomCircleButton @click="toggleCreatorOpenStatus" :isOpen="currentView === 'creator'"/>
            </div>
        </div>
            <ResponsiveTable 
                v-show="currentView === 'table'"
                :tableContainerStyle="tableContainerStyle"
                :bodyTrStyle="bodyTrStyle"
                :tableHeaders="table.headers"
                :tableData="table.data"
                :loading="getCustomerPublicationsLoading"
                :error="err"
                :absoluteArea="true"
            />
            <SocialNetworkPublicationCreator 
                v-if="currentView === 'creator'"
                @publicationCreated="closeCreatorAndRefreshTable"
            />
    </section>
</template>
<script setup>
    import { onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import CustomCircleButton from '@/components/standalone/customsButtons/CustomCircleAddButton.vue';
    import ResponsiveTable from '@/components/standalone/tables/ResponsiveTable/ResponsiveTable.vue';
    import { fetchSocialNetworkPublicationsByCustomerUserId, deleteSocialNetworkPublication } from '@/useCases/socialNetworkPublicationUseCases';
    import { getLocalWeekStringFromWeekString, getLocaleDateStringFromISO } from '@/tools/dateTools';
    import { displayConfirmationMessage } from '@/tools/modalTools';
    import { getColorStatus } from '@/tools/socialNetworkTools.js';
    import mainStyle from '@/assets/scss/exports/main-variables.module.scss';
    import InputTextOld from '@/components/standalone/inputs/InputTextOld.vue';
    import CustomClassicButton from '@/components/standalone/customsButtons/CustomClassicButton.vue';
    import { setUserMetricoolId, fetchUserMetricoolId } from '@/useCases/userUseCases';
    import SocialNetworkPublicationCreator from '@/components/linked/SocialNetworkPublicationCreator/SocialNetworkPublicationCreator.vue';

    const route = useRoute();
    const customerUserId = route.params.id;
    const currentView = ref('table');

    //DATA
    const err = ref({
        message: '',
        status: false
    });
    const getCustomerPublicationsLoading = ref(true);
    const validateMetricoolIdLoading = ref(false);
    const table = ref({
        headers: [
            '',
            'Canaux',
            'Visuels de publication',
            'Texte de publication',
            'Semaine / Année',
            'Date d\'import',
            'Status',
        ],
        data: []
    });
    const tableContainerStyle = ref({
        'height': '75dvh'
    });
    const bodyTrStyle = ref({
        'height': '270px'
    });
    const metricoolId = ref("");

    const handleMetricoolValue = (event) => {
        metricoolId.value = event;
    };

    const toggleCreatorOpenStatus = () => {
        currentView.value = currentView.value === 'table' ? 'creator' : 'table';
    };
    const closeCreatorAndRefreshTable = () => {
        getCustomerPublications();
        toggleCreatorOpenStatus();
    };

    const validateMetricoolId = async () => {
        try {
            validateMetricoolIdLoading.value = true
            const validMetricoolId = await setUserMetricoolId({
                userId: customerUserId, 
                metricoolId: metricoolId.value
            });
            metricoolId.value = validMetricoolId;
        } catch (error) {
            console.log(error);
        } finally {
            validateMetricoolIdLoading.value = false
        }
    };

    const getMetricoolId = async () => {
        try {
            const userId = customerUserId;
            const foundMetricoolId = await fetchUserMetricoolId(userId);
            metricoolId.value = foundMetricoolId;
        } catch (error) {
            console.log(error);
        }
    };

    const deleteCustomerPublication = async (publicationId) => {
        try {
            const confirmationMessage = `Êtes-vous sûr de vouloir supprimer la publication ?`;
            const isConfirmed = await displayConfirmationMessage(confirmationMessage);
            if (!isConfirmed) {
                return;
            }
            await deleteSocialNetworkPublication(publicationId);
            removeDeletedPublicationFromTable(publicationId);
        } catch (error) {
            
        }
    };
    const removeDeletedPublicationFromTable = (publicationId) => {
        if (!publicationId) {
            return;
        }
        const index = table.value.data.findIndex((publication) => {
            return publication._id.value === publicationId;
        });
        table.value.data.splice(index, 1);
    };

    const getVisual = (data) => {
        if (data.images?.length) {
            return {
                value: data.images,
                contentType: 'images'
            }
        }
        else if (data.video?.link) {
            return {
                value: data.video,
                contentType: 'video'
            }
        }
        else {
            return {
                value: 'Aucun visuel',
                contentType: 'text'
            }
        };
    };
    const getHtmlContent = (data) => {
        if (data) {
            return {
                value: data,
                contentType: 'html'
            }
        } else {
            return {
                value: 'Aucun texte',
                contentType: 'text'
            }
        }
    };
    const formatPublicationData = async (publications) => {
        try {
            const formatedPublications = publications.map((data) => {
                const visual = getVisual(data);
                const htmlContent = getHtmlContent(data?.content);
                const formatedItem = {
                    _id: {
                        value: data._id,
                        tdStyle: {
                            'display': 'none'
                        }          
                    },
                    buttons: {
                        value: [
                            {
                                label: 'delete',
                                color: mainStyle.errorColor,
                                width: '23px',
                                action: async () => {
                                    await deleteCustomerPublication(data._id, data.title);
                                },
                            }
                        ],
                        contentType: 'buttons',
                        tdStyle: {
                            'background-color': mainStyle.lightGrey,
                            'box-shadow': 'inset 0px 0px 2px 0px rgba(0,0,0,0.25), rgba(67, 71, 85, 0.27) 0px 0px 0.15em',
                            'width': '40px',
                        }
                    },
                    networks: {
                        value: data.networks,
                        dataLabel: 'Canaux',
                        contentType: 'networks-list',
                    },
                    visual: {
                        value: visual.value,
                        dataLabel: 'Visuels de publication',
                        contentType: visual.contentType,
                    },
                    htmlContent : {
                        value: htmlContent.value,
                        dataLabel: 'Texte de publication',
                        contentType: htmlContent.contentType,
                        tdStyle: {
                            'text-align': 'center',
                        }
                    },
                    week: {
                        value: getLocalWeekStringFromWeekString(data?.week),
                        dataLabel: 'Semaine / année',
                        contentType: 'text',
                        fontSize: '12px',
                        fontWeight: '600',
                        isVerticalAllowed: true,
                        tdStyle: {
                            'box-shadow': 'inset 0px 0px 1px 0px rgba(0,0,0,0.25), inset rgba(67, 71, 85, 0.27) 0px 0px 0.15em',
                            'background-color': mainStyle.lightGrey2,
                        }
                    },
                    importDate: {
                        value: getLocaleDateStringFromISO(data?.createdAt),
                        dataLabel: 'Date d\'import',
                        fontSize: '12px',
                        contentType: 'text',
                        isVerticalAllowed: true,
                    },
                    label: {
                        value: data.stringStatus,
                        color: getColorStatus(data.status),
                        contentType: 'label',
                        fontSize: '12px',
                        dataLabel: 'Status',
                        isVerticalAllowed: true
                    },
                };
                return formatedItem;
            });
            return formatedPublications;
        } catch (error) {
            console.error(error);
        }
    };
    const getCustomerPublications = async () => {
        try {
            getCustomerPublicationsLoading.value = true;
            err.value.status = false;
            const publications =  await fetchSocialNetworkPublicationsByCustomerUserId(customerUserId);
            const formatedPublications = await formatPublicationData(publications);
            table.value.data = formatedPublications;
        } catch (error) {
            if (error.error) {
                err.value.message = error.error;
            }
            err.value.status = true;
        } finally {
            getCustomerPublicationsLoading.value = false;
        }
    };

    onMounted(() => {
        getCustomerPublications();
        getMetricoolId();
    });

</script>
<style lang="scss" scoped>
    #customer-files-social-networks {
        position: relative;
        width: 100%;
        .card-header {
            padding: 0.4rem;
            display: flex;
            gap: 0.2rem;
            align-items: end;

           &__metricool-id {
                width: 20%;
           }
           &__validate-id {
                height: 34px;
               
           }
        }
        .add-network {
            
            &__open-button {
                position: absolute;
                right: 20px;
                top: 20px;
            }
        }

       
    }
</style>