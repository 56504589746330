<template>
    <li v-if="!isHidden()" class="side-nav-sub-item">
        <div @click="handleClick" class="side-nav-sub-item__link" :class="{ 'active-link': isActive }" role="button">
            {{ item.label }}
        </div>
    </li>
</template>
<script setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();
const props = defineProps({
    item: {
        type: Object,
        required: true
    }
});
const isActive = computed(() => {
    const itemRoute = props.item?.route;
    return itemRoute === route.path;
});
const isHidden = () => {
    return props.item?.isHidden ? props.item.isHidden : false;
};
const handleClick = () => {
    if (props.item.route) {
        router.push(props.item.route);
    }
};
</script>
<style lang="scss" scoped>
.side-nav-sub-item {
    &__link {
        font-size: 0.85rem;
        color: $grey-1;

        &.active-link {
            color: $primary-blue-color;
        }
    }
    
}
</style>