<template>
    <div class="editor-visual">
        <div v-if="selectedVisualType" class="editor-visual__type">
            <SingleSelectOld 
                @valueChange="handleVisualTypeChange"
                label="Type de visuel"
                :options="visualTypesList"
                :disabled="readOnly"
                :defaultValue="selectedVisualType"
            />
        </div>
        <div class="editor-visual__content">
            <div v-if="selectedVisualType === 'video'" class="editor-visual__content__video">
                <label for="video-upload">Vidéo</label>
                <div v-show="!readOnly" class="editor-visual__content__video__input">
                    <input @change="handleVideoFileChange" name="video-upload" :accept="acceptedVideoFiles" type="file" class="form-control">
                </div>
                <div class="editor-visual__content__video__preview">
                    <video v-show="videoPreview" id="video-preview" :src="videoPreview" controls></video>
                </div>
            </div>
            <div v-if="selectedVisualType === 'image'" class="editor-visual__content__images">
                <label for="images-upload">Images</label>
                <div v-show="!readOnly" class="editor-visual__content__images__input">
                    <input @change="handleImagesFilesChange" :accept="acceptedImageFiles" class="form-control" type="file" name="images-upload" multiple="multiple">
                </div>
                <div v-show="currentImages?.length" class="editor-visual__content__images__preview">
                    <div v-for="image in currentImages" class="editor-visual__content__images__preview__image">
                        <img :src="image" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import SingleSelectOld from '@/components/standalone/inputs/SingleSelectOld.vue';
import { ref, onMounted, defineEmits, watch } from 'vue';
const emit = defineEmits(['dataChange', 'typeChange']);
const props = defineProps({
    readOnly: {
        type: Boolean,
        required: false,
        default: false
    },
    images: {
        type: [ Array, FileList ],
        required: false
    },
    video: {
        type: Object,
        required: false
    }
});

const visualTypesList = ref([
    {
        value: 'image',
        label: 'Image'
    },
    {
        value: 'video',
        label: 'Vidéo'
    },
    {
        value: 'none',
        label: 'Aucun'
    }
]);

const selectedVisualType = ref(null);
const currentImages = ref(null);
const currentVideo = ref(null);
const videoPreview = ref(null);
const acceptedImageFiles = ref("image/png, image/jpeg");
const acceptedVideoFiles = ref("video/wmv, video/mpeg4, video/avi, video/mov, video/mp4");
const formData = ref({
    images: [],
    video: null
});

const handleVisualTypeChange = (value) => {
    selectedVisualType.value = value;
    emit('typeChange', selectedVisualType.value);
};
const handleVideoFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        videoPreview.value = URL.createObjectURL(file);
        currentVideo.value = file;
        formData.value.video = file;
    }
    emitChanges();
};
const handleImagesFilesChange = (event) => {
    const files = event.target.files;
    if (files) {
        currentImages.value = [];
        formData.value.images = files;
    }
    emitChanges();
};
const emitChanges = () => {
    let currentType = selectedVisualType.value;
    emit('dataChange', {
        ...currentType === 'image' && { images: formData.value.images },
        ...currentType === 'video' && { video: formData.value.video },
        ...currentType === 'none' && { images: [], video: null }
    });
};
watch([() => props.images, () => props.video], ([images, video]) => {
    if (images?.length) {
        currentImages.value = images;
    }
    else if (video?.link) {
        currentVideo.value = video;
        videoPreview.value = video.link;
    }
    else {
        currentImages.value = [];
        currentVideo.value = null;
        videoPreview.value = null;
    }
});
onMounted(() => {
    const images = props.images;
    const video = props.video;
    if (images?.length) {
        selectedVisualType.value = 'image';
        currentImages.value = images;
    }
    else if (video?.link) {
        selectedVisualType.value = 'video';
        currentVideo.value = video;
        videoPreview.value = video.link;
    }
    else {
        selectedVisualType.value = 'none';
    }
    emit('typeChange', selectedVisualType.value);
});
</script>
<style lang="scss" scoped>
.editor-visual {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &__content {
        &__video {
            &__input {
                margin-bottom: 1rem;
            }
            &__preview {
                height: 200px;

                video {
                    height: 100%;
                    width: auto;
                    border-radius: 0.5rem;
                }
            }
        }
        &__images {
            &__input {
                margin-bottom: 1rem;
            }
            &__preview {
                display: flex;
                flex-wrap: wrap;
                height: 200px;
                overflow: hidden;
                justify-content: flex-start;
                gap: 1rem;

                &__image {
                    height: 100%;
                    width: auto;

                    img {
                        border-radius: 0.4rem;
                        height: 100%;
                        width: auto;
                        background: url(https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif) no-repeat center;
                        background-size: 50%;
                    }
                }
            }
        }
    }
}
</style>