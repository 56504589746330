<template>
    <div class="tooltip-wrapper" ref="tooltipWrapperRef" @mouseenter="toggleTooltip" @mouseleave="toggleTooltip" >
        <slot></slot>
        <Teleport to="body">
            <div v-if="displayTooltip && !disabled" class="tooltip" ref="tooltipRef" :style="tooltipStyle">
                <p class="tooltip__content">{{ text }}</p>
            </div>
        </Teleport>
    </div>
</template>
<script setup>
import {  ref, nextTick } from 'vue';
const props = defineProps({
    text: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const tooltipWrapperRef = ref(null);
const tooltipRef = ref(null);
const displayTooltip = ref(false);
const tooltipStyle = ref({
    top: '0px',
    left: '0px',
});

const toggleTooltip = () => {
    displayTooltip.value = !displayTooltip.value;
    if (displayTooltip.value) {
        nextTick(() => {
            setTooltipPosition();
        });
    }
};

const setTooltipPosition = () => {
    const tooltipWrapper = tooltipWrapperRef.value;
    const tooltip = tooltipRef.value;
    if (!tooltipWrapper || !tooltip) return;
    const tooltipWrapperRect = tooltipWrapperRef.value?.getBoundingClientRect();
    const tooltipRect = tooltipRef.value?.getBoundingClientRect();
    const top = tooltipWrapperRect.top + tooltipWrapperRect.height;
    const left =  (tooltipWrapperRect.left + (tooltipWrapperRect.width / 2) - (tooltipRect.width / 2))
    tooltipStyle.value = {
        top: `${top}px`,
        left: `${left}px`,
    };
};
</script>
<style lang="scss" scoped>
.tooltip-wrapper {
    width: 100%;
}

.tooltip {
    position: fixed;
    z-index: 1000;
    background-color: $light-grey;;
    padding: 3px 6px;
    border-radius: 0.5rem;
    box-shadow:  0 .25rem .375rem -.0625rem hsla(0,0%,8%,.12),0 .125rem .25rem -.0625rem hsla(0,0%,8%,.07);

    &__content {
        margin: 0;;
        
    }
}
</style>