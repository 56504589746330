<template>
    <div class="public-layout">
        <div class="public-layout__wrapper">
            <slot/>
        </div>
        
    </div>
</template>
<style lang="scss" scoped>
.public-layout {
    &__wrapper {
        width: 100%;
        height: 100dvh;
        overflow: auto;
        overflow-x: hidden;
    }
}

</style>