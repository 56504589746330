<template>
    <section id="customer-notes" class="card">
        <div class="card-header">
            <h5 class="text-center">Notes Client</h5>
        </div>
        <hr class="mt-0 horizontal dark">
        <!-- ADD CUSTOMER NOTE -->
        <div @click="isAddNotePopupOpen = !isAddNotePopupOpen" id="add-note-button">
            <svg v-if="!isAddNotePopupOpen" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="#94E4AF" d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4v4Zm1 5q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"/></svg>
            <svg v-if="isAddNotePopupOpen" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="currentColor" d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2zm-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8l-1.4 1.4z"/></svg>
        </div>
        <div v-show="isAddNotePopupOpen" id="add-note-form" class="card">
            <div class="card-body">
                <div v-if="addNoteError.status" class="text-center text-danger">
                    {{ addNoteError.message }}
                </div>
                <h6 class="text-center mb-3">Ajouter un nouveau mémo</h6>
                <QuillEditor @click="selectArea('add-memo-editor')" style="background-color: white; min-height: 20vh;" id="add-memo-editor" class="editor" content-type="html" v-model:content="newCustomerMemo.content" :key="quillKey" theme="snow" toolbar="essential" :read-only="false"/>
                <div class="text-center mt-3">
                    <SoftButton @click="createNewMemo" :disabled="userRank < 3">
                        <div class="d-flex">
                            <div v-if="newCustomerMemo.loading" class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="button-text">Ajouter le mémo</div>
                        </div>
                    </SoftButton>
                </div>
            </div>
        </div>
        <!-- END CUSTOMER NOTE -->
        <div class="card-body d-flex">
            <div id="memos-container" class="col-12">
                <div v-if="err.status" class="text-danger text-center">
                    {{ err.message }}
                </div>
                <div v-if="loading" class="text-center" >
                    <div class="spinner-border text-center" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-if="!err.status && !loading">
                    <ul v-if="customerMemosList.length >= 1">
                        <li v-for="memo in customerMemosList" :key="memo._id">
                            <div class="memo-content-area">
                                <strong>Par: {{ memo.createdByUser.fullName }}, le {{ getDate(memo.createdAt) }}</strong>
                                <div class="memo-content-editor-container" v-if="memoCurrentlyEditing === memo._id">
                                    <QuillEditor  @click="selectArea('content-editor-container')" style="background-color: white; min-height: 20vh;" id="content-editor-container" class="editor" content-type="html" v-model:content="memo.content" theme="snow" toolbar="essential" :read-only="false"/>
                                </div>
                                <p v-else class="memo-content" v-html="memo.content"></p>
                            </div>
                            <div class="buttons-memo">
                                <svg v-if="memoCurrentlyEditing != memo._id" @click="selectMemoForEditing(memo._id)" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"><path fill="currentColor" d="m19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6l4.25 4.25Z"/></svg>                                        
                                <svg v-if="memoCurrentlyEditing != memo._id" @click="deleteMemo(memo._id)" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"><path fill="currentColor" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12l1.41 1.41L13.41 14l2.12 2.12l-1.41 1.41L12 15.41l-2.12 2.12l-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/></svg>
                                <svg v-if="memoCurrentlyEditing === memo._id" @click="updateMemo(memo)" id="save-icon" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 15 15"><path fill="currentColor" d="M0 1.5A1.5 1.5 0 0 1 1.5 0h8.586a1.5 1.5 0 0 1 1.06.44l3.415 3.414A1.5 1.5 0 0 1 15 4.914V13.5a1.5 1.5 0 0 1-1.5 1.5H11v-3.5A1.5 1.5 0 0 0 9.5 10h-4A1.5 1.5 0 0 0 4 11.5V15H1.5A1.5 1.5 0 0 1 0 13.5v-12Z"/><path fill="currentColor" d="M5 15h5v-3.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5V15Z"/></svg>
                            </div>
                        </li>
                    </ul>
                    <div v-else class="text-center">
                        Aucun mémo trouvé pour ce client...
                    </div>
                </div>
            </div>
        </div>                     
    </section>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import axios from "axios";
    import Cookies from "js-cookie";
    import SoftButton from "@/components/SoftButton.vue";
    import { QuillEditor } from '@vueup/vue-quill';
    import '@vueup/vue-quill/dist/vue-quill.snow.css';

    // PROPS
    const props = defineProps({
        customerId: {
            type: String,
            required: true
        },
        userRank: {
            type: Number,
            required: true
        }
    });
    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        "Content-Type": "application/json",
    };
    
    // STATE
    const err = ref({
        message: "",
        status: false
    });
    const loading = ref(false);
    const newCustomerMemo = ref({
        loading: false,
        content: ""
    });
    const addNoteError = ref({
        status: false,
        message: ""
    });
    const customerMemosList = ref([]);
    const isAddNotePopupOpen = ref(false);
    const memoCurrentlyEditing = ref("");
    const quillKey = ref(0);
    // METHODS
    const getCustomerMemos = async () => {
        try {
            loading.value = true;
            err.value.status = false;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/advisor/customers/get-memos/${props.customerId}`,
                headers: axiosConfig
            });

            customerMemosList.value = response.data;
        } catch (error) {
            if (error.response.status === 400) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue durant la récupérations des informations";
            }
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };
    const createNewMemo = async () => {
        try {
            newCustomerMemo.value.loading = true;

            const response = await axios({
                method: "POST",
                url: `${process.env.VUE_APP_SERVER}/advisor/customers/create-memo`,
                headers: axiosConfig,
                data: {
                    content: newCustomerMemo.value.content,
                    customerId: props.customerId
                }
            });

            newCustomerMemo.value.content = "";
            quillKey.value +=1;
            isAddNotePopupOpen.value = false;
            getCustomerMemos();
        } catch (error) {
            if (error.response.status === 400) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue durant la récupérations des informations";
            }
            err.value.status = true;
        } finally {
            newCustomerMemo.value.loading = false;
        }
    };
    const deleteMemo = async (memoId) => {
        try {
            await axios({
                method: "DELETE",
                url: `${process.env.VUE_APP_SERVER}/advisor/customers/delete-memo`,
                headers: axiosConfig,
                data: {
                    memoId: memoId,
                    customerId: props.customerId
                }
            });
            getCustomerMemos();
        } catch (error) {
            console.log(error);
        }
    };
    const getDate = (date) => {
        return new Date(date).toLocaleDateString("FR-fr")
    };
    const selectArea = (id) => {
        console.log();
        const area = document.querySelector(`#${id} .ql-editor`);
        area.focus()
    };
    const selectMemoForEditing = (memoId) => {
        if (memoCurrentlyEditing.value !== "" && memoId) {
            window.alert("Vous devez sauvegarder le mémo en cours d'étition avant d'en éditer un autre'");
            return;
        }
        memoCurrentlyEditing.value = memoId;
        
    };
    const updateMemo = async (memo) => {
        try {
            loading.value = true;
            err.value.status = false;
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/user-memo/update`, {
                method: "PATCH",
                headers: axiosConfig,
                body: JSON.stringify({
                    memoId: memo._id,
                    content: memo.content
                })
            });

            const response = await promise.json();

            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }

            memoCurrentlyEditing.value = "";
            getCustomerMemos();

        } catch (error) {
            if (error.status === 401 || 404) err.value.message = error.error;
            else err.value.message = "Une erreur est survenue durant la mise à jour de la note";
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };
    
    // LIFECYCLE HOOKS
    onMounted(async() => {
        getCustomerMemos();
    });
</script>
<style scoped>
    #customer-notes {
        min-height: 57vh;
    }
    h5 {
        margin: 0;
    }
    div.card-header {
        padding: 1rem;
    }
    div.card-body {
        padding: 0.8rem;
    }
    #memos-container > ul > li {
        list-style: none;
    }
    #memos-container {
        height: 33vh;
        overflow: auto;
    }
    #memos-container ul {
        margin-right: 3rem;
        list-style: none;
    }
    ::-webkit-scrollbar {
        width: 5px;
        background: #e9e9e9;
    }
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 30px;
    }
    div + .button-text {
        margin-left: 5px;
    }
    li {
        position: relative;
        padding: 5px;
        display: flex;
        justify-content: space-between;
    }
    li > p {
        margin-bottom: 0;
    }
    li + li {
        border-top: 1px solid rgb(0 0 0 / 8%);
    }
    .buttons-memo {
        min-width: fit-content;
    }
    .buttons-memo:hover {
        cursor: pointer;
    }
    .buttons-memo svg {
        color: #94e4af;
    }
    .buttons-memo svg:hover {
        color: #70ac84;
    }
    #add-note-button {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
    #add-note-form {
        right: 10px;
        top: 55px;
        position: absolute;
        z-index: 2;
        width: 70%;
    }
    #text-area {
        border: 1px solid #6c757d;
        border-radius: 10px;
        max-width: 100%;
    }
    .memo-content-area {
        width: 90%;
    }
    .memo-content-editor-container {
        padding: 10px 0;
    }
   
</style>