<template>
    <div class="row w-100 h-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Audit SEO en ligne</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="iframe-container" class="container-fluid">
                    <iframe id="audit-online" src="https://api.workwithkernel.com/audit.html"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    
</script>
<style scoped>
    #iframe-container {
        height: 80vh;
    }   
    #audit-online {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
</style>