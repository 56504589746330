<template>
    <div class="input-text">
        <label v-if="label" :for="name" :required="required ? 'required' : null">
            {{ label }} <span v-if="required">*</span>
        </label>
        <input @keyup="$emit('valueChange', value)" v-model="value" :style="setInputStyle()" type="text" :id="name" :name="name" :placeholder="placeholder" class="form-control"/>
    </div>
</template>
<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: false,
        default: ''
    },
    placeholder: {
        type: String,
        required: false,
        default: ''
    },
    value: {
        type: String,
        required: false,
        default: ''
    },
    inputStyle: {
        type: Object,
        required: false
    },
    required: {
        type: Boolean,
        required: false,
        default: false
    }
});

const setInputStyle = () => {
    const inputStyle = props.inputStyle;
    if (inputStyle) {
        return inputStyle;
    }
};
const value = ref(props.value);

watch(() => props.value, (newValue) => {
    value.value = newValue;
});
</script>
<style lang="scss" scoped>
.input-text {
    input {
        font-size: 0.875rem;
    }
}    

</style>