<template>
    <div class="editor-networks">
        <label>Canaux</label>
        <fieldset class="editor-networks__networks">
            <div class="editor-networks__networks__network" v-for="network in networksList" :key="network.value">
                <input
                    @change="$emit('dataChange', formData)" 
                    type="checkbox" 
                    :id="network.value" 
                    :name="network.value" 
                    :value="network.value" 
                    v-model="formData" 
                    :disabled="readOnly"
                >
                <label class="network__label" :for="network.value">{{ network.displayValue }}</label>
            </div>
        </fieldset>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { socialNetworksList } from '@/tools/socialNetworkTools.js';
const props = defineProps({
    selectedNetworks: {
        type: Array,
        required: true,
    },
    readOnly: {
        type: Boolean,
        required: false,
        default: false
    }
});
const networksList = ref(socialNetworksList);
const formData = ref([]);
onMounted(() => {
    formData.value = props.selectedNetworks;
});
</script>
<style lang="scss" scoped>
    .editor-networks {
        width: 100%;
        display: flex;
        flex-direction: column;
        
        &__networks {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
        }
    }
</style>