'use strict';

export const generateThumbnailFromVideoFile = async (videoFile) => {
    if (!videoFile) {
        throw new Error("videoFile required");
    }
    
    const video = document.createElement('video');
    if (!video) {
        throw new Error("video not found");
    }
    video.crossOrigin = 'anonymous';
    video.src = URL.createObjectURL(videoFile);
    
    await new Promise(resolve => {
        video.onloadeddata = () => {
            video.currentTime = 2;
        };
        video.onseeked = resolve;
    });
   
    const canvas = document.createElement('canvas');
    canvas.width = 426;
    canvas.height = 240;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const capturedImage = canvas.toDataURL('image/jpeg');
    return capturedImage;
};

export const generateDataUrlFromImageFile = async (imageFile) => {
    if (!imageFile) {
        throw new Error("imageFile required");
    }
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    await new Promise((resolve) => reader.onload = resolve);
    return reader.result;
};

export const generateImageFileFromBase64Image = async (videoFile) => {
    const capturedImage = await generateThumbnailFromVideoFile(videoFile);
    const imageFile = await fetch(capturedImage).then(res => res.blob());
  
    return imageFile;
}
