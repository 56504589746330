<template>
    <div @click="handleClick" @mouseenter="toggleStatusHover" @mouseleave="toggleStatusHover" class="navigation-card container-card" role="button">
        <div class="navigation-card__icon">
            <img class="navigation-card__icon__img" :src="icon"/>
        </div>
        <div class="navigation-card__content">
            <slot></slot>
        </div>
        <div class="navigation-card__after">
            <img class="navigation-card__after__img" :class="{ 'hovered': isHovered }" :src="ArrowIcon">
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import ArrowIcon from '@/assets/img/icons/arrow.png';

const router = useRouter();
const props = defineProps({
    link: {
        type: String,
        required: false
    },
    method: {
        type: Function,
        required: false
    },
    icon: {
        type: String,
        required: true
    }
});


const isHovered = ref(false);


const toggleStatusHover = (event) => {
    const type = event.type;
    if (type === 'mouseenter') {
        isHovered.value = true;
    }
    else if (type === 'mouseleave') {
        isHovered.value = false;
    }
};
const redirect = (link) => {
    router.push(link);
};
const handleClick = () => {
    const { method, link } = props;
    if (method) {
        method();
    }
    else if (link) {
        redirect(link);
    }
};
</script>
<style lang="scss" scoped>
.navigation-card {
    display: flex;
    height: 80px;
    overflow: hidden;
    transition: all 0.1s ease-out;
    flex-basis: 300px;
    flex-shrink: 0.5;
    gap: 1rem;


    &__icon {
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &__img {
            width: 26px;
            height: 26px;
            color: $primary-blue-color;
        }
    }

    &__content {
        font-weight: 600;
        color: $primary-blue-color;
        display: flex;
        justify-content: start;
        align-items: center;
        max-height: 100%;
        width: 65%;
        overflow: hidden;
    }

    &__after {
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid $light-grey-1;

        &__img {
            width: 14px;
            height: 14px;
            transform: rotate(180deg);
            transition: all 0.1s ease-in-out;

            &.hovered {
                transform: translate(5px, 0) rotate(180deg);
            }
        }
    }
}
</style>