<template>
    <ItemCardLayout :closeButton="true" class="social-networks-publication-card-expanded">
        <div class="social-networks-publication-card-expanded__week">
            <PublicationCardWeek :week="publication.week" />
        </div>
        <div class="social-networks-publication-card-expanded__visual">
            <div v-if="publication.images.length" class="social-networks-publication-card-expanded__visual__images">
                <PublicationCardImages :images="publication.images" />
            </div>
            <div v-else-if="publication.video?.link" class="social-networks-publication-card-expanded__visual__video">
                <PublicationCardVideo :video="publication.video" :videoStyle="{ borderRadius: true }"/>
            </div>
            <div v-else class="social-networks-publication-card-expanded__visual__no-visual">
                <p>Aucun visuel</p>
            </div>
        </div>
        <div class="social-networks-publication-card-expanded__networks">
            <PublicationCardNetworksList :networks="publication.networks" />
        </div>
        <div class="social-networks-publication-card-expanded__content">
            <PublicationCardContent :content="publication.content" :scrollable="true"/>
        </div>
        <div v-if="publication.stringStatus === 'En attente'" class="social-networks-publication-card-expanded__actions">
            <CustomClassicButton @click="$emit('correctionRequest')" :buttonStyle="correctionRequestButtonStyle">Demander une retouche</CustomClassicButton>
            <CustomClassicButton @click="$emit('approvePublication')" :buttonStyle="approveButtonStyle">Valider la publication</CustomClassicButton>
        </div>
    </ItemCardLayout>
</template>
<script setup>
import CustomClassicButton from '@/components/standalone/customsButtons/CustomClassicButton.vue';
import PublicationCardNetworksList from './components/PublicationCardNetworksList.vue';
import ItemCardLayout from '@/components/layouts/cardsLayouts/ItemCardLayout.vue';
import buttonsStyle from '@/assets/scss/exports/buttons-variables.module.scss';
import PublicationCardContent from './components/PublicationCardContent.vue';
import PublicationCardImages from './components/PublicationCardImages.vue';
import PublicationCardVideo from './components/PublicationCardVideo.vue';
import PublicationCardWeek from './components/PublicationCardWeek.vue';
import { ref } from 'vue';

const props = defineProps({
    publication: {
        type: Object,
        required: true
    },
    approvePublication: {
        type: Function,
        required: false
    }
});

const approveButtonStyle = ref({
    'font-size': '0.8rem',
    'color': buttonsStyle.grey1Color,
    
});
const correctionRequestButtonStyle = ref({
    'font-size': '0.8rem',
    'color': buttonsStyle.orange1Color,


});

</script>
<style lang="scss" scoped>
    .social-networks-publication-card-expanded {
        padding: 0.8rem;
        height: 80dvh;
        width: 33dvw;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        @media screen and (max-width: $large-desktop) {
            height: 80dvh;
            width: 35dvw;
        }  
        @media screen and (max-width: $normal-desktop) {
            height: 80dvh;
            width: 40dvw;
        }  
        @media screen and (max-width: $small-desktop) {
            height: 80dvh;
            width: 45dvw;
        }  
        @media screen and (max-width: $large-tablet) {
            height: 85dvh;
            width: 50dvw;
        }  
        @media screen and (max-width: $normal-tablet) {
            height: 85dvh;
            width: 60dvw;
        }   
        @media screen and (max-width: $small-tablet) {
            height: 85dvh;
            width: 70dvw;
        }   
        @media screen and (max-width: $large-mobile) {
            height: 100dvh;
            width: 100dvw;
        }    
        
        &__week {
            height: 5%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__visual {
            height: 38%;
            overflow: hidden;

            &__images {
                height: 100%;
            }
            &__video {
                height: 100%;
                
            }
            &__no-visual {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
            }
        }

        &__networks {
            height: 8%;
        }

        &__content {
            height: 40%;
          
        }

        &__actions {
            height: 8%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
        }
    }
</style>