import colorLabel from "@/assets/scss/kernel-ui/colors/colors.module.scss";
const defaultColor = colorLabel.defaultColorLabel;
const warningColor = colorLabel.warningColorLabel;
const errorColor = colorLabel.errorColorLabel;
const infoColor = colorLabel.infoColorLabel;
const waitingColor = colorLabel.waitingColorLabel;
const successColor = colorLabel.successColorLabel;
import router from "@/router";

const articleTypes = [
    {  value: "Externe", label: "Article Externe" }, 
    {  value: "Interne", label: "Page SEO" },
    {  value: "Blog", label: "Blog" },
    {  value: "Categorie", label: "Page Catégorie" }
];

const articleStatus = [
    { 
        value: 0, 
        label: "En rédaction", 
        customerLabel: "En rédaction", 
        color: defaultColor, 
        customerColor: defaultColor
    },
    { 
        value: 1, 
        label: "Attente validation admin", 
        customerLabel: "En rédaction", 
        color: warningColor, 
        customerColor: defaultColor
    },
    { 
        value: 2, 
        label: "Attente validation client", 
        customerLabel: "Contenu à valider", 
        color: warningColor, 
        customerColor: warningColor,
    },
    { 
        value: 3, 
        label: "En correction", 
        customerLabel: "En correction", 
        color: errorColor,
        customerColor: errorColor
    },
    { 
        value: 4, 
        label: "En cours de publication", 
        customerLabel: "En cours de publication", 
        color: infoColor, 
        customerColor: infoColor
    },
    { 
        value: 5, 
        label: "En attente de parution", 
        customerLabel: "En cours de publication", 
        color: waitingColor, 
        customerColor: infoColor
    },
    { 
        value: 6, 
        label: "Publié", 
        customerLabel: "Publié", 
        color: successColor, 
        customerColor: successColor
    }
];

export const articleTypesListForInputs = [
    {  value: "", label: "Tous" }, 
    {  value: "Externe", label: "Externe" }, 
    {  value: "Interne", label: "Page SEO" },
    {  value: "Blog", label: "Blog" },
    {  value: "Categorie", label: "Page Catégorie" }
];

export const articlesStatusListForCustomersInputs = [
    { label: "Tous", value: "" },
    { label: "En rédaction", value: "0, 1" },
    { label: "Contenu à valider", value: "2" },
    { label: "En correction", value: "3" },
    { label: "En cours de publication", value: "4, 5" },
    { label: "Publié", value: "6" }
];

const articleTools = {
    getType: (articleType) => {
        switch (articleType) {
            case "Externe":
                return "Externe";
            case "Interne":
                return "Page SEO";
            case "Blog":
                return "Blog";
            case "Categorie":
                return "Page Catégorie";
        }
    },
    getStringStatus: (status) => {
        switch (status) {
            case 0 :
                return "En rédaction"
            case 1:
                return "Attente validation admin";
            case 2: 
                return "Attente validation client";
            case 3: 
                return "En correction";
            case 4:
                return "En cours de publication";
            case 5:
                return "En attente de parution"
            case 6: 
                return "Publié"
        }
    },
    getArticleTypes: () => {
        return articleTypes;
    }
};

export default articleTools;

export const getArticleStringStatus = ({ reqStatus, isCustomer }) => {
    const statusLabel = articleStatus.find(status => status.value === reqStatus);
    if (!statusLabel) {
        throw new Error('Invalid article status');
    }
    return isCustomer ? statusLabel.customerLabel : statusLabel.label;
};

export const getArticleColorStatus = ({ reqStatus, isCustomer }) => {
    const statusColor = articleStatus.find(status => status.value === reqStatus);
    if (!statusColor) {
        throw new Error('Invalid article status');
    }
    return isCustomer ? statusColor.customerColor : statusColor.color;
};

export const getArticleType = ({ reqType }) => {
    const typeLabel = articleTypes.find((type) => type.value === reqType);
    if (!typeLabel) {
        throw new Error('Invalid article type');
    }
    return typeLabel.label;
};

export const getArticleActionForCustomer = ({ reqStatus, articleId }) => {
    const status = articleStatus.find((status) => status.value === reqStatus);
    if (!status) {
        throw new Error('Invalid article status');
    }
    switch (reqStatus) {
        case 0: 
        case 1:
        case 3:
            return {};
        case 2:
            return {
                type: "button",
                value: "Vérifier",
                isDisabled: false,
                action: () => {
                    router.push(`/article-validation/${articleId}`);
                }
            };
        case 4:
        case 5:
        case 6:
            return {
                type: "link",
                value: "Voir",
                to: `/article-reading/${articleId}`,
            };
        default:
            return {};
    }
};