<template>
    <div class="card">
        <div class="card-header">
            <h5>Utilisateurs par plateforme</h5>
        </div>
        <div class="card-body">
            <div v-if="err.status === true" id="error-container">
                <div>Nous n'avons pas pu récupérer ces données auprès de google...</div>
            </div>
            <div v-if="loading" id="spinner-container">
                <div class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <VueApexCharts v-if="!loading && err.status === false" type="donut" height="450" :options="options" :series="series"></VueApexCharts>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from "vue";
    import Cookies from "js-cookie";
    import VueApexCharts from "vue3-apexcharts";

    const props = defineProps({
        period: {
            type: String,
            required: true
        }
    });

    // STATE
    const err = ref({
        status: false,
    })
    const loading = ref(true);
    const options = ref({});
    const series = ref([]);

    // METHODS
    const getTrafficByPlatforms = async () => {
        try {
            loading.value = true;
            err.value.status = false;

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/google/get-data/traffic-by-platforms?period=${props.period}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                },
            });

            const response = await promise.json();
            const status = await promise.status;

            if (status !== 200) {
                throw new Error();
            }

            options.value = {
                colors: ["#94e4af", "#28829f", "#f7da65", "#f79191", "#deaef9"], 
                labels: response.platforms,
            };
            series.value = response.values;
        } catch (error) {
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };

    // LIFECYCLE HOOKS
    onMounted(() => {
        getTrafficByPlatforms();
    });
</script>

<style scoped>
    .card {
        height: 100%;
    }
    #spinner-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 390px;
    }
    #error-container {
        text-align: center;
    }
</style>