<template>
    <div class="single-select">
        <label v-if="hasLabel" :for="name">
            {{ label }}
        </label>
        <select v-model="currentValue" class="form-control" :style="setSelectStyle()" :name="name" :id="name" @change="selectValue" :disabled="disabled">
            <option v-for="(option, index) in options" :key="index" :value="option.value">
                {{ option.label }}
            </option>
        </select>
    </div>
</template>
<script setup>
import { ref, defineEmits, onMounted } from 'vue';
const emits = defineEmits(['valueChange']);
const currentValue = ref('');
const props = defineProps({
    hasLabel: {
        type: Boolean,
        default: true
    },
    label: {
        type: String,
        default: 'Label'
    },
    name: {
        type: String,
        default: ''
    },
    options: {
        type: Array,
        required: true
    },
    selectStyle: {
        type: Object,
    },
    disabled: {
        type: Boolean,
        default: false
    },
    defaultValue: {
        type: String,
        required: false,
    }
});
const setSelectStyle = () => {
    if (props.selectStyle) {
        return props.selectStyle;
    }
    return '';
};

const selectValue = (event) => {
    currentValue.value = event.target.value;
    emits('valueChange', currentValue.value);
};

onMounted(() => {
    currentValue.value = props.defaultValue || props.options[0].value;
});
</script>
<style lang="scss" scoped>
.single-select {
    display: flex;
    flex-direction: column;

    select {
        border-radius: 0.5rem;
        font-size: 0.875rem;
    }
}

</style>