<template>
    <button @click="action" class="custom-view-button" :style="style()" :disabled="disabled">
        <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="width" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>                                        
    </button>
</template>
<script setup>
    import { createDarkerColorFromHexOrRgb } from '@/tools/colorTools';
    import styles from '@/assets/scss/exports/main-variables.module.scss';
  
    // PROPS
    const props = defineProps({
        isOpen: {
            type: Boolean,
            required: false
        },
        width: {
            type: [ Number, String ],
            required: false,
        },
        color: {
            type: String,
            required: false,
        },
        action: {
            type: Function,
            required: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    });
    const style = () => {
        const { width, color } = props;
        const style = {
            'width': width || '32px',
            'height': width || '32px',
            'color': color || styles.mainColor,
            '--hover-color': createDarkerColorFromHexOrRgb(color || styles.mainColor, 30),
        };
        return style;
    };
</script>
<style lang="scss" scoped>
    :root {
        --hover-color: #defaultColor;
    }
    .custom-view-button {
        border: none;
        padding: 0;
        width: fit-content;
        background-color: transparent;
        border-radius: 100%;
        
        :hover {
            color: var(--hover-color);
        }
        &:disabled {
            color: $light-grey-1 !important;
            pointer-events: none;
        }
    }
</style>