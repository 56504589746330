<template>
    <div class="container-fluid mt-4">
        <div id="logout-button-container" class="d-flex align-items-center justify-content-end">
            <div id="logout-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 36 36"><path fill="currentColor" d="M7 6h16v9.8h2V6a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v24a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2H7Z" class="clr-i-outline clr-i-outline-path-1"/><path fill="currentColor" d="M28.16 17.28a1 1 0 0 0-1.41 1.41L30.13 22h-14.5a1 1 0 0 0-1 1a1 1 0 0 0 1 1h14.5l-3.38 3.46a1 1 0 1 0 1.41 1.41l5.84-5.8Z" class="clr-i-outline clr-i-outline-path-2"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                <p @click="logout" class="m-0">Déconnexion</p>
            </div>
        </div>
        <div class="row mb-5 mt-2">
            <div class="col-12">
                <div class="card">
                    <div v-if="isGoogleLogged" id="google-analytics-header" class="card-header">
                        <h5>Paramètres Google Analytics</h5>
                        <a @click="googleLogout" href="#">Déconnecter mon compte Google</a>
                    </div>
                    <div class="card-body d-flex justify-content-center">
                        <div v-if="!isGoogleLogged || isAnalyticsLoading" id="google-login-button-container">
                            <img src="https://developers.google.com/static/analytics/images/terms/lockup_ic_Google_Analytics_vert_388px_clr.png?hl=fr" alt="google-analytics-logo">
                            <button @click="googleLogin" :disabled="isAnalyticsLoading">
                                <div v-if="isAnalyticsLoading" class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                Se connecter avec Google Analytics
                            </button>
                        </div>
                        <div v-else class="d-flex w-100 gap-3">
                            <div class="select-container col-5">
                                <label for="accounts-list">Compte Analytics:</label>
                                <select @change="getGoogleProperties" v-model="googleAccounts.currentAccount" class="form-control" name="accounts-list" id="accounts-list">
                                    <option v-for="(account, index) in googleAccounts.accountsList" :key="index" :value="account.account">{{ account.displayName }}</option>
                                </select>
                            </div>
                            <div class="select-container col-5">
                                <label for="properties-list">Propriété Analytics:</label>
                                <select @change="setCurrentProperty" v-model="googleProperties.currentProperty" class="form-control" name="properties-list" id="properties-list" selected :disabled="googleProperties.propertiesList.length <= 1">
                                    <option v-for="(property, index) in googleProperties.propertiesList" :key="index" :value="property.name">{{ property.displayName }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="isGoogleError.status" id="google-error-container" class="text-danger">
                        <p>{{ isGoogleError.message }}</p>
                    </div>
                    <!-- <div id="google-privacy-policy-link">
                        <a href="https://www.workwithkernel.com/mentions-legales" target="_blank">
                            Politique de confidentialité et d’utilisation des données Google
                        </a>
                    </div> -->
                </div>
            </div>
           <div class="col-12">
                <div id="basic-info" class="card mt-4">
                    <div class="card-header">
                        <h5>Rénitialisation du mot de passe</h5>
                    </div>
                    <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                                <label for="current-password" class="form-label">Ancien mot depasse</label>
                                <input v-model="formPassword.currentPassword" id="current-password" class="form-control" type="password"/>
                                <label for="new-password" class="form-label">Nouveau mot de passe</label>
                                <input v-model="formPassword.newPassword" id="new-password" class="form-control" type="password"/>
                                <label for="new-password-validation" class="form-label">Confirmation nouveau mot de passe</label>
                                <input v-model="formPassword.newPasswordValidation" id="new-password-validation" class="form-control" type="password"/>
                            </div>
                        </div>
                        <div v-if="err.status === true || success.status === true" class="alert" :class="{'alert-danger': err.status, 'alert-success': success.status }">
                            <p v-if="err.status">{{ err.message }}</p>
                            <p v-if="success.status">{{ success.message }}</p>
                        </div>
                        <div class="d-flex mt-4">
                        <soft-button @click="changePassword" color="secondary" variant="outline" class="mb-0 ms-auto" type="button" name="button">
                            Sauvegarder
                        </soft-button>
                    </div>
                </div>
           </div>
           <div class="row mt-4">
                <div class="col-12">
                    <KernelCredits />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
    import NavPill from "./components/NavPill.vue";
    import SideNav from "./components/SideNav.vue";
    import SideNavItem from "./components/SideNavItem.vue";
    import SoftButton from "../../../components/SoftButton.vue";
    import axios from 'axios';
    import Cookies from "js-cookie";
    import { useRouter, useRoute } from "vue-router";
    import { onMounted, ref } from "vue";
    import googleLogo from "@/assets/img/logo-google.png";
    import KernelCredits from "./KernelCredits.vue";

    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        "Content-Type": "application/json",
    };
    const router = useRouter();
    const route = useRoute();
    // STATE
    const formPassword = ref({
        currentPassword: "",
        newPassword: "",
        newPasswordValidation: ""
    });
    const err = ref({
        message: "",
        status: false
    });
    const success = ref({
        message: "",
        status: false
    });
    const isAnalyticsLoading = ref(true);
    const initialPassword = () => {
        return {
            currentPassword: "",
            newPassword: "",
            newPasswordValidation: "",
        }
    };
    const form = ref(initialPassword());
    const requestSuccess = ref(false);

    const isGoogleLogged = ref(false);
    const isGoogleError = ref({
        status: false,
        message: ""
    });
    const googleAccounts = ref({
        currentAccount: "",
        accountsList: []
    });
    const googleProperties = ref({
        currentProperty: "",
        propertiesList: []
    });

    // METHODS
    const changePassword = async () => {
        try {
            err.value.status= false;

            const response = await axios({
                method: "PATCH",
                url: `${process.env.VUE_APP_SERVER}/user/change-password`,
                headers: axiosConfig,
                data: formPassword.value

            });
            if(response.status === 200){
                 success.value.status = true;
                 success.value.message = response.data.message;
                 form.value = formPassword.value = initialPassword()
                 requestSuccess.value = true;

            }
        } catch (error) {
            if (error.response.status === 400 || 500 || 409) {
                err.value.status = true;
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue, veuillez réessayer ultérieurement";
            }
        }
    };
    const logout = () => {
        Cookies.remove("token");
        Cookies.remove("rank");
        Cookies.remove("companyInformations");
        localStorage.removeItem("companyInformations");
        window.Intercom('shutdown', {
            app_id: process.env.VUE_APP_INTERCOM_APP_ID,
        })
        router.push("/login");
    };
    // GOOGLE METHODS
    const googleLogin = async () => {
        try {
            isGoogleError.value.status = false;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/user/google/login`,
                headers: axiosConfig
            });

            const newPage = window.open(response.data);
            newPage.focus();

            const checkClosed = setInterval(() => {
                if (newPage.closed) {
                    clearInterval(checkClosed);
                    window.location.reload();
                }
            }, 1000);
        } catch (error) {
            isGoogleError.value.message = "Une erreur est survenue durant la connexion à votre compte google, veuillez contacter un administrateur si le problème persiste";
            isGoogleError.value.status = true;
        }
    };
    const getGoogleToken = async (code) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${process.env.VUE_APP_SERVER}/user/google/get-token`,
                headers: axiosConfig,
                data: {
                    code: code
                }
            });
            if (response.status === 200) {
                window.close();
            }
        } catch (error) {
           isGoogleLogged.value = false;
        }
    };
    const checkGoogleToken = async () => {
        try {
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/google/check-token`, {
                method: "GET",
                headers: axiosConfig,
            });

            const response = await promise.json();

            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }
            if (response.isToken === true) {
                isGoogleLogged.value = true;
            } else {
                isGoogleLogged.value = false;
                isAnalyticsLoading.value = false;
            }
            
        } catch (error) {
            isGoogleLogged.value = false;
        }
    };
    const getGoogleAccounts = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/user/google/get-data/accounts`,
                headers: axiosConfig,
            });
            
            if (response.data.accountsList[0]) {
                googleAccounts.value.accountsList = response.data.accountsList;
                googleAccounts.value.currentAccount = response.data.currentAccount;
                await getGoogleProperties();
            } 
        } catch (error) {
            isGoogleError.value.message = "Une erreur est survenue durant la récupération de vos comptes Analytics, assurez-vous que votre compte Google est bien lié à au moins un compte Analytics";
            isGoogleError.value.status = true;
        }
    };
    const getGoogleProperties = async () => {
        try {
            isGoogleError.value.status = false;
            const response = await axios({
                method: "POST",
                url: `${process.env.VUE_APP_SERVER}/user/google/get-data/properties`,
                headers: axiosConfig,
                data: {
                    account: googleAccounts.value.currentAccount
                }
            });

            if (response.data?.propertiesList) {
                googleProperties.value.propertiesList = response.data.propertiesList;
                googleProperties.value.currentProperty = response.data.currentProperty;
            }

        } catch (error) {
            isGoogleError.value.message = "Une erreur est survenue durant la récupération de vos propriétés Analytics, assurez-vous que ce compte Analytics possède au moins une propriété Analytics GA4";
            isGoogleError.value.status = true;
        } finally {
            isAnalyticsLoading.value = false;
        }
    }; 
    const setCurrentProperty = async () => {
        try {
            await axios({
                method: "POST",
                url: `${process.env.VUE_APP_SERVER}/user/google/set-current/property`,
                headers: axiosConfig,
                data: {
                    account: googleAccounts.value.currentAccount,
                    property: googleProperties.value.currentProperty
                }
            });
        } catch (error) {
            
        }
    };
    const googleLogout = async () => {
        try {
            isGoogleError.value.status = false;
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/google/logout`, {
                method: "GET",
                headers: axiosConfig,
            });

            const response = await promise.json();

            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }
            
            location.reload();
        } catch (error) {
            isGoogleError.value.message = "Une erreur est survenue durant la déconnexion de votre compte Google";
            isGoogleError.value.status = true;
        }
    };

    // LIFECYCLE HOOKE  
    onMounted(async () => {
        if (route.query.code) {
            await getGoogleToken(route.query.code);
        }
        await checkGoogleToken();
        if (isGoogleLogged.value === true) {
            getGoogleAccounts(); 
        }
    });
</script>

<style scoped>
    #logout-button {
        display: flex;
        cursor: pointer;
        align-items: center;
    }
    #logout-button > svg {
        width: 25px;
        margin-right: 3px;
    }
    #logout-button:hover {
        color: #94e4af;
    }
    /* #google-login-button {
        display: flex;
        padding: 10px 0;
        border-radius: 13px;
        border: none;
        background-color: #94e4af78;
        min-width: 240px;
        align-items: center;
        justify-content: center;
    } */
    #google-analytics-header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    #google-login-button-container {
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    #google-login-button-container > button {
        padding: 10px 13px;
        border-radius: 30px;
        border: none;
        background-color: #94e4af78;
        min-width: 240px;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: #495057;
    }
    #google-login-button-container > button:active {
        box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    }
    #google-login-button-container > img {
        width: 200px;
        margin-bottom: 1rem;
        align-self: center;
    }
    #google-privacy-policy-link, #google-error-container {
        padding: 1rem;
    }
    
</style>