
<template>
    <div class="card h-100 p-4 text-center">
        <div class="form-container">
            <div class="input-container">
                <label for="searched-url">Url</label>
                <input v-model="inputSearchedUrl" id="searched-url" type="text" class="form-control"/>
            </div>
            <div id="apply-button-container">
                <SoftButton id="apply-button" @click="getKeywords(true)" :disabled="!domainName">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M7 9H2V7h5v2zm0 3H2v2h5v-2zm13.59 7l-3.83-3.83c-.8.52-1.74.83-2.76.83c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5c0 1.02-.31 1.96-.83 2.75L22 17.59L20.59 19zM17 11c0-1.65-1.35-3-3-3s-3 1.35-3 3s1.35 3 3 3s3-1.35 3-3zM2 19h10v-2H2v2z"/></svg>
                </SoftButton>
            </div>
            
        </div>
        <div v-show="err.status" class="text-danger">
            {{ err.message }}
        </div>
        <div id="cost-in-credits">
            <KernelCreditsBalance :key="refreshKey" />
            <div><strong>Coût par ligne :</strong> 1 crédit (10 lignes par requête)</div>
            <div v-show="domainKeywords.updatedAt"><strong>Dernère actualisation : </strong>{{ getLocaleDateStringFromISO(domainKeywords.updatedAt) }}</div>
            <svg v-if="domainKeywords.keywords.length >= 1" @click="getInCsv()" id="csv-button" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="m213.65 82.34l-56-56A8 8 0 0 0 152 24H56a16 16 0 0 0-16 16v72a8 8 0 0 0 8 8h160a8 8 0 0 0 8-8V88a8 8 0 0 0-2.35-5.66ZM152 88V44l44 44ZM48 180c0 11 7.18 20 16 20a14.24 14.24 0 0 0 10.22-4.66a8 8 0 0 1 11.56 11.06A30.06 30.06 0 0 1 64 216c-17.64 0-32-16.15-32-36s14.36-36 32-36a30.06 30.06 0 0 1 21.78 9.6a8 8 0 0 1-11.56 11.06A14.17 14.17 0 0 0 64 160c-8.82 0-16 9-16 20Zm103.81 16.31a20.82 20.82 0 0 1-9.19 15.23C137.43 215 131 216 125.13 216a61.34 61.34 0 0 1-15.19-2a8 8 0 0 1 4.31-15.41c4.38 1.2 14.95 2.7 19.55-.36c.88-.59 1.83-1.52 2.14-3.93c.34-2.67-.72-4.1-12.78-7.59c-9.35-2.7-25-7.23-23-23.11a20.58 20.58 0 0 1 9-14.95c11.85-8 30.72-3.31 32.84-2.76a8 8 0 0 1-4.07 15.48c-4.49-1.17-15.23-2.56-19.83.56a4.57 4.57 0 0 0-2 3.67c-.11.9-.13 1.09 1.12 1.9c2.31 1.49 6.45 2.68 10.45 3.84c9.82 2.83 26.33 7.66 24.14 24.97Zm63.72-41.62l-20 56a8 8 0 0 1-15.07 0l-20-56a8 8 0 0 1 15.07-5.38l12.47 34.9l12.46-34.9a8 8 0 0 1 15.07 5.38Z"/></svg>
        </div>
        <div class="table-responsive">
            <table class="table align-items-center mb-0">
                <thead>
                    <tr>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mot-clé</th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Position</th>
                        <th v-if="['rise', 'fall'].includes(domainKeywords.lastFilter)" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Différence</th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Trafic en %</th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Recherches / mois</th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">URL référencées </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="loading" style="width:100%;" class="text-center">
                        <td colspan="7">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    <tr v-show="domainKeywords.keywords.length > 0 && !loading" v-for="(keyword, index) in domainKeywords.keywords" :key="index">
                        <td>{{ keyword.Keyword }}</td>
                        <td>{{ keyword['Previous Position'] ? `${keyword['Previous Position']} > ` : "" }}<strong>{{ keyword.Position }}</strong></td>
                        <td v-if="['rise', 'fall'].includes(domainKeywords.lastFilter)" v-html="domainKeywordsTools.getPositionsDifferences(keyword)"></td>
                        <td>{{ keyword['Traffic (%)'] }}</td>
                        <td>{{ keyword['Search Volume'] }}</td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path fill="currentColor" d="M136.37 187.53a12 12 0 0 1 0 17l-5.94 5.94a60 60 0 0 1-84.88-84.88l24.12-24.11A60 60 0 0 1 152 99a12 12 0 1 1-16 18a36 36 0 0 0-49.37 1.47l-24.1 24.08a36 36 0 0 0 50.92 50.92l5.94-5.94a12 12 0 0 1 16.98 0Zm74.08-142a60.09 60.09 0 0 0-84.88 0l-5.94 5.94a12 12 0 0 0 17 17l5.94-5.94a36 36 0 0 1 50.92 50.92l-24.11 24.12A36 36 0 0 1 120 139a12 12 0 1 0-16 18a60 60 0 0 0 82.3-2.43l24.12-24.11a60.09 60.09 0 0 0 .03-84.91Z"/></svg>
                            <a :href="keyword.Url" target="_blank">{{ keyword.Url }}</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="domainKeywords.keywords.length > 0" class="more-keywords-button-container">
            <SoftButton @click="getKeywords(false)" class="more-keywords-button" :disabled="canLoadMoreKeywords === false">
                <div v-if="loadingMoreKeywords" class="load-more-loading spinner-border spinner-border-sm">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div>Afficher plus de résultats</div>
            </SoftButton>
            <p class="cost">Coût: 10 crédits</p>
        </div>
    </div>
</template>
<script setup>
    import {onMounted, ref, toRaw } from "vue";
    import Cookies from "js-cookie";
    import { getLocaleDateStringFromISO } from "@/tools/dateTools";
    import csvTools from "@/tools/csvTools.js";
    import { removeHttpFromLink } from "@/tools/divTools.js";
    import SoftButton from "@/components/SoftButton.vue";
    import KernelCreditsBalance from "@/components/KernelCreditsBalance/KernelCreditsBalance.vue";
    import domainKeywordsTools from "@/tools/domainKeywordsTools.js";
    

    // PROPS
    const props = defineProps({
        domainName: {
            type: String,
            required: true
        },
        domainKeywordsId: {
            type: String,
            required: true
        },
        database: {
            type: String,
            required: true
        },
    });
    
    const fetchConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        "Content-Type": "application/json",
    };

    // // STATE
    const loading = ref(false);
    const loadingMoreKeywords = ref(false);
    const canLoadMoreKeywords = ref(false);
    let refreshKey = ref(0);
    const err = ref({
        status: false,
        message: ""
    });
    const inputSearchedUrl = ref("");
    const domainKeywords = ref({
        keywords: [],
        searchedUrl: "",
        updatedAt: ""
    });

    // // METHODS
    const getKeywords = async (initialRequest) => {
        try {
            const params = domainKeywords.value;
            err.value.status = false;
            !initialRequest ? loadingMoreKeywords.value = true : loading.value = true;

            if (!props.domainName) {
                throw {
                    error: "Veuillez renseigner un nom de domaine",
                    status: 400
               }
            }

            const previousIndex = !initialRequest ? params.keywords.length : 0;

            
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/semrush/domain-keywords/get-keywords-by-url`, {
                method: 'POST',
                headers: fetchConfig,
                body: JSON.stringify({
                    domainName: props.domainName,
                    domainKeywordsId: props.domainKeywordsId,
                    initialRequest: initialRequest,
                    previousIndex: previousIndex,
                    searchedUrl: inputSearchedUrl.value,
                    database: props.database
                })
            });

            const status = promise.status;
            const response = await promise.json();
            
            if (status !== 200) {
                throw {
                    status: status,
                    ...response
                }
            }
         
            refreshKey.value += 1;

            if (domainKeywords.value.searchedUrl !== response.searchedUrl || initialRequest) {
                domainKeywords.value.keywords = [];
            }
            
            domainKeywords.value.updatedAt = response.updatedAt;
            domainKeywords.value.searchedUrl = response.searchedUrl;
            inputSearchedUrl.value = response.searchedUrl;
            domainKeywords.value.keywords.push(...response.keywords);

            if (response.keywords?.length >= 10) canLoadMoreKeywords.value = true
            else canLoadMoreKeywords.value = false;
            
        } catch (error) {
            if (error.status === 400 || 401 || 404 || 402 ) err.value.message = error.error;
            else err.value.message = "Une erreur est survenue";
            err.value.status = true;
            canLoadMoreKeywords.value = false;
        } finally {
            loading.value = false;
            loadingMoreKeywords.value = false;
        }
    };
    const getPreviousKeywords = async () => {
        try {
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/semrush/domain-keywords/get-previous`, {
                method: 'POST',
                headers: fetchConfig,
                body: JSON.stringify({
                    target: "keywordsByUrl database",
                })
            });

            const status = promise.status;
            const response = await promise.json();
            
            if (status !== 200) {
                throw {
                    status: status,
                    ...response
                }
            }
            
            const keywordsByUrl = response.keywordsByUrl;
            domainKeywords.value.keywords = keywordsByUrl.keywords;
            domainKeywords.value.updatedAt = keywordsByUrl.updatedAt;
            domainKeywords.value.searchedUrl = keywordsByUrl.searchedUrl;
            inputSearchedUrl.value = keywordsByUrl.searchedUrl;
        } catch (error) {
        } 
    };
    const getInCsv = () => {
        const dk = domainKeywords.value;
        const data = toRaw(domainKeywords.value.keywords);
        const domainName = removeHttpFromLink(props.domainName);
        const filename = `${domainName}_${removeHttpFromLink(dk.searchedUrl).replace(domainName, "")}_${getLocaleDateStringFromISO(dk.updatedAt)}`;
        csvTools.getInCsv(data, filename);
    };
    const refreshCredits = () => {
        refreshKey.value += 1;
    };
    defineExpose({
        refreshCredits,
        getPreviousKeywords,
    });

    // LIFECYCLE HOOKS
    onMounted(() => {
        getPreviousKeywords();
    })

</script>
<style scoped>
    .form-container {
        display: flex;
        margin-bottom: 1rem;
        flex-wrap: wrap;
        justify-content: start;
        align-items: end;
    }
    .input-container {
        display: flex;
        margin-right: 1rem;
        margin-top: 10px;
        min-width: 250px;
        width: 300px;
        flex-direction: column;
        align-items: start;
    }
    #apply-button {
       padding: 0.5rem 0.75rem;
       border-radius: 30px;
    }
    .btn-secondary {
        padding: 0.25rem 1rem;
        font-size: 12px;        
    }
    .btn-selected {       
        box-shadow: 0 0 0 0.2rem rgb(111 124 145 / 50%);
    }
    #cost-in-credits {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 8px;
        gap: 13px;
    }
    #cost-in-credits > div {
        margin: 0;
        font-size: 14px;
        text-align: end;
    }
    .more-keywords-button-container {
        padding: 0 45%;
        min-width: fit-content;
        margin-top: 10px;
        white-space: nowrap;
    }
    .more-keywords-button {
        font-weight: 600;
        border-radius: 30px;
        cursor: pointer;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .more-keywords-button:disabled {
        background-color: grey;
    }
    .more-keywords-button > div:nth-child(1) {
        margin-right: 5px;
    }
    @media screen and (max-width: 600px) {
        #more-keywords-button-container {
            padding: 0 10%;
        }
    }
    #subpages-buttons-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    .table-responsive {
        height: 48vh;
        overflow: auto;
    }
    thead {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 2;
    }
    #data-validity-reminder {
        display: flex;
        justify-content: end;
        
    }
    .cost {
        font-size: 12px;
        margin-top: 5px;
    }
    #csv-button {
        cursor: pointer;
    }
    
</style>