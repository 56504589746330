<template>
    <div class="editor-week">
        <label for="week">Semaine</label>
        <input @change="$emit('dataChange', formData)" v-model="formData" id="week" class="form-control" type="week" :disabled="readOnly">
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
const props = defineProps({
    selectedWeek: {
        type: String,
        required: true
    },
    readOnly: {
        type: Boolean,
        required: false,
        default: false
    }
});
const formData = ref('');
onMounted(() => {
    formData.value = props.selectedWeek;
});
</script>
<style lang="scss" scoped>
.editor-week {
    input[type="week"]::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 38px;
        cursor: pointer;
        color: transparent;
        background: transparent;
    }
}
</style>