<template>
    <div  id="chat-container">
        <div id="messages-container">
            <div class="message"  v-for="(message, index) in messagesArray" :key="index">
                <div v-if="message.type === 'bot'" class="chat">
                    <div class="d-flex flex-column gap-1">
                        <img src="../../assets/img/gpt.png" alt="Logo chatGpt">
                        <div class="html-content" v-html="message.message"></div>
                    </div>
                </div>
                <div v-if="message.type === 'user'" class="user">
                    <div class="d-flex flex-column align-items-end gap-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 32 32"><path fill="none" d="M8.007 24.93A4.996 4.996 0 0 1 13 20h6a4.996 4.996 0 0 1 4.993 4.93a11.94 11.94 0 0 1-15.986 0ZM20.5 12.5A4.5 4.5 0 1 1 16 8a4.5 4.5 0 0 1 4.5 4.5Z"/><path fill="currentColor" d="M26.749 24.93A13.99 13.99 0 1 0 2 16a13.899 13.899 0 0 0 3.251 8.93l-.02.017c.07.084.15.156.222.239c.09.103.187.2.28.3c.28.304.568.596.87.87c.092.084.187.162.28.242c.32.276.649.538.99.782c.044.03.084.069.128.1v-.012a13.901 13.901 0 0 0 16 0v.012c.044-.031.083-.07.128-.1c.34-.245.67-.506.99-.782c.093-.08.188-.159.28-.242c.302-.275.59-.566.87-.87c.093-.1.189-.197.28-.3c.071-.083.152-.155.222-.24ZM16 8a4.5 4.5 0 1 1-4.5 4.5A4.5 4.5 0 0 1 16 8ZM8.007 24.93A4.996 4.996 0 0 1 13 20h6a4.996 4.996 0 0 1 4.993 4.93a11.94 11.94 0 0 1-15.986 0Z"/></svg>               
                        <div class="html-content" v-html="message.message"></div>
                    </div>
                </div>
            </div>
        </div>
        <div id="loader" v-if="loading === true">
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="form d-flex" id="send-message-container">
            <textarea v-model="question" @keyup.enter="sendMessage" class="form-control" style="resize: none;" rows="3" placeholder="Ex: Qu'est ce qu'un article ?"></textarea>
            <!-- <SoftButtonVue /> -->
            <button @click="sendMessage"  class="btn btn-secondary">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                <path fill="currentColor" d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576L6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76l7.494-7.493Z"/></svg>
            </button>
        </div>
        <div v-if="err.status === true" class="alert" :class="{'alert-danger': err.status}">
            <p v-if="err.status">{{ err.message }}</p> 
        </div>	
    </div>
</template>
<script setup>
    import SoftButtonVue from '../SoftButton.vue';
    import axios from 'axios';
    import Cookies from "js-cookie";
    import { onMounted, ref } from "vue";


    const question = ref("");
    const messagesArray = ref([]);
    const loading = ref(false);
    const isOpen = ref(true);
    const err = ref({
        message: "",
        status: false
    });

    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`
    };

    const sendMessage = async () => {
        try {
            err.value.status = false;
            if (question.value === "") {
                return;
            }
            await messagesArray.value.push({
                message: question.value,
                type: "user"
            });
            const messageForGpt = question.value;
            await autoScrollOnBottom();
            question.value = "";
            loading.value = true;
            const response = await axios ({
                method: "POST",
                url: `${process.env.VUE_APP_SERVER}/gpt/generate`,
                headers: axiosConfig,
                data: {
                    question: messageForGpt
                }
            })
            if(response.status === 200) {
                await messagesArray.value.push({
                    message: response.data,
                    type: "bot"
                });
                await autoScrollOnBottom();
            }
            
        } catch (error) {
            // MESSAGE ERREUR
            if (error.response?.status === 400 || 500 || 429 ) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue, veuillez réessayer ultérieurement";
            }
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };
    const autoScrollOnBottom = () => {
        const messagesContainer = document.querySelector("#messages-container");
        const lastChild = document.querySelector("div.message");
        const messagesContainerHeight = messagesContainer?.scrollHeight;
        messagesContainer.scrollBy({ top: messagesContainerHeight })
    };
</script>
<style scoped>
   
    #button-container {
        position: absolute;
        left: -40px;
        top: -1px;
        border: 1px #80808026 solid;
        border-right: none;
        background-color: #F5F6F7;
        padding: 5px;
        border-radius: 10px 0 0 10px;
        width: 40px;
        z-index: 100;
        display: flex;
        align-items: center;
    }
    #button-container:hover {
        cursor: pointer;
    }
    #chat-container {
        justify-content: end;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
    }
    #messages-container {
        flex-direction: column;
        max-height: 100%;
        overflow-y: auto;
        padding: 0.4rem;
    }
    img {
        width: 30px;
        height: 100%;
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.4rem;    
        border-radius: 10%;
        background-color: rgb(148, 228, 175);
        color: rgb(251, 251, 251);
        border: none;
        padding: 1rem;
        height: 80%;
    }
    .form {
        display: flex;
        align-items: center;
        margin-top: 22px;
        gap: 5px;
    }
    .user, .chat {
        padding: 10px;
        border-radius: 10px;
    }
    .user {
        text-align: end;
        background-color: rgb(148, 228, 175);
        color: rgb(82, 81, 81);
    }
    .chat {
        background-color: rgb(131, 146, 171);
        color: rgb(255, 255, 255);
    }
    .main-container {
        position: relative;
        height: 100%;
        /* margin: auto; */
        /* height: calc(100vh - 60px); */
        /* background-color: transparent !important; */
    }
    ::-webkit-scrollbar {
        width: 5px;
        background: #e9e9e9;
    }
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 30px;
    }
    .message + .message {
        margin-top: 10px;
    }
    .spinner-grow {
        width: 7px;
        height: 7px
    }
    .spinner-grow + .spinner-grow {
        margin-left: 3px;
    }

    #error-message {
        font-size: 12px;
    }
    textarea {
        margin-bottom: 1rem;
    }
    #loader {
        margin-bottom: -18px;
        margin-left: 10px;
    }
    
    /* CHAT MESSAGES STYLE */
    .html-content:deep(h1) {
        font-size: 1.80rem !important;
    }
    .html-content:deep(h2) {
        font-size: 1.70rem !important;
    }
    .html-content:deep(h3) {
        font-size: 1.60rem !important;
    }
    .html-content:deep(h4) {
        font-size: 1.50rem !important;
    }
    .html-content:deep(h5) {
        font-size: 1.40rem !important;
    }
    .html-content:deep(h6) {
        font-size: 1.30rem !important;
    }
    .user .html-content:deep(h1), 
    .user .html-content:deep(h2),
    .user .html-content:deep(h3),
    .user .html-content:deep(h4),
    .user .html-content:deep(h5),
    .user .html-content:deep(h6) {
        color: rgb(82, 81, 81);
    }
    .chat .html-content:deep(h1), 
    .chat .html-content:deep(h2), 
    .chat .html-content:deep(h3), 
    .chat .html-content:deep(h4), 
    .chat .html-content:deep(h5), 
    .chat .html-content:deep(h6) {
        color: rgb(255, 255, 255);
    }
</style>
