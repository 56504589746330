<template>
    <div class="table-html-content">
        <div class="table-html-content__header"></div>
        <div v-html="cleanHtml()" :style="style()" class="table-html-content__body"></div>
        <div class="table-html-content__footer"></div>
    </div>
</template>
<script setup>
    import { sanitizeHtml } from '@/tools/htmlTools.js';
    const props = defineProps({
        html: {
            type: String,
            required: true
        },
        innerHeight: {
            type: [String, Number],
            required: false,
            default: 'auto'
        }
    });

    const cleanHtml = () => {
        const sanitizedHtml = sanitizeHtml(props.html);
        const html = sanitizedHtml;
        return html;
    };
    const style = () => {
        return {
            'height': props.innerHeight
        }
    };
</script>
<style lang="scss" scoped>
    .table-html-content {
        box-shadow: inset 0px 0px 20px 10px rgba(0,0,0,0.1);
        border-radius: 10px;
        overflow: hidden;

        &__header {
            height: 1px;
            padding-top: 2px;
            background-color: $light-grey;
        }
        &__body {
            overflow: auto;
            background-color: $light-grey;
            padding: 1rem;
            
            &::-webkit-scrollbar {
                width: 2px;
            }

            &:deep(p, h1, h2, h3, h4, h5, h6) {
                margin: 0;
                padding: 0;
            }
            &:deep(h1, h2, h3, h4, h5, h6) {
                font-weight: bold;
            }
            &:deep(h1) {
                font-size: 20px;
            }
            &:deep(h2) {
                font-size: 18px;
            }
            &:deep(h3) {
                font-size: 16px;
            }
            &:deep(h4) {
                font-size: 14px;
            }
            &:deep(h5) {
                font-size: 13px;
            }
            &:deep(p) {
                font-size: 13px;
            }
        }
       
        &__footer {
            height: 1px;
            padding-bottom: 2px;
            background-color: $light-grey;
        }
    }
</style>