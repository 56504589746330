<template>
    <div class="card">
        <div class="card-header">
            <h5>Evolution du trafic</h5>
        </div>
        <div class="card-body">
            <div v-if="loading" id="spinner-container">
                <div class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <VueApexCharts v-if="!loading" type="area" height="390" :options="options" :series="series"></VueApexCharts>
        </div>
    </div>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import VueApexCharts from "vue3-apexcharts";
    import Cookies from "js-cookie";
    import axios from "axios";

    const props = defineProps({
        period: {
            type: String,
            required: true
        }
    });
    // STATE
    const loading = ref(true);
    const options = ref({});
    const series = ref([]);
    
    // METHODS
    const getDataFromAnalytics = async () => {
        try {
            loading.value = true;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/user/google/get-data/traffic-evolution?period=${props.period}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                },
            });
            options.value = {
                xaxis: {
                    categories: response.data.lastPeriod.dates,
                    labels: {
                        show: true,
                        hideOverlappingLabels: true,
                    },
                },
                stroke: {
                    curve: 'smooth',
                    width: [1, 5],
                },
                dataLabels: {
                    enabled: false
                }
            };
            series.value = [
                {
                    name: "Utilisateurs période précédente",
                    data: response.data.previousPeriod.counts,
                    color: "#94e4af",
                    
                },
                {
                    name: "Utilisateurs période selectionnée",
                    data: response.data.lastPeriod.counts,
                    color: "#28829f"
                },   
            ];
        } catch (error) {
            
        } finally {
            loading.value = false;
        }
    };  
    
    onMounted(async() => {
        await getDataFromAnalytics();
    });
</script>

<style scoped>
    .card {
        height: 100%;
    }
    #spinner-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 390px;
    }
</style>