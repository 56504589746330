<template>
    <section class="card">
        <div class="card-header">
            <h6 class="text-center">Notes d'optimisation</h6>
        </div>
        <hr class="mt-0 mb-0 horizontal dark">
        <!-- ADD OPTIMISATION NOTES AREA -->
        <div class="add-optimisation-note">
            <div id="add-note-button">   
                <CustomCircleAddButton @click="isAddNotePopupOpen = !isAddNotePopupOpen" :isOpen="isAddNotePopupOpen" />
            </div>
            <div v-show="isAddNotePopupOpen" id="add-note-form" class="card">
                <div class="card-body">
                    <div v-if="addNoteError.status" class="text-center text-danger">
                        {{ addNoteError.message }}
                    </div>
                    <form>
                        <div class="note-input-container">
                            <label for="content">Note</label>
                            <QuillEditor @click="selectArea" style="background-color: white; min-height: 20vh;" id="editor" content-type="html" v-model:content="newNote.content" :key="quillKey" theme="snow" toolbar="essential" :read-only="false"/>
                        </div>
                        <div id="note-add-button-container">
                            <SoftButton @click.prevent="addNewNote">
                                Ajouter la note
                            </SoftButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- END -->
        <div class="card-body">
            <div id="notes-container">
                <div v-if="err.status" class="text-center">
                    {{ err.message }}
                </div>
                <div v-if="loading" class="text-center" >
                    <div class="spinner-border text-center" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-if="!err.status && !loading">
                    <div class="one-note-container" v-for="(note, index) in notesList" :key="index">
                        <div class="one-note-content">
                            <strong>Par: {{ note.createdByUser.fullName }}, le {{ getDate(note.createdAt) }}</strong>
                            <div class="note-content-editor-container" v-if="noteCurrentlyEditing === note._id">
                                <QuillEditor @click="selectArea('content-editor-container')" style="background-color: white; min-height: 20vh;" id="content-editor-container" class="editor" content-type="html" v-model:content="note.content" theme="snow" toolbar="essential" :read-only="false"/>
                            </div>
                            <p v-else class="note-content" v-html="note.content"></p>
                        </div>
                        <div class="one-note-buttons">
                            <svg v-if="noteCurrentlyEditing != note._id" @click="selectNoteForEditing(note._id)" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"><path fill="currentColor" d="m19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6l4.25 4.25Z"/></svg>                                        
                            <svg v-if="noteCurrentlyEditing != note._id" @click="deleteNote(note._id)" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"><path fill="currentColor" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12l1.41 1.41L13.41 14l2.12 2.12l-1.41 1.41L12 15.41l-2.12 2.12l-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/></svg>
                            <svg v-if="noteCurrentlyEditing === note._id" @click="updateNote(note)" id="save-icon" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 15 15"><path fill="currentColor" d="M0 1.5A1.5 1.5 0 0 1 1.5 0h8.586a1.5 1.5 0 0 1 1.06.44l3.415 3.414A1.5 1.5 0 0 1 15 4.914V13.5a1.5 1.5 0 0 1-1.5 1.5H11v-3.5A1.5 1.5 0 0 0 9.5 10h-4A1.5 1.5 0 0 0 4 11.5V15H1.5A1.5 1.5 0 0 1 0 13.5v-12Z"/><path fill="currentColor" d="M5 15h5v-3.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5V15Z"/></svg>
                        </div>
                      
                    </div>       
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import SoftButton from "@/components/SoftButton.vue";
    import CustomCircleAddButton from "@/components/standalone/customsButtons/CustomCircleAddButton.vue";
    import Cookies from "js-cookie";
    import { QuillEditor } from '@vueup/vue-quill';
    import '@vueup/vue-quill/dist/vue-quill.snow.css';

    const fetchConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        "Content-Type": "application/json",
    };

    //PROPS
    const props = defineProps({
        customerId: {
            type: String,
            required: true
        }
    });

    // STATE
    const err = ref({
        status: false,
        message: ""
    });
    const addNoteError = ref({
        status: false,
        message: ""
    });
    const loading = ref(false);
    const isAddNotePopupOpen = ref(false);
    const newNote = ref({
        content: ""
    });
    const notesList = ref([]);
    const notesQueryFilter = ref("");
    const quillKey = ref(0);
    const noteCurrentlyEditing = ref(""); 

    // METHODS
    const getNotes = async () => {
        try {
            err.value.status = false;
            loading.value = true;

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/optimisation-notes/get-all?customerRoute=false&customerId=${props.customerId}&queryFilter=${notesQueryFilter.value}`, {
                method: "GET",
                headers: fetchConfig
            });

            const response = await promise.json();
          
            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }
            if (response.optimisationNotes.length === 0) {
                err.value.message = "Aucune note trouvée pour cet utilisateur";
                err.value.status = true;
            }
            notesList.value = response.optimisationNotes;
            
        } catch (error) {
            if (error.status === 401 || 404 || 400) {
                err.value.message = error.error;
            }
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };
    const addNewNote = async () => {
        try {
            addNoteError.value.status = false;

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/optimisation-notes/create`, {
                method: "POST",
                headers: fetchConfig,
                body: JSON.stringify({
                    userId: props.customerId,
                    content: newNote.value.content,
                    customerId: props.customerId
                })
            });
            
            const response = await promise.json();
            
            if (promise.status !== 201) {
                throw { 
                    status: promise.status,
                    ...response
                };
            }

            newNote.value.content = "";
            quillKey.value += 1;
            isAddNotePopupOpen.value = false;
            getNotes();
        } catch (error) {
            console.log(error);
            if (error.status === 400 || 404) {
                addNoteError.value.message = error.error;
            } else {
                addNoteError.value.message = "Une erreur est survenue durant l'ajout de la note...";
            }
            addNoteError.value.status = true;
        }
    };
    const deleteNote = async (noteId) => {
        try {
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/optimisation-notes/delete`, {
                method: "DELETE",
                headers: fetchConfig,
                body: JSON.stringify({
                    noteId: noteId
                })
            });

            const response = await promise.json();
            
            if (promise.status !== 200) {
                throw { 
                    status: promise.status,
                    ...response
                };
            }

            getNotes();

        } catch (error) {
            if (error.status === 400 || 404) {
                addNoteError.value.message = error.error;
            } else {
                addNoteError.value.message = "Une erreur est survenue durant l'ajout de la note...";
            }

            addNoteError.value.status = true;
        }
    };
    const getDate = (date) => {
        return new Date(date).toLocaleDateString("FR-fr")
    };
    const selectArea = () => {
        const area = document.querySelector("#editor .ql-editor");
        area.focus()
    };
    const selectNoteForEditing = (noteId) => {
        if (noteCurrentlyEditing.value !== "" && noteId) {
            window.alert("Vous devez sauvegarder le mémo en cours d'étition avant d'en éditer un autre'");
            return;
        }
        noteCurrentlyEditing.value = noteId;
        
    };
    const updateNote = async (note) => {
        try {
            loading.value = true;
            err.value.status = false;
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/optimisation-notes/update`, {
                method: "PATCH",
                headers: fetchConfig,
                body: JSON.stringify({
                    noteId: note._id,
                    content: note.content
                })
            });

            const response = await promise.json();

            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }

            noteCurrentlyEditing.value = "";
            getNotes();

        } catch (error) {
            if (error.status === 401 || 404) err.value.message = error.error;
            else err.value.message = "Une erreur est survenue durant la mise à jour de la note";
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };
    // LIFECYCLE HOOKS
    onMounted(() => {
        getNotes();
    });
</script>
<style scoped>
    h6 {
        margin: 0;
    }
    section.card {
        min-height: 500px;
    }
    div.card-header {
        padding: 1rem;
    }
    #spinner-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #error-container {
        text-align: center;
    }
    .table-responsive {
        max-height: 60vh;
        overflow: auto;
    }
    thead {
        position: sticky;
        top: 0;
        background-color: white;
    }
    td {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    /* ADD BILLING AREA */
    #add-note-button {
        position: absolute;
        right: 20px;
        top: 20px;
    }
    #add-note-form {
        right: 10px;
        position: absolute;
        z-index: 2;
        min-width: 70%;
    }
    .note-input-container {
        margin-bottom: 1rem;
    }
    #note-add-button-container {
        text-align: center;
    }
    #note-filter-container {
        display: flex;
        padding: 1rem 0;
        justify-content: center;
        align-items: center;
    }
    #note-filter-container > div {
        width: 55%;
        margin-right: 8px;
    }
    #apply-note-filter-button {
        border-radius: 0.5rem 0 0 0.5rem;
        box-shadow: none;
        border: 2px solid #94E4AF;
    }
    #reset-filter-button {
        border-radius: 0 0.5rem 0.5rem 0;
        padding: 0.75rem;
        box-shadow: none;
        border: 2px solid #94E4AF;
    }
    #notes-container {
        max-height: 792px;
        overflow-y: auto;
        padding: 2rem;
    }
    .one-note-container {
        position: relative;
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }
    .one-note-container p {
        margin-top: 1rem;
    }
    .one-note-container + .one-note-container {
        border-top: 1px solid rgb(0 0 0 / 8%);
    }
    .one-note-buttons > svg {
        cursor: pointer;
    }
    .one-note-buttons svg {
        color: #94e4af;
    }
    .one-note-buttons svg:hover {
        color: #70ac84;
    }
    .one-note-content {
        width: 90%;
    }
    .note-content-area {
        width: 90%;
    }
    .note-content-editor-container {
        padding: 10px 0;
    }
    

</style>