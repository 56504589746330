import mailToForgotPassword from '../views/auth/mailToForgotPassword.vue';
import recoverPassword from '../views/auth/recoverPassword.vue';
import SignupBasic from '../views/auth/signup/Basic.vue';
import Basic from '../views/auth/signin/Basic.vue';

const commonRoutes  = [
    {
        path: "/register",
        name: "Création du compte",
        component: SignupBasic,
        meta: {
            layout: "public"
        }
    },
    {
        path: "/login",
        name: "Connexion",
        component: Basic,
        meta: {
            layout: "public"
        }
    },
    {
        path: "/forgot-password/:token",
        name: "Récupération du mot de passe",
        component: recoverPassword,
        meta: {
            layout: "public"
        }
    },
    {
        path: "/formMail",
        name: "Mot de passe oublié",
        component: mailToForgotPassword,
        meta: {
            layout: "public"
        }
    },
];

export default commonRoutes;