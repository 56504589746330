<template>
    <div class="article-seo-suggestions-keywords">
        <h6 @click="changeOpenStatus">
            <span v-if="!isOpen">+</span>
            <span v-else>-</span>
            {{ sectionName }}
            ({{ data.length }})
        </h6>
        <ul v-show="isOpen">
            <li v-for="(data, index) in data" :key="index + refreshKey" >
                <div class="item" :class="getColorFromCount(data[3], data[1], data[2])">
                    <div class="item-content item-content__1" >
                        {{ data[0] }}
                    </div>
                    <div v-if="data[1] && data[2]" class="item-content item-content__2">
                       <strong>{{ data[3] }} </strong> / {{ data[1] }} - {{ data[2] }}
                    </div>
                </div>
            </li>
        </ul>
        
    </div>
</template>
<script setup>
    import { ref } from 'vue';
    
    // PROPS
    const props = defineProps({
        sectionName: {
            type: String,
            required: true,
            validator: (value) => {
                return value.length < 40;
            }
        },
        defaultOpenStatus: {
            type: Boolean,
            required: false,
            default: false
        },
        data: {
            type: [Array, String],
            required: true
        },
    });

    const isOpen = ref(props.defaultOpenStatus);
    const refreshKey = ref(0);
    
    // METHODS
    const changeOpenStatus = () => {
        isOpen.value = !isOpen.value
    };
    const getColorFromCount = (currentCount, min, max) => {
        if (currentCount <= max && currentCount >= min) return 'item_perfect-count';
        else if (currentCount > 0 && currentCount < min) return 'item_light-count';
        // else if (currentCount > max && currentCount <= (max + 3)) return 'item_little-too-count' ;
        else if (currentCount > max ) return 'item_far-too-count';
    };

</script>
<style src="./article-seo-suggestions-keywords.scss" lang="scss" scoped>
</style>