<template>
    <section id="dashboard-latest-articles" class="container-card">
        <div id="dashboard-latest-articles__header" class="container-card__header">
            <h3>Articles récents</h3>
            <router-link to="/natural-seo/history-of-contents" class="link-light">
                Tout voir
            </router-link>
        </div>
        <div id="dashboard-latest-articles__body" class="container-card__body">
            <MainLoader 
                v-if="getMyArticlesStatus.loading" 
            />
            <EmbeddedError 
                v-else-if="getMyArticlesStatus.error.status"
                :message="getMyArticlesStatus.error.message"
            />
            <FlexTable 
                v-else-if="articles.length"
                :headers="headers"
                :data="articles"
            />
            <EmbeddedMessage 
                v-else
                message="Aucun article trouvé."
            />
        </div>
    </section>
</template>
<script setup>
import { getArticleStringStatus, getArticleColorStatus, getArticleType, getArticleActionForCustomer } from "@/tools/articleTools.js";
import EmbeddedMessage from "@/components/standalone/statusMessages/standard/EmbeddedMessage.vue";
import EmbeddedError from "@/components/standalone/statusMessages/errors/EmbeddedError.vue";
import { fetchMyLatestCustomerArticles } from "@/useCases/articleUseCases.js";
import FlexTable from "@/components/base/tables/FlexTable/FlexTable.vue";
import MainLoader from "@/components/standalone/loaders/MainLoader.vue";
import { onMounted, ref } from "vue";

const headers = [
    { label: "Titre de l'article", tooltip: true, style: { 'flex': "2" }},
    { label: "Status" },
    { label: "Type de contenu" },
    { label: ""},
];
const articles = ref([]);
const getMyArticlesStatus = ref({
    error: {
        status: false,
        message: "",
    },
    loading: true,
});

const prepareArticles = (articles) => {
    return articles.map(article => {
        return {
            title: {
                type: "text",
                label: "Titre de l'article",
                value: article.title,
                style: {
                    "flex": "2"
                }
            },
            status: { 
                type: "label",
                label: "Status",
                value: getArticleStringStatus({ reqStatus: article.status, isCustomer: true}),
                color: getArticleColorStatus({ reqStatus: article.status, isCustomer: true}),
            },
            type: {
                type: "text",
                label: "Type de contenu",
                value: getArticleType({ reqType: article.type }),
            },
            action: getArticleActionForCustomer({ reqStatus: article.status, articleId: article._id }),
        };
    });
};

const getMyArticles = async () => {
    try {
        getMyArticlesStatus.value.loading = true;
        getMyArticlesStatus.value.error.status = false;
        const foundArticles = await fetchMyLatestCustomerArticles();
        const preparedArticles = prepareArticles(foundArticles);
        articles.value = preparedArticles;
    } catch (error) {
        console.log(error);
        getMyArticlesStatus.value.error.message = "Une erreur est survenue. Veuillez réessayer plus tard ou contacter un administrateur.";
        getMyArticlesStatus.value.error.status = true;
    } finally {
        getMyArticlesStatus.value.loading = false;
    }
};

onMounted(() => {
    getMyArticles();
});
</script>
<style lang="scss" scoped>
#dashboard-latest-articles {
    &__header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    &__body {
        width: 100%;
    }
}
</style>
