<template>
    <div class="row w-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Mes liens</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="card h-100 p-4 text-center">
                        <div class="d-flex btn-group col-4" role="group" aria-label="Basic example">
                            <button @click="reloadLinksList('Externe')"  type="button" class="btn btn-secondary" :class="{ 'btn-selected': linksType === 'Externe' }">Externes</button>
                            <button @click="reloadLinksList('Interne')"  type="button" class="btn btn-secondary" :class="{ 'btn-selected': linksType === 'Interne' }">Internes / Blog</button>
                        </div>
                        <div class="table-responsive">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Titre Article</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Lien</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mot(s) clé(s)</th>
                                        <th v-if="linksType === 'Interne'" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date publication</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="linksLoading" style="width:100%;" class="text-center">
                                        <td colspan="6">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="err.status === true">
                                        <td colspan="6">
                                            <div class="alert alert-danger">
                                                <p v-if="err.status">{{ err.message }}</p>  
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="linksList.length == 0 && err.status === false && linksLoading === false">
                                        <td colspan="6">Aucun lien pour le moment...</td>
                                    </tr>

                                    <tr v-show="!linksLoading && !err.status && linksList.length > 0" v-for="(link, index) in linksList" :key="index">
                                        <td class="text-center ellipse medium">{{ link.title }}</td>
                                        <td class="text-center ellipse large"><a :href="link.link" target="_blank">{{ link.link }}</a></td>
                                        <td class="text-center ellipse medium">{{ link.keywords.primary }}{{ link.keywords.secondary ? `/ ${link.keywords.secondary}` : ""}}</td>
                                        <td  v-if="linksType === 'Interne'" class="text-center">{{ link.type === 'Interne' ? "Page SEO" : link.type }}</td>
                                        <td class="text-center">{{ getDate(link.publishedDate) }}</td>
                                    </tr> 
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import axios from "axios";
    import Cookies from "js-cookie";

    // STATE
    const err = ref({
        status: false,
        message: ""
    });
    const linksLoading = ref(true);
    const linksList = ref([]);
    const linksType = ref("Externe");

    // METHODS
    const getLinksList = async () => {
        try {
            linksLoading.value = true;
            err.value.status = false;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/user/links/get-list?type=${linksType.value}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                },
            });
            if (response.status === 200) {
                linksList.value = response.data;
            }
        } catch (error) {
            if (error.response?.data?.error === 404) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue...";
            }
            err.value.status = true;
        } finally {
            linksLoading.value = false;
        }
    };
    const reloadLinksList = async (type) => {
        linksType.value = type;
        getLinksList();
    };
    const getDate = (date) => {
        return new Date(date).toLocaleDateString("FR-fr")
    };

    // LYFECYCLE HOOKS
    onMounted(() => {
        getLinksList();
    });
</script>
<style scoped>
    @import "../../../../../src/assets/css/tables-custom.css";
    .table-responsive {
        max-height: 80vh;
        overflow: auto;
    }
    #filters-container {
        display: flex;
        justify-content: flex-end;
        padding: 0 1rem;
    }
    .filter-container {
        padding: 5px;
        text-align: center;
    }
    select {
        text-align: center;
    }
    td {
        max-width: 330px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .btn-secondary {
        padding: 0.25rem 1rem;
        font-size: 12px;        
    }
    .btn-selected {       
        box-shadow: 0 0 0 0.2rem rgb(111 124 145 / 50%);
    }
</style>