<template>
    <button @click="action" class="custom-delete-button" :style="style()" :disabled="disabled">
        <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="width" viewBox="0 0 24 24"><path fill="currentColor" d="M7 21q-.825 0-1.413-.588T5 19V6q-.425 0-.713-.288T4 5q0-.425.288-.713T5 4h4q0-.425.288-.713T10 3h4q.425 0 .713.288T15 4h4q.425 0 .713.288T20 5q0 .425-.288.713T19 6v13q0 .825-.588 1.413T17 21H7Zm5-7.1l1.9 1.9q.275.275.7.275t.7-.275q.275-.275.275-.7t-.275-.7l-1.9-1.9l1.9-1.9q.275-.275.275-.7t-.275-.7q-.275-.275-.7-.275t-.7.275L12 11.1l-1.9-1.9q-.275-.275-.7-.275t-.7.275q-.275.275-.275.7t.275.7l1.9 1.9l-1.9 1.9q-.275.275-.275.7t.275.7q.275.275.7.275t.7-.275l1.9-1.9Z"/></svg>
    </button>
</template>
<script setup>
    import { createDarkerColorFromHexOrRgb } from '@/tools/colorTools';
    import styles from '@/assets/scss/exports/main-variables.module.scss';
  
    // PROPS
    const props = defineProps({
        isOpen: {
            type: Boolean,
            required: false
        },
        width: {
            type: [ Number, String ],
            required: false,
        },
        color: {
            type: String,
            required: false,
        },
        action: {
            type: Function,
            required: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    });
    const style = () => {
        const { width, color } = props;
        const style = {
            'width': width || '32px',
            'height': width || '32px',
            'color': color || styles.mainColor,
            '--hover-color': createDarkerColorFromHexOrRgb(color || styles.mainColor, 30),
        };
        return style;
    };
</script>
<style lang="scss" scoped>
    :root {
        --hover-color: #defaultColor;
    }
    .custom-delete-button {
        border: none;
        padding: 0;
        width: fit-content;
        background-color: transparent;
        border-radius: 100%;
        
        :hover {
            color: var(--hover-color);
        }
        &:disabled {
            color: $light-grey-1 !important;
            pointer-events: none;
        }
    }
</style>