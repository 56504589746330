<template>
    <div class="file-drag-and-drop-area">
        <div 
            class="file-drag-and-drop-area__wrapper"
            @dragover.prevent="dragOver"
            @dragleave.prevent="dragLeave"
            @drop.prevent="drop"
        >
            <div class="file-drag-and-drop-area__content">
                <div class="file-drag-and-drop-area__content__icon">
                    <i class="fas fa-file-upload"></i>
                </div>
                <div class="file-drag-and-drop-area__content__text">
                    Glissez et déposez vos fichiers ici OU <strong @click="openFileSelectionWindow" role="button">cliquez pour les sélectionner</strong>
                </div>
            </div>
            <CustomKernelButton @click="$emit('fileChange', files)" :loading="loading" class="primary-button">
                Envoyer les fichiers
            </CustomKernelButton>
        </div>
        <div class="file-drag-and-drop-area__preview">
            <div class="file-drag-and-drop-area__preview__item" v-for="(file, index) in files" :key="index">
                <div>
                    {{ file.name }}
                </div>
                <div @click="removeFile(index)" role="button" class="file-drag-and-drop-area__preview__item__delete">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M6 13q-.425 0-.712-.288T5 12q0-.425.288-.712T6 11h12q.425 0 .713.288T19 12q0 .425-.288.713T18 13z"/></svg>
                </div>
            </div>
        </div>  
    </div>
</template>
<script setup>
import { ref } from 'vue';
import CustomKernelButton from '@/components/base/buttons/CustomKernelButton.vue';

const props = defineProps({
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const files = ref([]);

const dragOver = () => {
    const dropArea = document.querySelector('.file-drag-and-drop-area__wrapper');
    dropArea?.classList.add('drag-over');
};
const dragLeave = () => {
    const dropArea = document.querySelector('.file-drag-and-drop-area__wrapper');
    dropArea?.classList.remove('drag-over');
};
const drop = (event) => {
   files.value.push(...event.dataTransfer.files);
   dragLeave();
};
const removeFile = (index) => {
    files.value.splice(index, 1);
};
const cleanFiles = () => {
    files.value = [];
};
const openFileSelectionWindow = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.addEventListener('change', (event) => {
        files.value.push(...event.target.files);
    });
    input.click();
};

defineExpose({
    cleanFiles,
});
</script>
<style lang="scss" scoped>
.file-drag-and-drop-area {
    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.6rem;
        border: 3px $light-grey dashed;
        border-radius: 0.4rem;
        padding: 1rem;
        min-height: 200px;

        &.drag-over {
            border: 3px $main-color dashed;
        } 
    }
    &__content {
        display: flex;
        gap: 0.4rem;

        &__content {
            display: flex;
            justify-content: center;
            gap: 0.4rem;
        }
    }
    &__preview {

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.4rem;

            &__delete {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                align-content: center;
                background-color: $error-color;
                color: white;
            }
        }
    }
}
</style>