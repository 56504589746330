<template>
    <div class="loader-container">
        <span class="loader"></span>
    </div>
</template>
<script setup>
</script>
<style lang="scss" scoped>
    .loader-container {
        display: flex;
        justify-content: center;
        margin: 4rem 2rem;

        .loader {
            width: 39px;
            height: 39px;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            border: 3px solid;
            border-color: $grey-1 $grey-1 transparent transparent;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;

            &::after,
            &::before {
                content: '';  
                box-sizing: border-box;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                border: 3px solid;
                border-color: transparent transparent $main-color $main-color;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                box-sizing: border-box;
                animation: rotationBack 0.5s linear infinite;
                transform-origin: center center;
            }

            &::before {
                width: 22px;
                height: 22px;
                border-color: #FFF #FFF transparent transparent;
                animation: rotation 1.5s linear infinite;
            }

            @keyframes rotation {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            } 

            @keyframes rotationBack {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(-360deg);
                }
            }
        }
    }
</style>