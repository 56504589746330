import { getFetchConfig } from '@/tools/fetchTools.js';

export const fetchUserById = async (userId) => {
    const fetchConfig = getFetchConfig('json');

    const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/get-one?userId=${userId}`, {
        method: "GET",
        headers: fetchConfig,
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;  
};

export const fetchCustomersList = async ({ scope = 'basic' }) => {
    const fetchConfig = getFetchConfig('json');
    const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/customers?scope=${scope}`, {
        method: "GET",
        headers: fetchConfig,
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;  
};

export const fetchWritersList = async ({ scope = 'basic' }) => {
    const fetchConfig = getFetchConfig('json');
    const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/writers?scope=${scope}`, {
        method: "GET",
        headers: fetchConfig,
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;  
};
export const setUserMetricoolId = async ({ userId, metricoolId }) => {
    const fetchConfig = getFetchConfig('json');
    const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/metricool-id/set`, {
        method: "POST",
        headers: fetchConfig,
        body: JSON.stringify({
            userId,
            metricoolId
        })
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;  
};

export const fetchUserMetricoolId = async (userId) => {
    const userIdParams = userId ? `?userId=${userId}` : '';
    const fetchConfig = getFetchConfig('json');
    const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/metricool-id/get${userIdParams}`, {
        method: "GET",
        headers: fetchConfig,
    });

    const status = promise.status;

    if (status === 204) {
        return null;
    }

    const response = await promise.json();
    
    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;  
};

export const fetchMyUserRank = async () => {
    const fetchConfig = getFetchConfig('json');
    const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/me/rank`, {
        method: "GET",
        headers: fetchConfig,
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const fetchMyUserNotifications = async () => {
    const fetchConfig = getFetchConfig('json');
    const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/me/notifications`, {
        method: "GET",
        headers: fetchConfig,
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const fetchMyUserFullName = async () => {
    const fetchConfig = getFetchConfig('json');
    const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/me/full-name`, {
        method: "GET",
        headers: fetchConfig,
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const setUserCompany = async ({ userId, companyId }) => {
    const fetchConfig = getFetchConfig('json');
    const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/company/set`, {
        method: "POST",
        headers: fetchConfig,
        body: JSON.stringify({
            userId,
            companyId
        })
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const fetchUserCompany = async (userId) => {
    const fetchConfig = getFetchConfig('json');
    const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/company?userId=${userId}`, {
        method: "GET",
        headers: fetchConfig,
    });

    const status = promise.status;

    if (status === 204) {
        return null;
    }

    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const fetchMyCompanyInformations = async () => {
    const fetchConfig = getFetchConfig('json');
    const promise = await fetch(`${process.env.VUE_APP_SERVER}/user/me/company/info`, {
        method: "GET",
        headers: fetchConfig,
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

// export const fetchMyCompany = async (userId) => {
//     const fetchConfig = getFetchConfig('json');
//     const url = `${process.env.VUE_APP_SERVER}/me/company/logo`;

//     const promise = await fetch(url, {
//         method: 'GET',
//         headers: fetchConfig,
//     });
//     const status = promise.status;

//     if (status !== 200) {
//         throw {
//             status: status,
//         }
//     }
//     return await promise.blob();
// };