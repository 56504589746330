<template>
     <div id="customer-writing">
        <div class="row w-100 g-0 p-4">
            <div class="position-relative z-index-2">
                <div class="mb-1 card card-plain">
                    <div class="p-3 card-body">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-flex flex-column h-100">
                                    <h2 class="mb-0 font-weight-bolder">Editeur d'articles</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="top-form">
                    <fieldset>
                        <label for="keyword">Mot-clé</label>
                        <div class="top-form__sub-container">
                            <input v-model="article.keyword" type="text" name="keyword" id="keyword" class="form-control" autocomplete="off">
                            <SoftButton id="validate-keyword-button" @click="validateKeywordAndGetSeoSuggestions" :color="softButtonColor" :disabled="articleSeoSuggestionsLoading">
                                <div v-if="articleSeoSuggestionsLoading" class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.612 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3l-1.4 1.4ZM9.5 14q1.875 0 3.188-1.313T14 9.5q0-1.875-1.313-3.188T9.5 5Q7.625 5 6.312 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14Z"/></svg>
                            </SoftButton>
                        </div>
                    </fieldset>
                    <div class="top-form__buttons-container">
                        <SoftButton id="local-save-button" @click="localSavingContent">
                            Sauvegarde Locale
                            <svg v-if="localSaveLoadingSuccess" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
                        </SoftButton>
                        <SoftButton @click="resetAll" color="warning">
                            Réinitialiser
                        </SoftButton>
                    </div>
                </div>
                <div class="d-flex justify-content-end" style="font-size: 13px;" :class="goodWordsNumber" >{{ getWordsCount() }} mot(s) &nbsp;<strong>{{ article.estimatedWordsRequired ? `/ ${article.estimatedWordsRequired} mots requis estimés` : "" }}</strong> </div>
                <QuillEditor @keyup="refreshSeoWordsCounts" id="editor" style="background-color: white; height: fit-content;" content-type="html" v-model:content="article.content" theme="snow" toolbar="full" :read-only="false"/>
            </div>
        </div>
        <div v-if="showRightSideToolsBox" id="right-side-tools-box-container">
            <RightSideToolsBox ref="RightSideToolsBoxRef" :articleContent="article.content" :disableBackups="true" />
        </div>
     </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { QuillEditor } from '@vueup/vue-quill';
import SoftButton from "@/components/SoftButton.vue";
import RightSideToolsBox from '@/components/RightSideToolsBox/RightSideToolsBox.vue';
import { customerArticleStorage } from "@/tools/localstorageTools.js"; 
import { convert } from 'html-to-text';

// DATA
const RightSideToolsBoxRef = ref();
const showRightSideToolsBox = ref(true);
const softButtonColor = ref("secondary")
const localSaveLoadingSuccess = ref(false);
const articleSeoSuggestionsLoading = ref(false);
const goodWordsNumber = ref('');
const article = ref({
    keyword: '',
    content: '',
    estimatedWordsRequired: 0
});

// METHODS
const localSavingContent = () => {
    try {
        let storage = customerArticleStorage.getOrCreateAndReturnStorage();
        storage.content = article.value.content;
        localStorage.setItem('customerArticle', JSON.stringify(storage));
        
    } catch (error) {
        console.log(error);
    } finally {
        localSaveLoadingSuccess.value = true;
        setTimeout(() => localSaveLoadingSuccess.value = false, 4000);
    }
};
const getLocalSave = () => {
    try {
        const storage = JSON.parse(localStorage.getItem('customerArticle'));

        if (!storage) {
            console.log('No local save found');
            return;
        }

        article.value.keyword = storage?.keyword;
        article.value.content = storage?.content;
        article.value.estimatedWordsRequired = storage?.estimatedWordsRequired;
    } catch (error) {
        console.log(error);
    }
};
const validateKeywordAndGetSeoSuggestions = async () => {
    try {
        articleSeoSuggestionsLoading.value = true;
        const keyword = article.value.keyword;

        if (!keyword) return;

        let storage = customerArticleStorage.getOrCreateAndReturnStorage();

        storage.keyword = keyword;

        localStorage.setItem('customerArticle', JSON.stringify(storage));

        await RightSideToolsBoxRef.value.getArticleSeoSuggestions(keyword);
        
    } catch (error) {
        console.log(err);
    } finally {
        articleSeoSuggestionsLoading.value = false;
    }
};
const refreshSeoWordsCounts = async (event) => {
    const reactiveKeys = [',', '.', '!', '?'];
    RightSideToolsBoxRef.value.refreshWordsCounts(article.value.content);
    
    if (reactiveKeys.includes(event?.key)) {
        await localSavingContent();
        RightSideToolsBoxRef.value.refreshSEOScores();
    }
};
const resetAll = async () => {
    const warningText = "Attention, cette action entrainement la perte des données de toute la page ! Êtes-vous sûr de vouloir continuer ?";

    if (window.confirm(warningText)) {
        localStorage.clear('customerArticle');
        location.reload();
    } else {
        return;
    }
};
const getWordsCount = () => {
    const option = {
        selectors: [ 
            { selector: 'img', format: 'skip' },
            { selector: 'a', options: { ignoreHref: true } }
        ]
    }

    const htmlArticle = article.value.content;

    const htmlContentToText = convert(htmlArticle, option);
    const parsedText = htmlContentToText.replace(/’|'/gu, '').replace(/[^\w\s\p{L}]/gu, ' ').trim();
    const words = parsedText.split(/\s+/);

    if (words.length >= article.value.estimatedWordsRequired ) goodWordsNumber.value = 'good-words-number';
    else if (words.length < article.value.estimatedWordsRequired) goodWordsNumber.value = 'low-words-number';
    else goodWordsNumber.value = '';

    return words.length !== 1 ? words.length : 0;
};

// LIFECYCLE HOOKS
onMounted(() => {
    getLocalSave();
    if (article.value.content && article.value.keyword) {
        // validateKeywordAndGetSeoSuggestions();
    }
});

</script>
<style lang="scss" src="./customer-writing.scss" scoped>

</style>