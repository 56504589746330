<template>
    <div class="row w-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Statistiques</h2>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="container-fluid py-4">
                    <div id="period-container">
                        <div>
                            <label for="accounts-list">Période</label>
                            <select @change="renderKey = renderKey + 1" v-model="currentPeriod" name="period" id="period" class="form-control">
                                <option value="7daysAgo">7 derniers jours</option>
                                <option value="30daysAgo">30 derniers jours</option>
                                <!-- <option value="90daysAgo">90 derniers jours</option>
                                <option value="180daysAgo">180 derniers jours</option>
                                <option value="365daysAgo">1 an</option> -->
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-container col-12">
                            <GoogleAnalyticsReport :period="currentPeriod" :key="renderKey" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-container col-12 col-xxl-7">
                            <GoogleAnalyticsTraffic :period="currentPeriod" :key="renderKey" />
                        </div>
                        <div class="card-container col-12 col-xxl-5">
                            <GoogleTrafficOrigins :period="currentPeriod" :key="renderKey" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-container col-12">
                            <GoogleMostViewedPages :period="currentPeriod" :key="renderKey"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-container col-12 col-xxl-7">
                            <GoogleAnalyticsTrafficByCountry :period="currentPeriod" :key="renderKey"/>
                        </div>
                        <div class="card-container col-12 col-xxl-5">
                            <GoogleAnalyticsTrafficGenders :period="currentPeriod" :key="renderKey"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card-container col-12 col-xxl-5">
                            <GoogleAnalyticsTrafficsByPlatform :period="currentPeriod" :key="renderKey"/>
                        </div>
                        <div class="card-container col-12 col-xxl-7">
                            <GoogleAnalyticsPerformanceSources :period="currentPeriod" :key="renderKey" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from "vue";
    import axios from "axios";
    import GoogleAnalyticsTraffic from "./GoogleAnalyticsTraffic.vue";
    import GoogleAnalyticsReport from "./GoogleAnalyticsReport.vue";
    import GoogleMostViewedPages from "./GoogleMostViewedPages.vue";
    import GoogleTrafficOrigins from "./GoogleTrafficOrigins.vue";
    import GoogleAnalyticsTrafficByCountry from "./GoogleAnalyticsTrafficByCountry.vue";
    import GoogleAnalyticsTrafficGenders from "./GoogleAnalyticsTrafficGenders.vue";
    import GoogleAnalyticsTrafficsByPlatform from "./GoogleAnalyticsTrafficsByPlatform.vue";
    import GoogleAnalyticsPerformanceSources from "./GoogleAnalyticsPerformanceSources.vue";
    // STATE
    const currentPeriod = ref("7daysAgo");
    const renderKey = ref(0);

   

</script>

<style scoped>
    #period-container {
        display: flex;
        margin-bottom: 1.5rem;
    }
    .card-container {
        margin-bottom: 1.5rem;
    }

</style>