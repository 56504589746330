<template>
    <div id="main-container" class="d-flex">
        <div @click="listOpen = !listOpen" id="list-button">
            <svg v-if="listOpen" xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24"><g fill="none" stroke="#94e4af" stroke-width="2"><path d="M21 12a9 9 0 1 1-18 0a9 9 0 0 1 18 0Z"/><path stroke-linecap="round" d="m9 15l6-6m0 6L9 9"/></g></svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 512 512"><path fill="#94e4af" d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208s208-93.31 208-208S370.69 48 256 48Zm-88 302a24 24 0 1 1 24-24a24 24 0 0 1-24 24Zm0-71a24 24 0 1 1 24-24a24 24 0 0 1-24 24Zm0-73a24 24 0 1 1 24-24a24 24 0 0 1-24 24Zm184 135H224a16 16 0 0 1 0-32h128a16 16 0 0 1 0 32Zm0-71H224a16 16 0 0 1 0-32h128a16 16 0 0 1 0 32Zm0-72H224a16 16 0 0 1 0-32h128a16 16 0 0 1 0 32Z"/></svg>
            <div v-if="listOpen">Masquer les articles publiés</div>
            <div v-else>Afficher les articles publiés</div>
        </div>
        <div v-if="listOpen" id="list" class="card">
            <div class="card-body">
                <div class="col-4 mb-3">
                    <div class="input-group">
                        <span class="input-group-text text-body">
                            <i class="fas fa-search" aria-hidden="true"></i>
                        </span>
                        <input v-model="inputFilter" @keyup="filterArticles" type="text" class="form-control"/>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Titre</th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mot(s) clé(s)</th>
                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Type</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr v-if="contentLoading" style="width:100%;" class="text-center">
                                <td colspan="6">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>

                            <tr v-if="err.status === true">
                                <td colspan="6">
                                    <div class="alert alert-danger">
                                        <p v-if="err.status">{{ err.message }}</p>  
                                    </div>
                                </td>
                            </tr>

                            <tr v-if="articlesList.length == 0 && err.status === false && contentLoading === false">
                                <td colspan="3">Aucun article ...</td>
                            </tr>

                            <tr v-show="!contentLoading && !err.status" v-for="(article, index) in filteredArticlesList" :key="index">
                                <td class="text-center ellipse large">{{ article.title }}</td>
                                <td class="text-center ellipse large">{{ article.keywords.primary }}{{ article.keywords.secondary ? `/ ${article.keywords.secondary}` : ""}}</td>
                                <td class="text-center">{{ articleTools.getType(article.type) }}</td>
                                <td class="text-center">
                                    <div class="d-flex justify-content-around align-items-center">
                                        <router-link :to="`/article-writing/${article._id}`" target="_blank" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="#94e4af" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>                                        
                                        </router-link>
                                    </div>
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import axios from "axios";
    import Cookies from "js-cookie";
    import articleTools from "@/tools/articleTools.js";
    // PROPS
    const props = defineProps({
        customerId: {
            type: String,
            required: true
        }
    });
    // STATE
    const listOpen = ref(false);
    const contentLoading = ref(true);
    const err = ref({
        status: false,
        message: ""
    });
    const inputFilter = ref("");
    const articlesList = ref([]);
    const filteredArticlesList = ref([]);

    // METHODS
    const getArticlesList = async () => {
        try {
            contentLoading.value = true;
            err.value.status = false;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/advisor/customers/get-articles-list?customerId=${props.customerId}`,
                headers: { 'Authorization': `Bearer ${Cookies.get('token')}` }
            });
            articlesList.value = response.data;
            filteredArticlesList.value = response.data;
        } catch (error) {
            if (error.status === 400) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue durant la récupération des articles";
            }
            err.value.status = true;
        } finally {
            contentLoading.value = false;
        }
    };
    const filterArticles = () => {  
        const filteredArticles = articlesList.value.filter((article) => {
            return article?.title.toLowerCase().includes(inputFilter.value.toLowerCase()) ||
                article.link?.toLowerCase().includes(inputFilter.value.toLowerCase())
        });
        filteredArticlesList.value = filteredArticles;
    };
    
    // LYFECYCLE HOOKS
    onMounted(() => {
        getArticlesList();
    });

</script>
<style lang="scss" scoped>
    @import "../../assets/css/tables-custom.css";
    #main-container {
        position: relative;
    }
    #list-button{
        display: flex;
        cursor: pointer;
        font-size: 0.8rem;
        align-items: center;
    }
    #list-button:hover {
        color: #94e4af;
    }
    #list {
        position: absolute;
        top: 40px;
        right: -140px;
        background-color: white;
        z-index: 1;
        width: 60dvw;

        @media screen and (max-width: 768px){
            width: 70dvw;
        }
    }
    .table-responsive {
        max-height: 40dvh;
        overflow: auto;
    }
    thead {
        position: sticky;
        top: 0;
        background-color: white;
    }
    
    
</style>