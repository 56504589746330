import { getFetchConfig } from '@/tools/fetchTools.js';

export const uploadUserFiles = async (files) => {
    const fetchConfig = getFetchConfig();
    const formData = new FormData();

    for (const file of files) {
        formData.append('files', file);
    }
    
    const url = `${process.env.VUE_APP_SERVER}/user/file/upload`;
    
    const promise = await fetch(url, {
        method: 'POST',
        headers: fetchConfig,
        body: formData,
    });
    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

