<template>
    <div id="main-container" class="row w-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="d-flex flex-row h-100 align-items-center">
                                <h2 class="mb-0 font-weight-bolder">Dossier client \</h2>
                                <h4>{{ customerInformations.fullName }}{{ customerInformations.websiteUrl ? ` - ${customerInformations.websiteUrl}` : "" }}</h4>
                            </div>
                        </div>
                    </div> 
                </div>
                <div id="nav-tab">
                    <ul id="nav-tab-ul">
                        <li v-show="verifiedUserRank >= page.displayContitionByRank" v-for="page in pagesList" :key="page.value"  @click="selectedPage = page.value" class="nav-tab-element" :class="{ 'active-tab': selectedPage === page.value }">
                            {{ page.displayName }}
                        </li>
                    </ul>
                </div>
                <div class="container-fluid py-4">
                    
                    <div v-if="selectedPage === 'general'" id="general-page">
                        <div class="row">
                            <div class="col-12 col-lg-5 card-container">
                                <CustomerInformations 
                                    :customerId="customerId" 
                                    :userRank="verifiedUserRank" 
                                    :customerInformations="customerInformations"
                                    :loading="customerInformationsLoading"
                                    :err="customerInformationsError"
                                />
                            </div>
                            <div class="col-12 col-lg-7 card-container">
                                <CustomerKernelCredits :customerId="customerId" :userRank="verifiedUserRank"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" >
                                <CustomerNotes :customerId="customerId" :userRank="verifiedUserRank"/>
                            </div>
                        </div>
                    </div>
                
                    <div v-if="selectedPage === 'billing'" id="billing-page">
                        <div class="row">
                            <div class="col-12 card-container">
                                <CustomerBilling :customerId="customerId" :userRank="verifiedUserRank"/>
                            </div>
                        </div>
                    </div>
            
                    <div v-if="selectedPage === 'articles'" id="articles-page">
                        <div class="row">
                            <div class="col-12 card-container">
                                <CustomerArticlesList :customerId="customerId"/>
                            </div>
                        </div>
                    </div>

                    <div v-if="selectedPage === 'optimisation-notes'" id="optimisation-notes-page">
                        <div class="row">
                            <div class="col-12 card-container">
                                <CustomerOptimisationNotes :customerId="customerId"/>
                            </div>
                        </div>
                    </div>

                    <div v-if="selectedPage === 'social-networks'" id="social-networks-page">
                        <div class="row">
                            <div class="col-12 card-container">
                                <CustomerFilesSocialNetworks :customerId="customerId"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    
    import { onMounted, ref } from "vue";
    import { useRoute, useRouter } from "vue-router";
    import CustomerFilesSocialNetworks from '@/components/linked/customerFilesComponents/CustomerFilesSocialNetworks/CustomerFilesSocialNetworks.vue';
    import CustomerOptimisationNotes from "@/components/linked/customerFilesComponents/CustomerOptimisationNotes.vue";
    import CustomerArticlesList from '@/components/linked/customerFilesComponents/CustomerArticlesAndLinksList.vue';
    import CustomerKernelCredits from "@/components/linked/customerFilesComponents/CustomerKernelCredits.vue";
    import CustomerInformations from '@/components/linked/customerFilesComponents/CustomerInformations.vue';
    import CustomerBilling from "@/components/linked/customerFilesComponents/CustomerBilling.vue";
    import CustomerNotes from '@/components/linked/customerFilesComponents/CustomerNotes.vue';
    import userTools from "@/tools/userTools.js";
    import Cookies from "js-cookie";

    const route = useRoute();
    const router = useRouter();

    // DATA
    const customerId = ref(route.params.id);
    const verifiedUserRank = ref(0);
    const selectedPage = ref("general");
    const pagesList = ref([
        { value: "general", displayName: "Général", displayContitionByRank: 1 },
        { value: "billing", displayName: "Facturation", displayContitionByRank: 4 },
        { value: "articles", displayName: "Articles / Liens", displayContitionByRank: 1 },
        { value: "social-networks", displayName: "Réseaux sociaux", displayContitionByRank: 1 },
        { value: "optimisation-notes", displayName: "Notes d'optimisation", displayContitionByRank: 1 }
    ]);
    const customerInformations = ref({});
    const customerInformationsLoading = ref(true);
    const customerInformationsError = ref({
        status: false,
        message: ""
    });
    const fetchConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`
    };

    // METHODS
    const verifyUserRank = async () => {
        try {
            const authParams = 'rankLevel';
            const authorizations = await userTools.getUserAuthorizations(Cookies.get('token'), authParams);
            verifiedUserRank.value = authorizations.rankLevel;
        } catch (error) {
            verifiedUserRank.value = 1;
        } 
    };
    const getCustomerInformations = async () => {
        try {
            customerInformationsError.value.status = false;
            customerInformationsLoading.value = true;
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/advisor/customers/get-main-informations/${customerId.value}`, {
                method: "GET",
                headers: fetchConfig
            });

            const response = await promise.json();
            
            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }

            customerInformations.value = { ...response };
        } catch (error) {
            if (error.status === 400 || 404) customerInformationsError.value.message = error.error;
            else customerInformationsError.value.message = "Une erreur est survenue";
            customerInformationsError.value.status = true;
        } finally {
            customerInformationsLoading.value = false;
        }
    };
    const changeSelectedPageDependingOnOrigin = () => {
        const referrer = route.meta.referrer;
        if (referrer === `/advisor/customer/${customerId.value}/social-network-publication/add`) {
            selectedPage.value = 'social-networks';
        }
    };

    // LIFECYCLE HOOKS
    onMounted(async() => {
        await verifyUserRank();
        changeSelectedPageDependingOnOrigin();
        getCustomerInformations();
    });
</script>
<style lang="scss" src="./customer-file.scss" scoped>
    
</style>
