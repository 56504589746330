<template>
    <div class="publication-card-label" :style="labelStyle()" :class="{ 'publication-card-label_pending': status === 0 }">
        <svg v-if="stringStatus === 'Publié'" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24"><path fill="currentColor" d="m10.5 13.4l4.9-4.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-5.6 5.6q-.3.3-.7.3t-.7-.3l-2.6-2.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l1.9 1.9Z"/></svg>
        <svg class="publication-card-label__pending-label" v-else-if="stringStatus === 'En attente'" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><g fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m12 12l6.125 4.17A2 2 0 0 1 19 17.822V20.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-2.677a2 2 0 0 1 .875-1.654L12 12Zm0 0l6.125-4.17A2 2 0 0 0 19 6.178V3.5a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5v2.677a2 2 0 0 0 .875 1.654L12 12Z"/><path fill="currentColor" d="M15 20.207v.643a.15.15 0 0 1-.15.15h-5.7a.15.15 0 0 1-.15-.15v-.643a.5.5 0 0 1 .146-.353l2.288-2.288a.8.8 0 0 1 1.132 0l2.288 2.288a.5.5 0 0 1 .146.353ZM12 11l5-3H7l5 3Z"/><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18v-6"/></g></svg>
        <svg v-else-if="stringStatus === 'Validé'" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 15 15"><path fill="currentColor" fill-rule="evenodd" d="M4.5 1a.5.5 0 0 1 .5.5V2h5v-.5a.5.5 0 0 1 1 0V2h1.5A1.5 1.5 0 0 1 14 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 12.5v-9A1.5 1.5 0 0 1 2.5 2H4v-.5a.5.5 0 0 1 .5-.5ZM10 3v.5a.5.5 0 0 0 1 0V3h1.5a.5.5 0 0 1 .5.5V5H2V3.5a.5.5 0 0 1 .5-.5H4v.5a.5.5 0 0 0 1 0V3h5ZM2 6v6.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V6H2Zm5 1.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0ZM9.5 7a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1Zm1.5.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0Zm.5 1.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1ZM9 9.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0ZM7.5 9a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1ZM5 9.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0ZM3.5 9a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1ZM3 11.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0Zm2.5-.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1Zm1.5.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0Zm2.5-.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1Z" clip-rule="evenodd"/></svg>
        <svg v-else-if="stringStatus === 'Correction'" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 20h4L18.5 9.5a2.828 2.828 0 1 0-4-4L4 16v4m9.5-13.5l4 4M19 16v3m0 3v.01"/></svg>
    </div>
</template>
<script setup>
import { createDarkerColorFromHexOrRgb } from '@/tools/colorTools';
import { getColorStatus } from '@/tools/socialNetworkTools.js';
const props = defineProps({
    status: {
        type: Number,
        required: true,
    },
    stringStatus: {
        type: String,
        required: true,
    }
});
const setBackgroundColor = () => {
    const status = props.status;
    const color = getColorStatus(status);
    return color;
};
const labelStyle = () => {
    const backgroundColor = setBackgroundColor();
    const darkerBackgroundColor = createDarkerColorFromHexOrRgb(backgroundColor, 50);
    return {
        backgroundColor: backgroundColor,
        color: darkerBackgroundColor,
    };
};
</script>
<style lang="scss" scoped>
    .publication-card-label {  
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        border-radius: 50%;

        &_pending {
            animation:  pulse 2s infinite;

            &:hover {
                cursor: pointer;
                filter: brightness(0.9);
            }
        }
    }
</style>