import { getFetchConfig } from '@/tools/fetchTools.js'

export const checkUserAnalyticsConnexion = async () => {
    const fetchConfig = getFetchConfig('json');

    const promise = await fetch(`${process.env.VUE_APP_SERVER}/google-analytics/connexion/check`, {
        method: "GET",
        headers: fetchConfig,
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};