<template>
    <div class="card">
        <div class="card-header">
            <h5>Pages les plus consultées</h5>
            <div id="pagination">
                <button @click="previousPage" class="form-control pagination-b previous-b" :disabled="currentPagesListIndex === 0">&lt; Précédent</button>
                <button @click="nextPage" class="form-control pagination-b next-b" :disabled="currentPagesListIndex === completePagesList.length - 1">Suivant &gt;</button>
            </div>
        </div>
        <div class="card-body">
            <div v-if="err.status === true" id="error-container">
                <div>Nous n'avons pas pu récupérer ces données auprès de google...</div>
            </div>
            <div v-if="loading" id="spinner-container">
                <div class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-if="!loading" id="page-list-container" class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th width="90%" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Url de la page</th>
                            <th width="10%" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Utilisateurs actifs</th>
                            <th width="10%" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Taux de rebond</th>
                            <th width="10%" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Durée moyenne engagement</th>
                            <th width="10%" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Vues</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(page, index) in currentPagesList" :key="index">
                            <td><a :href="`https://${page.url}`" target="_blank">{{ getUrlString(page.url) }}</a></td>
                            <td class="text-center">{{ page.activeUsers }}</td>
                            <td class="text-center">{{ page.bounceRate }} %</td>
                            <td class="text-center">{{ page.userEngagement }}</td>
                            <td class="text-center">{{ page.views }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from "vue";
    import axios from "axios";
    import Cookies from "js-cookie";
import { parseDate } from "simple-datatables/dist/date-170bba30";

    const props = defineProps({
        period: {
            type: String,
            required: true
        }
    });
    // STATE
    const loading = ref(true);
    const err = ref({
        status: false
    });
    const completePagesList = ref([]);
    const currentPagesList = ref([]);
    const currentPagesListIndex = ref(0);


    // METHODS
    const getPagesList = async () => {
        try {
            err.value.status = false;
            loading.value = true;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/user/google/get-data/viewed-pages?period=${props.period}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                },
            });
            let count = 0;
            let array = [];
            response.data.map((element, index) => {
                array.push(element);
                count += 1;
                if (array.length === 10 || index === response.data.length - 1) {
                    completePagesList.value.push(array);
                    array = [];
                }
            });
            currentPagesList.value = completePagesList.value[0];
        } catch (error) {
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };
    const nextPage = () => {
        currentPagesList.value = completePagesList.value[currentPagesListIndex.value + 1]
        currentPagesListIndex.value = currentPagesListIndex.value + 1;     
    };
    const previousPage = () => {
        currentPagesList.value = completePagesList.value[currentPagesListIndex.value - 1];
        currentPagesListIndex.value = currentPagesListIndex.value - 1;
    };
    const getUrlString = (url) => {
        let parsedUrl = url.substring(url.indexOf("/"), url.indexOf("/") + 60);
        parsedUrl.length >= 60 ? parsedUrl = parsedUrl.concat("...") : "";
        return parsedUrl;
    };

    // LIFECYCLE HOOKS
    onMounted(() => {
        getPagesList();
    });
</script>

<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }
    .card {
        min-height: 620px;
    }
    #spinner-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
    }
    #error-container {
        text-align: center;
    }
    #pagination {
        width: 42%;
        display: flex;
        justify-content: end;
        align-items: end;
    }
    .pagination-b {
        min-width: fit-content;
        max-width: 6%;
    }
    .previous-b {
        border-radius: 0.5rem 0 0 0.5rem;
    }
    .next-b {
        border-radius: 0 0.5rem 0.5rem 0;
    }
</style>