<template>
    <div class="container-card" :style="setStyle">
        <div class="container-card__wrapper">
            <div v-if="cardTitle" class="container-card__header">
                <h3>{{ cardTitle }}</h3>
            </div>
            <slot></slot>
        </div>
    </div>
</template>
<script setup>
    import { computed, onMounted } from 'vue';
    import { parseAndFormatDimension } from '@/tools/cssTools.js';
    const props = defineProps({
        cardTitle: {
            type: String,
            required: false
        },
        height: {
            type: [String, Number],
            required: false,
            default: '80dvh'
        }
    });
    const setStyle = computed(() => {
        const height = parseAndFormatDimension(props.height);
        const style = {
            height: height
        };
        return style;
    });
</script>
<style lang="scss" scoped>
    .container-card {
        padding: $card-container-padding;
        background-color: #fff;
        border-radius: 1rem;
        box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);

        &__wrapper {
            padding: 1.1rem 0.4rem;
            height: 100%;
            width: 100%;
            overflow-y: auto;
        }
    }
</style>