<template>
    <div class="side-nav-bar" :class="[ openClass, isMobile ]">
        <div 
            @mouseenter="handleMouseEnter" 
            @mouseleave="closeMenu" 
            class="side-nav-bar__wrapper container-card"
            :class="[ openClass, isMobile ]"
        >
            <LogoArea :openStatus="openStatus"/>
            <div class="side-nav-bar__wrapper__side-nav-items">
                <div v-if="verifiedUserRank >= 1" class="side-nav-bar__wrapper__side-nav-items__section">
                    <SideNavItem                        
                        v-for="(item, index) in sideNavItemsList.filter(item => item.role === 'user')"
                        :key="index"
                        :item="item"
                        :openStatus="openStatus"
                        :notifications="userNotifications"
                    />
                </div>
                <div v-if="verifiedUserRank >= 2" class="side-nav-bar__wrapper__side-nav-items__section">
                    <div v-if="openStatus === 1" class="side-nav-bar__wrapper__side-nav-items__section__title">Espace rédacteur</div>
                    <SideNavItem 
                        v-for="(item, index) in sideNavItemsList.filter(item => item.role === 'writer')"
                        :key="index"
                        :item="item"
                        :openStatus="openStatus"
                        :notifications="userNotifications"
                    />
                </div>
                <div v-if="verifiedUserRank >= 3" class="side-nav-bar__wrapper__side-nav-items__section">
                    <div v-if="openStatus === 1" class="side-nav-bar__wrapper__side-nav-items__section__title">Espace conseiller</div>
                    <SideNavItem 
                        v-for="(item, index) in sideNavItemsList.filter(item => item.role === 'advisor')"
                        :key="index"
                        :item="item"
                        :openStatus="openStatus"
                        :notifications="userNotifications"
                    />
                </div>
                <div v-if="verifiedUserRank === 4" class="side-nav-bar__wrapper__side-nav-items__section">
                    <div v-if="openStatus === 1" class="side-nav-bar__wrapper__side-nav-items__section__title">Espace admin</div>
                    <SideNavItem 
                        v-for="(item, index) in sideNavItemsList.filter(item => item.role === 'admin')"
                        :key="index"
                        :item="item"
                        :openStatus="openStatus"
                        :notifications="userNotifications"
                    />
                </div>
            </div>
        </div>
        <StatusToggleButton 
            class="side-nav-bar__toggle-button"
            :class="openClass"
            :openStatus="openStatus"
            @click.stop="toggleOpenStatus"
        />
    </div>
</template>
<script setup>
import { fetchMyUserRank, fetchMyUserNotifications } from '@/useCases/userUseCases.js';
import { ref, computed, onMounted, watch, nextTick, onUnmounted } from 'vue';
import StatusToggleButton from './components/StatusToggleButton.vue';
import SideNavItem from './components/SideNavItem/SideNavItem.vue';
import { startIntercom } from '@/services/intercomService.js';
import sideNavItemsList from './sideNavItemsList.js';
import LogoArea from './components/LogoArea.vue';
import _ from 'lodash';

// REACTIVE
const referenceStatus = ref(1);
const openStatus = ref(1);
const reactiveScreenWidth = ref(null);
const reactiveLogoHeight = ref(null);
const verifiedUserRank = ref(null);
const userNotifications = ref({});
let lasNotificationsUpdate = null;

// COMPUTED
const openClass = computed(() => {
    const screen = reactiveScreenWidth.value;
    if (openStatus.value === 0) {
        if (screen < 768) {
            return 'closed';
        }
        return 'partially-open';
    }
    if (openStatus.value === 1) {
        return 'open';
    }
});
const isMobile = computed(() => {
    const screen = reactiveScreenWidth.value;
    if (screen < 768) {
        return 'is-mobile';
    } else {
        return '';
    }
});


// METHODS
const toggleOpenStatus = () => {
    openStatus.value = openStatus.value === 1 ? 0 : 1;
    referenceStatus.value = openStatus.value;
};
const handleMouseEnter = () => {
    openMenu();
    getUserNotifications();
};
const openMenu = () => {
    const screen = window.innerWidth;
    if (screen > 768 && openStatus.value === 0) {
        openStatus.value = 1;
    }
};
const closeMenu = () => {
    const screen = window.innerWidth;
    if (screen > 768 && openStatus.value === 1 && referenceStatus.value === 0) {
        openStatus.value = 0;
    }
};
const setItemsListHeight = (newHeight) => {
    const itemsList = document.querySelector('.side-nav-bar__wrapper__side-nav-items');
    itemsList.style.height = `calc(100% - (${newHeight}px + 1rem))`;
};
const checkImageReadiness = () => {
    const image = document.querySelector('#logo-area img');
    const imageContainer = document.querySelector('#logo-area'); 
    if (image?.complete) {
        reactiveLogoHeight.value = imageContainer.offsetHeight;
    } else {
        image.addEventListener('load', () => {
            reactiveLogoHeight.value = imageContainer.offsetHeight;
        });
    }
};
const handleClickOutside = (event) => {
    const target = event.target;
    const sideNavBar = document.querySelector('.side-nav-bar');
    const button = document.querySelector('.status-toggle-button');
    const svgButton = document.querySelector('.status-toggle-button__svg');
    const elements = [sideNavBar, button, svgButton];
    if (reactiveScreenWidth.value > 768) {
        return;
    }
    if (elements.some((element) => element && element.contains(target))) {
        return;
    } 
    openStatus.value = 0;
};
const getUserRank = async () => {
    const userRank = await fetchMyUserRank();
    if (userRank) {
        verifiedUserRank.value = userRank;
    } else {
        verifiedUserRank.value = 1;
    }
};
const getUserNotifications = async () => {
    const date = Date.now();
    if (lasNotificationsUpdate && (date - lasNotificationsUpdate) < 15000) return;
    const foundNotifications = await fetchMyUserNotifications();
    if (foundNotifications) {
        lasNotificationsUpdate = Date.now();
        userNotifications.value = foundNotifications;
    }
};
// DEBOUNCED RESIZE HANDLER
let debouncedResizeHandler = _.debounce(() => {
    reactiveScreenWidth.value = window.innerWidth;
    if (window.innerWidth < 768) {
        openStatus.value = 0;
    }
    else if (window.innerWidth >= 768) {
        openStatus.value = 1;
    }
}, 200);

// WATCHERS
watch(reactiveLogoHeight, (newHeight) => {
    setItemsListHeight(newHeight);
});

// LIFECYCLE HOOKS
onMounted(async () => {
    startIntercom();
    await nextTick();
    reactiveScreenWidth.value = window.innerWidth;
    checkImageReadiness();
    getUserRank();
    getUserNotifications();
    document.addEventListener('click', handleClickOutside);
    debouncedResizeHandler = window.addEventListener('resize', debouncedResizeHandler);
});
onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
    window.removeEventListener('resize', debouncedResizeHandler);
});

</script>
<style lang="scss" scoped>
.side-nav-bar {
    height: 100dvh;
    position: relative;
    transition: all 0.2s ease;
    z-index: 1000;
    margin-right: 1rem;
    
    &.open {
        min-width: 16rem;
        transition: all 0.2s ease;
    }
    &.partially-open {
        min-width: 5rem;
        transition: all 0.2s ease;
    }
    
    &.is-mobile {
        position: fixed;
        z-index: 1001;
        margin-right: 0;
    }

    &__wrapper {
        width: 100%;
        position: absolute;
        height: 98dvh;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
        transition: all 0.3s ease;
        overflow: hidden;

        &__side-nav-items {
            overflow-y: auto;
            height: 90dvh;

            &::-webkit-scrollbar {
                width: 0;
            }
            &:hover::-webkit-scrollbar {
                width: 2px;
            }
            

            &__section {
                display: flex;
                flex-direction: column;
                margin-top: 1rem;
                gap: 0.4rem;

                &__title {
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: $primary-blue-color;
                    margin-bottom: 0.5rem;
                }
            }
        }

        &.closed {
            display: none;
        }
    }

    &__toggle-button {
        position: absolute;
        top: 30px;
        right: -60px;
        transform: translateY(-50%);

        &.closed {
            right: -50px;
        }
    }
}

</style>