export const getArticleDate = (article) => {
    if (article.publishedDate) {
        return `Publié: ${getLocaleDateStringFromISO(article.publishedDate)}`;
    }
    else if (article.dueDate) {
        return `Prévu: ${getLocaleDateStringFromISO(article.dueDate)}`;
    } 
    else {
        return "Non définie";
    } 
};

export const getArticleDateForCustomer = (article) => {
    if (article.publishedDate) {
        return getLocaleDateStringFromISO(article.publishedDate);
    }
    else if (article.dueDate) {
        return getLocaleDateStringFromISO(article.dueDate);
    } 
    else {
        return "Non définie";
    }
};

export const getLocaleDateStringFromISO = (ISODate) => {
    return new Date(ISODate).toLocaleDateString("FR-fr");
};

export const getLocalWeekStringFromWeekString = (weekString) => {
    if (!weekString) {
        return "Non définie";
    };
    const week = weekString.split("W")[1];
    const year = weekString.split("W")[0].replace('-', '');
    return `${week}/${year}`;
};


export const getISODatesRangeFromWeekString = (weekString) => {
    const [year, week] = weekString.split('-W');

    const januaryFirst = new Date(year, 0, 1);
    const firstDay = new Date(januaryFirst.getTime() + ((week - 1) * 7 - januaryFirst.getDay() + 1) * 24 * 60 * 60 * 1000);

    const lastDay = new Date(firstDay.getTime() + 6 * 24 * 60 * 60 * 1000);

    return {
        firstDayOfWeek: firstDay.toISOString(),
        lastDayOfWeek: lastDay.toISOString()
    };
};