import { getFetchConfig } from '@/tools/fetchTools.js';

export const createSocialNetworkPublication = async (publication) => {
    const fetchConfig = getFetchConfig();
    const formData = new FormData();
  
    for (const key in publication) {
        if (key === 'images') {
            for (const file of publication[key]) {
                formData.append('images', file);
            }
        }
        else if (key === 'networks') {
            formData.append(key, JSON.stringify(publication[key]));
        }
        else {
            formData.append(key, publication[key]);
        }
       
    }

    const url = `${process.env.VUE_APP_SERVER}/social-network-publication/create`;
    const promise = await fetch(url, {
        method: 'POST',
        headers: fetchConfig,
        body: formData,
    });
    const status = promise.status;
    const response = await promise.json();
    
    if (status !== 201) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const fetchSocialNetworkPublicationsByCustomerUserId = async (customerUserId) => {
    const fetchConfig = getFetchConfig('json');

    if (!customerUserId) {
        throw new Error("customerUserId required");
    }
    
    const url = `${process.env.VUE_APP_SERVER}/social-network-publication/get-publications-by-customer-user-id/${customerUserId}`;
    const promise = await fetch(url, {
        method: 'GET',
        headers: fetchConfig
    });
    
    const status = promise.status;
    const response = await promise.json();
   
    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
}; 

export const fetchAllSocialNetworkPublications = async (filters = []) => {
    const { dateType, dateOrder, publicationStatus, currentIndex, networks, customerUserId } = filters;
    const networksToString = networks ? networks.join(',') : '';
    const fetchConfig = getFetchConfig('json');
    
    const url = `${process.env.VUE_APP_SERVER}/social-network-publication/get-all-publications?dateType=${dateType}&dateOrder=${dateOrder}&publicationStatus=${publicationStatus}&skipNumber=${currentIndex}&networks=${networksToString}&customerUserId=${customerUserId}`;
    const promise = await fetch(url, {
        method: 'GET',
        headers: fetchConfig
    });
    
    const status = promise.status;
    const response = await promise.json();
   
    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const fetchOneSocialNetworkPublicationById = async (publicationId) => {
    const fetchConfig = getFetchConfig('json');

    if (!publicationId) {
        throw new Error("publicationId required");
    }

    const url = `${process.env.VUE_APP_SERVER}/social-network-publication/get-one-by-id/${publicationId}`;
    const promise = await fetch(url, {
        method: 'GET',
        headers: fetchConfig
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const fetchMySocialNetworkPublications = async () => {
    const fetchConfig = getFetchConfig('json');

    const url = `${process.env.VUE_APP_SERVER}/social-network-publication/my-publications`;
    const promise = await fetch(url, {
        method: 'GET',
        headers: fetchConfig
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const fetchMyLatestCustomerSocialNetworkPublications = async () => {
    const fetchConfig = getFetchConfig('json');

    const url = `${process.env.VUE_APP_SERVER}/social-network-publication/customer/latest-publications`;
    const promise = await fetch(url, {
        method: 'GET',
        headers: fetchConfig
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
    return response;
};

export const approveSocialNetworkPublication = async (publicationId) => {
    const fetchConfig = getFetchConfig('json');
    
    if (!publicationId) {
        throw new Error("publicationId required");
    }

    const url = `${process.env.VUE_APP_SERVER}/social-network-publication/approve/${publicationId}`;
    const promise = await fetch(url, {
        method: 'PATCH',
        headers: fetchConfig
    });

    const status = promise.status;
    const response = await promise.json();
   
    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
};

export const requestSocialNetworkPublicationCorrection = async (publicationId, correctionNotes) => {
    const fetchConfig = getFetchConfig('json');
    
    if (!publicationId) {
        throw new Error("publicationId required");
    }

    const url = `${process.env.VUE_APP_SERVER}/social-network-publication/request-correction`;
    const promise = await fetch(url, {
        method: 'PATCH',
        headers: fetchConfig,
        body: JSON.stringify({
            publicationId: publicationId,
            correctionNotes: correctionNotes
        })
    });

    const status = promise.status;
    const response = await promise.json();
   
    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
};

export const deleteSocialNetworkPublication = async (publicationId) => {
    const fetchConfig = getFetchConfig('json');
    
    if (!publicationId) {
        throw new Error("publicationId required");
    }

    const url = `${process.env.VUE_APP_SERVER}/social-network-publication/delete/${publicationId}`;
    const promise = await fetch(url, {
        method: 'DELETE',
        headers: fetchConfig
    });

    const status = promise.status;
    const response = await promise.json();
   
    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
};

export const publishSocialNetworkPublication = async (publicationId) => {
    const fetchConfig = getFetchConfig('json');
    
    if (!publicationId) {
        throw new Error("publicationId required");
    }

    const url = `${process.env.VUE_APP_SERVER}/social-network-publication/publish/${publicationId}`;
    const promise = await fetch(url, {
        method: 'PATCH',
        headers: fetchConfig
    });

    const status = promise.status;
    const response = await promise.json();
   
    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
};

export const updateSocialNetworkPublication = async (publication) => {
    const fetchConfig = getFetchConfig();
    const formData = new FormData();
    console.log({publication});
    if (!publication.week) {
        throw { error: "Vous devez sélectionner une semaine" };
    }
    if (publication.networks?.length === 0) {
        throw { error: "Vous devez sélectionner au moins un réseau social" };
    }
    if (!publication._id) {
        throw new Error("publicationId required");
    }

    for (const key in publication) {
        if (key === 'networks') {
            formData.append(key, JSON.stringify(publication[key]));
        }
        else if (key === 'images') {
            for (const file of publication[key]) {
                formData.append('images', file);
            }
        }
        else {
            formData.append(key, publication[key]);
        }
    }

    const url = `${process.env.VUE_APP_SERVER}/social-network-publication/update-one`;
    const promise = await fetch(url, {
        method: 'PATCH',
        headers: fetchConfig,
        body: formData
    });

    const status = promise.status;
    const response = await promise.json();

    if (status !== 200) {
        throw {
            status: status,
            ...response
        }
    }
};