<template>
    <div class="input-writers-list">
        <Datalist 
            @valueChange="$emit('valueChange', $event)"
            :label="dataList.label"
            :inputsStyle="inputStyle"
            :dataList="dataList.options"
            :required="required"
        />
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { fetchWritersList } from '@/useCases/userUseCases';
import Datalist from '@/components/standalone/inputs/Datalist.vue';

const props = defineProps({
    inputStyle: {
        type: Object,
        required: false
    },
    required: {
        type: Boolean,
        required: false,
        default: false
    }
});

const dataList = ref({
    options: [],
    label: 'Rédacteur',
});

const getCustomersList = async ()  => {
    const writersList = await fetchWritersList({ scope: 'minimal' });

    dataList.value.options = writersList.map(writer => {
        return {
            value: writer._id,
            label: writer.fullName
        };
    });
};

onMounted(() => {
    getCustomersList();
});
</script>
<style lang="scss" scoped></style>