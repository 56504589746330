<template>
    <div id="redaction-history" class="row w-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Historique de rédaction du mois</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <!-- CARD -->
                    <div class="card h-100 p-4">
                        <div v-if="err.status" class="text-center text-danger">
                            {{ err.message }}
                        </div>
                        <div>
                            Nombre d'articles : {{ articles.list?.length }}
                        </div>
                        <div class="table-responsive">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nom article</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Client</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date rédaction</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="articles.loading" colspan="6">
                                        <td colspan="6" style="width:100%;" class="text-center">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-show="!articles.loading && articles.list.length > 0" v-for="(article, index) in articles.list" :key="index">
                                        <td class="text-center ellipse large">
                                            {{ article.title }}
                                        </td>
                                        <td class="text-center ellipse large">
                                            {{ article.customerUser.fullName }} <span v-if="article.customerUser.websiteUrl"> / {{ article.customerUser.websiteUrl }}</span>
                                        </td>
                                        <td class="text-center">
                                            <div class="d-flex justify-content-center">
                                                <div class="alert" :class="{ 
                                                    'alert-dark': article.status === 0, 
                                                    'alert-primary': article.status === 1 || article.status === 4,
                                                    'alert-danger': article.status === 3,
                                                    'alert-warning': article.status === 2,
                                                    'alert-info': article.status === 5,
                                                    'alert-success': article.status === 6
                                                }">
                                                    <div>
                                                        {{ articleTools.getStringStatus(article.status) }}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </td>
                                        <td class="text-center">
                                            {{ articleTools.getType(article.type) }}
                                        </td>
                                        <td class="text-center">
                                            {{ getLocaleDateStringFromISO(article.writingDate) }}
                                        </td>
                                    </tr>
                                    <tr v-if="!articles.loading && articles.list.length === 0">
                                        <td class="text-center" colspan="5">
                                            Aucun article pour le mois en cours...
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { getMyArticleWritingHistory } from '@/useCases/articleUseCases.js';
import { getLocaleDateStringFromISO } from '@/tools/dateTools';
import articleTools from '@/tools/articleTools';

// DATA
const err = ref({
    message: "",
    status: false
});
const articles = ref({
    list: [],
    loading: true
});

// METHODS
const getArticles = async () => {
    try {
        err.value.status = false;
        articles.value.loading = true;
        articles.value.list = await getMyArticleWritingHistory();
    } catch (error) {
        if (error.error) err.value.message = error.error;
        err.value.status = true;
    } finally {
        articles.value.loading = false;
    }
};

// LIFECYCLE HOOKS
onMounted(() => {
    getArticles();
});
</script>

<style lang="scss" src="./redaction-history.scss" scoped>
@import "../../../../../src/assets/css/tables-custom.css";
</style>