<template>
    <div class="social-network-publication-creator">
        <div class="social-network-publication-creator__content-wrapper">
            <label for="editor">Contenu de la publication</label>
            <QuillEditor 
                @click="selectArea" 
                v-model:content="publication.content" 
                style="background-color: white; min-height: 200px;" 
                id="editor" 
                content-type="html" 
                theme="snow" 
                toolbar="essential" 
            />
        </div>
       <div class="social-network-publication-creator__networks-wrapper">
            <SocialNetworkPublicationNetworks 
                @dataChange="handleNetworksChange"
                :selectedNetworks="publication.networks"
            />
       </div>
       <div class="social-network-publication-creator__week-wrapper">
            <SocialNetworkPublicationWeek 
                @dataChange="handleWeekChange"
                :selectedWeek="publication.week"
            />
       </div>
       <div class="social-network-publication-creator__visual-wrapper">
            <SocialNetworkPublicationVisual 
                @dataChange="handleVisualChange"
                @typeChange="handleVisualTypeChange"
                :images="publication?.images"
                :video="publication?.video"
            />
       </div>
       <div class="social-network-publication-creator__button-wrapper">
            <CustomClassicButton
                @click="createPublication"
                :loading="createPublicationStatus.loading"
            >
                Créer la publication
            </CustomClassicButton>
       </div>
       <EmbeddedError 
            v-if="createPublicationStatus.error.status"
            :message="createPublicationStatus.error.message"
         />
    </div>
</template>
<script setup>
import { ref, defineEmits } from 'vue';
import { useRoute } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill';
import EmbeddedError from '../../standalone/statusMessages/errors/EmbeddedError.vue';
import { createSocialNetworkPublication } from '@/useCases/socialNetworkPublicationUseCases.js';
import CustomClassicButton from '@/components/standalone/customsButtons/CustomClassicButton.vue';
import { generateImageFileFromBase64Image } from '@/tools/mediaTools';
import SocialNetworkPublicationWeek from '@/components/linked/SocialNetworkPublicationComponents/SocialNetworkPublicationWeek.vue';
import SocialNetworkPublicationVisual from '@/components/linked/SocialNetworkPublicationComponents/SocialNetworkPublicationVisual.vue';
import SocialNetworkPublicationNetworks from '@/components/linked/SocialNetworkPublicationComponents/SocialNetworkPublicationNetworks.vue';

const emit = defineEmits(['publicationCreated']);
const route = useRoute();

const setDefaultPublication = () => {
    return {
        content: '',
        networks: [],
        week: '',
        images: [],
        video: null,
        thumbnail: null, 
        customerUser: route.params.id
    }
};
const publication = ref(setDefaultPublication());
const tempVisual = ref({
    type: '',
    images: [],
    video: null
});
const createPublicationStatus = ref({
    loading: false,
    error: {
        status: false,
        message: ''
    }
});


const handleNetworksChange = (networks) => {
    publication.value.networks = networks;
};
const handleWeekChange = (week) => {
    publication.value.week = week;
};
const handleVisualTypeChange = (type) => {
    tempVisual.value.type = type;
};
const handleVisualChange = (visualData) => {
    const visualType = tempVisual.value.type;
    switch (visualType) {
        case 'image':
            tempVisual.value.images = visualData.images;
        break;
        case 'video':
            tempVisual.value.video = visualData.video;
        break;
    }
};

const selectArea = () => {
    const area = document.querySelector("#editor .ql-editor");
    area.focus();
};

const cleanUnusedPublicationVisual = () => {
    const visualType = tempVisual.value.type;
    switch (visualType) {
        case 'image':
            publication.value.video = null;
            publication.value.thumbnail = null;
        break;
        case 'video':
            publication.value.images = [];
        break;
        case 'none':
            publication.value.images = [];
            publication.value.video = null;
            publication.value.thumbnail = null;
        break;
    }
};
const checkPublicationData = () => {
    const data = publication.value;
    const tempVisualData = tempVisual.value;
    if (data.networks.length === 0) throw { 
        error: "Vous devez sélectionner au moins un canal" 
    }
    if (!data.week) throw {
        error: "Veuillez selectionner une semaine"
    }
    if (tempVisualData.type === 'image' && tempVisualData.images.length === 0) throw {
        error: "Vous devez sélectionner au moins une image"
    }
    if (tempVisualData.type === 'video' && !tempVisualData.video) throw {
        error: "Vous devez sélectionner une vidéo"
    }
};

const createPublication = async () => {
    try {
        createPublicationStatus.value.loading = true;
        createPublicationStatus.value.error.status = false;
        checkPublicationData();
        if (tempVisual.value.type === 'image') {
            publication.value.images = tempVisual.value.images;
        }
        else if (tempVisual.value.type === 'video') {
            publication.value.video = tempVisual.value.video;
            publication.value.thumbnail = await generateImageFileFromBase64Image(tempVisual.value.video);
        }
        cleanUnusedPublicationVisual();
        await createSocialNetworkPublication(publication.value);
        emit('publicationCreated');
    } catch (error) {
        if (error.error) {
            createPublicationStatus.value.error.message = error.error;
        } else {
            createPublicationStatus.value.error.message = "Une erreur est survenue lors de la création de la publication";
        }
        createPublicationStatus.value.error.status = true;
    } finally {
        createPublicationStatus.value.loading = false;
    }
};
</script>
<style lang="scss" scoped>
.social-network-publication-creator {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

</style>