<template>
    <div
      class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg" :style="{backgroundImage:'url(' + require('@/assets/img/curved-images/curved9.jpg') + ')',}">
       <span class="mask bg-gradient-dark opacity-6"></span>
    </div>
  
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
          <div class="card z-index-0">
            <div class="pt-4 text-center mt-2">
              <h5>Mot de passe oublié</h5>
              <p>Veuillez renseigner votre nouveau mot de passe</p>
            </div>
            <div class="card-body">
                <form class="text-start">
                    <div class="mb-3">
                        <input v-model="newPassword" class="form-control" id="newPassword" type="password" placeholder="nouveau mot de passe" name="newPassword" />
                    </div>           
                    <div class="mb-3">
                        <input v-model="confirmNewPassword" class="form-control mt-2" id="newPassword" type="password" placeholder="confirmation nouveau mot de passe" name="newPassword" />
                    </div>                          
                    <div class="alert alert-secondary text-sm">
                        <strong>Le mot de passe doit contenir au moins: </strong><br><br>
                        <ul>
                            <li>une majuscule</li>
                            <li>une minuscule</li>
                            <li>un chiffre</li>
                            <li>un caractère spécial (!@#$%^&\*/.)</li>
                            <li>avoir une longueur minimale de 8 caractères</li>
                        </ul>
                    </div>
                    <div v-if="err.status === true || success.status === true" class="alert" :class="{'alert-danger': err.status, 'alert-success': success.status }">
                        <p v-if="err.status">{{ err.message }}</p> 
                        <p v-if="success.status">{{ success.message }}</p> 
                    </div>	
                    <div>
                        <router-link to="/login">
                            <soft-button @click.prevent="changeNewPassword" color="dark" full-width variant="gradient" class="my-4 mb-2">Valider nouveau mot de passe</soft-button>
                        </router-link>
                    </div>
                </form>
              </div>
          </div>
        </div>
      </div>
    </div>
</template>  
<script setup>
    import SoftButton from "@/components/SoftButton.vue";
    import { ref } from "vue";  
    import axios from 'axios';
    import Cookies from "js-cookie";
    import { useRoute } from "vue-router";
    import { useRouter } from 'vue-router';


    const route = useRoute();
    const router = useRouter();

    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`
    };

    const newPassword = ref("");
    const confirmNewPassword = ref("");

    const err = ref({
        message: "",
        status: false
    });
    const success = ref({
        message: "",
        status: false
    });

    const requestSuccess = ref(false);


    const changeNewPassword = async () => {
        try {
            err.value.status= false;

            const response = await axios ({
                method: "POST",
                url: `${process.env.VUE_APP_SERVER}/user/update-password`,
                headers: axiosConfig,
                data: {
                    password: newPassword.value,
                    passwordValidation: confirmNewPassword.value,
                    token: route.params.token,
                }
                
            })
            if (response.status === 200) {
                success.value.status = true;
                success.value.message = "Le mot de passe a bien été changé, vous allez être redirigé dans 6 secondes sur la page de connexion";
                requestSuccess.value = true;
				setTimeout(() => {router.push("/login")}, 6000);
            }
            
            console.log(response);
            
        } catch (error) {
            if (error.response.status === 400 || 500 || 409) {
                err.value.status = true;
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue, veuillez réessayer ultérieurement";
            }
        }
        }
    
</script>
  
  
  
  
  <style scoped>
  img {
      max-width: 50%;
  }
  button {
      margin-top: 5rem;
  }
  
  </style>