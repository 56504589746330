<template>
    <div class="publication-card-content">
        <div class="publication-card-content__content" :class="{ 'scrollable': scrollable }" v-html="cleanHtml()"></div>
    </div>
</template>
<script setup>
import { sanitizeHtml } from '@/tools/htmlTools.js';
import { defineEmits } from 'vue';
const props = defineProps({
    content: {
        type: String,
        required: true
    },
    readMore: {
        type: Boolean,
        required: false,
        default: false
    },
    scrollable: {
        type: Boolean,
        required: false,
        default: false
    }
});
const cleanHtml = () => {
    const characterLimit = 230; 
    let htmlContent = props.content;
    if (props.readMore && htmlContent.length > characterLimit) {
        htmlContent = htmlContent.substring(0, characterLimit) + '...';
    }
    const sanitizedHtml = sanitizeHtml(htmlContent);
    const html = sanitizedHtml;
    return html;
};
</script>
<style lang="scss" scoped>
.publication-card-content {
    height: 100%;
    width: 100%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__read-more {
        color: $grey-1;
        cursor: pointer;
        margin-top: 0.4rem;
        text-decoration: underline;
        margin: 0;
    }
    
    &__content {
        height: 100%;
        overflow: hidden;
        padding: 0 0.4rem;
        text-align: center;

        &.scrollable {
            overflow-y: auto;
            height: 100%;
        }

        &::-webkit-scrollbar {
            width: 2px;
        }

        &:deep(p, h1, h2, h3, h4, h5, h6) {
            margin: 0;
            padding: 0;
        }
        &:deep(h1, h2, h3, h4, h5, h6) {
            font-weight: bold;
        }
        &:deep(h1) {
            font-size: 20px;
        }
        &:deep(h2) {
            font-size: 18px;
        }
        &:deep(h3) {
            font-size: 16px;
        }
        &:deep(h4) {
            font-size: 14px;
        }
        &:deep(h5) {
            font-size: 13px;
        }
        &:deep(p) {
            font-size: 13px;
        }
    }
}
</style>