<template>
    <div class="table-images" :style="setTableImagesStyle()">
        <TinyCarousel 
            :images="images"
            :canBeExpanded="true"
            ref="tinyCarouselRef"
        />
    </div>
</template> 
<script setup>
    import { ref } from 'vue';
    import TinyCarousel from '@/components/standalone/carousels/TinyCarousel.vue';

    const props = defineProps({
        images: {
            type: Array,
            required: true
        },
        innerHeight: {
            type: [String, Number],
            required: false,
            default: 'auto'
        },
        hidePoints: {
            type: Boolean,
            required: false,
            default: false
        },
        tableImagesStyle: {
            type: Object,
            required: false,
            default: () => ({})
        }
    });
    
    const setTableImagesStyle = () => {
        const height = props.innerHeight;
        setCarouselHeight(parseInt(height));
        setCarouselWidth(parseInt(height) * (4 / 3));
        return {
            'height': height,
            ...props.tableImagesStyle
        }
    };
    const setCarouselHeight = (height) => {
        document.documentElement.style.setProperty('--carousel-height', height + 'px');
    };
    const setCarouselWidth = (width) => {
        document.documentElement.style.setProperty('--carousel-width', width + 'px');
    };
</script>
<style lang="scss" scoped>
    
</style>