<template>
    <div class="tiny-carousel" ref="tinyCarouselRef">
        <div v-show="index === currentImageIndex" class="tiny-carousel__wrapper" v-for="(image, index) in images" :key="index">
            <img :src="image" @click="expandImage">
        </div>
        <div v-if="images.length > 1" class="tiny-carousel__controls">
            <button @click="previousImage" class="tiny-carousel__controls__previous"> &lt; </button>
            <button @click="nextImage" class="tiny-carousel__controls__next"> &gt; </button>
        </div>
        <div v-if="images.length > 1" class="tiny-carousel__points">
            <span v-for="(image, index) in images" :key="index" :class="{'active': index === currentImageIndex}"></span>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, computed } from 'vue';
const props = defineProps({
    images: {
        type: Array,
        required: true
    },
    canBeExpanded: {
        type: Boolean,
        required: false,
        default: false
    },
    width: {
        type: [String, Number],
        required: false,
        default: '100%'
    },
});

const currentImageIndex = ref(0);
const currentImageExpanded = ref(false);
const tinyCarouselRef = ref(0);

const expandImage = () => {
    currentImageExpanded.value = !currentImageExpanded.value;
};
const nextImage = () => {
    currentImageIndex.value = (currentImageIndex.value + 1) % props.images.length;
};
const previousImage = () => {
    currentImageIndex.value = (currentImageIndex.value - 1 + props.images.length) % props.images.length;
};
</script>
<style lang="scss" scoped>
.tiny-carousel {
    height: 100%;
    position: relative;

    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        background-color: $light-grey;

        > img {
            max-width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__controls {
        button {
            width: 40px;
            height: 80px;
            background-color: rgb(255 255 255 / 64%);
            border: none;
            font-size: 26px;
            font-weight: 500;
            color: $blue-1;
            font-family: monospace;
            transition: all 0.1s ease-in;

            &:hover {
                color: #fff;
                background-color: #00000023;
            }
        }
        &__previous {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            border-radius: 0 20px 20px 0;
        }
        &__next {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            border-radius: 20px 0 0 20px;
        }
    }

    &__points {
        border-radius: 10px 10px 0 0;
        height: 15px;
        padding: 6px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        span {
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background-color: #0000003f;
            transition: all 0.1s ease-in;

            &.active {
                background-color: $main-color;
            }
        }
    }

 
}
</style>