<template>
    <div id="dashboard-globe">
      <canvas :width="width" :height="height"></canvas>
    </div>
</template>

<script setup>
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { onMounted } from "vue";
import * as THREE from "three";

const props = defineProps({
  width: {
    type: String,
    default: "700",
  },
  height: {
    type: String,
    default: "600",
  },
});

onMounted(async () => {
    (() => {
        const container = document.getElementById("dashboard-globe");
        const canvas = container.getElementsByTagName("canvas")[0];

        const globeRadius = 100;
        const globeWidth = 4098 / 2;
        const globeHeight = 1968 / 2;

        function convertFlatCoordsToSphereCoords(x, y) {
            let latitude = ((x - globeWidth) / globeWidth) * -180;
            let longitude = ((y - globeHeight) / globeHeight) * -90;
            latitude = (latitude * Math.PI) / 180;
            longitude = (longitude * Math.PI) / 180;
            const radius = Math.cos(longitude) * globeRadius;

            return {
                x: Math.cos(latitude) * radius,
                y: Math.sin(longitude) * globeRadius,
                z: Math.sin(latitude) * radius,
            };
        }

        function makeMagic(points) {
            const { width, height } = container.getBoundingClientRect();
            const geometries = [];
            // 1. Setup scene
            const scene = new THREE.Scene();
            // 2. Setup camera
            const camera = new THREE.PerspectiveCamera(45, width / height);
            // 3. Setup renderer
            const renderer = new THREE.WebGLRenderer({
                canvas,
                antialias: true,
                alpha: true,
                context: canvas.getContext('webgl2') 
            });
            renderer.setSize(width, height);
            // 4. Add points to canvas
            // - Material that the dots will be made of.
            const pointGeometry = new THREE.SphereGeometry(0.5, 1, 1);
            const pointMaterial = new THREE.MeshBasicMaterial({
                color: "#989db5",
            });

            for (let point of points) {
                const { x, y, z } = convertFlatCoordsToSphereCoords(
                    point.x,
                    point.y,
                    width,
                    height
                );

                if (x && y && z) {
                    pointGeometry.translate(x, y, z);
                    geometries.push(pointGeometry.clone());
                    pointGeometry.translate(-x, -y, -z);
                }
            }

            const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries);
            const globeShape = new THREE.Mesh(mergedGeometry, pointMaterial);
            scene.add(globeShape);

            container.classList.add("peekaboo");

            // Setup orbital controls
            camera.orbitControls = new OrbitControls(camera, canvas);
            camera.orbitControls.enableKeys = false;
            camera.orbitControls.enablePan = false;
            camera.orbitControls.enableZoom = false;
            camera.orbitControls.enableDamping = false;
            camera.orbitControls.enableRotate = true;
            camera.orbitControls.autoRotate = true;
            camera.orbitControls.autoRotateSpeed = 0.5;
            camera.position.z = -265;

            function animate() {
                // orbitControls.autoRotate is enabled so orbitControls.update
                // must be called inside animation loop.
                camera.orbitControls.update();
                requestAnimationFrame(animate);
                renderer.render(scene, camera);
            }
            animate();
        }

    function hasWebGL() {
        const gl = canvas.getContext("webgl1") || canvas.getContext("experimental-webgl");
        if (gl && gl instanceof WebGLRenderingContext) {
            return true;
        } else {
            return false;
        }
    }

    function init() {
        if (hasWebGL()) {
            window;
            window
                .fetch(
                    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard-pro/assets/js/points.json"
                )
                .then((response) => response.json())
                .then((data) => {
                    makeMagic(data.points);
                });
            }
        }
        init();
    })();
});

</script>
<style lang="scss" scoped>
#dashboard-globe {
    position: absolute;
    z-index: -1;
    right: 50%;
    top: 15%;
    transform: translate(50%, 15%) scale(0.8);

    @media screen and (min-width: $small-tablet) {
        right: 15%;
        top: 10%;
        transform: translate(15%, 10%) scale(0.9);
    }
    @media screen and (min-width: $normal-desktop) {
        top: 10%;
        transform: translate(15%, 10%) scale(1);
    }
    @media screen and (min-width: $extra-large-desktop) {
        right: 30%;
        top: 10%;
        transform: translate(30%, 10%) scale(1.1);
    }
}
</style>