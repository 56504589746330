<template>
    <div class="card">
        <div class="card-header">
            <h6 class="text-center">Crédits Kernel</h6>
        </div>
        <hr class="m-0 horizontal dark">
        <div class="card-body">
            <div v-if="err.status === true" id="error-container">
                <div>{{ err.message }}</div>
            </div>
            <div v-if="loading" id="spinner-container">
                <div class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-if="!loading && !err.status" class="row">
                <div class="col-12 col-xxl-6">
                    <p><strong>Crédits mensuels: </strong>{{ kernelCredits.core }}</p> 
                    <p><strong>Crédits supplémentaires: </strong>{{ kernelCredits.added }}</p> 
                </div>
                <div v-if="userRank === 4" class="col-12 col-xxl-6">
                    <div id="credits-manager-container" class="col">
                        <div>
                            <label class="w-100 text-center" for="add-credits">Ajout / Retrait crédits</label>
                            <input v-model="inputCredits" class="form-control" type="number" name="add-credits" id="add-credits">
                        </div>
                       <div id="buttons-container">
                            <SoftButton @click="addOrRemoveCreditsToUser('add')" :disabled="currentlySaving">+ Ajouter</SoftButton>
                            <SoftButton @click="addOrRemoveCreditsToUser('remove')" :color="'warning'" :disabled="currentlySaving">- Retirer</SoftButton>
                       </div>
                    </div>
                </div>
            </div>  
            <div class="row">

            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import Cookies from "js-cookie";
    import SoftButton from "@/components/SoftButton.vue";
    // PROPS
    const props = defineProps({
        customerId: {
            type: String,
            required: true
        },
        userRank: {
            type: Number,
            required: true
        }
    });
    // STATE
    const err = ref({
        status: false,
        message: ""
    })
    const loading = ref(true);
    const currentlySaving = ref(false);
    const kernelCredits = ref({});
    const inputCredits = ref(0);

    // METHODS
    const getCurrentBalance = async () => {
        try {
            err.value.status = false; 
            loading.value = true;
           
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/admin/user/kernel-credits/get-current-balance?userId=${props.customerId}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`,
                },
            });

            const response = await promise.json();

            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }

            kernelCredits.value = response.kernelCredits;
            inputCredits.value = 0;
        } catch (error) {
            if (error.status === 401 || 404) err.value.message = error.error;
            else err.value.message = "Une erreur est survenue...";
            err.value.status = true; 
        } finally {
            loading.value = false;
        }
    };
    const addOrRemoveCreditsToUser = async (action) => {
        try {
            err.value.status = false; 
            currentlySaving.value = true;

            await fetch(`${process.env.VUE_APP_SERVER}/admin/user/kernel-credits/add-or-remove-credits`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    action: action,
                    userId: props.customerId,
                    credits: inputCredits.value
                })
            });
           
            getCurrentBalance();

        } catch (error) {
            err.value.status = true; 
        } finally {
            currentlySaving.value = false;
        }
    };

    // LIFECYCLE HOOKS
    onMounted(() => {
        getCurrentBalance();
    });
</script>
<style scoped>
    .card {
        min-height: 228px;
    }
    #credits-manager-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    #buttons-container {
        display: flex;
        justify-content: center;
    }
    #buttons-container button {
        margin: 0.5rem;
    }
    div.card-header {
        padding: 1rem;
    }
    h6 {
        margin: 0;
    }
    #spinner-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #error-container {
        text-align: center;
    }
    
</style>