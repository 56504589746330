<template>
    <div class="publication-card-networks-list">
        <div class="publication-card-networks-list__wrapper">
            <img v-for="(image, index) in getNetworksImages()" :key="index" :src="image">
        </div>
    </div>
</template>
<script setup>
import { getSocialNetworkImagesFromName } from '@/tools/socialNetworkTools';
const props = defineProps({
    networks: {
        type: Array,
        required: true
    }
});
const getNetworksImages = () => {
    const networks = props.networks;
    const networksImages = networks.map(network => {
        const networkImages = getSocialNetworkImagesFromName(network);
        return networkImages;
    });
    return networksImages;
};
</script>
<style lang="scss" scoped>
    .publication-card-networks-list {
        &__wrapper {
            height: 100%;
            margin: 0.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.2rem;
            img {
                border-radius: 20%;
                width: 24px;
            }
        }
    }
</style>