<template>
    <main id="dashboard" class="classic-page">
        <div id="dashboard__header"class="classic-page__header">
            <h2 v-if="fullName">👋 Bonjour {{ fullName }}</h2>
        </div>
        <div id="dashboard__body" class="classic-page__body">
            <DashboardShortcuts />
            <DashboardLatestArticles />
            <DashboardLatestPublications />
            <DashboardGlobe width="600px" height="500px" />
        </div>
    </main>
</template>
<script setup>
import DashboardLatestPublications from './DashboardLatestPublications.vue';
import DashboardLatestArticles from './DashboardLatestArticles.vue';
import { fetchMyUserFullName } from '@/useCases/userUseCases.js';
import DashboardShortcuts from './DashboardShortcuts.vue';
import DashboardGlobe from './DashboardGlobe.vue';
import { onMounted, ref } from 'vue';
 
const fullName = ref('');

const getFullName = async () => {
    try {
        fullName.value = await fetchMyUserFullName();
    } catch (error) {
        fullName.value = 'Utilisateur';
    }
};

onMounted(async () => {
    getFullName();
});
</script>
<style lang="scss" scoped>
#dashboard {
   &__body {
        display: flex;
        flex-direction: column;
        gap: 3rem;

        #dashboard-latest-articles,
        #dashboard-latest-publications {
            width: 100%;

            @media (min-width: $large-tablet) {
                width: 900px;
            }
            @media (min-width: $extra-large-desktop) {
                width: 1180px;
            }
        }
   }
}   
</style>