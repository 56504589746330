<template>
    <div class="flex-table">
        <div v-if="!isUnderBreakpoint" class="flex-table__header" >
            <div v-for="(header, index) in headers" :key="index" class="flex-table__header__cell" :style="header.style">{{ header.label }}</div>
        </div>
        <div class="flex-table__body">
            <div v-for="(row, rowIndex) in data" :key="rowIndex" class="flex-table__body__row" :class="{ 'vertical': isUnderBreakpoint }">
                <div v-for="(cell, cellIndex) in row" :key="cellIndex" class="flex-table__body__row__cell" :style="cell.style" :class="{ 'vertical': isUnderBreakpoint }">
                    <div v-if="isUnderBreakpoint" class="flex-table__body__row__cell__label">{{ cell.label }}</div>
                    <div ref="cellRef" class="flex-table__body__row__cell__value">
                        <FlexTableText
                            v-if="cell.type === 'text'"  
                            :text="cell.value"
                            :tooltip="!isUnderBreakpoint && doesTheCellHaveTooltip(cell.label)"
                        />
                        <FlexTableButton
                            v-else-if="cell.type === 'button' && !cell.isDisabled"
                            @click="cell.action"
                        >
                            {{ cell.value }}
                        </FlexTableButton> 
                        <FlexTableLabel 
                            v-else-if="cell.type === 'label'"
                            :labelColor="cell?.color"
                            :isVertical="isUnderBreakpoint"
                        >
                            {{ cell.value }}    
                        </FlexTableLabel>
                        <FlexTableInternalLink 
                            v-else-if="cell.type === 'link'"
                            :text="cell.value"
                            :to="cell.to"
                        />
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script setup>
import { onMounted, watch, ref, onUnmounted } from 'vue';
import FlexTableButton from './FlexTableButton.vue';
import FlexTableLabel from './FlexTableLabel.vue';
import FlexTableText from './FlexTableText.vue';
import FlexTableInternalLink from './FlexTableInternalLink.vue';

const props = defineProps({
    headers: {
        type: Array,
        required: true,
    },
    data: {
        type: Array,
        required: false,
        default: []
    },
    breakpoint: {
        type: [Number, String],
        default: 768,
    },
});


const widowSize = ref(window.innerWidth);
const isUnderBreakpoint = ref(false);


const doesTheCellHaveTooltip = (cellLabel) => {
    const headers = props.headers;
    const header = headers.find(header => header.label.toLowerCase() == cellLabel.toLowerCase());
    const hasTooltip = header?.tooltip;
    return hasTooltip;
};


watch(widowSize, (newValue) => {
    const breakpointNumber = parseInt(props.breakpoint);
    if (newValue < breakpointNumber) {
        isUnderBreakpoint.value = true;
    } 
    if (newValue >= breakpointNumber) {
        isUnderBreakpoint.value = false;
    }
    
}, { immediate: true });
onMounted(() => {
    window.addEventListener('resize', () => {
        widowSize.value = window.innerWidth;
    })
});
onUnmounted(() => {
    window.removeEventListener('resize', () => {
        widowSize.value = window.innerWidth;
    })
});
</script>
<style lang="scss" scoped>
.flex-table {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__header {
        width: 100%;
        display: flex;
        justify-content: start;

        &__cell {
            font-weight: 500;
            color: $light-grey-2;
            text-transform: uppercase;
            font-size: 0.9rem;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            &:not(:last-child) {
                border-bottom: solid 1px $light-grey;
            } 

            &.vertical {
                flex-direction: column;
                background: $light-grey-extra;
                border: solid 1px $light-grey;
                border-radius: 0.5rem;
                padding: 15px;
                margin-top: 1rem;

                &:nth-child(odd) {
                    background: white;
                }
            }

            &__header {
                display: none;
                font-weight: bold;
            }

            &__cell {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                overflow: hidden;
             
                &.vertical {
                    overflow: visible;
                }
                
                &__label {
                    font-weight: bold;
                    font-style: italic;
                    width: 60%;
                    border-radius: 0.5rem;
                    
                }

                &__value {
                    width: 100%;
                }
            }
        }
    }

    .flex-table__header__cell, .flex-table__body__row__cell {
        display: flex;
        flex: 1 0 0;
        padding: 1.1rem 0.5rem;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
</style>