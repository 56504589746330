<template>
    <div class="row w-100 g-0 p-4">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                            <h2 class="mb-0 font-weight-bolder">Validation brief : {{ articleParams.title }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="card p-4">
                        <form autocomplete="off" v-on:submit.prevent>
                            <div>
                                <label for="title" required="required">Titre de l'article *</label>
                                <input v-model="articleParams.title" id="title" type="text" class="form-control">
                            </div>
                            <div class="d-flex gap-2">
                                <div class="col-6">
                                    <label for="primary-keyword">Mot clé principal *</label>
                                    <input v-model="articleParams.primaryKeyword" id="primary-keyword" type="text" class="form-control">
                                </div>
                                <div class="col-6">
                                    <label for="secondary-keyword">Mot clé secondaire</label>
                                    <input v-model="articleParams.secondaryKeyword" id="secondary-keyword" type="text" class="form-control">
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-content-between">
                                    <label for="customer">Client *</label>
                                    <div v-if="articleParams.customerUser.id !== ''" class="d-flex">
                                        <!-- CUSTOMER ARTICLES LIST -->
                                        <CustomerArticlesTinyList :key="articleParams.customerUser.id" :customerId="articleParams.customerUser.id" />
                                        <router-link target="_blank" :to="`/advisor/customer/${articleParams.customerUser.id}`">
                                            <div id="client-folder-button">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24"><circle cx="10" cy="8" r="4" fill="#94e4af"/><path fill="currentColor" d="M10.35 14.01C7.62 13.91 2 15.27 2 18v2h9.54c-2.47-2.76-1.23-5.89-1.19-5.99zm9.08 4.01c.36-.59.57-1.28.57-2.02c0-2.21-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4c.74 0 1.43-.22 2.02-.57L20.59 22L22 20.59l-2.57-2.57zM16 18c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2z"/></svg>
                                                <div>Dossier client</div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                                <input @keyup="filterUsersList('customersList')" v-model="articleParams.customerUser.displayValue" @focusin="customersList.show = true" @focusout="closeList('customersList')" id="customer" type="text" class="form-control">
                                <div v-if="customersList.show" class="datalist-container">
                                    <ul class="datalist">
                                        <li v-for="customer in customersList.filteredList" :key="customer._id" @click.once="setUserParams(customer, 'customer')">
                                            {{ `${customer.websiteUrl} / ${customer.fullName} - ${customer.email}` }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <label for="writer">Rédacteur *</label>
                                <input @keyup="filterUsersList('writersList')" v-model="articleParams.writerUser.displayValue" @focusin="writersList.show = true" @focusout="closeList('writersList')" id="writer" type="text" class="form-control">
                                <div v-if="writersList.show" class="datalist-container">
                                    <ul class="datalist">
                                        <li v-for="writer in writersList.filteredList" :key="writer._id" @click.once="setUserParams(writer, 'writer')">
                                            {{ `${writer.email} - ${writer.fullName}` }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <label for="editorial-notes">Notes</label>
                                <textarea v-model="articleParams.editorialNotes" name="editorial-notes" id="editorial-notes" cols="30" rows="10" class="form-control"></textarea>
                            </div>    
                            <div>
                                <label for="due-date">Date limite *</label>
                                <input v-model="articleParams.dueDate" type="date" name="due-date" id="due-date" class="form-control">
                            </div>
                            <div>
                                <label for="type">Type d'aticle</label>
                                <select v-model="articleParams.type" name="type" id="type" class="form-control">
                                    <option v-for="articleType in articleTypes" :key="articleType.value" :value="articleType.value">
                                        {{ articleType.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex align-items-center gap-1">
                                <label for="seo-suggestions" class="m-0">Activer les suggestions SEO</label>
                                <input v-model="articleParams.seoSuggestions" type="checkbox" name="seo-suggestions" id="seo-suggestions" class="m-0">
                            </div>
                            <div v-if="err.status === true || success.status === true" class="alert" :class="{'alert-danger': err.status, 'alert-success': success.status }">
                                <p v-if="err.status">{{ err.message }}</p> 
                                <p v-if="success.status">{{ success.message }}</p> 
                            </div>
                            <div id="buttons-container" class="d-flex align-items-center mt-3">
                                <soft-button id="create-button" @click.prevent="validateBrief" :disabled="loading || articleAlreadyBeingWriting">
                                    <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <div v-else>Envoyer en rédaction</div>
                                </soft-button>
                                <soft-button id="save-brief-button" color="secondary" @click="saveBrief" :disabled="saveBriefLoading">
                                    <div v-if="saveBriefLoading" class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <div>Sauvegarder</div>
                                </soft-button>
                                <soft-button @click="deleteBrief" color="danger">
                                    Supprimer le brief
                                </soft-button>
                                <div id="required"><span>*</span> Champs requis</div>
                            </div>	
                        </form>
                    </div>
                </div>    
            </div>
        </div>
     </div>
</template>
<script setup>
    import SoftButton from "@/components/SoftButton.vue";
    import { onMounted, ref } from "vue"; 
    import axios from 'axios';
    import Cookies from "js-cookie";
    import { useRoute, useRouter } from "vue-router";
    import CustomerArticlesTinyList from "@/components/services/CustomerArticlesTinyList.vue";
    import articleTools from "@/tools/articleTools.js";
    const axiosConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        "Content-Type": "application/json"
    };
    const route = useRoute();
    const router = useRouter();
    // STATE

    const err = ref({
        status: false,
        message: ""
    });

    const success = ref({
        status: false,
        message: ""
    });
    const articleTypes = articleTools.getArticleTypes();
    const loading = ref(false);
    const saveBriefLoading = ref(false);
    const articleAlreadyBeingWriting = ref(false);

    const customersList = ref({
        show: false,
        list: [],
        filteredList: []
    });

    const writersList = ref({
        show: false,
        list: [],
        filteredList: []
    });

    const articleParams = ref({
        title: "",
        primaryKeyword: "",
        secondaryKeyword: "",
        customerUser: {
            displayValue: "",
            id: ""
        },
        writerUser: {
            displayValue: "",
            id: ""
        },
        editorialNotes: "",
        dueDate: "",
        type: "Externe",
        seoSuggestions: false,
    });

    // METHODS
    const getArticleData = async() => {
        try {
            loading.value = true;
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/admin/articles/get-one/${route.params.id}`,
                headers: axiosConfig
            });
            if (response.status === 200) {
                const data  = response.data;
                articleParams.value.title = data.title;
                articleParams.value.primaryKeyword = data.keywords.primary;
                articleParams.value.secondaryKeyword = data.keywords.secondary;
                articleParams.value.editorialNotes = data.editorialNotes;
                articleParams.value.dueDate = formatISODateToCalendarFormat(data.dueDate);
                articleParams.value.type = data.type;
                articleParams.value.status = data.status;
                const customer =  customersList.value.list.find(customer => customer._id === data.customerUser);
                const writer = writersList.value.list.find(writer => writer._id === data.writerUser);
                
                if (writer) articleAlreadyBeingWriting.value = true;
                else articleAlreadyBeingWriting.value = false;

                if (customer) {
                    setUserParams(customer, "customer");
                }
                if (writer) {
                    setUserParams(writer, "writer");
                }
               
            }
        } catch (error) {
            if (error.response.status === 400) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue, merci de réessayer plus tard ou de contacter un administrateur si le problème persiste";
            }
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };

    const setUserParams = (user, userType) => {
        switch (userType) {
            case "customer":
                articleParams.value.customerUser.displayValue = `${user.websiteUrl} - ${user.fullName}`;
                articleParams.value.customerUser.id = user._id;
            break;
            case "writer":
                articleParams.value.writerUser.displayValue = user.fullName;
                articleParams.value.writerUser.id = user._id;
            break;
        }
    };

    const closeList = (listName) => {
        setTimeout(() => {
            switch (listName) {
                case "customersList":
                    customersList.value.show = false;
                break;
                case "writersList":
                    writersList.value.show = false;
                break;
            } 
        }, 250);
    };
    
    const getCustomersList = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/admin/users/get-all?rankLevel=client`,
                headers: axiosConfig
            });
            if (response.status === 200) {
                customersList.value.list = response.data;
                customersList.value.filteredList = response.data;
            }
        } catch (error) {
            err.value.message = "Une erreur est survenue durant la récupérations des clients...";
            err.value.status = true;
        }
    };

    const getWritersList = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.VUE_APP_SERVER}/admin/users/get-all?rankLevel=staff`,
                headers: axiosConfig
            });
            if (response.status === 200) {
                writersList.value.list = response.data;
                writersList.value.filteredList = response.data;
            }
        } catch (error) { 
            err.value.message = "Une erreur est survenue durant la récupérations des rédacteurs...";
            err.value.status = true;
        }
    };

    const filterUsersList = (type) => {
        let filteredList = [];
        switch (type) {
            case "customersList":
                articleParams.value.customerUser.id = "";
                filteredList = customersList.value.list.filter((user) => {
                    return user.fullName.toLowerCase().includes(articleParams.value.customerUser.displayValue.toLowerCase()) ||
                        user.websiteUrl?.toLowerCase().includes(articleParams.value.customerUser.displayValue.toLowerCase()) ||
                        user.email?.toLowerCase().includes(articleParams.value.customerUser.displayValue.toLowerCase());
                });
                customersList.value.filteredList = filteredList;
            break;
            case "writersList":
                articleParams.value.writerUser.id = "";
                filteredList = writersList.value.list.filter((user) => {
                    return user.fullName.toLowerCase().includes(articleParams.value.writerUser.displayValue.toLowerCase()) ||
                        user.email?.toLowerCase().includes(articleParams.value.writerUser.displayValue.toLowerCase());
                });
                writersList.value.filteredList = filteredList;
            break;  
        }
    };
    const validateBrief = async () => {
        try {
            loading.value = true;
            err.value.status = false;
            success.value.status = false;
            const response = await axios({
                method: "PATCH",
                url: `${process.env.VUE_APP_SERVER}/admin/articles/validate-brief`,
                headers: axiosConfig,
                data: {
                    articleId: route.params.id,
                    title: articleParams.value.title,
                    primaryKeyword: articleParams.value.primaryKeyword,
                    secondaryKeyword: articleParams.value.secondaryKeyword,
                    customerUser: articleParams.value.customerUser.id,
                    writerUser: articleParams.value.writerUser.id,
                    dueDate: articleParams.value.dueDate,
                    type: articleParams.value.type,
                    editorialNotes: articleParams.value.editorialNotes,
                    seoSuggestions: articleParams.value.seoSuggestions
                }
            });
            if (response.status === 201) {
                success.value.status = true;
                success.value.message = response.data.message;
                router.push("/admin/articles/brief-validation-list");
            }
        } catch (error) {
            if (error.response.status === 400) {
                err.value.message = error.response.data.error;
            } else {
                err.value.message = "Une erreur est survenue, merci de réessayer plus tard ou de contacter un administrateur si le problème persiste";
            }
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };
    const formatISODateToCalendarFormat = (providedDate) => {
        const date = new Date(providedDate);
        const year = date.getFullYear();
        let month = date.getMonth()+1;
        let dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year+'-' + month + '-'+dt;
    };
    const saveBrief = async () => {
        try {
            saveBriefLoading.value = true;
            err.value.status = false;
            const article = articleParams.value;
            console.log(article);
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/articles/brief/update-core`, {
                method: "PATCH",
                headers: axiosConfig,
                body: JSON.stringify({
                    articleId: route.params.id,
                    title: article.title,
                    primaryKeyword: article.primaryKeyword,
                    secondaryKeyword: article.secondaryKeyword,
                    type: article.type,
                    editorialNotes: article.editorialNotes,
                    dueDate: formatISODateToCalendarFormat(article.dueDate),
                    customerUserId: article.customerUser.id,
                    seoSuggestions: article.seoSuggestions,
                    writerUser: articleParams.value.writerUser.id,
                })
            });
            
            const response = await promise.json();
            

            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                } 
            }

            getArticleData();
        } catch (error) {
            if (error.status === 404 || 401 || 400) err.value.message = error.error;
            else err.value.message = "Une erreur est survenue";
            err.value.status = true;
        } finally {
            saveBriefLoading.value = false;
        }
    };
    const deleteBrief = async () => {
        try {
            const warningInfo = "Souhaitez-vous vraiment supprimer ce brief d'article ? Cette action est irréversible !";
            if(window.confirm(warningInfo)) {
                const promise = await fetch(`${process.env.VUE_APP_SERVER}/articles/brief/delete`, {
                    method: "DELETE",
                    headers: axiosConfig,
                    body: JSON.stringify({
                        articleId: route.params.id
                    })
                });

                const response = await promise.json();

                if (promise.status !== 200) {
                    throw {
                        status: promise.status,
                        ...response
                    }
                }   
                
                router.push("/admin/articles/brief-validation-list");
            } else return;
        } catch (error) {
            if (error.status === 404 || 401 || 400) err.value.message = error.error;
            else err.value.message = "Une erreur est survenue";
            err.value.status = true;
        }
    };

    onMounted(async() => {
        await getCustomersList();
        await getWritersList();
        await getArticleData();
    });

</script>
<style scoped>
    input, select, textarea {
        margin-bottom: 1rem;
    }
    .datalist-container {
        position: relative;
        width: 100%;
    }
    .datalist {
        background-color: white;
        position: absolute;
        width: 100%;
        border-radius: 10px;
        padding: 0;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        max-height: 600px;
        overflow: auto;
    }
    .datalist > li {
        list-style: none;
        padding: 10px;
    }
    .datalist > li:hover {
        cursor: pointer;
        background-color: #94E4AF;
    }
    .datalist > li:first-child {
        border-radius: 10px 10px 0 0;
    }
    .datalist > li:last-child {
        border-radius: 0 0 10px 10px;
    }
    .datalist > li:only-child {
        border-radius: 10px 10px 10px 10px;
    }
    #save-brief-button {
        display: flex;
    }
    #save-brief-button > div:first-child {
        margin-right: 5px;
    }
    #required {
        font-size: 12px;
    }
    #required > span {
        font-weight: bold;
        font-size: 0.75rem;
    }
    #view-client {
        height: 12px;
        margin: 0;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 20px;
        width: calc(100% - 20px) ;
        height: 38px;
        cursor: pointer;
        color: transparent;
        background: transparent;
    }
    #client-folder-button {
        display: flex;
        align-items: center;
        width: max-content;
        margin-left: 1rem;
    }
    #buttons-container {
        flex-wrap: wrap;
    }
    #buttons-container button {
        margin-right: 1rem;
        margin-top: 0.5rem;
    }
</style>