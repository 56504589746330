<template>
    <router-link to="/my-account/settings">
        <button class="top-right-bar transparent-button">
            <div class="top-right-bar__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16"><path fill="currentColor" d="M6 9a2 2 0 0 1 1.937 1.5H13.5a.5.5 0 0 1 .09.992l-.09.008l-5.563.001a2 2 0 0 1-3.874 0L2.5 11.5a.5.5 0 0 1-.09-.992l.09-.008h1.563A2 2 0 0 1 6 9m4-6a2 2 0 0 1 1.937 1.5H13.5a.5.5 0 0 1 .09.992l-.09.008l-1.563.001a2 2 0 0 1-3.874 0L2.5 5.5a.5.5 0 0 1-.09-.992L2.5 4.5h5.563A2 2 0 0 1 10 3"/></svg>
            </div>
        </button>
    </router-link>
</template>
<script setup>
</script>
<style lang="scss" scoped>
.top-right-bar {
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.2rem 0.4rem;
    font-size: 0.94rem;
    font-weight: 600;
    z-index: 1000;
    color: $primary-blue-color;
    
    &:hover {
        color: $primary-color;
    }
}
</style>