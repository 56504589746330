<template>
    <button @click="action" class="left-arrow-button" :style="style()" :disabled="disabled">
        <svg xmlns="http://www.w3.org/2000/svg"  :width="width" :height="width" viewBox="0 0 24 24"><path fill="currentColor" d="m7.825 12l3.875 3.9q.275.275.288.688t-.288.712q-.275.275-.7.275t-.7-.275l-4.6-4.6q-.15-.15-.213-.325T5.426 12q0-.2.063-.375T5.7 11.3l4.6-4.6q.275-.275.688-.287t.712.287q.275.275.275.7t-.275.7L7.825 12Zm6.6 0l3.875 3.9q.275.275.288.688t-.288.712q-.275.275-.7.275t-.7-.275l-4.6-4.6q-.15-.15-.213-.325T12.026 12q0-.2.063-.375t.212-.325l4.6-4.6q.275-.275.688-.287t.712.287q.275.275.275.7t-.275.7L14.425 12Z"/></svg>
    </button>
</template>
<script setup>
    import { createDarkerColorFromHexOrRgb } from '@/tools/colorTools';
    import styles from '@/assets/scss/exports/main-variables.module.scss';
  
    // PROPS
    const props = defineProps({
        isOpen: {
            type: Boolean,
            required: false
        },
        width: {
            type: [ Number, String ],
            required: false,
        },
        color: {
            type: String,
            required: false,
        },
        action: {
            type: Function,
            required: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    });
    const style = () => {
        const { width, color } = props;
        const style = {
            'width': width || '32px',
            'height': width || '32px',
            'color': color || styles.mainColor,
            '--hover-color': createDarkerColorFromHexOrRgb(color || styles.mainColor, 30),
        };
        return style;
    };
</script>
<style lang="scss" scoped>
    :root {
        --hover-color: #defaultColor;
    }
    .left-arrow-button {
        border: none;
        padding: 0;
        width: fit-content;
        background-color: transparent;
        border-radius: 100%;
        
        :hover {
            color: var(--hover-color);
        }
    }
</style>