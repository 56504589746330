<template>
    <div class="social-network-publication-card-container">
        <ItemCardLayout class="social-networks-publication-card">
            <div class="social-networks-publication-card__visual">
                <div v-if="publication.images.length" class="social-networks-publication-card__visual__images">
                    <PublicationCardImages :images="publication.images" />
                </div>
                <div v-else-if="publication.video?.link" class="social-networks-publication-card__visual__video">
                    <PublicationCardVideo :video="publication.video" />
                </div>
                <div v-else class="social-networks-publication-card__visual__no-visual">
                    <p>Aucun visuel</p>
                </div>
            </div>
            <div class="social-networks-publication-card__networks">
                <PublicationCardNetworksList :networks="publication.networks" />
            </div>
            <div class="social-networks-publication-card__content">
                <PublicationCardContent :content="publication.content" :readMore="true"/>
            </div>
            <div class="social-networks-publication-card__week">
                <PublicationCardWeek :week="publication.week" />
            </div>
            <div class="social-networks-publication-card__view-publication">
                <p @click="togglePublicationExpansion">Voir la publication</p>
            </div>
        </ItemCardLayout>
        <div class="social-network-publication-card-container__label">
            <PublicationCardLabel @click="toggleActionMenu" :status="publication.status" :stringStatus="publication.stringStatus"/>
        </div>
        <transition name="action-menu">
            <div v-if="actionMenuOpened && publication.status === 0" class="social-network-publication-card-container__actions-menu">
                <PublicationCardActionMenu 
                    @approvePublication="approvePublication" 
                    @openCorrectionRequest="toggleCorrectionRequestDisplay" 
                    :publication="publication" 
                    :approvePublication="approvePublication"
                />
            </div>
        </transition>
    </div>
    <transition name="expanded">
        <div v-if="correctionRequestOpened" class="social-network-publication-card-expanded-correction-request">
            <PublicationCardCorrectionRequest 
                @correctionRequestSended="reload" 
                @close="toggleCorrectionRequestDisplay" 
                :publication="publication" 
            />
        </div>
    </transition>
    <transition name="expanded">
        <div v-if="publicationExpanded" class="social-network-publication-card-expanded">
            <MySocialNetworksPublicationCardExpanded 
                @close="togglePublicationExpansion" 
                @correctionRequest="toggleCorrectionRequestDisplay"
                @approvePublication="approvePublication"
                :publication="publication" 
                :approvePublication="approvePublication"
            />
        </div>
    </transition>
    <div v-show="correctionRequestOpened || publicationExpanded" class="social-network-publication-card-expanded-background"></div>
</template>
<script setup>
import { ref, defineEmits, onMounted } from 'vue';
import { approveSocialNetworkPublication } from '@/useCases/socialNetworkPublicationUseCases';
import MySocialNetworksPublicationCardExpanded from './MySocialNetworksPublicationCardExpanded.vue';
import PublicationCardCorrectionRequest from './components/PublicationCardCorrectionRequest.vue';
import PublicationCardNetworksList from './components/PublicationCardNetworksList.vue';
import PublicationCardActionMenu from './components/PublicationCardActionMenu.vue';
import ItemCardLayout from '@/components/layouts/cardsLayouts/ItemCardLayout.vue';
import PublicationCardContent from './components/PublicationCardContent.vue';
import PublicationCardImages from './components/PublicationCardImages.vue';
import PublicationCardVideo from './components/PublicationCardVideo.vue';
import PublicationCardLabel from './components/PublicationCardLabel.vue';
import PublicationCardTitle from './components/PublicationCardTitle.vue';
import PublicationCardWeek from './components/PublicationCardWeek.vue';
import { displayConfirmationMessage } from '@/tools/modalTools';

const props = defineProps({
    publication: {
        type: Object,
        required: true
    }
});
const emits = defineEmits([
    'reload'
]);
const publicationExpanded = ref(false);
const actionMenuOpened = ref(false);
const correctionRequestOpened = ref(false);
const expandedLoaded = ref(false);

const togglePublicationExpansion = () => {
    publicationExpanded.value = !publicationExpanded.value;
    actionMenuOpened.value === false || toggleActionMenu();
};
const toggleActionMenu = () => {
    actionMenuOpened.value = !actionMenuOpened.value;
};
const toggleCorrectionRequestDisplay = () => {
    publicationExpanded.value === false || togglePublicationExpansion();
    correctionRequestOpened.value = !correctionRequestOpened.value;
    actionMenuOpened.value === false || toggleActionMenu();
};
const approvePublication = async() => {
    try {
        toggleActionMenu();
        const publicationId = props.publication._id;
        const confirmationMessage = 'Êtes-vous sûr de vouloir approuver cette publication ?';
        const isConfirmed = await displayConfirmationMessage(confirmationMessage);
        if (!isConfirmed) return;
        await approveSocialNetworkPublication(publicationId);
        emits('reload');
    } catch (error) {
        console.log(error);
    }
};
const reload = () => {
    emits('reload');
};

</script>
<style lang="scss" scoped>
    .social-network-publication-card-container {
        position: relative;
        transition: 0.4s ease-out;
        
        &:hover {
            transform: translate(0, -10px);
        }
        .social-networks-publication-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 480px;
            width: 290px;
            overflow: hidden;
            position: relative;
            padding-bottom: 0.4rem;

            &__visual {
                height: 50%;
                overflow: hidden;
                
                &__images {
                    height: 100%;
                }
                &__video {
                    height: 100%;
                }
                &__no-visual {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                }
            }

            &__week {
                height: 5%;
                padding: 0.4rem;
            }

            &__content {
                height: 28%;
                overflow: hidden;
            }

            &__networks {
                display: flex;
                justify-content: center;
                align-items: center;
                height: fit-content;
            }

            &__view-publication {
                height: 5%;
                display: flex;
                justify-content: center;

                p {
                    margin: 0;
                    font-size: 0.9rem;
                    text-decoration: underline;
                    transition: linear 0.2s, background-position-x 1s;

                    &:hover {
                        cursor: pointer;
                        color: $main-color;
                    }
                }
            }

        }

        &__label {
            position: absolute;
            top: -20px;
            right: -20px;
            z-index: 100;
        }
        
        &__actions-menu {
            position: absolute;
            top: -24px;
            right: 30px;
            z-index: 100;
        }
    }

    .social-network-publication-card-expanded {
        position: fixed;
        z-index: 1001;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100dvw;
        height: 100dvh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .social-network-publication-card-expanded-correction-request {
        position: fixed;
        z-index: 1001;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100dvw;
        height: 100dvh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .social-network-publication-card-expanded-background {
        position: fixed;
        left: 0;
        top: 0;
        height: 100dvh;
        width: 100dvw;
        background-color: rgb(51 51 51 / 39%);
        z-index: 1000;
        backdrop-filter: blur(5px);
    }
    .action-menu-enter-from {
        opacity: 0;
        right: -20px;
    }
    .action-menu-enter-to {
        opacity: 1;
    }
    .action-menu-enter-active {
        transition: all 0.3s ease;
    }
    .action-menu-leave-from {
        opacity: 1;
    }
    .action-menu-leave-to {
        opacity: 0;
    }
    .action-menu-leave-active {
        transition: all 0.3s ease;
    }

    .expanded-enter-from {
        opacity: 0;
        top: 100%;
    }
    .expanded-enter-to {
        opacity: 1;
        bottom: 0;
    }
    .expanded-enter-active {
        transition: all 0.3s ease;
    }
    .expanded-leave-from {
        opacity: 1;
    }
    .expanded-leave-to {
        opacity: 0;
        top: 0;
    }
    .expanded-leave-active {
        transition: all 0.3s ease;
    }
</style>