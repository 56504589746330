<template>
    <div class="social-network-publication-editor">
        <div class="social-network-publication-editor__header">
            <LeftArrowButton 
                @click="$emit('close', publication._id)" 
                :color="styles.grey1"
                width="36px"
            />
            <EditorCustomerDetails 
                :customerDetails="customerDetails" 
            />
        </div>
        <div v-if="publication._id" class="social-network-publication-editor__body">
            <div class="social-network-publication-editor__body__editor">
                <QuillEditor 
                    @click="selectArea" 
                    v-model:content="publication.content" 
                    style="background-color: white; min-height: 200px;" 
                    id="editor" 
                    content-type="html" 
                    theme="snow" 
                    toolbar="essential" 
                    :read-only="readOnly"
                />
            </div>
            <div v-if="publication.correctionNotes && publication.status === 3"  class="social-network-publication-editor__body__correction-note">
                <EditorCorrectionNote 
                    :correctionNote="publication.correctionNotes"
                />
            </div>
            <div class="social-network-publication-editor__body__networks">
                <SocialNetworkPublicationNetworks 
                    @dataChange="handleNetworksChange"
                    :selectedNetworks="publication.networks"
                    :readOnly="readOnly" 
                />
            </div>
            <div class="social-network-publication-editor__body__week">
                <SocialNetworkPublicationWeek 
                    @dataChange="handleWeekChange"
                    :selectedWeek="publication.week"
                    :readOnly="readOnly" 
                />
            </div>
            <div class="social-network-publication-editor__body__visual">
                <SocialNetworkPublicationVisual 
                    @dataChange="handleVisualChange"
                    @typeChange="handleVisualTypeChange"
                    :images="publication?.images"
                    :video="publication?.video"
                    :readOnly="readOnly"
                />
            </div>
            <EmbeddedErrorVue 
                v-if="savePublicationStatus.error"
                :message="savePublicationStatus.errorMessage"
            />
            <div  class="social-network-publication-editor__body__buttons">
                <CustomClassicButton 
                    v-if="publication.status === 3 && !readOnly" 
                    @click="savePublication({ isCorrectionValidation: true })"
                    :color="styles.warningColor"
                    :loading="savePublicationStatus.correctionLoading"
                >
                    Valider la correction
                </CustomClassicButton>
                <CustomClassicButton
                    v-if="!readOnly" 
                    @click="savePublication"
                    :loading="savePublicationStatus.loading"
                >
                    Sauvegarder
                </CustomClassicButton>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import LeftArrowButton from '@/components/standalone/customsButtons/LeftArrowButton.vue';
import EditorCustomerDetails from './components/EditorCustomerDetails.vue';
import styles from '@/assets/scss/exports/main-variables.module.scss';
import { fetchUserById } from '@/useCases/userUseCases';
import { fetchOneSocialNetworkPublicationById, updateSocialNetworkPublication } from '@/useCases/socialNetworkPublicationUseCases';
import SocialNetworkPublicationNetworks from '../SocialNetworkPublicationComponents/SocialNetworkPublicationNetworks.vue';
import SocialNetworkPublicationWeek from '../SocialNetworkPublicationComponents/SocialNetworkPublicationWeek.vue';
import EditorCorrectionNote from './components/EditorCorrectionNote.vue';
import SocialNetworkPublicationVisual from '../SocialNetworkPublicationComponents/SocialNetworkPublicationVisual.vue';
import CustomClassicButton from '@/components/standalone/customsButtons/CustomClassicButton.vue';
import EmbeddedErrorVue from '@/components/standalone/statusMessages/errors/EmbeddedError.vue';
import { QuillEditor } from '@vueup/vue-quill';
import { generateImageFileFromBase64Image } from '@/tools/mediaTools';
import { displayConfirmationMessage } from '@/tools/modalTools';

const emit = defineEmits(['close']);
const props = defineProps({
    readOnly: {
        type: Boolean,
        default: false
    },
    publicationId: {
        type: String,
        required: true
    }
});
const customerDetails = ref({});
const publication = ref({
    _id: '',
    content: '',
    customerUser: '',
    networks: [],
    week: '',
    status: 0,
    images: [],
    video: {
        link: '',
        description: ''
    },
    correctionNotes: ''
});
const revisedPublication = ref({});
const savePublicationStatus = ref({
    error: false,
    errorMessage: '',
    loading: false,
    correctionLoading: false
});
const tempVisual = ref({
    type: '',
    images: [],
    video: null
});

const handleNetworksChange = (networks) => {
    revisedPublication.value.networks = networks;
};
const handleWeekChange = (week) => {
    revisedPublication.value.week = week;
};
const handleVisualTypeChange = (type) => {
    tempVisual.value.type = type;
};
const handleVisualChange = (visualData) => {
    const visualType = tempVisual.value.type;
    switch (visualType) {
        case 'image':
            tempVisual.value.images = visualData.images;
        break;
        case 'video':
            tempVisual.value.video = visualData.video;
        break;
    }
};

const getCustomerDetails = async () => {
    try {
        const customerDetailsData = await fetchUserById(publication.value?.customerUser);
        customerDetails.value = customerDetailsData;
    } catch (error) {
        console.log(error);
    }
};
const getPublication = async () => {
    try {
        const publicationData = await fetchOneSocialNetworkPublicationById(props.publicationId);
        publication.value = publicationData;
        revisedPublication.value._id = publicationData._id;
    } catch (error) {
        console.log(error);
    }
};
const cleanUnusedPublicationVisual = () => {
    const visualType = tempVisual.value.type;
    switch (visualType) {
        case 'image':
            revisedPublication.value.video = null;
            revisedPublication.value.thumbnail = null;
        break;
        case 'video':
            revisedPublication.value.images = [];
        break;
        case 'none':
            revisedPublication.value.images = [];
            revisedPublication.value.video = null;
            revisedPublication.value.thumbnail = null;
        break;
    }
};
const assignEmptyPublicationData = () => {
    try {
        const originalData = publication.value;
        const revisedData = revisedPublication.value;
        for (const key in originalData) {
            if (['_id', 'networks', 'week', 'content'].includes(key) && !revisedData[key]) {
                revisedPublication.value[key] = originalData[key];
            } 
        }
    } catch (error) {
        throw error;
    }
};

const checkPublicationData = () => {
    const data = revisedPublication.value;
    const tempVisualData = tempVisual.value;
    if (data.networks.length === 0) throw { 
        error: "Vous devez sélectionner au moins un canal" 
    }
    if (!data.week) throw {
        error: "Veuillez selectionner une semaine"
    }
    if (tempVisualData.type === 'image' && tempVisualData.images.length === 0) throw {
        error: "Vous devez sélectionner au moins une image"
    }
    if (tempVisualData.type === 'video' && !tempVisualData.video) throw {
        error: "Vous devez sélectionner une vidéo"
    }
};

const savePublication = async ({ isCorrectionValidation = false }) => {
    try {
        const correction = isCorrectionValidation;

        assignEmptyPublicationData();
        checkPublicationData();
        if (tempVisual.value.type === 'image') {
            revisedPublication.value.images = tempVisual.value.images;
            revisedPublication.value.deleteAllVisuals = false;
        }
        else if (tempVisual.value.type === 'video') {
            revisedPublication.value.video = tempVisual.value.video;
            revisedPublication.value.thumbnail = await generateImageFileFromBase64Image(tempVisual.value.video);
            revisedPublication.value.deleteAllVisuals = false;
        }
        else if (tempVisual.value.type === 'none') {
            revisedPublication.value.deleteAllVisuals = true;
        }

        cleanUnusedPublicationVisual();
        
        if (correction) {
            const confirmationMessage = `Êtes-vous sûr de vouloir valider la correction ?`;
            const isConfirmed = await displayConfirmationMessage(confirmationMessage);
            if (!isConfirmed) {
                return;
            }
            revisedPublication.value.status = 0;
        }
        
        savePublicationStatus.value.error = false;
        correction ? savePublicationStatus.value.correctionLoading = true : savePublicationStatus.value.loading = true;
        await updateSocialNetworkPublication(revisedPublication.value);
        getPublication();
    } catch (error) {
        if (error.error) {
            savePublicationStatus.value.errorMessage = error.error;
        } else {
            savePublicationStatus.value.errorMessage = 'Une erreur est survenue lors de la sauvegarde de la publication';
        }
        savePublicationStatus.value.error = true;
    } finally {
        savePublicationStatus.value.loading = false;
        savePublicationStatus.value.correctionLoading = false;
        
    }
};


const selectArea = () => {
    const area = document.querySelector("#editor .ql-editor");
    area.focus();
};

onMounted(async() => {
    await getPublication();
    await getCustomerDetails();
});
</script>
<style lang="scss" scoped>
.social-network-publication-editor {
    height: 100%;
    padding: $card-container-padding;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__body {
        position: relative;
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;

        &__editor {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-height: 250px;
            margin: 1rem 0;
            
        }

        &__correction-note {
            position: absolute;
            top: -0.8rem;
            right: 0;
        }

        &__buttons {
            width: 100%;
            bottom: 0;
            display: flex;
            justify-content: flex-end;
            gap: 0.5rem;
        }
    }
}

</style>