"use strict"
import csvDownload from 'json-to-csv-export';

const csvTools = {
    getInCsv: async (dataToReturn, fileName) => {
        const dataToConvert = {
            data: dataToReturn,
            filename: fileName ? fileName : "Données_CSV",
        }
        csvDownload(dataToConvert);
    }
};

export default csvTools;