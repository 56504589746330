<template>
    <ClassicPageLayout pageTitle="Messagerie réseaux sociaux" class="social-networks-inbox">
        <iframe v-if="source" :src="source"></iframe>
        <MainLoader v-else />
    </ClassicPageLayout>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { fetchUserMetricoolId } from '@/useCases/userUseCases.js';
import MainLoader from '@/components/standalone/loaders/MainLoader.vue';
import ClassicPageLayout from '@/components/layouts/pagesLayouts/ClassicPageLayout.vue';



const router = useRouter();
const source = ref('');

const getUserMetricoolId = async () => {
    try {
        const userMetricoolId = await fetchUserMetricoolId();
        if (!userMetricoolId) {
            router.push('/social-networks/subscribe')
        }
        source.value = `https://app.metricool.com/autoin/${userMetricoolId}?redirect=inbox`;
    } catch (error) {
        console.log(error);
    }
};

onMounted(async () => {
    await getUserMetricoolId();
});
</script>
<style lang="scss" scoped>
.social-networks-inbox {
    iframe {
        width: 100%;
        height: 90dvh;
        border-radius: 0.4rem;
    }
}
</style>