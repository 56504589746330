<template>
    <div class="card">
        <div class="card-header">
            <h5>AppCredits</h5>
        </div>
        <div class="card-body">
            <div v-if="err.status === true" id="error-container">
                <div>Une erreur est survenue...</div>
            </div>
            <div v-if="loading" id="spinner-container">
                <div class="spinner-border text-secondary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-if="!err.status && !loading" class="row">
                <div class="col-12 col-xxl-6">
                    <p><strong>Crédits mensuels: </strong>{{ credits.core }}</p>
                </div>
                <div class="col-12 col-xxl-6">
                    <p><strong>Crédits supplémentaires: </strong>{{ credits.added }}</p>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <p class="text-sm m-0">Les crédits AppCredits sont réinitialisés le 1er de chaque mois. Pour obtenir plus de crédits, veuillez contacter le service client.</p>
        </div>
    </div>
</template>
<script setup>
    import { ref, onMounted } from "vue";
    import Cookies from "js-cookie";

    // STATE
    const err = ref({
        status: false,
    });
    const loading = ref(true);
    const credits = ref({
        core: 0,
        added: 0
    });

    // METHODS
    const getKernelCredits = async () => {
        try {
            err.value.status = false;
            loading.value = true;

            const response = await fetch(`${process.env.VUE_APP_SERVER}/user/kernel-credits/get-current-balance`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${Cookies.get('token')}`
                }
            });

            const data = await response.json();
            credits.value = data.kernelCredits;
        } catch (error) {
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };

    // LIFECYCLE HOOKS
    onMounted(() => {
        getKernelCredits();
    });
</script>

<style scoped>
    #spinner-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #error-container {
        text-align: center;
    }
</style>