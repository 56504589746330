<template>
    <div class="text-editor">
        <div v-if="menu" class="text-editor__menu">
            <button v-for="(button, index) in buttonsList" :key="index" @click="button.command()" :class="{ 'is-active': editor?.isActive('bold') }">
                <span v-html="button.label()"></span>
            </button>
        </div>
        <EditorContent @click="selectTextArea" class="text-editor__content" :editor="editor"/>
    </div>
</template>
<script setup>
import { useEditor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import { sanitizeHtml } from '@/tools/htmlTools.js';
import { ref, onBeforeUnmount, defineEmits, watch } from 'vue';

const props = defineProps({
    placeholder: {
        type: String,
        required: false,
        default: 'Ecrivez votre texte ici...'
    },
    menu: {
        type: Boolean,
        required: false,
        default: false
    },
    readOnly: {
        type: Boolean,
        required: false,
        default: false
    },
    value: {
        type: String,
        required: false
    }
});
const emit = defineEmits(['returnContent']);
const editor = useEditor({
    extensions: [
        StarterKit,
        Placeholder.configure({
            placeholder: props.placeholder,
        }),
    ],
    injectCSS: false,
    content: '',
    editable: !props.readOnly,
    onUpdate: ({ editor }) => {
        const sanitizedHtml = sanitizeHtml(editor.getHTML());
        emit('returnContent', sanitizedHtml);
    },
});
const selectTextArea = () => {
    const area = document.querySelector(".text-editor__content > div");
    area.focus()
};
const buttonsList = ref([
    { 
        value: 'bold', 
        label: () => '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M7.877 18.25V5.75h4.198q1.433 0 2.529.904T15.7 9.006q0 .967-.508 1.693q-.507.726-1.257 1.064q.913.256 1.55 1.074q.638.817.638 1.97q0 1.61-1.202 2.527q-1.202.916-2.646.916H7.877Zm1.275-1.185h3.061q1.162 0 1.876-.699q.715-.699.715-1.628q0-.928-.715-1.627q-.714-.7-1.893-.7H9.152v4.654Zm0-5.815h2.863q.998 0 1.69-.617q.693-.618.693-1.546q0-.947-.704-1.552q-.704-.606-1.667-.606H9.152v4.321Z"/></svg>', 
        command: () => editor.value?.chain().focus().toggleBold().run() 
    },
    {
        value: 'italic',
        label: () => '',
        command: () => editor.value?.chain().focus().toggleItalic().run()
    }
]);

watch(() => props.value, (newValue) => {
    editor.value.commands.setContent(newValue);
});
onBeforeUnmount(() => {
    editor.value?.destroy();
});
</script>
<style lang="scss" scoped>
    .text-editor {
        height: 100%;
        width: 100%;

        &__menu {
            display: flex;
            justify-content: flex-start;
            gap: 0.2rem;
            margin-bottom: 0.2rem;
            
            button {
                background: none;
                border: 1px $grey-1 solid;
                cursor: pointer;
                padding: 0.2rem;
                border-radius: 0.4rem;
                transition: all 0.2s ease-in-out;
                &:hover {
                    background: $grey-1;
                }
            }
        }

        &__content {
            height: 90%;
            width: 100%;
            overflow-y: auto;
            padding: 1rem;
            border: 1px $grey-1 solid;
            border-radius: 0.4rem;
            margin: 0.8rem 0;

            &:deep(.tiptap) {
                &:focus-visible {
                    outline: none;
                }

                & p.is-editor-empty:first-child::before {
                    content: attr(data-placeholder);
                    float: left;
                    color: $grey-1;
                    pointer-events: none;
                    height: 0;
                }
            }
        }

       
    }
</style>