<template>
    <section id="customer-informations" class="card">
        <div class="card-header">
            <h6 class="text-center">Facturation</h6>
        </div>
        <hr class="mt-0 mb-0 horizontal dark">
        <!-- ADD BILLING AREA -->
        <div class="add-billing">
            <div @click="isAddBillingPopupOpen = !isAddBillingPopupOpen" id="add-billing-button">
                <svg v-if="!isAddBillingPopupOpen" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="#94E4AF" d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4v4Zm1 5q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"/></svg>
                <svg v-if="isAddBillingPopupOpen" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="currentColor" d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2zm-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8l-1.4 1.4z"/></svg>
            </div>
            <div v-show="isAddBillingPopupOpen" id="add-billing-form" class="card">
                <div class="card-body">
                    <div v-if="addBillingError.status" class="text-center text-danger">
                        {{ addBillingError.message }}
                    </div>
                    <form>
                        <div class="billing-input-container">
                            <label for="billing-name">Nom de la facture</label>
                            <input v-model="newBilling.name" id="billing-name" class="form-control" type="text">
                        </div>
                        <div class="billing-input-container">   
                            <label for="billing-link">Lien de la facture</label>
                            <input v-model="newBilling.link" id="billing-link" class="form-control" type="text">
                        </div>
                        <div id="billing-add-button-container">
                            <SoftButton @click.prevent="addNewInvoice">
                                Ajouter la facture
                            </SoftButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- END ADD BILLING AREA -->
        <div class="card-body">
            <div id="billing-filter-container">
                <div>
                    <input v-model="billingQueryFilter" type="text" name="billing-filter" id="billing-filter" class="form-control" placeholder="Nom complet ou partiel de la facture">
                </div>
                <SoftButton @click="getBillings" id="apply-billing-filter-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="currentColor" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.612 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3l-1.4 1.4ZM9.5 14q1.875 0 3.188-1.313T14 9.5q0-1.875-1.313-3.188T9.5 5Q7.625 5 6.312 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14Z"/></svg>
                </SoftButton>
                <SoftButton @click="resetBillingsFilter" id="reset-filter-button" color="muted">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="currentColor" d="M12 16c1.671 0 3-1.331 3-3s-1.329-3-3-3s-3 1.331-3 3s1.329 3 3 3z"/><path fill="currentColor" d="M20.817 11.186a8.94 8.94 0 0 0-1.355-3.219a9.053 9.053 0 0 0-2.43-2.43a8.95 8.95 0 0 0-3.219-1.355a9.028 9.028 0 0 0-1.838-.18V2L8 5l3.975 3V6.002c.484-.002.968.044 1.435.14a6.961 6.961 0 0 1 2.502 1.053a7.005 7.005 0 0 1 1.892 1.892A6.967 6.967 0 0 1 19 13a7.032 7.032 0 0 1-.55 2.725a7.11 7.11 0 0 1-.644 1.188a7.2 7.2 0 0 1-.858 1.039a7.028 7.028 0 0 1-3.536 1.907a7.13 7.13 0 0 1-2.822 0a6.961 6.961 0 0 1-2.503-1.054a7.002 7.002 0 0 1-1.89-1.89A6.996 6.996 0 0 1 5 13H3a9.02 9.02 0 0 0 1.539 5.034a9.096 9.096 0 0 0 2.428 2.428A8.95 8.95 0 0 0 12 22a9.09 9.09 0 0 0 1.814-.183a9.014 9.014 0 0 0 3.218-1.355a8.886 8.886 0 0 0 1.331-1.099a9.228 9.228 0 0 0 1.1-1.332A8.952 8.952 0 0 0 21 13a9.09 9.09 0 0 0-.183-1.814z"/></svg>
                </SoftButton>
            </div>
            <div id="memos-container">
                <div v-if="err.status === true" id="error-container">
                    <div>{{ err.message }}</div>
                </div>
                <div v-if="loading" id="spinner-container">
                    <div class="spinner-border text-secondary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-if="!loading && !err.status" class="table-responsive">
                    <table class="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th width="50%" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Nom facture</th>
                                <th width="41%" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Date de mise en ligne</th>
                                <th width="3%"></th>
                                <th width="3%"></th>
                                <th width="3%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(billing, index) in billingsList" :key="index">

                                <!-- INPUT NAME SHOW IF IN EDITING MODE -->
                                <td v-if="editing === billing._id" width="50%">
                                    <label>Nom de la facture</label>
                                    <input v-model="billing.name" type="text" class="form-control">
                                </td>
                                <td v-else width="50%">{{ billing.name }}</td>

                                <!-- DATE SHOW IF IN EDITING MODE -->
                                <td v-if="editing !== billing._id" width="40%" class="text-center">{{ getDate(billing.createdAt) }}</td>

                                <!-- LINK INPUT SHOW IF EDITING MODE -->
                                <td v-if="editing === billing._id" width="40%">
                                    <label>Lien de la facture</label>
                                    <input v-model="billing.link" type="text" class="form-control">
                                </td>

                                <!-- EDITING BUTTON HIDE IF IN EDITING MODE -->
                                <td v-if="editing !== billing._id">
                                    <svg @click="selectInvoiceForEditing(billing._id)" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"><path fill="#94e4af" d="m19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6l4.25 4.25Z"/></svg>
                                </td>
                                <!-- LINK BUTTON HIDE IF IN EDITING MODE -->
                                <td v-if="editing !== billing._id">
                                    <a :href="`${billing.link}`" target="_blank" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"><path fill="#94e4af" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>                                        
                                    </a>
                                </td>
                                <!-- DELETE BUTTON HIDE IF IN EDITING MODE -->
                                <td v-if="editing !== billing._id">
                                    <div @click="deleteInvoice(billing._id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"><path fill="#94e4af" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12l1.41 1.41L13.41 14l2.12 2.12l-1.41 1.41L12 15.41l-2.12 2.12l-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/></svg>
                                    </div>
                                </td>
                                <!-- SAVE BUTTON SHOW IF IN EDITING MODE -->
                                <td class="text-center" v-if="editing === billing._id">
                                    <svg @click="updateInvoice(billing)" id="save-icon" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 15 15"><path fill="#94e4af" d="M0 1.5A1.5 1.5 0 0 1 1.5 0h8.586a1.5 1.5 0 0 1 1.06.44l3.415 3.414A1.5 1.5 0 0 1 15 4.914V13.5a1.5 1.5 0 0 1-1.5 1.5H11v-3.5A1.5 1.5 0 0 0 9.5 10h-4A1.5 1.5 0 0 0 4 11.5V15H1.5A1.5 1.5 0 0 1 0 13.5v-12Z"/><path fill="currentColor" d="M5 15h5v-3.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5V15Z"/></svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import SoftButton from "@/components/SoftButton.vue";
    import Cookies from "js-cookie";

    const fetchConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        "Content-Type": "application/json",
    };

    //PROPS
    const props = defineProps({
        customerId: {
            type: String,
            required: true
        }
    });

    // STATE
    const err = ref({
        status: false,
        message: ""
    });
    const addBillingError = ref({
        status: false,
        message: ""
    });
    const loading = ref(false);
    const isAddBillingPopupOpen = ref(false);
    const newBilling = ref({
        name: "",
        link: ""
    });
    const billingsList = ref([]);
    const billingQueryFilter = ref("");
    const editing = ref("");

    // METHODS
    const getBillings = async () => {
        try {
            err.value.status = false;
            loading.value = true;

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/billings/get-all?customerRoute=false&customerId=${props.customerId}&queryFilter=${billingQueryFilter.value}&limit=36`, {
                method: "GET",
                headers: fetchConfig
            });

            const response = await promise.json();

            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }
            if (response.billings.length === 0) {
                err.value.message = "Aucune facture trouvée pour cet utilisateur";
                err.value.status = true;
            }
            billingsList.value = response.billings;
            
        } catch (error) {
            if (error.status === 401 || 404 || 400) {
                err.value.message = error.error;
            }
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    };
    const addNewInvoice = async () => {
        try {
            addBillingError.value.status = false;

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/billings/create`, {
                method: "POST",
                headers: fetchConfig,
                body: JSON.stringify({
                    userId: props.customerId,
                    billingName: newBilling.value.name,
                    billingLink: newBilling.value.link
                })
            });

            if (promise.status !== 201) {
                throw { 
                    status: promise.status,
                    ...await promise.json()
                };
            }
            
            newBilling.value = {
                name: "",
                link: ""
            };
            isAddBillingPopupOpen.value = false;
            getBillings();
        } catch (error) {
            if (error.status === 400 || 404) {;
                addBillingError.value.message = error.error;
            }
            addBillingError.value.status = true;
            
        }
    };
    const deleteInvoice = async (invoiceId) => {
        try {
            const textConfirmation = ("Souhaitez vous vraiment supprimer cette facture ? Attention, cette action est irréversible");

            if (window.confirm(textConfirmation)) {
                const promise = await fetch(`${process.env.VUE_APP_SERVER}/billings/delete`, {
                    method: "DELETE",
                    headers: fetchConfig,
                    body: JSON.stringify({
                        billingId: invoiceId
                    })
                });

                const response = await promise.json();

                if (promise.status !== 200) {
                    throw {
                        status: promise.status,
                        ...response
                    }
                }
                getBillings();
            }
        } catch (error) {
            if (error.status === 400) err.value.message = error.error;
            else err.value.message = "Une erreur est survenue";
            err.value.status = true;
        }
    };
    const selectInvoiceForEditing = (invoiceId) => {
        if (editing.value !== "" && invoiceId) {
            window.alert("Vous devez sauvegarder la facture en cours d'étition avant d'étiter une autre facture");
            return;
        }
        editing.value = invoiceId;
    };
    const updateInvoice = async (billing) => {
        try {
            err.value.status = false;
            loading.value = true;
            const promise = await fetch(`${process.env.VUE_APP_SERVER}/billings/update`, {
                method: "PATCH",
                headers: fetchConfig,
                body: JSON.stringify({
                    billingId: billing._id,
                    name: billing.name,
                    link: billing.link
                })
            });

            const response = await promise.json();

            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }
            editing.value = "";
            getBillings();
        } catch (error) {
            if (error.status === 400) err.value.message = error.error;
            else err.value.message = "Une erreur est survenue durant la mise à jour de la facture";
            err.value.status = true;
        } finally {
            loading.value = false;
        }
    }
    const resetBillingsFilter = () => {
        billingQueryFilter.value = "";
        getBillings();
    };
    const getDate = (date) => {
        return new Date(date).toLocaleDateString("FR-fr")
    };

    // LIFECYCLE HOOKS
    onMounted(() => {
        getBillings();
    });
</script>
<style scoped>
    h6 {
        margin: 0;
    }
    section.card {
        min-height: 500px;
    }
    div.card-header {
        padding: 1rem;
    }
    #spinner-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #error-container {
        text-align: center;
    }
    .table-responsive {
        max-height: 60vh;
        overflow: auto;
    }
    thead {
        position: sticky;
        top: 0;
        background-color: white;
    }
    td {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    /* ADD BILLING AREA */
    #add-billing-button {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
    #add-billing-form {
        right: 10px;
        position: absolute;
        z-index: 2;
        min-width: 70%;
    }
    .billing-input-container {
        margin-bottom: 1rem;
    }
    #add-billing-form > button {
        text-align: center;
    }
    #billing-add-button-container {
        text-align: center;
    }
    #billing-filter-container {
        display: flex;
        padding: 1rem 0;
        justify-content: center;
        align-items: center;
    }
    #billing-filter-container > div {
        width: 55%;
        margin-right: 8px;
    }
    #apply-billing-filter-button {
        border-radius: 0.5rem 0 0 0.5rem;
        box-shadow: none;
        border: 2px solid #94E4AF;
    }
    #reset-filter-button {
        border-radius: 0 0.5rem 0.5rem 0;
        padding: 0.75rem;
        box-shadow: none;
        border: 2px solid #94E4AF;
    }
    svg {
        cursor: pointer;
    }
    #save-icon {
        margin-top: 2rem;
    }
</style>