<template>
    <div class="row w-100 p-4 g-0">
        <div class="col-lg-12 position-relative z-index-2">
            <div class="mb-4 card card-plain">
                <div class="p-3 card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <h2 class="mb-0 font-weight-bolder">Optimisation interne</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="card">
                        <div class="card-body">
                            <div id="notes-container">
                                <div v-if="err.status" class="text-center">
                                    {{ err.message }}
                                </div>
                                <div v-if="loading" class="text-center" >
                                    <div class="spinner-border text-center" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div v-if="!err.status && !loading">
                                    <div class="one-note-container" v-for="(note, index) in notesList" :key="index">
                                        <strong>Par: {{ note.createdByUser.fullName }}, le {{ getDate(note.createdAt) }}</strong>
                                        <p v-html="note.content"></p>
                                    </div>       
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted, ref } from "vue";
    import SoftButton from "@/components/SoftButton.vue";
    import Cookies from "js-cookie";

    const fetchConfig = {
        'Authorization': `Bearer ${Cookies.get('token')}`,
        "Content-Type": "application/json",
    };

    // STATE
    const err = ref({
        status: false,
        message: ""
    });
    const loading = ref(true);
    const notesList = ref([]);

    // METHODS
    const getNotes = async () => {
        try {
            loading.value = true;
            err.value.status = false;

            const promise = await fetch(`${process.env.VUE_APP_SERVER}/customer/optimization-notes/get-all`, {
                method: "GET",
                headers: fetchConfig
            });

            const response = await promise.json();
          
            if (promise.status !== 200) {
                throw {
                    status: promise.status,
                    ...response
                }
            }
            if (response.optimizationNotes.length === 0) {
                err.value.message = "Aucune optimisation trouvée...";
                err.value.status = true;
            }

            notesList.value = response.optimizationNotes;
        } catch (error) {
            console.log(error);
        } finally {
            loading.value = false;
        }
    };
    const getDate = (date) => {
        return new Date(date).toLocaleDateString("FR-fr")
    };

    // LIFECYCLE HOOKS
    onMounted(() => {
        getNotes();
    });
</script>
<style scoped>
    h6 {
        margin: 0;
    }
    section.card {
        min-height: 500px;
    }
    div.card-header {
        padding: 1rem;
    }
    #notes-container {
        max-height: 997px;
        overflow-y: auto;
        padding: 2rem;
    }
    #spinner-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #error-container {
        text-align: center;
    }
    .one-note-container {
        position: relative;
        padding: 10px;
    }
    .one-note-container p {
        margin-top: 1rem;
    }
    .one-note-container + .one-note-container {
        border-top: 1px solid rgb(0 0 0 / 8%);
    }
</style>