<template>
    <div class="publication-card-video">
        <video :src="video.link" preload="none" :poster="video?.thumbnail" :style="videoStyle()" controls></video>
    </div>
</template>
<script setup>
const props = defineProps({
    video: {
        type: Object,
        required: true
    },
    videoStyle: {
        type: Object,
        required: false,
        default: {
            borderRadius: false
        }
    }
});
const videoStyle = () => {
    const style = props.videoStyle;
    const videoStyle = {
        'border-radius' : style.borderRadius ? '10px' : 'none'
    };

    return videoStyle;
};


</script>
<style lang="scss" scoped>
.publication-card-video {
    height: 100%;
    width: 100%;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>